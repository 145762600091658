import React from 'react';
import ReactTable from 'react-table';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grid,
} from '@material-ui/core/';
import { observer } from 'mobx-react';
import './style.css';
import AppState from '../../state';
import EditICon from '@material-ui/icons/Edit';
import Settings from '@material-ui/icons/Settings';
import { browserHistory } from 'react-router';
import Form from './FormAddEditProject';
import { Utils } from '../../services';
import FormTipoProjeto from './FormTipoProjeto';

class Projetos extends React.Component {
  constructor(props) {
    super(props);
    this.basic_http_headers = {
      Authorization: AppState.token,
      'Content-Type': 'application/json; charset=utf-8',
    };
    this.state = {
      dialog: false,
      tipoProjetoDialog: false,
      edicao: false,
      showConfMenu: false,
      projetos: [],
      idProjeto: '',
    };
    this.getTableData();
  }

  getTableData = () => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/projetos/GetProjects.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          projetos: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  closeDialog = () => {
    if (this.state.dialog) {
      this.setState({
        dialog: false,
        edicao: false,
      });
    } else if (this.state.tipoProjetoDialog) {
      this.setState({
        tipoProjetoDialog: false,
      });
    }
  };

  openDialog = () => {
    this.setState({
      edicao: false,
      dialog: true,
    });
  };

  openTipoProjetoDialog = () => {
    this.setState({
      tipoProjetoDialog: true,
    });
  };

  openDialogEdicao = (projeto) => {
    this.setState({
      edicao: true,
      dialog: true,
      idProjeto: projeto,
    });
  };

  toggleConfMenu = () => {
    this.setState((state) => ({
      showConfMenu: !state.showConfMenu,
    }));
  };

  closeConfMenu = () => {
    this.setState((state) => ({
      showConfMenu: false,
    }));
  };

  render() {
    return (
      <div style={{ marginTop: '4em' }}>
        <Grid container alignItems={'center'}>
          <Grid item xs={6}>
            <h1>Cadastro de Projetos</h1>
          </Grid>
          <Grid item xs={6} style={{ zIndex: '2' }}>
            <div>
              <Button
                style={{ float: 'right' }}
                buttonRef={(node) => {
                  this.anchorEl = node;
                }}
                variant="outlined"
                aria-owns={
                  this.state.showConfMenu ? 'menu-list-zoom' : undefined
                }
                aria-haspopup="true"
                onClick={this.toggleConfMenu}
              >
                <Settings />
              </Button>
              <Popper
                open={this.state.showConfMenu}
                anchorEl={this.anchorEl}
                transition
                disablePortal
                placement="left-start"
                modifiers={{
                  arrow: {
                    enabled: true,
                    element: this.anchorEl,
                  },
                }}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{ transformOrigin: 'top right' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={this.closeConfMenu}>
                        <MenuList>
                          <MenuItem onClick={this.openTipoProjetoDialog}>
                            Tipos de Projetos
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            gap: '24px',
            justifyContent: 'flex-end',
            padding: '16px 0',
          }}
        >
          <Button variant="contained" color="primary" onClick={this.openDialog}>
            <AddIcon />
            Novo Projeto
          </Button>
        </Grid>

        <ReactTable
          filterable
          data={this.state.projetos}
          defaultPageSize={20}
          className="-striped -highlight"
          defaultFilterMethod={(filter, row) =>
            filter.id === 'dtInicio' || filter.id === 'dtEncerramento'
              ? Utils.dateToPT(String(row[filter.id])).startsWith(
                  filter.value.toLowerCase()
                )
              : String(row[filter.id])
                  .toLowerCase()
                  .startsWith(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: 'Projeto',
              columns: [
                {
                  Header: 'Nº',
                  accessor: 'id_projeto',
                  maxWidth: 50,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Nome',
                  accessor: 'desc_curta',
                  Cell: (row) => (
                    <div
                      style={{
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'tipo',
                  accessor: 'tipo_projeto',
                  maxWidth: 110,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'responsável',
                  accessor: 'nome_responsavel',
                  minWidth: 160,
                  Cell: (row) => (
                    <div
                      style={{
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Razão Social do Cliente',
                  accessor: 'razao_social',
                  minWidth: 160,
                  Cell: (row) => (
                    <div
                      style={{
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'CPF/CNPJ Cliente',
                  accessor: 'cpf_cnpj_cliente',
                  maxWidth: 160,
                  Cell: (row) => (
                    <div
                      style={{
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'inicio',
                  accessor: 'dt_inicio',
                  id: 'dtInicio',
                  width: 100,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'fim',
                  accessor: 'dt_encerramento',
                  id: 'dtEncerramento',
                  width: 100,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },

                {
                  Header: 'Ação',
                  accessor: 'status',
                  width: 96,
                  filterable: false,
                  Cell: (row) => (
                    <div className="action-cell" style={{ paddingLeft: '0px' }}>
                      {
                        <EditICon
                          onClick={() =>
                            this.openDialogEdicao(row.original['id_projeto'])
                          }
                          style={{
                            cursor: 'pointer',
                            width: '22px',
                            height: '22px',
                            minWidth: '22px',
                            minHeight: '22px',
                            marginLeft: '5px',
                          }}
                        />
                      }
                    </div>
                  ),
                },
              ],
            },
          ]}
        />

        <Form
          open={this.state.dialog}
          close={this.closeDialog}
          edicao={this.state.edicao}
          carregaTabela={this.getTableData}
          idProjeto={this.state.idProjeto}
        />

        <FormTipoProjeto
          open={this.state.tipoProjetoDialog}
          close={this.closeDialog}
        />
      </div>
    );
  }
}

export default observer(Projetos);
