import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core/";

import readXlsxFile from "read-excel-file";
import { browserHistory } from "react-router";
import moment from "moment";

import AppState from "../../../state";
import Utils from "../../../services/utils";
import ExportXLS from "../../../components/lib/ExportXLS";

import {
  addDespesaPessoalFuncionario,
  getDespesasPessoaisFuncionarios
} from "../../../api/funcionarios";
import ImportButton from "../../../components/lib/ImportButton";

const getDadosExportacao = () => {};

const FuncionariosDespesaPessoalEconomica = props => {
  const currentDate = new Date();
  const [despesas, setDespesas] = useState([]);
  const mes = currentDate.getMonth() + 1;
  const [filterData, setFilterData] = useState({
    ano: currentDate.getFullYear(),
    mes: `${mes}`.padStart(2, "0")
  });
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      AppState.isLoading = true;
      const { mes, ano } = filterData;
      const despesas = await getDespesasPessoaisFuncionarios(
        `${ano}-${mes}-01`
      );
      AppState.isLoading = false;
      setDespesas(despesas);
    };

    fetchData();
  }, [shouldUpdate, filterData]);

  const importarPlanilha = async ev => {
    const input = ev.target;
    const file = input.files[0];
    let funcionarios = [];

    AppState.isLoading = true;
    try {
      const planilhaConfiguracao = await readXlsxFile(file, {
        sheet: "CONFIGURAÇÃO"
      });
      const data_referencia = moment(planilhaConfiguracao[0][1], "DD/MM/YYYY");
      const despesas = await getDespesasPessoaisFuncionarios(
        data_referencia.set("date", 1).format("YYYY-MM-DD")
      );

      if (
        despesas.length > 0 &&
        !window.confirm(
          `Já existem registros para o mês de referência ${data_referencia.format(
            "MM/YYYY"
          )}. Os mesmos serão excluídos. Deseja continuar?`
        )
      ) {
        return false;
      }

      const sheet = await readXlsxFile(file, { sheet: "DESPESAS" });
      const [, header, , ...data] = sheet;
      const rows = data.filter(row => row[1]);

      const mapContas = header.reduce((acc, value, index) => {
        if (value && value.includes("2.1")) {
          acc[value] = index;
        }
        return acc;
      }, {});

      const total = rows.length;
      for (let rowIdx = 0; rowIdx < total; rowIdx++) {
        const funcionario = {
          cpf_cnpj_contraparte: rows[rowIdx][1], //coluna do CPF
          tipo_contraparte:
            rows[rowIdx][2].toLowerCase() === "não" ? "funcionario" : "pj",
          contas: [],
          valor_total_pessoal_base: rows[rowIdx][44],
          valor_total_despesa: rows[rowIdx][45],
          valor_total_beneficios: rows[rowIdx][46],
          valor_total_encargos: rows[rowIdx][47],
          valor_total_custo: String(parseFloat(rows[rowIdx][44]) + parseFloat(rows[rowIdx][46]) + parseFloat(rows[rowIdx][47])),
          valor_total_remuneracao_liquida: rows[rowIdx][49]
        };

        for (let conta in mapContas) {
          funcionario.contas = funcionario.contas.concat({
            id_conta: conta,
            valor: rows[rowIdx][mapContas[conta]]
          });
        }

        funcionarios = funcionarios.concat(funcionario);
      }

      addDespesaPessoalFuncionario({
        data_referencia: data_referencia.format("DD/MM/YYYY"),
        funcionarios
      })
        .then(() => {
          AppState.isLoading = false;
          alert("Despesas importadas com sucesso!");
          setShouldUpdate(true);
        })
        .catch(function(error) {
          AppState.isLoading = false;
          if (
            error.toString() === "" ||
            error.toString().split(" ").length < 2
          ) {
            alert("Erro desconhecido, tente novamente");
            return;
          }
          var Err_status = error.toString().split(" ")[1];
          if (Err_status === "400") {
            const [, ...ret_message] = error.response["status"].value.split(
              " "
            );
            alert(ret_message.join(" "));
          } else if (Err_status === "401") {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status === "500") {
            alert("Erro desconhecido, tente novamente");
          }
        });
    } catch (error) {
      AppState.isLoading = false;
      alert("Erro durante o processamento da planilha");
      console.error(error);
    }
  };

  return (
    <div style={{ marginTop: "5em" }}>
      <h1>Despesas de Pessoais - Modelo Econômico</h1>

      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ marginTop: "30px", marginBottom: "10px" }}
      >
        <Grid item>
          <FormControl
            component="fieldset"
            style={{ width: "120px", paddingTop: "13px", paddingRight: "10px" }}
          >
            <InputLabel> Mês de lançamento </InputLabel>
            <Select
              value={filterData.mes}
              onChange={ev =>
                setFilterData({ ...filterData, mes: ev.target.value })
              }
            >
              <MenuItem value="01">Janeiro</MenuItem>
              <MenuItem value="02">Fevereiro</MenuItem>
              <MenuItem value="03">Março</MenuItem>
              <MenuItem value="04">Abril</MenuItem>
              <MenuItem value="05">Maio</MenuItem>
              <MenuItem value="06">Junho</MenuItem>
              <MenuItem value="07">Julho</MenuItem>
              <MenuItem value="08">Agosto</MenuItem>
              <MenuItem value="09">Setembro</MenuItem>
              <MenuItem value="10">Outubro</MenuItem>
              <MenuItem value="11">Novembro</MenuItem>
              <MenuItem value="12">Dezembro</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            component="fieldset"
            style={{ width: "100px", paddingTop: "13px" }}
          >
            <InputLabel> Ano de lançamento </InputLabel>
            <Select
              value={filterData.ano}
              onChange={ev =>
                setFilterData({ ...filterData, ano: ev.target.value })
              }
            >
              {[
                currentDate.getFullYear() - 1,
                currentDate.getFullYear(),
                currentDate.getFullYear() + 1
              ].map((year, index) => (
                <MenuItem value={year} key={index}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container justify={"flex-end"} style={{ marginBottom: "8px" }}>
            <Grid item style={{ paddingBottom: "0px" }}>
              <ImportButton
                importButtonText={"Importar Despesas Pessoais"}
                onImport={importarPlanilha}
                templateUrl={`${process.env.PUBLIC_URL}/${AppState.serverAddr}/arquivos/despesas_pessoal_economico.xlsx`}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ReactTable
            style={{ marginTop: "5px" }}
            filterable
            defaultPageSize={10}
            data={despesas}
            defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                    .toLowerCase()
                    .startsWith(filter.value.toLowerCase())
            }
            columns={[
              {
                Header: "CPF",
                accessor: "cpf_cnpj_contraparte",
                maxWidth: 150,
                Cell: row => (
                  <div
                    style={{
                      textAlign: "center",
                      position: "relative",
                      top: "30%"
                    }}
                  >
                    {row.value}
                  </div>
                )
              },
              {
                Header: "Nome",
                accessor: "nome_funcionario",
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {row.value}
                  </div>
                )
              },
              {
                Header: "Total Salário",
                accessor: "vlr_total_salario",
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {Utils.float2moeda(row.value)}
                  </div>
                ),

                Footer: row => (
                  <span
                    style={{
                      textAlign: "center"
                    }}
                  >
                    {"R$ " +
                      Utils.float2moeda(
                        row.data.reduce(
                          (total, { vlr_total_salario = 0 }) =>
                            (total += parseFloat(vlr_total_salario)),
                          0
                        )
                      )}
                  </span>
                )
              },
              {
                Header: "Total Encargos",
                accessor: "vlr_total_encargos",
                maxWidth: 190,
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {Utils.float2moeda(row.value)}
                  </div>
                ),
                Footer: row => (
                  <span
                    style={{
                      textAlign: "center"
                    }}
                  >
                    {// Get the total of the price
                    "R$ " +
                      Utils.float2moeda(
                        row.data.reduce(
                          (total, { vlr_total_encargos = 0 }) =>
                            (total += parseFloat(vlr_total_encargos)),
                          0
                        )
                      )}
                  </span>
                )
              },
              {
                Header: "Total Benefícios",
                accessor: "vlr_total_beneficios",
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {Utils.float2moeda(row.value)}
                  </div>
                ),
                Footer: row => (
                  <span
                    style={{
                      textAlign: "center"
                    }}
                  >
                    {// Get the total of the price
                    "R$ " +
                      Utils.float2moeda(
                        row.data.reduce(
                          (total, { vlr_total_beneficios = 0 }) =>
                            (total += parseFloat(vlr_total_beneficios)),
                          0
                        )
                      )}
                  </span>
                )
              },
              {
                Header: "Total Despesas",
                accessor: "vlr_total_despesas",
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {Utils.float2moeda(row.value)}
                  </div>
                ),
                Footer: row => (
                  <span
                    style={{
                      textAlign: "center"
                    }}
                  >
                    {// Get the total of the price
                    "R$ " +
                      Utils.float2moeda(
                        row.data.reduce(
                          (total, { vlr_total_despesas = 0 }) =>
                            (total += parseFloat(vlr_total_despesas)),
                          0
                        )
                      )}
                  </span>
                )
              },
              {
                Header: "Custo Total",
                accessor: "vlr_total_custo",
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {Utils.float2moeda(row.value)}
                  </div>
                ),
                Footer: row => (
                  <span
                    style={{
                      textAlign: "center"
                    }}
                  >
                    {// Get the total of the price
                    "R$ " +
                      Utils.float2moeda(
                        row.data.reduce(
                          (total, { vlr_total_custo = 0 }) =>
                            (total += parseFloat(vlr_total_custo)),
                          0
                        )
                      )}
                  </span>
                )
              },
              {
                Header: "Remuneração Líquida",
                accessor: "vlr_total_remuneracao_liquida",
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {Utils.float2moeda(row.value)}
                  </div>
                ),
                Footer: row => (
                  <span
                    style={{
                      textAlign: "center"
                    }}
                  >
                    {// Get the total of the price
                    "R$ " +
                      Utils.float2moeda(
                        row.data.reduce(
                          (total, { vlr_total_remuneracao_liquida = 0 }) =>
                            (total += parseFloat(
                              vlr_total_remuneracao_liquida
                            )),
                          0
                        )
                      )}
                  </span>
                )
              }
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FuncionariosDespesaPessoalEconomica;
