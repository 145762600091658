import React from "react";
import { 
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ReactTable from "react-table";
import Form from "./Form";
import AppState from "../../../state";
import { browserHistory } from "react-router";
import { Utils } from "../../../services";

import Tooltip from "../../../components/lib/Tooltip";
import { VictoryTooltip,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
} from "victory";


class RelatorioAvaliacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edicao: false,
      dialog: false,
      avaliacoes: [],
      avaliacoes_bkp: [],
      gradeFilter: [1, 2, 3, 4, 5],
      dt_plano_acao: new Date().getMonth() > 6 ? new Date().getFullYear() + '-07-01' : new Date().getFullYear() + '-01-01',
      semesterOptions: [],
      chartData: [{x: 1, y: 0}, {x: 2, y: 0}, {x: 3, y: 0}, {x: 4, y: 0}, {x: 5, y: 0}]
    };
  }

  componentDidMount() {
    this.getTableData();
    this.getSemesterOptions();
  }

  openEdicaoDialog = (cnpj_fornecedor, nome_fornecedor) => {
    this.setState({
      edicao: true,
      dialog: true,
      cnpj_fornecedor: cnpj_fornecedor,
      nome_fornecedor: nome_fornecedor,
      dt_plano_acao: this.state.dt_plano_acao
    });
  };

  dateHandleChange = (event) => {
    this.setState({dt_plano_acao: event.target.value}, () => {this.getTableData();});
  }

  gradeFilterHandleChange = (event) => {
    this.setState({
      gradeFilter: event.target.value,
      avaliacoes: this.state.avaliacoes_bkp.filter((val) => {
        return event.target.value.includes(parseInt(val.avaliacao_fornecedor));
      })
    });
  }

  getSemesterOptions = () => {
    let thisMonth = (new Date()).getMonth();
    let thisYear = (new Date()).getFullYear();
    let semesterOptions = [];
    if (thisMonth > 5) {
      semesterOptions.push(['02/' + thisYear, thisYear + '-07-01']);
    }
    semesterOptions.push(['01/' + thisYear, thisYear + '-01-01']);
    for (let i = 1; i < 5; i++) {
      semesterOptions.push(['02/' + (thisYear - i), (thisYear - i) + '-07-01']);
      semesterOptions.push(['01/' + (thisYear - i), (thisYear - i) + '-01-01']);
    }
    this.setState({semesterOptions: semesterOptions})
  }

  reduceEvaluationData = (data) => {
    let response = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0
    };
    for (let i = 0; i < data.length; i++) {
      let actual = data[i];
      response[parseInt(actual.avaliacao_fornecedor)] = response[parseInt(actual.avaliacao_fornecedor)] + 1;
    }
    if (data.length == 0) {
      return [{x: 1, y: 0}, {x: 2, y: 0}, {x: 3, y: 0}, {x: 4, y: 0}, {x: 5, y: 0}];
    }
    return Object.keys(response).map((key) => {return {x: key, y: response[key]}});
  }

  getTableData = () => {
    AppState.isLoading = true;

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/fornecedores/GetAvaliacoes.php?json={"dt_plano_acao":"${this.state.dt_plano_acao}"}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: AppState.token
        }
      }
    )
      .then(response => {
        if (!response.ok) {
          return response.json().then(json => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then(response => {
        this.setState(
          {
            avaliacoes: response.request,
            avaliacoes_bkp: response.request,
            chartData: this.reduceEvaluationData(response.request)
          },
          () => (AppState.isLoading = false)
        );
      })
      .catch(function(error) {
        AppState.isLoading = false;
        if (error.toString() == "" || error.toString().split(" ").length < 2) {
          alert("Erro desconhecido, tente novamente");
          return;
        }
        var Err_status = error.toString().split(" ")[1];
        if (Err_status == 400) {
          var ret = error.response["status"].value.split(" ")[0];
          if (ret == "40001" || ret == "40002") {
            console.log("Dados invalidos/não informados");
          }
          else alert("Dados inválidos");
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert("Erro desconhecido, tente novamente");
        }
      });
  };

  closeDialog = () => {
    this.setState({
      dialog: false
    });
  };

  canSeeActionPlan = () =>{
    var menu =  JSON.parse(localStorage.getItem('Menu'));
    if(!menu || !menu.views)
      return false;
    var view = menu.views.find(elem => elem.id_view==='relatorio_avaliacoes');
    if (!view)
      return false;
    var func = view.funcionalidades;
    if(!func)
      return false;
    if(func.find(elem => elem.id_funcionalidade==='plano_acao_fornecedor'))
      return true;
    else
      return false;

  }

  render() {
    return (
      <div id={this.constructor.name} style={{ marginTop: "5em" }}>
        <Grid item sm={5}>
          <h1>Relatório de Fornecedores</h1>
        </Grid>
        <Grid
          container
          spacing={32}
          style={{ marginTop: "15px" }}
        >
          <Grid item>
            <FormControl>
              <InputLabel>Avaliações</InputLabel>
              <Select
                multiple
                style={{ marginBottom: "14px", minWidth: "156px" }}
                name={"gradeFilter"}
                value={this.state.gradeFilter}
                onChange={this.gradeFilterHandleChange}
              >
                <MenuItem key={0} value={1}>
                  1
                </MenuItem>
                <MenuItem key={1} value={2}>
                  2
                </MenuItem>
                <MenuItem key={2} value={3}>
                  3
                </MenuItem>
                <MenuItem key={3} value={4}>
                  4
                </MenuItem>
                <MenuItem key={4} value={5}>
                  5
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              component="fieldset"
              style={{ marginBottom: "14px", minWidth: "156px" }}
            >
              <InputLabel>Avaliações</InputLabel>
              <Select
                style={{ marginBottom: "14px", minWidth: "156px" }}
                name={"date"}
                value={this.state.dt_plano_acao}
                onChange={this.dateHandleChange}
              >
                {this.state.semesterOptions.map((val, index) => {
                  return (
                    <MenuItem key={index} value={val[1]}>
                      {val[0]}
                    </MenuItem>    
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          // spacing={32}
          style={{ marginTop: "15px", marginBottom: "50px", textAlign:"center", height: '300px'}}
        >
          <h3>Distribuição de avaliações</h3>
          <VictoryChart
            animate={{duration: this.state.animation}}
            domainPadding={{x: [20, 20]}}
            padding={{left: 50, right: 20, top: 30, bottom: 30}}
            theme={VictoryTheme.material}
            width={500}
            height={300}
          >
            <VictoryBar 
              data={this.state.chartData.map(val => {
                return {
                  'x': val.x, 
                  'y': val.y,
                  'yLabel': val.y,
                  'subtitle': 'Nota ' + val.x
                }})}
              style={{ data: { fill: "#528e70" } }}
              labelComponent={<VictoryTooltip flyoutComponent={<Tooltip/>} />}
              labels={() => " "}
            />
            <VictoryAxis dependentAxis
              tickFormat={(x) => x.toFixed(0)}
            />
            <VictoryAxis 
              // style={{ 
              //   tickLabels: {padding: 30, angle: 90}
              // }}
              tickFormat={(x) => parseFloat(x).toFixed(0)}
            />
          </VictoryChart>
        </Grid>
        <Grid
          item
          spacing={32}
          style={{ marginTop: "15px", textAlign:"center"}}
        >
          <h3>Avaliações realizadas</h3>
          <ReactTable
            filterable
            data={this.state.avaliacoes}
            defaultPageSize={20}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .startsWith(filter.value.toLowerCase())
            }
            columns={[
              {
                Header: "Data Pagamento",
                accessor: "dt_pag_recb",
                width: 150
              },
              {
                Header: "Fornecedor",
                accessor: "razao_social",
                width: 150,
                id: "fornecedor",
                Cell: row => (
                  <div
                    className="action-cell"
                    style={{ textAlign: "center" }}
                  >
                    {row.value}
                  </div>
                )
              },
              {
                Header: "CNPJ / CPF",
                accessor: "cpf_cnpj_contraparte",
                width: 140
              },
              {
                Header: "Descrição",
                accessor: "descricao",
              },
              {
                Header: "Valor",
                accessor: "valor",
                width: 120,
                Footer: row => (
                  <span>
                    {// Get the total of the price
                    "R$ " +
                      Utils.float2moeda(
                        row.data.reduce(
                          (total, { valor }) => (total += parseFloat(valor)),
                          0
                        )
                      )}
                  </span>
                ),
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      position: "relative"
                    }}
                  >
                    {"R$ " + Utils.float2moeda(row.value)}
                  </div>
                ),
                sortMethod: (a, b) => Number(a)-Number(b)
              },
              {
                Header: "Avaliação",
                accessor: "avaliacao_fornecedor",
                width: 150
              },
              {
                Header: "Ação",
                filterable: false,
                accessor: "action",
                // show: ()=>this.canEditContrato(),
                width: 50,
                Cell: row => (
                  <div className="action-cell" style={{ padding: "0px" }}>
                    {
                      <EditIcon
                        onClick={() =>
                          this.openEdicaoDialog(row.original["cpf_cnpj_contraparte"], row.original['razao_social'])
                        }
                        style={{
                          cursor: "pointer",
                          width: "22px",
                          height: "22px",
                          minWidth: "22px",
                          minHeight: "22px",
                          marginLeft: "5px"
                        }}
                      />
                    }
                  </div>
                )
              }
            ]}
          />
        </Grid>

        {this.state.dialog && (
          <Form
            open={this.state.dialog}
            close={this.closeDialog}
            cnpj_fornecedor={this.state.cnpj_fornecedor}
            nome_fornecedor={this.state.nome_fornecedor}
            dt_plano_acao={this.state.dt_plano_acao}
          />
        )}
      </div>
    );
  }
}

export default RelatorioAvaliacao;
