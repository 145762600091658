import React                from 'react';
import ReactDOM             from 'react-dom';
import MuiThemeProvider     from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';


import { ServiceWorker}     from './services';
import Main                 from './Main'
import 'cropperjs/dist/cropper.css';
import './main.css';
import 'whatwg-fetch';
import Events from 'minivents';

const ROOT_ELEMENT_ID  = 'root';
const RootElement      =  document.getElementById(ROOT_ELEMENT_ID);

const theme = createMuiTheme({
  typography: {
    fontSize: 20,
    useNextVariants: true
  },
});



window.events = new Events();


ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Main />
  </MuiThemeProvider>
, RootElement);
ServiceWorker();