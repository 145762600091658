import React from 'react';
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import ReactTable from 'react-table';
import { Utils } from '../../../services';
import AppState from '../../../state';
import { browserHistory } from 'react-router';
import CurrencyInput from '../../../components/lib/CurrencyInput';
import _ from 'lodash';

class CapitalGiro extends React.Component {
  constructor(props) {
    super(props);

    this.reactTable = React.createRef();

    this.state = {
      month: new Date().getMonth() == 12 ? 0 : new Date().getMonth() + 1,
      year:
        new Date().getMonth() == 12
          ? new Date().getFullYear() + 1
          : new Date().getFullYear(),
      table_data: [],
      analysis: 'accounts',
      defaultExpandedRows: [],
      expanded: [],
    };
    this.renderEditableObs = this.renderEditableObs.bind(this);
    this.renderEditableProjecao = this.renderEditableProjecao.bind(this);
  }

  componentDidMount() {
    this.getTableData();
  }

  getTableData = function () {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetCapitalDeGiro.php?json={"dt_referencia":"${this.state.year}-${this.state.month}-01"}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            table_data: this.formatData(response.request),
            defaultExpandedRows: new Array(20).map((element, index) => {
              return { [index]: true };
            }),
          },
          () => {
            AppState.isLoading = false;
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  formatData = (data) => {
    let allExpenses = {
      id_conta: '2.1',
      display: '2.1 - PESSOAL',
      valor_projetado: 0.0,
      valor_realizado: 0.0,
    };
    let result = data.map((obj) => {
      obj.valor_realizado = parseFloat(obj.valor_realizado)
        ? parseFloat(obj.valor_realizado)
        : 0.0;
      obj.valor_projetado = parseFloat(obj.valor_projetado)
        ? parseFloat(obj.valor_projetado)
        : 0.0;
      if (obj.id_conta.startsWith('2.1.')) {
        allExpenses.valor_projetado += obj.valor_projetado;
        allExpenses.valor_realizado += obj.valor_realizado;
      }
      return obj;
    });
    result.push(allExpenses);
    result = result.sort(function (a, b) {
      let a_split = a.id_conta.split('.');
      let b_split = b.id_conta.split('.');
      if (a_split[0] == b_split[0]) {
        if (a_split[1] == b_split[1]) {
          if (a_split.length !== b_split.length) {
            return a_split.length - b_split.length;
          }
          return parseInt(a_split[2]) - parseInt(b_split[2]);
        }
        return parseInt(a_split[1]) - parseInt(b_split[1]);
      }
      return parseInt(a_split[0]) - parseInt(b_split[0]);
    });
    return result;
  };

  periodHandleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.getTableData();
      }
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  updateObservacao = (id_observacao, observacao) => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      id_capital_observacao: id_observacao,
      observacao: observacao,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/EditCapitalObservacao.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }
      })
      .then(() => {
        this.getTableData();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40002') {
            console.log('40002 - Erro na atualização dos Titulos');
            alert('Erro na atualização dos Titulos');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  insertObservacao = (id_conta, observacao) => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      id_conta: id_conta,
      observacao: observacao,
      dt_observacao: `${this.state.year}-${this.state.month}-01`,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/AddCapitalObservacao.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }
      })
      .then(() => {
        this.getTableData();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40002') {
            console.log('40002 - Erro na atualização dos Titulos');
            alert('Erro na atualização dos Titulos');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  updateProjecao = (id_projecao, valor_projecao) => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      id_projecao: id_projecao,
      valor: valor_projecao,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/EditCapitalProjecao.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }
      })
      .then(() => {
        AppState.isLoading = false;
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40002') {
            console.log('40002 - Erro na atualização dos Titulos');
            alert('Erro na atualização dos Titulos');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  insertProjecao = (id_projeto, id_conta, valor) => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      id_projeto: id_projeto,
      id_conta: id_conta,
      valor: valor,
      dt_projecao: `${this.state.year}-${this.state.month}-01`,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/AddCapitalProjecao.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }
      })
      .then(() => {
        AppState.isLoading = false;
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40002') {
            console.log('40002 - Erro na atualização dos Titulos');
            alert('Erro na atualização dos Titulos');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  renderEditableObs(cellInfo) {
    let index = cellInfo.subRows[0]._index;
    return (
      <div
        style={{ backgroundColor: '#fafafa', whiteSpace: 'pre-wrap' }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          this.finishObservacao(cellInfo, e);
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.table_data[index]
            ? this.state.table_data[index][cellInfo.column.id]
            : '',
        }}
      />
    );
  }

  renderEditableProjecao(cellInfo) {
    let index = cellInfo.index;
    return (
      <CurrencyInput
        style={{ backgroundColor: '#fafafa' }}
        contentEditable
        suppressContentEditableWarning
        allowNegative={true}
        onBlur={(e) => {
          this.finishProjecao(cellInfo, e);
        }}
        value={this.state.table_data[index][cellInfo.column.id]}
      />
    );
  }

  finishObservacao = (row, event) => {
    let id_observacao = row.subRows[0]._original.id_capital_observacao;
    let id_conta = row.subRows[0]._original.id_conta;
    let observacao = event.target.innerHTML;
    if (id_observacao) {
      return this.updateObservacao(id_observacao, observacao);
    }
    return this.insertObservacao(id_conta, observacao);
  };

  finishProjecao = (row, event) => {
    let id_capital_projecao = row.original.id_projecao;
    let id_projeto = row.original.id_projeto;
    let id_conta = row.original.id_conta;
    let valor = Utils.moeda2float(event.target.value);
    if (id_capital_projecao) {
      return this.updateProjecao(id_capital_projecao, valor);
    }
    return this.insertProjecao(id_projeto, id_conta, valor);
  };

  handleTableCellClick = (state, rowInfo, column, instance, ...rest) => {
    if (rowInfo) {
      if (rowInfo.row.display.startsWith('2.1 - ')) {
        const needsExpander =
          rowInfo.subRows && rowInfo.subRows.length > 1 ? true : false;
        const expanderEnabled = !column.disableExpander;
        const expandedRows = Object.keys(this.state.expanded)
          .filter((expandedIndex) => {
            return this.state.expanded[expandedIndex] !== false;
          })
          .map(Number);

        const rowIsExpanded =
          expandedRows.includes(rowInfo.nestingPath[0]) && needsExpander
            ? true
            : false;
        const newExpanded = !needsExpander
          ? this.state.expanded
          : rowIsExpanded && expanderEnabled
          ? {
              ...this.state.expanded,
              [rowInfo.nestingPath[0]]: false,
            }
          : {
              ...this.state.expanded,
              [rowInfo.nestingPath[0]]: {},
            };

        return {
          style:
            needsExpander && expanderEnabled
              ? { cursor: 'pointer' }
              : { cursor: 'auto' },
          onClick: (e, handleOriginal) => {
            this.setState({
              expanded: newExpanded,
            });
          },
        };
      }
    }
    return {
      onClick: (e, handleOriginal) => {
        if (handleOriginal) {
          handleOriginal();
        }
      },
    };
  };

  render() {
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <h1>Capital de Giro</h1>

        <Grid
          container
          justify="space-between"
          style={{ marginTop: '16px', marginBottom: '8px' }}
        >
          <Grid item>
            <Grid container justify="flex-start" style={{ marginTop: '30px' }}>
              <Grid item>
                <FormControl
                  component="fieldset"
                  style={{
                    width: '200px',
                    paddingTop: '15px',
                    marginLeft: '15px',
                  }}
                >
                  <InputLabel htmlFor={'year'}> Ano </InputLabel>
                  <Select
                    inputProps={{ id: 'year', name: 'year' }}
                    style={{ margin: '0.5em 0px' }}
                    value={this.state.year}
                    onChange={this.periodHandleChange}
                  >
                    <MenuItem value={new Date().getFullYear() - 1}>
                      {new Date().getFullYear() - 1}
                    </MenuItem>
                    <MenuItem value={new Date().getFullYear()}>
                      {new Date().getFullYear()}
                    </MenuItem>
                    <MenuItem value={new Date().getFullYear() + 1}>
                      {new Date().getFullYear() + 1}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  component="fieldset"
                  style={{
                    width: '200px',
                    paddingTop: '15px',
                    marginLeft: '15px',
                  }}
                >
                  <InputLabel htmlFor={'month'}> Mês </InputLabel>
                  <Select
                    inputProps={{ id: 'month', name: 'month' }}
                    style={{ margin: '0.5em 0px' }}
                    value={this.state.month}
                    onChange={this.periodHandleChange}
                  >
                    <MenuItem value={1}>Janeiro</MenuItem>
                    <MenuItem value={2}>Fevereiro</MenuItem>
                    <MenuItem value={3}>Março</MenuItem>
                    <MenuItem value={4}>Abril</MenuItem>
                    <MenuItem value={5}>Maio</MenuItem>
                    <MenuItem value={6}>Junho</MenuItem>
                    <MenuItem value={7}>Julho</MenuItem>
                    <MenuItem value={8}>Agosto</MenuItem>
                    <MenuItem value={9}>Setembro</MenuItem>
                    <MenuItem value={10}>Outubro</MenuItem>
                    <MenuItem value={11}>Novembro</MenuItem>
                    <MenuItem value={12}>Dezembro</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ReactTable
          filterable={true}
          data={this.state.table_data}
          defaultPageSize={20}
          className="-striped -highlight"
          getTdProps={this.handleTableCellClick}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .startsWith(filter.value.toLowerCase())
          }
          pivotBy={['display']}
          expanded={this.state.expanded}
          onExpandedChange={(newExpanded) => {
            if (
              newExpanded.filter((x) => x).length <
              this.state.expanded.filter((x) => x).length
            )
              this.getTableData();
            this.setState({
              expanded: newExpanded,
            });
          }}
          columns={[
            {
              Header: ' ',
              columns: [
                {
                  Header: 'Conta',
                  accessor: 'display',
                  width: 300,
                  PivotValue: ({ value }) => <span>{value}</span>,
                },
              ],
            },
            {
              Header: 'Capital de Giro',
              columns: [
                {
                  Header: 'Projeto',
                  accessor: 'id_projeto',
                  width: 60,
                  aggregate: (values) => _.round(_.sum(values)),
                  Cell: (row) => {
                    return <span>{row.aggregated ? `` : row.value}</span>;
                  },
                },
                {
                  Header: 'Valor Realizado',
                  accessor: 'valor_realizado',
                  width: 110,
                  aggregate: (values) => _.round(_.sum(values)),
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      {'R$ ' + Utils.float2moeda(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Valor Projetado',
                  accessor: 'valor_projetado',
                  width: 110,
                  aggregate: (values) => _.round(_.sum(values)),
                  Cell: (row) => {
                    if (row.aggregated && (row.value || row.value === 0)) {
                      return (
                        <div
                          style={{
                            height: '22px',
                            verticalAlign: 'middle',
                            position: 'relative',
                          }}
                        >
                          {'R$ ' + Utils.float2moeda(row.value)}
                        </div>
                      );
                    } else if (row.value || row.value === 0) {
                      return this.renderEditableProjecao(row);
                    }
                    return '';
                  },
                },
                {
                  Header: 'Observação',
                  accessor: 'observacao',
                  width: 600,
                  aggregate: (values) => [...new Set(values)],
                  Cell: (row) => {
                    if (row.aggregated) {
                      if (row.row.display.startsWith('2.1 - ')) {
                        return '';
                      }
                      return this.renderEditableObs(row);
                    }
                    return '';
                  },
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
}

export default CapitalGiro;
