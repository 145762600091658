import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl, Grid, TextField, Tab, Tabs } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import AppState from '../../../state';
import { browserHistory } from 'react-router';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { Utils } from '../../../services';
import ReactTable from 'react-table';

import {
  VictoryStack,
  VictoryLabel,
  VictoryTheme,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLegend,
} from 'victory';

const valueThreshold = 10;

class RelatorioConciliacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      period: 'EsseMes',
      dtInicio: moment().startOf('month').format('YYYY-MM-DD'),
      dtFim: moment().endOf('month').format('YYYY-MM-DD'),
      bankFilter: [],
      percentual_conciliado: 0,
      initial: true,
      data_receita_conciliada: [
        { x: 'Ambgest', y: 0 },
        { x: 'Banco', y: 0 },
      ],
      data_receita_nao_conciliada: [
        { x: 'Ambgest', y: 0 },
        { x: 'Banco', y: 0 },
      ],
      data_despesa_conciliada: [
        { x: 'Ambgest', y: 0 },
        { x: 'Banco', y: 0 },
      ],
      data_despesa_nao_conciliada: [
        { x: 'Ambgest', y: 0 },
        { x: 'Banco', y: 0 },
      ],
      tableData: [],
      chosen_visualization: 'grafico',
      income_table_data: [
        { nome: 'Entradas Ambgest', verificada: 0, nao_verificada: 0 },
        { nome: 'Entradas Banco', verificada: 0, nao_verificada: 0 },
      ],
      outcome_table_data: [
        { nome: 'Saídas Ambgest', verificada: 0, nao_verificada: 0 },
        { nome: 'Saídas Banco', verificada: 0, nao_verificada: 0 },
      ],
    };

    this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
  }

  _debouncedHandleChange = () => {
    this.getData();
  };

  handleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.getData();
      }
    );
  };

  visualizationHandleChange = (undefined, newValue) => {
    this.setState({
      chosen_visualization: newValue,
    });
  };

  getData = () => {
    if (this.state.bankFilter == '') {
      alert('Por favor selecione um banco para visualizar os dados.');
      return;
    } else {
      this.getChartsData();
      this.getTableData();
    }
  };

  getChartsData = function () {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/relatorios/GetIndicadoresConciliacao.php?json={"dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}", "banco":"${this.state.bankFilter}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        let receita_nao_conciliada_banco = parseFloat(
          response.request[0].receita_n_conciliada_banco
        );
        let receita_conciliada_banco = parseFloat(
          response.request[0].receita_conciliada_banco
        );
        let despesa_nao_conciliada_banco = parseFloat(
          response.request[0].despesa_n_conciliada_banco
        );
        let despesa_conciliada_banco = parseFloat(
          response.request[0].despesa_conciliada_banco
        );

        let receita_nao_conciliada_ambgest = parseFloat(
          response.request[0].receita_n_conciliada_ambgest
        );
        let receita_conciliada_ambgest = parseFloat(
          response.request[0].receita_conciliada_ambgest
        );
        let despesa_nao_conciliada_ambgest = parseFloat(
          response.request[0].despesa_n_conciliada_ambgest
        );
        let despesa_conciliada_ambgest = parseFloat(
          response.request[0].despesa_conciliada_ambgest
        );
        this.setState(
          {
            data_receita_conciliada: [
              {
                x: 'Ambgest',
                y: receita_conciliada_ambgest
                  ? receita_conciliada_ambgest
                  : 0.0,
              },
              {
                x: 'Banco',
                y: receita_conciliada_banco ? receita_conciliada_banco : 0.0,
              },
            ],
            data_despesa_conciliada: [
              {
                x: 'Ambgest',
                y: despesa_conciliada_ambgest
                  ? despesa_conciliada_ambgest
                  : 0.0,
              },
              {
                x: 'Banco',
                y: despesa_conciliada_banco ? despesa_conciliada_banco : 0.0,
              },
            ],
            data_receita_nao_conciliada: [
              {
                x: 'Ambgest',
                y: receita_nao_conciliada_ambgest
                  ? receita_nao_conciliada_ambgest
                  : 0.0,
              },
              {
                x: 'Banco',
                y: receita_nao_conciliada_banco
                  ? receita_nao_conciliada_banco
                  : 0.0,
              },
            ],
            data_despesa_nao_conciliada: [
              {
                x: 'Ambgest',
                y: despesa_nao_conciliada_ambgest
                  ? despesa_nao_conciliada_ambgest
                  : 0.0,
              },
              {
                x: 'Banco',
                y: despesa_nao_conciliada_banco
                  ? despesa_nao_conciliada_banco
                  : 0.0,
              },
            ],
            initial: false,
            income_table_data: [
              {
                nome: 'Entradas Ambgest',
                verificada: receita_conciliada_ambgest,
                nao_verificada: receita_nao_conciliada_ambgest,
              },
              {
                nome: 'Entradas Banco',
                verificada: receita_conciliada_banco,
                nao_verificada: receita_nao_conciliada_banco,
              },
            ],
            outcome_table_data: [
              {
                nome: 'Saídas Ambgest',
                verificada: despesa_conciliada_ambgest,
                nao_verificada: despesa_nao_conciliada_ambgest,
              },
              {
                nome: 'Saídas Banco',
                verificada: despesa_conciliada_banco,
                nao_verificada: despesa_nao_conciliada_banco,
              },
            ],
          },
          () => {
            AppState.isLoading = false;
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Periodo invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getTableData = function () {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/relatorios/GetJustificativasConciliacao.php?json={"dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}", "banco":"${this.state.bankFilter}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            tableData: response.request
              ? this.reduceData(response.request)
              : [],
          },
          () => {
            AppState.isLoading = false;
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        console.log(error);
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Periodo invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  reduceData = (data) => {
    let res = [];
    let i;
    for (i = 0; i < data.length; i++) {
      let obj = data[i];
      if (
        !(obj.id_lancamento_bancario && obj.id_lancamento_pgto_recb) ||
        i == 0
      ) {
        // Caso um dos id seja null ou seja o primeiro item
        res.push(obj);
      } else {
        let ambgestIndex = res.findIndex(
          (datum) =>
            datum.id_lancamento_pgto_recb === obj.id_lancamento_pgto_recb
        );
        let bankIndex = res.findIndex(
          (datum) => datum.id_lancamento_bancario === obj.id_lancamento_bancario
        );
        if (ambgestIndex !== -1) {
          res[ambgestIndex].valor_banco =
            parseFloat(obj.valor_banco) +
            parseFloat(res[ambgestIndex].valor_banco);
          res[ambgestIndex].id_lancamento_bancario =
            res[ambgestIndex].id_lancamento_bancario +
            ', ' +
            obj.id_lancamento_bancario;
        } else if (bankIndex !== -1) {
          res[bankIndex].valor_ambgest =
            parseFloat(obj.valor_ambgest) +
            parseFloat(res[bankIndex].valor_ambgest);
          res[bankIndex].id_lancamento_pgto_recb =
            res[bankIndex].id_lancamento_pgto_recb +
            ', ' +
            obj.id_lancamento_pgto_recb;
        } else {
          res.push(obj);
        }
      }
    }
    return res.filter(
      (x) =>
        Math.abs(x.valor_ambgest) - Math.abs(x.valor_banco) > valueThreshold
    );
  };

  periodHandleChange = (event) => {
    let dtInicio = '';
    let dtFim = '';
    if (event.target.value == 'EsseMes') {
      dtInicio = moment().startOf('month').format('YYYY-MM-DD');
      dtFim = moment().endOf('month').format('YYYY-MM-DD');
    } else if (event.target.value == 'MesAnterior') {
      dtInicio = moment()
        .subtract(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      dtFim = moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('YYYY-MM-DD');
    }
    this.setState(
      {
        dtInicio: dtInicio,
        dtFim: dtFim,
        period: event.target.value,
      },
      () => {
        if (this.state.period != 'custom') {
          this.getData();
        }
      }
    );
  };

  dateHandleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () => {
        if (this.state.dtInicio.length === 10 && this.state.dtFim.length === 10)
          this._debouncedHandleChange();
      }
    );
  };

  render() {
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <h1>Relatório de Conciliação</h1>

        <Grid
          container
          justify="space-between"
          style={{ marginTop: '16px', marginBottom: '8px' }}
        >
          <Grid item>
            <Grid container justify="flex-start" style={{ marginTop: '30px' }}>
              <Grid item>
                <FormControl
                  component="fieldset"
                  style={{
                    width: '200px',
                    paddingTop: '15px',
                    marginLeft: '15px',
                  }}
                >
                  <InputLabel htmlFor={'bankFilter'}> Banco </InputLabel>
                  <Select
                    inputProps={{ id: 'bankFilter', name: 'bankFilter' }}
                    style={{ margin: '0.5em 0px' }}
                    value={this.state.bankFilter}
                    onChange={this.handleChange}
                  >
                    <MenuItem value={'237 Banco Bradesco S.A.'}>
                      Banco Bradesco S.A.
                    </MenuItem>
                    <MenuItem value={'341 Itaú Unibanco S.A.'}>
                      Itaú Unibanco S.A.
                    </MenuItem>
                    <MenuItem value={'033 Banco Santander (Brasil) S.A.'}>
                      Banco Santander (Brasil) S.A.
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  component="fieldset"
                  style={{
                    width: '200px',
                    paddingTop: '15px',
                    marginLeft: '15px',
                  }}
                >
                  <InputLabel htmlFor={'period'}>
                    {' '}
                    Período de lançamento{' '}
                  </InputLabel>
                  <Select
                    inputProps={{ id: 'period', name: 'period' }}
                    style={{ margin: '0.5em 0px' }}
                    value={this.state.period || 'Ontem'}
                    onChange={this.periodHandleChange}
                  >
                    <MenuItem value={'EsseMes'}>Esse Mês</MenuItem>
                    <MenuItem value={'MesAnterior'}>Mês Anterior</MenuItem>
                    <MenuItem value={'custom'}>Customizado</MenuItem>
                  </Select>
                </FormControl>

                {this.state.period === 'custom' && (
                  <TextField
                    style={{
                      margin: '0px 16px 8px 32px',
                      paddingTop: '5px',
                    }}
                    label="Inicio"
                    type="date"
                    defaultValue={this.state.dt_inicio}
                    onChange={this.dateHandleChange('dtInicio')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}

                {this.state.period === 'custom' && (
                  <TextField
                    style={{ paddingTop: '5px' }}
                    label="Fim"
                    type="date"
                    defaultValue={this.state.dt_fim}
                    onChange={this.dateHandleChange('dtFim')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="space-between"
          style={{ marginTop: '16px', marginBottom: '8px' }}
        >
          <Tabs
            value={this.state.chosen_visualization}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.visualizationHandleChange}
            style={{ marginBottom: '8px', marginLeft: '15px' }}
          >
            <Tab label="Gráfico" value="grafico" index={0} />
            <Tab label="Tabela" value="tabela" index={1} />
          </Tabs>
          {this.state.chosen_visualization == 'grafico' && (
            <Grid container justify="space-around" spacing={8}>
              <Grid
                item
                sm={3}
                style={{ textAlign: 'center', marginBottom: '40px' }}
              >
                <h4>Entradas</h4>
                <VictoryChart
                  theme={VictoryTheme.material}
                  domainPadding={{ x: [100, 100] }}
                  height={400}
                  width={400}
                >
                  <VictoryLegend
                    x={40}
                    y={380}
                    orientation="horizontal"
                    gutter={20}
                    data={[
                      { name: 'Verificada', symbol: { fill: '#528e70' } },
                      { name: 'Não Verificada', symbol: { fill: 'gold' } },
                    ]}
                  />
                  <VictoryStack
                    alignment="middle"
                    animate={{ duration: 1000, easing: 'exp' }}
                    domain={{ y: this.state.initial ? [0, 150000] : null }}
                    padding={{ left: 10 }}
                    colorScale={['#528e70', 'gold']}
                  >
                    <VictoryBar
                      data={this.state.data_receita_conciliada}
                      labels={function (d) {
                        return d.datum.y > 0
                          ? 'R$ ' + `${Utils.float2moeda(d.datum.y)}`
                          : '';
                      }}
                      labelComponent={<VictoryLabel dy={-30} />}
                    />
                    <VictoryBar
                      data={this.state.data_receita_nao_conciliada}
                      labels={function (d) {
                        return d.datum.y > 0
                          ? 'R$ ' + `${Utils.float2moeda(d.datum.y)}`
                          : '';
                      }}
                      labelComponent={<VictoryLabel dy={0} />}
                    />
                  </VictoryStack>
                  <VictoryAxis
                    dependentAxis
                    tickFormat={(tick) =>
                      tick > 1000
                        ? `${Math.round(tick / 1000)}k`
                        : `${Math.round(tick)}`
                    }
                  />
                  <VictoryAxis tickFormat={['Ambgest', 'Banco']} />
                </VictoryChart>
              </Grid>
              <Grid
                item
                sm={3}
                style={{ textAlign: 'center', marginBottom: '40px' }}
              >
                <h4>Saídas</h4>
                <VictoryChart
                  theme={VictoryTheme.material}
                  domainPadding={{ x: [100, 100] }}
                  height={400}
                  width={400}
                >
                  <VictoryLegend
                    x={40}
                    y={380}
                    orientation="horizontal"
                    gutter={20}
                    data={[
                      { name: 'Verificada', symbol: { fill: '#528e70' } },
                      { name: 'Não Verificada', symbol: { fill: 'gold' } },
                    ]}
                  />
                  <VictoryStack
                    alignment="middle"
                    animate={{ duration: 1000, easing: 'exp' }}
                    domain={{ y: this.state.initial ? [0, 150000] : null }}
                    padding={{ left: 10 }}
                    colorScale={['#528e70', 'gold']}
                  >
                    <VictoryBar
                      data={this.state.data_despesa_conciliada}
                      labels={function (d) {
                        return d.datum.y > 0
                          ? 'R$ ' + `${Utils.float2moeda(d.datum.y)}`
                          : '';
                      }}
                      labelComponent={<VictoryLabel dy={-30} />}
                    />
                    <VictoryBar
                      data={this.state.data_despesa_nao_conciliada}
                      labels={function (d) {
                        return d.datum.y > 0
                          ? 'R$ ' + `${Utils.float2moeda(d.datum.y)}`
                          : '';
                      }}
                      labelComponent={<VictoryLabel dy={0} />}
                    />
                  </VictoryStack>
                  <VictoryAxis
                    dependentAxis
                    tickFormat={(tick) =>
                      tick > 1000
                        ? `${Math.round(tick / 1000)}k`
                        : `${Math.round(tick)}`
                    }
                  />
                  <VictoryAxis tickFormat={['Ambgest', 'Banco']} />
                </VictoryChart>
              </Grid>
            </Grid>
          )}
          {this.state.chosen_visualization == 'tabela' && (
            //nome verificada nao_verificada
            <Grid container justify="space-around" spacing={8}>
              <Grid
                item
                sm={5}
                style={{ textAlign: 'center', marginBottom: '40px' }}
              >
                <h4>Entradas</h4>
                <ReactTable
                  filterable={false}
                  sortable={false}
                  className="-striped -highlight"
                  data={this.state.income_table_data}
                  defaultPageSize={2}
                  showPaginationBottom={false}
                  columns={[
                    {
                      Header: '',
                      accessor: 'nome',
                    },
                    {
                      Header: 'Verificada',
                      accessor: 'verificada',
                      Footer: (row) => (
                        <span>
                          {
                            // Get the total of the price
                            'R$ ' +
                              Utils.float2moeda(
                                row.data.reduce((total, data, index) => {
                                  if (index == 0) {
                                    return (total += parseFloat(
                                      data.verificada ? data.verificada : 0
                                    ));
                                  } else {
                                    return (total -= parseFloat(
                                      data.verificada ? data.verificada : 0
                                    ));
                                  }
                                }, 0)
                              )
                          }
                        </span>
                      ),
                      Cell: (row) => (
                        <div
                          style={{
                            height: '22px',
                            verticalAlign: 'middle',
                            position: 'relative',
                          }}
                        >
                          {'R$ ' + Utils.float2moeda(row.value)}
                        </div>
                      ),
                    },
                    {
                      Header: 'Não Verificada',
                      accessor: 'nao_verificada',
                      Footer: (row) => (
                        <span>
                          {
                            // Get the total of the price
                            'R$ ' +
                              Utils.float2moeda(
                                row.data.reduce((total, data, index) => {
                                  if (index == 0) {
                                    return (total += parseFloat(
                                      data.nao_verificada
                                        ? data.nao_verificada
                                        : 0
                                    ));
                                  } else {
                                    return (total -= parseFloat(
                                      data.nao_verificada
                                        ? data.nao_verificada
                                        : 0
                                    ));
                                  }
                                }, 0)
                              )
                          }
                        </span>
                      ),
                      Cell: (row) => (
                        <div
                          style={{
                            height: '22px',
                            verticalAlign: 'middle',
                            position: 'relative',
                          }}
                        >
                          {'R$ ' + Utils.float2moeda(row.value)}
                        </div>
                      ),
                    },
                  ]}
                />
              </Grid>
              <Grid item sm={5} style={{ textAlign: 'center' }}>
                <h4>Saídas</h4>
                <ReactTable
                  filterable={false}
                  sortable={false}
                  className="-striped -highlight"
                  data={this.state.outcome_table_data}
                  defaultPageSize={2}
                  showPaginationBottom={false}
                  columns={[
                    {
                      Header: '',
                      accessor: 'nome',
                    },
                    {
                      Header: 'Verificada',
                      accessor: 'verificada',
                      Footer: (row) => (
                        <span>
                          {
                            // Get the total of the price
                            'R$ ' +
                              Utils.float2moeda(
                                row.data.reduce((total, data, index) => {
                                  if (index == 0) {
                                    return (total += parseFloat(
                                      data.verificada ? data.verificada : 0
                                    ));
                                  } else {
                                    return (total -= parseFloat(
                                      data.verificada ? data.verificada : 0
                                    ));
                                  }
                                }, 0)
                              )
                          }
                        </span>
                      ),
                      Cell: (row) => (
                        <div
                          style={{
                            height: '22px',
                            verticalAlign: 'middle',
                            position: 'relative',
                          }}
                        >
                          {'R$ ' + Utils.float2moeda(row.value)}
                        </div>
                      ),
                    },
                    {
                      Header: 'Não Verificada',
                      accessor: 'nao_verificada',
                      Footer: (row) => (
                        <span>
                          {
                            // Get the total of the price
                            'R$ ' +
                              Utils.float2moeda(
                                row.data.reduce((total, data, index) => {
                                  if (index == 0) {
                                    return (total += parseFloat(
                                      data.nao_verificada
                                        ? data.nao_verificada
                                        : 0
                                    ));
                                  } else {
                                    return (total -= parseFloat(
                                      data.nao_verificada
                                        ? data.nao_verificada
                                        : 0
                                    ));
                                  }
                                }, 0)
                              )
                          }
                        </span>
                      ),
                      Cell: (row) => (
                        <div
                          style={{
                            height: '22px',
                            verticalAlign: 'middle',
                            position: 'relative',
                          }}
                        >
                          {'R$ ' + Utils.float2moeda(row.value)}
                        </div>
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <div>
          <h2> Relatório de Conciliações com Divergência </h2>
          <ReactTable
            filterable
            // sortable={false}
            className="-striped -highlight"
            data={this.state.tableData}
            defaultPageSize={10}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .startsWith(filter.value.toLowerCase())
            }
            // getTrProps={(state, rowInfo) => {
            //   if (rowInfo && rowInfo.row && state.data) {
            //     if (rowInfo.index > 0) {
            //       let this_id_banco = rowInfo.row.id_lancamento_bancario;
            //       let this_id_ambgest = rowInfo.row.id_lancamento_pgto_recb;
            //       let last_id_banco = state.data[rowInfo.index - 1].id_lancamento_bancario;
            //       let last_id_ambgest = state.data[rowInfo.index - 1].id_lancamento_pgto_recb;

            //       if (this_id_ambgest == last_id_ambgest || this_id_banco == last_id_banco) {
            //         state.offset = (state.offset ? state.offset : 0) + 1;
            //         rowInfo.row.hideDescription = true;
            //       }
            //     }

            //     return {
            //       style: {
            //         background: (rowInfo.index + (state.offset ? state.offset : 0)) % 2 == 0 ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0)'
            //       }
            //     }
            //   } else {
            //     return {}
            //   }
            // }}
            columns={[
              {
                Header: 'Nº - Banco',
                accessor: 'id_lancamento_bancario',
                width: 100,
              },
              {
                Header: 'Nº - Ambgest',
                accessor: 'id_lancamento_pgto_recb',
                width: 100,
              },
              {
                Header: 'Natureza',
                accessor: 'natureza',
                width: 100,
              },
              {
                Header: 'Valor - Banco',
                accessor: 'valor_banco',
                width: 110,
                sortMethod: (a, b) => {
                  a = parseFloat(a);
                  b = parseFloat(b);
                  if (a < b) {
                    return -1;
                  } else if (a > b) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                Footer: (row) => (
                  <span>
                    {
                      // Get the total of the price
                      'R$ ' +
                        Utils.float2moeda(
                          row.data
                            .filter(
                              (data, index, self) =>
                                index ===
                                self.findIndex(
                                  (datum) =>
                                    datum.id_lancamento_bancario ===
                                    data.id_lancamento_bancario
                                )
                            )
                            .reduce(
                              (total, { valor_banco }) =>
                                (total += parseFloat(
                                  valor_banco ? valor_banco : 0
                                )),
                              0
                            )
                        )
                    }
                  </span>
                ),
                Cell: (row) => (
                  <div
                    style={{
                      height: '22px',
                      verticalAlign: 'middle',
                      position: 'relative',
                    }}
                  >
                    {'R$ ' + Utils.float2moeda(row.value)}
                  </div>
                ),
              },
              {
                Header: 'Valor - Ambgest',
                accessor: 'valor_ambgest',
                width: 110,
                sortMethod: (a, b) => {
                  a = parseFloat(a);
                  b = parseFloat(b);
                  if (a < b) {
                    return -1;
                  } else if (a > b) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                Footer: (row) => (
                  <span>
                    {
                      // Get the total of the price
                      'R$ ' +
                        Utils.float2moeda(
                          row.data
                            .filter(
                              (data, index, self) =>
                                index ===
                                self.findIndex(
                                  (datum) =>
                                    datum.id_lancamento_pgto_recb ===
                                    data.id_lancamento_pgto_recb
                                )
                            )
                            .reduce(
                              (total, { valor_ambgest }) =>
                                (total += parseFloat(
                                  valor_ambgest ? valor_ambgest : 0
                                )),
                              0
                            )
                        )
                    }
                  </span>
                ),
                Cell: (row) => (
                  <div
                    style={{
                      height: '22px',
                      verticalAlign: 'middle',
                      position: 'relative',
                    }}
                  >
                    {'R$ ' + Utils.float2moeda(row.value)}
                  </div>
                ),
              },
              {
                Header: 'Justificativa',
                accessor: 'descricao',
                width: 500,
                Cell: (row) => {
                  return (
                    !row.row.hideDescription && (
                      <div
                        style={{
                          height: '22px',
                          verticalAlign: 'middle',
                          position: 'relative',
                        }}
                      >
                        {row.value}
                      </div>
                    )
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default RelatorioConciliacao;
