import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReactTable from 'react-table';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditICon from '@material-ui/icons/Edit';
import { FormControl, Grid, Checkbox } from '@material-ui/core';
import { browserHistory } from 'react-router';
import ReactExport from 'react-data-export';
import readXlsxFile from 'read-excel-file';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import Form from './Form';
import AppState from '../../../state';
import { Utils } from '../../../services';
import Modal from '../../../components/lib/Modal';
import ConfirmDeleteForm from './ConfirmDeleteForm';
import * as lancamentoAPi from '../../../api/lancamentos';
import debounce from 'lodash.debounce';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const schema = {
  'CPF Funcionário': {
    prop: 'cpf',
    type: String,
    required: false,
    // Excel stores dates as integers.
    // E.g. '24/03/2018' === 43183.
    // Such dates are parsed to UTC+0 timezone with time 12:00 .
  },
  'Conta Contábil': {
    prop: 'conta',
    type: String,
    required: false,
  },
  'É corrente?': {
    prop: 'eh_corrente',
    type: String,
    required: false,
    parse(value) {
      return value === 'Sim' ? 'true' : value === 'Não' ? 'false' : '';
    },
    // Excel stored booleans as numbers:
    // `1` is `true` and `0` is `false`.
    // Such numbers are parsed to booleans.
  },
  Valor: {
    prop: 'valor',
    type: Number,
    required: false,
  },
  Descrição: {
    prop: 'desc',
    type: String,
    required: false,
  },
  'Nº Banco de Pagamento': {
    prop: 'n_banco',
    type: String,
    required: false,
  },
};

class Pagamentos extends React.Component {
  constructor(props) {
    super(props);

    this.importInput = React.createRef();

    this.state = {
      dialog: false,
      dialogConfirmationDelete: false,
      lancamentoSelecionado: '',
      pagRebList: [],
      backupPagRebList: [],
      period: '5dias',
      employeeList: [],
      fullEmployeeList: [],
      dt_inicio: '',
      dt_fim: '',
      checked: [],
      selectAll: false,
      confirmation: false,
      confirmationMessage: '',
      statusFilter: 'Todos',
      periodType: 'dt_pag_recb',
      projectList: [],
      rateioFilter: false,
    };

    this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
  }

  _debouncedHandleChange = () => {
    this.getTableData();
  };

  hasDeletePermission = () => {
    const permissions = JSON.parse(localStorage.getItem('Menu'));
    const lancamentoView = permissions.views
      .filter((view) => view.id_view === 'lancamento_pgto_receb')
      .pop();

    const hasDeletePermission = lancamentoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === 'delete_pgto_receb';
      },
      false
    );

    return hasDeletePermission;
  };

  hasEditPermission = () => {
    const permissions = JSON.parse(localStorage.getItem('Menu'));
    const lancamentoView = permissions.views
      .filter((view) => view.id_view === 'lancamento_pgto_receb')
      .pop();

    const hasEditPermission = lancamentoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === 'edit_pgto_receb';
      },
      false
    );

    return hasEditPermission;
  };

  componentDidMount() {
    this.getTableData();
    this.getUsuarios();
    this.getAllUsuarios();

    const input = document.getElementById('raised-button-file');
    let ref = this;
    input.addEventListener('change', () => {
      readXlsxFile(input.files[0], { schema }).then(({ rows }) => {
        let errors = [];
        let ret = [];
        rows.forEach(function (entry, index) {
          if (entry.cpf) {
            if (
              !ref.state.fullEmployeeList.find(
                (x) => x.cpf_usuario === entry.cpf
              )
            ) {
              errors.push(
                'CPF não cadastrado no sistema na linha ' + (index + 2)
              );
            }
            if (!entry.conta) {
              errors.push(
                'Conta contábil inválida/vazia na linha ' + (index + 2)
              );
            }
            if (entry.eh_corrente === null || entry.eh_corrente === '') {
              errors.push("'É Corrente' não informado na linha " + (index + 2));
            }
            if (!entry.valor) {
              errors.push('Valor não informado na linha ' + (index + 2));
            }
            if (!entry.n_banco) {
              errors.push(
                'Número do Banco não informado na linha ' + (index + 2)
              );
            }
            ret.push(entry);
          }
        });

        input.value = null;

        if (errors.length > 0) {
          let str = '';
          errors.forEach(function (v) {
            str += v + '\n';
          });
          input.value = '';
          alert('Os seguintes erros foram encontrados:\n' + str);
        } else {
          this.processarPagamentoBatch(JSON.stringify(ret));
        }
      });
    });
  }

  _handleCheck = (name) => (event) => {
    var value =
      event.target && event.target.checked
        ? event.target.checked
        : typeof event === 'string'
          ? event
          : '';

    this.setState({ [name]: value }, () => {
      this.setState({
        pagRebList: this.state.backupPagRebList.filter((lancamento) => {
          return this.filterTableData(lancamento, this.state.status);
        }),
      });
    });
  };

  processarPagamentoBatch = (dados) => {
    // metodo post para o servidor php, servidor retorna arquivo de erro

    AppState.isLoading = true;

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/AddPgtoRecbBatch.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: dados,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        alert('Importação realizada com sucesso!');
        this.getTableData();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          const [ret, ...message] = error.response['status'].value.split(' ');
          if (ret === '40001') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40002') {
            alert('Erro: Planilha sem dados');
          } else if (ret === '40003') {
            alert(
              'Erro ao realizar lançamentos.\nVerifique os dados e tente novamente.'
            );
          } else if (ret === '40005' || ret === '40004') {
            alert(`Erro ao realizar lançamentos.\n${message.join(' ')}`);
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getUsuarios = () => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/usuarios/GetUsers.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState({
          employeeList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getAllUsuarios = () => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/usuarios/GetUsers.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState({
          fullEmployeeList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  preparaDado = (dados) => {
    if (dados === null || dados.length < 1) {
      return [];
    }

    return dados.slice(0).map(({ _original }) => {
      return {
        ..._original,
        dt_vencimento: Utils.dateToPT(_original.dt_vencimento),
        dt_pag_recb: Utils.dateToPT(_original.dt_pag_recb),
        eh_corrente: _original.eh_corrente === 'f' ? 'Não' : 'Sim',
        valor: Utils.float2moeda(_original.valor),
        custo_indireto: _original.custo_indireto === 'f' ? 'Não' : 'Sim',
      };
    });
  };

  closeDialog = () => {
    this.setState({ dialog: false }, () => this.getTableData());
  };

  periodHandleChange = (event) => {
    this.setState(
      {
        dt_incio: '',
        dt_fim: '',
        period: event.target.value,
      },
      () => {
        if (this.state.period !== 'custom') {
          this.getTableData();
        }
      }
    );
  };

  statusHandleChange = (event) => {
    let status = event.target.value;
    this.setState({
      pagRebList: this.state.backupPagRebList.filter((lancamento) =>
        this.filterTableData(lancamento, status)
      ),
      statusFilter: status,
    });
  };

  filterTableData = (lancamento, status = null) => {
    status = status == null ? this.state.statusFilter : status;
    if (this.state.rateioFilter && lancamento.descricao.includes('- rateio')) {
      return false;
    }
    if (status == 'Em Aprovação') {
      return lancamento.status == 'Em Aprovação' ? true : false;
    } else if (status == 'Aprovado') {
      return lancamento.status == 'Aprovado' ? true : false;
    } else if (status == 'Pago') {
      return lancamento.status == 'Pago' ? true : false;
    } else if (status == 'Reprovado') {
      return lancamento.status == 'Reprovado' ? true : false;
    }
    return true;
  };

  periodTypeHandleChange = (event) => {
    this.setState(
      {
        periodType: event.target.value,
      },
      () => {
        this.getTableData();
      }
    );
  };

  getTableData = () => {
    AppState.isLoading = true;

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetPgtoRecebs.php?json={"periodo":"${this.state.period}","tipo_data":"${this.state.periodType}","dt_inicio": "${this.state.dt_inicio}","dt_fim": "${this.state.dt_fim}"}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          AppState.isLoading = false;
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        const data = response.request.filter((lancamento) =>
          this.filterTableData(lancamento)
        );

        const registrosCorrenteT = data.filter((lancamento) => lancamento.eh_corrente === 't');


        const uniqueProjects = Array.from(
          new Set(data.map((el) => el.nome_display))
        ).sort();
        this.setState(
          {
            pagRebList: localStorage.getItem('noInvoiceCurrent') === 'true' ? registrosCorrenteT : data,
            backupPagRebList: response.request,
            projectList: uniqueProjects.map((el) => ({ label: el, value: el })),
          },
          () => {
            AppState.isLoading = false;
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('Dados invalidos/não informados');
          }

          if (ret === '40003') {
            console.log('Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  dateFilterHandleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () => {
        if (
          this.state.dt_inicio.length === 10 &&
          this.state.dt_fim.length === 10
        )
          this._debouncedHandleChange();
      }
    );
  };

  closeDialog = () => {
    this.setState({ dialog: false, edicao: false }, () => this.getTableData());
  };

  openDialog = () => {
    this.setState({
      edicao: false,
      dialog: true,
    });
  };

  openDialogEdicao = (idPagamento) => {
    this.setState({
      edicao: true,
      dialog: true,
      pagamento: idPagamento,
    });
  };

  displayDeleteConfirmation = (lancamento) => {
    this.setState({
      dialogConfirmationDelete: true,
      lancamentoSelecionado: lancamento,
    });
  };

  onConfirmDelete = (idLancamento, isSelected) => {
    AppState.isLoading = true;
    lancamentoAPi
      .deleteLancamento(idLancamento, isSelected)
      .then(() => {
        AppState.isLoading = false;
        alert('Pgto/Receb removido com sucesso.');
        this.getTableData();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40002') {
            alert('Erro: Não foi possível remover o lançamento');
          } else if (ret === '40003') {
            alert(
              'Erro: Não foi possível remover o título vinculado ao lançamento'
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      })
      .finally(() => {
        this.setState({
          lancamentoSelecionado: '',
          dialogConfirmationDelete: false,
        });
      });
  };

  render() {
    const hasDeletePermission = this.hasDeletePermission();
    const hasEditPermission = this.hasEditPermission();
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <h1>Pagamentos / Recebimentos</h1>

        <Grid
          container
          spacing={8}
          direction="column"
          style={{ marginTop: '40px', marginBottom: '0px', maxWidth: '600px' }}
        >
          <Grid item>
            <FormControl
              fullWidth
              component="fieldset"
              style={{ paddingTop: '13px' }}
            >
              <InputLabel htmlFor={'period'}>Tipo de Filtro</InputLabel>
              <Select
                inputProps={{ id: 'period', name: 'period' }}
                value={this.state.periodType}
                onChange={this.periodTypeHandleChange}
              >
                <MenuItem value={'dt_pag_recb'}>Pagamento/Receb.</MenuItem>
                <MenuItem value={'dt_vencimento'}>Vencimento</MenuItem>
                {/* <MenuItem value={"dt_lancamento"}>Lançamento</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              fullWidth
              component="fieldset"
              style={{ paddingTop: '13px' }}
            >
              <InputLabel>
                {' '}
                Período de{' '}
                {this.state.periodType == 'dt_pag_recb'
                  ? 'Lançamento'
                  : 'Vencimento'}{' '}
              </InputLabel>
              <Select
                inputProps={{ id: 'period', name: 'period' }}
                value={this.state.period}
                onChange={this.periodHandleChange}
              >
                <MenuItem value={'Hoje'}>Hoje</MenuItem>
                <MenuItem value={'Ontem'}>Ontem</MenuItem>
                <MenuItem value={'5dias'}>Últimos 5 dias</MenuItem>
                <MenuItem value={'EsseMes'}>Esse Mês</MenuItem>
                <MenuItem value={'30dias'}>Últimos 30 dias</MenuItem>
                <MenuItem value={'60dias'}>Últimos 60 dias</MenuItem>
                <MenuItem value={'90dias'}>Últimos 90 dias</MenuItem>
                <MenuItem value={'p5dias'}>Próximos 5 dias</MenuItem>
                <MenuItem value={'custom'}>Customizado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              fullWidth
              component="fieldset"
              style={{ paddingTop: '13px' }}
            >
              <InputLabel> Status </InputLabel>
              <Select
                inputProps={{ id: 'status', name: 'status' }}
                value={this.state.statusFilter}
                onChange={this.statusHandleChange}
              >
                <MenuItem value={'Todos'}>Todos</MenuItem>
                <MenuItem value={'Em Aprovação'}>Em Aprovação</MenuItem>
                <MenuItem value={'Aprovado'}>Aprovado</MenuItem>
                <MenuItem value={'Pago'}>Pago</MenuItem>
                <MenuItem value={'Reprovado'}>Reprovado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              fullWidth
              component="fieldset"
              style={{ width: '50px', paddingTop: '13px' }}
            >
              <Checkbox
                checked={this.state.rateioFilter}
                onChange={this._handleCheck('rateioFilter')}
              />
            </FormControl>
            <FormControl
              fullWidth
              component="fieldset"
              style={{ width: '100px', paddingTop: '26px' }}
            >
              Filtra rateios?
            </FormControl>
          </Grid>
          {this.state.period === 'custom' && (
            <Grid item>
              <TextField
                fullWidth
                style={{
                  marginRight: '16px',
                  paddingTop: '13px',
                }}
                label="Inicio"
                type="date"
                defaultValue={this.state.dt_inicio}
                onChange={this.dateFilterHandleChange('dt_inicio')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}

          {this.state.period === 'custom' && (
            <Grid item>
              <TextField
                fullWidth
                style={{ paddingTop: '13px' }}
                label="Fim"
                type="date"
                defaultValue={this.state.dt_fim}
                onChange={this.dateFilterHandleChange('dt_fim')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          container
          style={{ marginBottom: '8px', paddingTop: '40px' }}
          spacing={16}
          alignItems="flex-end"
          justify='flex-end'
        >
          <Grid item>
            <a
              href={`${process.env.PUBLIC_URL}/${AppState.serverAddr}/arquivos/Template Pagamentos.xlsx`}
              download
            >
              Baixar Template
            </a>
            <div>
              <label
                htmlFor="raised-button-file"
                style={{ width: '100%', marginBottom: 0 }}
              >
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  id="raised-button-file"
                  type="file"
                  ref={this.importInput}
                  style={{ display: 'none' }}
                />
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth={true}
                  color={'primary'}
                  style={{ height: '36px' }}
                >
                  Importar Folha Pgto
                </Button>
              </label>
            </div>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openDialog()}
            >
              <AddIcon />
              Novo PGTO/RECEB
            </Button>
          </Grid>
          <Grid item>
            <ExcelFile
              filename={'RelatorioPgtoRecebs-' + Utils.getTodayDate()}
              element={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.exportarExcel}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 192 192"
                    style={{ fill: '#000000', paddingRight: '6px' }}
                  >
                    <g
                      fill="none"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                      style={{ mixBlendMode: 'normal' }}
                    >
                      <path d="M0,192v-192h192v192z" fill="none"></path>
                      <g fill="#ffffff">
                        <g id="surface1">
                          <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  Exportar
                </Button>
              }
            >
              <ExcelSheet
                data={() =>
                  this.preparaDado(
                    this.selectTable.getResolvedState().sortedData
                  )
                }
                name="PgtoReceb"
              >
                <ExcelColumn
                  label="ID Pgto/Recb"
                  value="id_lancamento"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="ID Título"
                  value="id_titulo"
                  style={{ font: { sz: '24', bold: true } }}
                />
                {localStorage.getItem('noInvoiceCurrent') === 'false' ? (
                  <ExcelColumn
                    label="É Corrente?"
                    value="eh_corrente"
                    style={{ font: { sz: '24', bold: true } }}
                  />
                ) : []}
                <ExcelColumn
                  label="Tipo Contraparte"
                  value="tipo_contraparte"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CPJ/CNPJ Contraparte"
                  value="cpf_cnpj_contraparte"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Nome Contraparte"
                  value="nome_contraparte"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Natureza"
                  value="natureza"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Conta"
                  value="id_conta"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Desc. Conta"
                  value="desc_conta"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Projeto"
                  value="nome_display"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Descrição"
                  value="descricao"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Valor"
                  value="valor"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Data Pgto./Receb."
                  value="dt_pag_recb"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Data Vencimento"
                  value="dt_vencimento"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Forma Pagamento"
                  value="forma_pagamento"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Contraparte Outro"
                  value="nome_contraparte_outro"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Código Contrato"
                  value="id_contrato"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="N Cheque"
                  value="n_cheque"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Banco Pgto/Receb"
                  value="banco_pgto_receb"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="N Documento"
                  value="num_documento"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Custo Indireto"
                  value="custo_indireto"
                  style={{ font: { sz: '24', bold: true } }}
                />
              </ExcelSheet>
            </ExcelFile>
          </Grid>
        </Grid>
        <ReactTable
          filterable={true}
          data={this.state.pagRebList}
          defaultPageSize={20}
          className="-striped -highlight"
          ref={(r) => {
            this.selectTable = r;
          }}
          defaultFilterMethod={(filter, row) =>
            filter.id === 'dt_pag_recb' || filter.id === 'dt_vencimento'
              ? Utils.dateToPT(String(row[filter.id])).startsWith(
                filter.value.toLowerCase()
              )
              : String(row[filter.id])
                .toLowerCase()
                .startsWith(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: 'Títulos',
              columns: [
                {
                  Header: 'Nº',
                  accessor: 'id_lancamento',
                  width: 60,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Titulo',
                  accessor: 'id_titulo',
                  width: 60,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        textAlign: 'center',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                // {
                //   Header: "Status",
                //   accessor: "status",
                //   width: 80,
                //   Cell: row => (
                //     <div
                //       style={{
                //         height: "22px",
                //         verticalAlign: "middle",
                //         position: "relative",
                //         top: "15%",
                //         textTransform:
                //           row.value === "pj" ? "uppercase" : "capitalize"
                //       }}
                //     >
                //       {row.value}
                //     </div>
                //   )
                // },
                {
                  Header: 'natureza',
                  accessor: 'natureza',
                  width: 75,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {row.value === 'receita'
                        ? 'Receita'
                        : row.value === 'despesa'
                          ? 'Despesa'
                          : 'Controle'}
                    </div>
                  ),
                },
                {
                  Header: 'Tipo Contraparte',
                  accessor: 'tipo_contraparte',
                  width: 100,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                        textTransform:
                          row.value === 'pj' ? 'uppercase' : 'capitalize',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Nome Contraparte',
                  accessor: 'nome_contraparte',
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'conta',
                  accessor: 'id_conta',
                  width: 60,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Desc. Conta',
                  accessor: 'desc_conta',
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'projeto',
                  accessor: 'nome_display',
                  width: 124,
                  filterMethod: (filter, row) => {
                    if (filter.value === 'all') {
                      return true;
                    }
                    if (filter.value === 'allButZero') {
                      return row[filter.id] !== '0 - AmbientalConsult';
                    }
                    return row[filter.id] === filter.value;
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Todos</option>
                      <option value="allButZero">Todos Menos 0</option>
                      {this.state.projectList.map((el) => {
                        return <option value={el.value}>{el.label}</option>;
                      })}
                    </select>
                  ),
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'descricao',
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'valor',
                  accessor: 'valor',
                  width: 90,
                  Footer: (row) => (
                    <span>
                      {
                        // Get the total of the price
                        'R$ ' +
                        Utils.float2moeda(
                          row.data.reduce(
                            (total, { valor }) =>
                              (total += parseFloat(valor)),
                            0
                          )
                        )
                      }
                    </span>
                  ),
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {'R$ ' + Utils.float2moeda(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Atualização',
                  accessor: 'dt_pag_recb',
                  width: 95,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Vencimento',
                  accessor: 'dt_vencimento',
                  width: 90,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                        top: '15%',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Ação',
                  width: 90,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'left',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="baseline"
                      >
                        {hasDeletePermission ? (
                          <DeleteIcon
                            style={{ cursor: 'pointer' }}
                            onClick={(event) =>
                              this.displayDeleteConfirmation(row.original)
                            }
                          />
                        ) : (
                          ''
                        )}
                        {hasEditPermission ? (
                          <EditICon
                            onClick={() =>
                              this.openDialogEdicao(
                                row.original['id_lancamento']
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              width: '22px',
                              height: '22px',
                              minWidth: '22px',
                              minHeight: '22px',
                              marginLeft: '5px',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Grid>
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
        {this.state.dialogConfirmationDelete && (
          <Modal
            titulo="Confirmação"
            onCloseButton={() =>
              this.setState({
                dialogConfirmationDelete: false,
                id_lancamento_selecionado: '',
              })
            }
            isOpen={this.state.dialogConfirmationDelete}
          >
            <ConfirmDeleteForm
              lancamento={this.state.lancamentoSelecionado}
              onConfirm={this.onConfirmDelete}
            />
          </Modal>
        )}
        <Form
          open={this.state.dialog}
          close={this.closeDialog}
          pagamento={this.state.pagamento}
          edicao={this.state.edicao}
          isLoading={true}
        />
      </div>
    );
  }
}

export default Pagamentos;
