import { observable } from 'mobx';

// Mudar aqui para desenvolvimento
// caso não esteja usando a porta 80 no docker, alterar na prop Appstate.serverAddr abaixo
const isDevMode = false;

const AppState = observable({
  token: '',
  userInfo: null,
  empresa: '',
  idPerfil: null,
  isMaster: false,
  isAdmin: false,
  isLoggedIn: false,
  isLoading: false,
  serverAddr: isDevMode ? 'http://localhost:8080' : 'server',
  isDevMode,
});

export default AppState;

/**** Procedimentos para produção **** /
 1) Verificar se serverAddr: 'server',  nesse arquivo
 2) Verificar se "homepage": "/seggest", em package.json
 /************************************/

/**** Procedimentos para teste **** /
 1) Verificar se serverAddr: 'server',  nesse arquivo
 2) Verificar se "homepage": "/teste/seggest", em package.json
 /************************************/
