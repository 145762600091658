import React from 'react'
import ReactTable from "react-table";
import AppState from "../../../state";
import {browserHistory} from "react-router";
import {Utils} from "../../../services";
import ReactExport from "react-data-export";
import {
    Grid,
    Button,
    Select,
    MenuItem
} from "@material-ui/core";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const  getTodayDate = () =>{
    var today = new Date((new Date()).getTime() - ((new Date()).getTimezoneOffset() * 60000));
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = dd + '/' + mm + '/' + yyyy;
    return today;
}

class RelatorioTimesheet extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            apontamentos:[],
            filtroTipo:"mes"
        }
        this.carrega_relatorio();
    }

    carrega_relatorio = () => {
        AppState.isLoading = true;
        const json = JSON.stringify({
            periodo: this.state.filtroTipo
        })

        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/relatorios/RelatorioTimesheet.php?json=${json}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': AppState.token
                }
            })
            .then((response) => {
                if(response.status === 401) {
                    AppState.isLoading = false;
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                    return;
                }
                const res = response.json();
                return res;
            })
            .then((response) => {
                AppState.isLoading = false;
                this.setState({
                    apontamentos: response.request
                })
            })
            .catch(function(error) {
                AppState.isLoading = false;
                console.log("Erro de api!", error);
            });
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        },this.carrega_relatorio);
    };

    render() {
        return (<div id={this.constructor.name} style={{marginTop: "5em"}}>
            <h1>Relatório Timesheet</h1>
            <Grid container  direction="row" justify="space-between" style={{marginTop:'30px', marginBottom:"10px"}}>
                <Grid item>
                    <Select
                        value={this.state.filtroTipo}
                        onChange={this.handleChange('filtroTipo')}
                        style={{minWidth:"120px"}}
                        inputProps={{
                            name: 'filtroTipo',
                            id: 'filtroTipo',
                        }}
                    >
                        <MenuItem value="mes">Mês Atual</MenuItem>
                        <MenuItem value="semana">Semana Atual</MenuItem>
                    </Select>
                </Grid>
            <Grid item>
                <ExcelFile filename={"RelatorioTimesheet-" + getTodayDate()}
                           element={
                               <Button variant="contained" color="primary" onClick={this.exportarExcel}>
                                   <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                        width="24" height="24"
                                        viewBox="0 0 192 192"
                                        style={{fill:"#000000", paddingRight:"6px"}}><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode:"normal"}}><path d="M0,192v-192h192v192z" fill="none"></path><g fill="#ffffff"><g id="surface1"><path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path></g></g></g></svg>
                                   Exportar
                               </Button>
                           }>
                    <ExcelSheet data={this.state.apontamentos} name="Apontamentos">
                        <ExcelColumn label="CPF" value="cpf_usuario" style={{font: {sz: '24', bold: true}}}/>
                        <ExcelColumn label="Nome" value="nome_usuario" style={{font: {sz: "24", bold: true}}}/>
                        <ExcelColumn label="Data" value="data" style={{font: {sz: "24", bold: true}}}/>
                        <ExcelColumn label="Jornada Padrão" value="jornada_diaria_trabalho" style={{font: {sz: "24", bold: true}}}/>
                        <ExcelColumn label="Qtd. Apontada" value="qtd_horas" style={{font: {sz: "24", bold: true}}}/>
                        <ExcelColumn label="Diferença" value="hora_diferenca" style={{font: {sz: "24", bold: true}}}/>
                    </ExcelSheet>
                </ExcelFile>
            </Grid>
            </Grid>
            <ReactTable
                filterable
                data={this.state.apontamentos}
                defaultPageSize={100}
                defaultFilterMethod={(filter, row) =>
                    (filter.id === 'data')?(
                        Utils.dateToPT(String(row[filter.id])).startsWith(filter.value.toLowerCase())
                    ):(
                        String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                    )
                }
                columns={[{

                    "Header": "Apontamentos não realizados/Incompletos",
                    "columns": [
                {
                    "Header": "CPF",
                    "accessor": "cpf_usuario",
                    "maxWidth": 116
                },
                {
                    "Header": "Nome",
                    "accessor": "nome_usuario"

                },
                {
                    "Header": "data",
                    "accessor": "data",
                    "maxWidth": 96,
                    Cell: row => (
                        <div
                            style={{
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >{Utils.dateToPT(row.value)}
                        </div>
                    )
                },
                {
                    "Header": "jornada",
                    "accessor": "jornada_diaria_trabalho",
                    "width": 80,
                    Cell: row => (
                        <div
                            style={{
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >{row.value}
                        </div>
                    )
                },
                {
                    "Header": "qtd. apontadas",
                    "accessor": "qtd_horas",
                    "width": 112,
                    Cell: row => (
                        <div
                            style={{
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >{row.value}
                        </div>
                    )
                },
                {
                    "Header": "diferença",
                    "accessor": "hora_diferenca",
                    "width": 80,
                    Cell: row => (
                        <div
                            style={{
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >{row.value}
                        </div>
                    )
                }
                ]
                }]
                }
                className="-striped -highlight"/>
        </div>)
    }

}

export default
RelatorioTimesheet;