import React from "react";

import AppState from "../../../state";
import { browserHistory } from "react-router";
import { Utils } from "../../../services";
import moment from "moment";
import ReactTable from "react-table";
import { Checkbox, FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ReactExport from "react-data-export";
import debounce from 'lodash.debounce';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class RelatorioDespesasDiretas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dtInicio: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            dtFim: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
            projeto: "10000",
            data: []
        };
        this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
    }

    _debouncedHandleChange = () => {
        this.getData();
    }

    componentDidMount() {
        this.getData();
        this.getProjects();
    }

    ajusta_corrente = dado => {
        if (dado == 'f')
            return "Não";
        else if (dado == 't')
            return "Sim";
        else
            return dado;
    }

    preparaDado = dados => {
        if (dados === null || dados.length < 1) {
            return [];
        }

        return dados.slice(0).map(({ _original }) => {
            return {
                ..._original,
                dt_vencimento: Utils.dateToPT(_original.dt_encerramento),
                dt_pag_recb: Utils.dateToPT(_original.dt_pag_recb),
                eh_corrente: _original.eh_corrente === "f" ? "Não" : "Sim",
                valor: Utils.float2moeda(_original.valor)
            };
        });
    };

    dateFilterHandleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                if (
                    this.state.dtInicio.length === 10 &&
                    this.state.dtFim.length === 10
                )
                    this._debouncedHandleChange();
            }
        );
    };

    handleChange = name => event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        }, () => this._debouncedHandleChange());
    };

    getProjects = (isLoading = false) => {
        if (AppState.isLoading == false)
            AppState.isLoading = true;
        fetch(
            `${process.env.PUBLIC_URL}/${AppState.serverAddr}/projetos/GetProjects.php`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: AppState.token
                }
            }
        )
            .then(response => {
                if (!response.ok) {
                    return response.json().then(json => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json();
                    return res;
                }
            })
            .then(response => {
                this.setState({
                    projectList: response.request
                }, () => {
                    if (isLoading == false) {
                        AppState.isLoading = isLoading;
                    }
                });
            })
            .catch(function (error) {
                if (isLoading == false) {
                    AppState.isLoading = isLoading;
                }
                if (error.toString() === "" || error.toString().split(" ").length < 2) {
                    alert("Erro desconhecido, tente novamente");
                    return;
                }
                var Err_status = error.toString().split(" ")[1];
                if (Err_status === "400") {
                    var ret = error.response["status"].value.split(" ")[0];
                    if (ret === "40001") {
                        console.log("40001 - Erro desconhecido");
                    } else alert("Dados inválidos");
                } else if (Err_status === "401") {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                } else if (Err_status === "500") {
                    alert("Erro desconhecido, tente novamente");
                }
            });
    };

    getData = () => {
        AppState.isLoading = true;
        fetch(
            `${process.env.PUBLIC_URL}/${AppState.serverAddr}/relatorios/RelatorioDespesasDiretas.php?json={"dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}", "projeto":"${this.state.projeto}"}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: AppState.token
                }
            }
        )
            .then(response => {
                if (!response.ok) {
                    return response.json().then(json => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json();
                    return res;
                }
            })
            .then(response => {

                const registrosCorrenteT = response.request.filter((lancamento) => lancamento.eh_corrente === 't');

                const data = localStorage.getItem('noInvoiceCurrent') === 'true' ? registrosCorrenteT : response.request;
                this.setState({
                    data: data
                }, () => AppState.isLoading = false);
            })
            .catch(function (error) {
                AppState.isLoading = false;
                if (error.toString() === "" || error.toString().split(" ").length < 2) {
                    alert("Erro desconhecido, tente novamente");
                    return;
                }
                var Err_status = error.toString().split(" ")[1];
                if (Err_status === "400") {
                    var ret = error.response["status"].value.split(" ")[0];
                    if (ret === "40001") {
                        console.log("40001 natureza invalido/não informada");
                    } else if (ret === "40002") {
                        console.log("40002 json invalido/não informado");
                    } else if (ret === "40003") {
                        console.log("40003 erro desconhecido");
                    } else alert("Dados inválidos");
                } else if (Err_status === "401") {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                } else if (Err_status === "500") {
                    alert("Erro desconhecido, tente novamente");
                }
            });
    };

    render() {
        return (
            <div id={this.constructor.name} style={{ marginTop: "5em" }}>
                <Grid
                    container
                    justify={"space-between"}
                    style={{ paddingTop: "13px", marginBottom: "0px" }}
                >
                    <Grid item>
                        <FormControl fullWidth={true}>
                            <InputLabel htmlFor="projeto">Selecione o Projeto</InputLabel>
                            <Select
                                style={{
                                    minWidth: "200px",
                                    paddingTop: "8px"
                                }}
                                value={this.state.projeto}
                                inputProps={{
                                    name: "projeto",
                                    id: "projeto"
                                }}
                                onChange={this.handleChange()}
                            >
                                {this.state.projectList && this.state.projectList.map(item => (
                                    <MenuItem key={item.id_projeto} value={item.id_projeto}>
                                        {item.id_projeto} - {item.desc_curta}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            style={{
                                marginLeft: "10px",
                                marginRight: "40px",
                                paddingTop: "8px"
                            }}
                            label="Inicio"
                            type="date"
                            defaultValue={this.state.dtInicio}
                            onChange={this.dateFilterHandleChange("dtInicio")}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />

                        <TextField
                            style={{ paddingTop: "8px" }}
                            label="Fim"
                            type="date"
                            defaultValue={this.state.dtFim}
                            onChange={this.dateFilterHandleChange("dtFim")}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            justify={"flex-end"}
                            style={{ marginBottom: "8px" }}
                        >

                            <div
                                style={{
                                    paddingTop: "1.5em"
                                }}
                            >
                                <ExcelFile
                                    filename={"RelatorioDespesasDiretas-" + Utils.getTodayDate()}
                                    element={
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.exportarExcel}
                                            style={{ marginLeft: "32px" }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 192 192"
                                                style={{ fill: "#000000", paddingRight: "6px" }}
                                            >
                                                <g
                                                    fill="none"
                                                    fillRule="nonzero"
                                                    stroke="none"
                                                    strokeWidth="1"
                                                    strokeLinecap="butt"
                                                    strokeLinejoin="miter"
                                                    strokeMiterlimit="10"
                                                    strokeDasharray=""
                                                    strokeDashoffset="0"
                                                    fontFamily="none"
                                                    fontWeight="none"
                                                    fontSize="none"
                                                    textAnchor="none"
                                                    style={{ mixBlendMode: "normal" }}
                                                >
                                                    <path d="M0,192v-192h192v192z" fill="none"></path>
                                                    <g fill="#ffffff">
                                                        <g id="surface1">
                                                            <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            Exportar
                                        </Button>
                                    }
                                >
                                    <ExcelSheet
                                        data={() =>
                                            this.preparaDado(
                                                this.selectTable.getResolvedState().sortedData
                                            )
                                        }
                                        name="Despesas"
                                    >
                                        <ExcelColumn
                                            label="ID Projeto"
                                            value="id_projeto"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="Nome Projeto"
                                            value="nome_projeto"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="DT Encerramento Projeto"
                                            value="dt_encerramento"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="ID Pagamento"
                                            value="id_lancamento"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="Valor"
                                            value="valor"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="Descrição"
                                            value="descricao"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="Conta"
                                            value="id_conta"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="Data Pgto"
                                            value="dt_pag_recb"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        {localStorage.getItem('noInvoiceCurrent') === 'false' ? <ExcelColumn
                                            label="É Corrente?"
                                            value="eh_corrente"
                                            style={{ font: { sz: "24", bold: true } }}
                                        /> : []}
                                        <ExcelColumn
                                            label="Tipo Contraparte"
                                            value="tipo_contraparte"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="CPJ/CNPJ Contraparte"
                                            value="cpf_cnpj_contraparte"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="Contraparte Outro"
                                            value="nome_contraparte_outro"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>


                <ReactTable
                    filterable
                    fullWidth
                    className="-striped -highlight"
                    style={{ marginTop: "16px", width: "100%" }}
                    data={this.state.data}
                    ref={r => {
                        this.selectTable = r;
                    }}
                    defaultPageSize={10}
                    showPagination={true}
                    defaultFilterMethod={(filter, row) =>
                        filter.id === "dt_pag_recb" || filter.id === "dt_encerramento"
                            ? Utils.dateToPT(String(row[filter.id])).startsWith(
                                filter.value.toLowerCase()
                            )
                            :
                            String(row[filter.id])
                                .toLowerCase()
                                .startsWith(filter.value.toLowerCase())

                    }
                    columns={[
                        {
                            columns: [
                                {
                                    Header: "ID Projeto",
                                    accessor: "id_projeto",
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {row.value}
                                        </div>
                                    )
                                },
                                {
                                    Header: "Nome Projeto",
                                    accessor: "nome_projeto",
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {row.value}
                                        </div>
                                    )
                                },
                                {
                                    Header: "Tipo Projeto",
                                    accessor: "tipo_projeto",
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {row.value}
                                        </div>
                                    )
                                },
                                {
                                    Header: "Dt Encerramento",
                                    accessor: "dt_encerramento",
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {Utils.dateToPT(row.value)}
                                        </div>
                                    )
                                },
                                {
                                    Header: "ID Pagamento",
                                    accessor: "id_lancamento",
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {row.value}
                                        </div>
                                    )
                                },
                                {
                                    Header: "Valor",
                                    accessor: "valor",
                                    Footer: row => (
                                        <span>
                                            {
                                                "R$ " +
                                                Utils.float2moeda(
                                                    row.data.reduce(
                                                        (total, { valor }) => (total += parseFloat(valor)),
                                                        0
                                                    )
                                                )}
                                        </span>
                                    ),
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {Utils.float2moeda(row.value)}
                                        </div>
                                    )
                                },
                                {
                                    Header: "Descrição",
                                    accessor: "descricao",
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {row.value}
                                        </div>
                                    )
                                },
                                {
                                    Header: "ID Conta",
                                    accessor: "id_conta",
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {row.value}
                                        </div>
                                    )
                                },
                                {
                                    Header: "DT Pagto",
                                    accessor: "dt_pag_recb",
                                    filterable: true,
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {Utils.dateToPT(row.value)}
                                        </div>
                                    )
                                },
                                localStorage.getItem('noInvoiceCurrent') === 'false' && {
                                    Header: "Eh Corrente",
                                    accessor: "eh_corrente",
                                    filterMethod: (filter, row) => {
                                        return this.ajusta_corrente(String(row[filter.id]))
                                            .toLowerCase()
                                            .startsWith(filter.value.toLowerCase());
                                    },
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>
                                            {this.ajusta_corrente(row.value)}
                                        </div>
                                    )
                                }
                            ]
                        }
                    ]}
                />
            </div>
        );
    }
}
export default RelatorioDespesasDiretas;

