import React from "react";
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Icon,
} from "@material-ui/core";

const Modal = ({ titulo, children, isOpen = false, onCloseButton = () => {}, maxWidth, fullWidth }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCloseButton}
      maxWidth={maxWidth ? maxWidth : "lg"}
      fullWidth={fullWidth ? fullWidth : false}
      disableBackdropClick={true}
    >
      <DialogContent style={{ paddingTop: "16px" }}>
        <Grid
          container={true}
          alignItems={"flex-start"}
          justify={"space-around"}
          direction={"row"}
          spacing={32}
        >
          <Grid item sm={10}>
            <Typography variant="h4">{titulo}</Typography>
          </Grid>
          <Grid item sm={2}>
            <Icon style={{ marginLeft: "90%" }} onClick={onCloseButton}>
              close
            </Icon>
          </Grid>
        </Grid>

        {children}
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  titulo: PropTypes.string.isRequired,
  children: PropTypes.object,
  isOpen: PropTypes.bool,
  onCloseButton: PropTypes.func,
}

export default Modal;
