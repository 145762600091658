import React from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogContent,
    Icon
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactTable from "react-table";
import AppState from "../../../../state";
import {browserHistory} from "react-router";
import AutoSelect from '../../../../components/inputs/AutoSelect';

class Themes extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            temas_secoes: [],
            temas: [],
            newSection: '',
            newTheme: '',
            selectedThemeId: '',
            newThemeKey: false
        }
    }

    componentDidMount() {
        AppState.isLoading = this.props.isLoading;
        this.getTemasSecoes(this.props.isLoading)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.edicao != this.props.edicao) {
            this.setState({
                edicao: this.props.edicao
            },()=>{
                if (this.props.edicao) {
                    this.setState({
                        temas: this.props.temas
                    });
                }
            })
        }
    }

    closeDialog = () =>{
        this.setState({
            newSection: '',
            newTheme: '',
            selectedThemeId: '',
            newThemeKey: false
        })
        this.props.close()
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    themeHandleChange = (event) => {
        console.log(event);
        this.setState({
            newTheme: event ? event.value : '',
        })
    }

    getTemasSecoes = (isLoading = false) => {
        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/questoes/GetTemasSecoes.php`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization':AppState.token,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                }
            })
            .then((response) => {
                this.setState({
                    temas_secoes: response.request,
                    temas: [...new Set(response.request.map((val) => {return val.tema}))]
                }, () => { 
                    if (isLoading == false) {
                        AppState.isLoading = isLoading;
                    };
                    });
                })
            .catch(function(error) {
                if (isLoading == false) {
                    AppState.isLoading = isLoading;
                }
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    if(ret == '40001') {
                        console.log("40001 - Erro desconhecido");
                    }
                    else
                        alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })

    }

    addTemaSecao = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            tema: this.state.newTheme,
            secao: this.state.newSection
        })

        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/questoes/AddTemaSecao.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })

            .then((response) => {
                if(!response.ok) {
                    return response.json().then((json) => {
                        var error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                }

                const res = response.json()
                return res;
                AppState.isLoading = false
            })
            .then((response) => {
                this.setState({
                    newTheme: '',
                    newSection: ''
                })
                this.getTemasSecoes();
                AppState.isLoading = false;
            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    deleteTemaSecao = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            id_tema_secao: this.state.selectedThemeId
        })

        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/questoes/DeleteTemaSecao.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })
        .then((response) => {
            if(!response.ok) {
                return response.json().then((json) => {
                    var error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            }

            const res = response.json()
            return res;
            AppState.isLoading = false
        })
        .then((response) => {
            this.setState({
                selectedThemeId: ''
            })
            this.getTemasSecoes();
            AppState.isLoading = false;
        })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                console.log(ret)
                if (ret == '40003') {
                    alert("Tema / Seção atribuído a questões");    
                    return;
                }
                alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    render() {
        return (
            <Dialog
                style={{ height: "110vh" }}
                fullWidth={true}
                open={this.props.open}
                onClose={this.props.close}
                disableBackdropClick={true}
            >
                <DialogContent>
                    <Icon
                        style={{ float: "right" }}
                        title={"fechar"}
                        onClick={this.closeDialog}
                    >
                        close
                    </Icon>
                    <Typography variant="h4" style={{marginBottom:'20px'}}>Temas e Seções</Typography>

                    <Grid
                        container
                        // spacing={32}
                        // style={{display: "flex", flexDirection: "row"}}
                    >   
                        <Grid
                            container
                            justify='flex-end'
                            style={{display: "flex", flexDirection: "row"}}
                        >   
                            {this.state.newThemeKey && (
                                <Grid 
                                    // container
                                    // justify='flex-start'
                                    style={{display: "flex", flexDirection: "row", marginLeft: "2rem", marginBottom: "2rem", alignItems: 'center'}}
                                >
                                    <div
                                        style={{minWidth: "20rem"}}
                                    >
                                        <AutoSelect
                                            creatable={true}
                                            suggestions={this.state.temas.map((val) => {return {'label': val, 'value': val}})}
                                            placeholder='Temas'
                                            multi={false}
                                            value={this.state.selectedTheme}
                                            handleChange2={this.themeHandleChange}
                                        />
                                    </div>
                                    <TextField
                                        value={this.state.newSection}
                                        label={"Nova Seção"}
                                        name={"newSection"}
                                        fullWidth
                                        onChange={(e) => this.handleChange(e)}
                                        style={{minWidth: "20rem", marginLeft: "2rem"}}
                                    />
                                </Grid>
                            )}
                            <Grid
                                style={{display: "flex", flexDirection: "row", marginLeft: "2rem", marginBottom: "2rem"}}
                            >
                                {this.state.newThemeKey ? (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => this.addTemaSecao()}
                                    >
                                        Salvar
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => this.setState({
                                            newThemeKey: true
                                        })}
                                    >
                                        <AddIcon/>
                                        Novo
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justify='center'
                            style={{display: "flex", flexDirection: "row"}}
                        >  
                            <ReactTable
                                filterable={true}
                                data={this.state.temas_secoes}
                                pageSize={10}
                                className="-striped -highlight"
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                                }
                                columns={[
                                    {
                                        Header: "Tema",
                                        accessor: "tema",
                                        width: 200,
                                        Cell: row => (
                                            <div style={{
                                                height: "22px",
                                                verticalAlign: "middle",
                                                position: "relative",
                                                textAlign: "center" }}
                                            > {row.value} </div> )
                                    }, {
                                        Header: "Seção",
                                        accessor: "secao",
                                        width: 200,
                                        Cell: row => (
                                            <div style={{
                                                height: "22px",
                                                verticalAlign: "middle",
                                                position: "relative",
                                                textAlign: "center" }}
                                            > {row.value} </div> )
                                    }, {
                                        Header: 'Ação',
                                        accessor: "acao",
                                        filterable: false,
                                        width: 100,
                                        Cell: row =>
                                        <div
                                            style={{
                                            height: "22px",
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                            position: "relative"
                                            }}
                                        >
                                            <Grid
                                            container
                                            direction="row"
                                            justify="space-around"
                                            alignItems="baseline"
                                            >
                                            <DeleteIcon
                                                onClick={() =>
                                                    this.setState({
                                                        selectedThemeId: row.original.id_tema_secao
                                                    }, () => {
                                                        this.deleteTemaSecao();
                                                    })
                                                }
                                                style={{
                                                    cursor: "pointer",
                                                    width: "22px",
                                                    height: "22px",
                                                    minWidth: "22px",
                                                    minHeight: "22px",
                                                    marginLeft: "5px"
                                                }}
                                            />
                                            </Grid>
                                        </div>
                                        }
                                ]}
                                />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>)
    }   

}

export default Themes;