import React from 'react'
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogContent,
    Icon,
    Checkbox
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add";
import AppState from "../../../../state";
import {browserHistory} from "react-router";
import ReactTable from "react-table";
import DeleteIcon from "@material-ui/icons/Delete";
import StarRating from 'react-svg-star-rating'

class Form extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pergunta: '',
            id_questao: '',
            id_tema_secao: '',
            temas_secoes: [],
            temas: [],
            tema_selecionado: '',
            opcoes: [],
            nivel: '',
            resposta: '',
            adicao_questao: false,
            opcao_texto: '',
            opcao_correta: false,
            respondida: false,
            status: false
        }
    }

    componentDidMount() {
        AppState.isLoading = this.props.isLoading;
        this.getTemasSecoes(this.props.isLoading)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.edicao != this.props.edicao) {
            this.setState({
                edicao: this.props.edicao
            },()=>{
                if (this.props.edicao) {
                    this.setState({
                        id_tema_secao: this.props.questao.id_tema_secao,
                        tema_selecionado: this.state.temas_secoes.filter((val) => val.id_tema_secao == this.props.questao.id_tema_secao)[0].tema,
                        pergunta: this.props.questao.pergunta,
                        nivel: parseInt(this.props.questao.nivel),
                        resposta: this.props.questao.resposta,
                        opcoes: this.props.questao.opcoes,
                        id_questao: this.props.questao.id_questao,
                        respondida: this.props.questao.respondida,
                        status: this.props.questao.status == 't' ? true: false
                    });
                }
            })
        }
    }

    closeDialog = () =>{
        this.setState({
            pergunta: '',
            id_questao: '',
            id_tema_secao: '',
            tema_selecionado: '',
            opcoes: [],
            nivel: '',
            resposta: '',
            adicao_questao: false,
            opcao_texto: '',
            opcao_correta: false,
            respondida: false,
            status: false
        })
        this.props.close()
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    checkboxHandleChange(event) {
        this.setState({
            [event.target.name]: event.target.checked,
        })
    }

    getTemasSecoes = (isLoading = false) => {
        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/questoes/GetTemasSecoes.php`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization':AppState.token,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                }
            })
            .then((response) => {
                this.setState({
                    temas_secoes: response.request,
                    temas: [...new Set(response.request.map((val) => {return val.tema}))]
                }, () => { 
                    if (isLoading == false) {
                        AppState.isLoading = isLoading;
                    };
                    });
                })
            .catch(function(error) {
                if (isLoading == false) {
                    AppState.isLoading = isLoading;
                }
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    if(ret == '40001') {
                        console.log("40001 - Erro desconhecido");
                    }
                    else
                        alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })

    }

    addQuestao = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            pergunta: this.state.pergunta,
            nivel: this.state.nivel,
            id_tema_secao: this.state.id_tema_secao,
            resposta: this.state.resposta,
            opcoes: this.state.opcoes.map((x) => { return {"texto": x.texto, "correto": x.correto == 't' || x.correto === true ? true : false}})
        })

        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/questoes/AddQuestao.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })

            .then((response) => {
                if(!response.ok) {
                    return response.json().then((json) => {
                        var error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                }

                const res = response.json()
                return res;
                AppState.isLoading = false
            })
            .then((response) => {
                AppState.isLoading = false;
                this.closeDialog()

            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    if (ret == "40003") {
                        alert("Opções inválidas")
                    } else {
                        alert("Dados inválidos");
                    }
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    editQuestao = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            id_questao: this.state.id_questao,
            pergunta: this.state.pergunta,
            nivel: this.state.nivel,
            id_tema_secao: this.state.id_tema_secao,
            resposta: this.state.resposta,
            opcoes: this.state.opcoes.map((x) => { return {"texto": x.texto, "correto": x.correto == 't' || x.correto === true ? true : false}}),
            status: this.state.status
        })

        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/questoes/UpdateQuestao.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })
        .then((response) => {
            if(!response.ok) {
                return response.json().then((json) => {
                    var error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            }

            const res = response.json()
            return res;
            AppState.isLoading = false
        })
        .then((response) => {
            AppState.isLoading = false;
            this.closeDialog()
        })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                console.log(ret)
                if (ret == "40003") {
                    alert("Opções inválidas")
                } else if (ret == "40004") {
                    alert("Questão já foi respondida em treinamentos / avaliações")
                } else {
                    alert("Dados inválidos");
                }
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    render() {
        return (
            <Dialog
                style={{ height: "110vh" }}
                maxWidth={"md"}
                fullWidth={true}
                open={this.props.open}
                onClose={this.props.close}
                disableBackdropClick={true}
            >
                <DialogContent>
                    <Icon
                        style={{ float: "right" }}
                        title={"fechar"}
                        onClick={this.closeDialog}
                    >
                        close
                    </Icon>
                <Typography variant="h4" style={{marginBottom:'40px'}}>Questao</Typography>

                <Grid
                    container
                    spacing={32}
                >
                    <Grid item sm={6}>
                        <TextField
                            value={this.state.pergunta}
                            label={"Pergunta"}
                            name={"pergunta"}
                            fullWidth
                            onChange={(e) => this.handleChange(e)}
                        />

                        <div style={{paddingTop: "1rem"}}>
                            <FormControl fullWidth={true}>
                                <InputLabel htmlFor="temas">Selecione um Tema</InputLabel>
                                <Select
                                    value={ this.state.tema_selecionado }
                                    inputProps={{
                                        name: 'tema_selecionado',
                                        id: 'tema_selecionado',
                                    }}
                                    onChange={(e) => this.handleChange(e)}
                                >
                                    {this.state.temas.map((item, idx)=>(
                                        <MenuItem key={idx} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        
                        <div style={{paddingTop: "1rem"}}>
                            <TextField
                                value={this.state.resposta}
                                label={"Resposta"}
                                name={"resposta"}
                                fullWidth
                                onChange={(e) => this.handleChange(e)}
                            />
                        </div>
                    </Grid>

                    <Grid 
                        item 
                        sm={6}
                    >
                        <FormControl fullWidth={true} style={{alignItems:"center"}}>
                            <InputLabel htmlFor="nivel">Nível:</InputLabel>
                            <br></br>
                            {(!this.props.edicao || this.state.nivel != '') &&
                                <StarRating
                                    initialRating={this.state.nivel}
                                    handleOnClick={value => this.setState({nivel: value})}
                                    emptyColor={'#9ea6d8'}
                                    size={'20px'}
                                    count={3}
                                />
                            }
                        </FormControl>

                        <div style={{paddingTop: "1rem"}}>
                            <FormControl fullWidth={true} disabled={this.state.tema_selecionado == ""}>
                                <InputLabel htmlFor="temas">Selecione uma Seção</InputLabel>
                                <Select
                                    value={ this.state.id_tema_secao }
                                    inputProps={{
                                        name: 'id_tema_secao',
                                        id: 'id_tema_secao',
                                    }}
                                    onChange={(e) => this.handleChange(e)}
                                >
                                    {this.state.temas_secoes.filter((x) => x.tema == this.state.tema_selecionado).map((item, idx)=>(
                                        <MenuItem key={idx} value={item.id_tema_secao}>{item.secao}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{paddingTop: "1rem"}}>
                            <FormControlLabel control={<Checkbox 
                                checked={this.state.status}
                                value="status"
                                name={"status"}
                                onChange={(e) => this.checkboxHandleChange(e)} />} 
                                label={this.state.status ? 'Ativo' : 'Inativo'} 
                            />
                        </div>

                    </Grid>

                </Grid>
                <Grid
                    container
                    spacing={32}
                    alignItems='center'
                    justify='space-between'
                    style={{display: "flex", flexDirection: "row", width: "100%", paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "1rem"}}
                >
                    <FormControl style={{width: "60%"}} >
                        {this.state.adicao_questao && 
                        <TextField label={"Texto da opção"}
                            value={this.state.opcao_texto}
                            name={"opcao_texto"}
                            onChange={(e) => this.handleChange(e)}
                        />
                        }
                    </FormControl>

                    <FormControl style={{width: "10%"}} >
                    {this.state.adicao_questao && 
                        <FormControlLabel 
                            label="Correto" 
                            control={
                            <Checkbox
                                checked={this.state.opcao_correta}
                                value="opcao_correta"
                                name={"opcao_correta"}
                                onChange={(e) => this.checkboxHandleChange(e)}
                            />} 
                            />
                    }
                    </FormControl>
                    
                    <FormControl style={{width: "10%"}} >
                        {this.state.adicao_questao && 
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    if (this.state.opcoes.length !== 4) {
                                        this.setState(prevState => {
                                            let newOption = {texto: prevState.opcao_texto, correto: prevState.opcao_correta}
                                            return {
                                                opcoes: prevState.opcoes.concat(newOption),
                                                opcao_texto: '',
                                                opcao_correta: false
                                            }
                                        })
                                    } else {
                                        alert('Não é possível adicionar mais de 4 opções')
                                    }
                                }}
                            >
                                Confirmar
                            </Button>
                        }
                        {!this.state.adicao_questao &&
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    this.setState({
                                        adicao_questao: true
                                    })
                                }}
                                disabled={this.state.respondida == 't'}
                            >
                                <AddIcon/>
                                Opção
                            </Button>
                        }
                    </FormControl>
                </Grid>
                <Grid
                    container
                    spacing={32}
                    style={{paddingTop: "3rem", paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "3rem"}}
                >
                
                    <ReactTable
                        data={this.state.opcoes}
                        className="-striped -highlight"
                        defaultPageSize={4}
                        showPaginationBottom={false}
                        style={{width: "100%"}}
                        columns={[
                            {
                                Header: "Opção",
                                accessor: "texto",
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Correto",
                                accessor: "correto",
                                width: 100,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value === false || row.value === 'f' ? "Não" : "Sim"} </div> )
                            }, {
                                Header: "Ação",
                                width: 100,
                                Cell: row =>
                                    <div
                                        style={{
                                        height: "22px",
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        position: "relative"
                                        }}
                                    >
                                        {this.state.respondida == 'f' &&
                                            <DeleteIcon
                                                onClick={() =>
                                                    this.setState(prevState => {
                                                        return {
                                                            opcoes: prevState.opcoes.filter((x) => x.texto != row.original.texto)
                                                        }
                                                    })
                                                }
                                                style={{
                                                    cursor: "pointer",
                                                    width: "22px",
                                                    height: "22px",
                                                    minWidth: "22px",
                                                    minHeight: "22px",
                                                    marginLeft: "5px"
                                                }}
                                            />
                                        }
                                    </div>
                            }
                        ]}
                    />
                </Grid>

                <Grid style={{marginTop: "2em"}} container={true} direction="row" justify="center" alignItems="center">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.state.edicao ? this.editQuestao : this.addQuestao}
                    >
                        Salvar
                    </Button>
                </Grid>

                </DialogContent>
            </Dialog>)
    }   

}

export default Form;