import React from 'react';
import {
  Button,
  Dialog,
  Grid,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Input,
  Chip,
  FormControl,
  Switch,
  withStyles,
} from '@material-ui/core';
import AppState from '../../state';
import { browserHistory } from 'react-router';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { Utils } from '../../services';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import moment from 'moment';

import * as projetoAPI from '../../api/projetos';
import CurrencyInput from '../../components/lib/CurrencyInput';

const styles = {
  halfGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%',
    maxWidth: '100%',
    flexBasis: 'auto',
  },
};

const BigChip = withStyles({
  deleteIcon: {
    position: 'absolute',
    right: '0',
  },
})(Chip);

const inputMask =
  (mask) =>
  ({ inputRef, ...other }) => {
    return (
      <MaskedInput {...other} /*ref={inputRef}*/ mask={mask} guide={false} />
    );
  };

const numberMaskDefaultOptions = {
  prefix: 'R$ ',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
};

const percentualMaskOptions = {
  prefix: '',
  suffix: ' %',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
};

const dateMask = inputMask([
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]);
const percentualMask = inputMask(createNumberMask(percentualMaskOptions));

dateMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.aditivoInput = React.createRef();
    this.contratoInput = React.createRef();

    this.state = {
      edicao: false,
      nome: '',
      nomeErro: false,
      descProjeto: '',
      descProjetoErro: false,
      tiposDeProjeto: [],
      tipoSelecionado: '',
      tipoSelecionadoErro: false,
      responsavel: '',
      responsavelErro: false,
      responsavelList: [],
      cliente: '',
      clienteErro: false,
      clienteList: [],
      status: true,
      date: '',
      dateErro: false,
      numeroProcesso: '',
      numeroProcessoErro: false,
      coordenador: [],
      coordenadorErro: false,
      coodernadorList: [],
      fiscal: '',
      fiscais: [],
      fiscaisErro: false,
      valorProjeto: '',
      anexoAditivoLabel: 'Anexar Aditivo',
      valorAditivo: '',
      vencimentoAditivo: '',
      dataInicioVigencia: '',
      anexoAditivo: '',
      anexocontrato: '',
      aditivos: [],
      contrato: [],
      linkContrato: null,
      dtEncerramento: null,
      descricaoCurta: '',
      valorBaseProjeto: '',
      numProjeto: '',
      valorProjetoCalculado: Number(0),
      issContrato: '',
      pisContrato: '',
      cofinsContrato: '',
      csllContrato: '',
      irContrato: '',
    };
  }

  getDados = () => {
    this.getProjectTypes();
    this.getClientList();
    this.getCoordenadores();
    this.getResponsaveis();
  };

  getDataPrimeiroDiaMesCorrente = () => {
    const date = moment(new Date()).startOf('month').format('DD/MM/YYYY');
    this.setState({ date });
  };

  componentDidMount() {
    this.getDados();
    this.getDataPrimeiroDiaMesCorrente();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.edicao !== this.props.edicao) {
      this.setState(
        {
          edicao: this.props.edicao,
        },
        () => {
          if (this.props.edicao) {
            this.getProjectData();
          }
        }
      );
    }
  }

  getProjectData = () => {
    AppState.isLoading = true;
    projetoAPI
      .getProjectById(this.props.idProjeto)
      .then((response) => {
        let array = response.request[0].coordenadores;
        let ret = [];
        array.forEach((item) => {
          ret.push(
            this.state.coodernadorList.filter(
              (el) => el.cpf_usuario === item.cpf_usuario
            )[0]
          );
        });

        const valorBaseProjeto = parseFloat(
          response.request[0].valor_base_projeto
        );
        const aditivos = response.request[0].aditivos.map((aditivo) => ({
          ...aditivo,
          valor: parseFloat(aditivo.valor),
        }));
        const totalAditivos = aditivos.reduce(
          (total, { valor }) => (total += valor),
          0
        );
        const valorProjetoCalculado = valorBaseProjeto + totalAditivos;

        this.setState(
          {
            numProjeto: response.request[0].id_projeto,
            nome: response.request[0].desc_curta,
            descProjeto: response.request[0].desc_completa,
            tipoSelecionado: response.request[0].tipo_projeto,
            responsavel: response.request[0].cpf_responsavel,
            cliente: response.request[0].cpf_cnpj_cliente,
            date: Utils.dateToPT(response.request[0].dt_inicio),
            numeroProcesso: response.request[0].numero_processo,
            coordenador: ret,
            fiscais: response.request[0].fiscais,
            dtEncerramento: response.request[0].dt_encerramento,
            status: response.request[0].dt_encerramento === null,
            valorProjeto: Utils.float2moeda(
              response.request[0].valor_total_projeto
            ),
            valorBaseProjeto,
            linkContrato: response.request[0].link_contrato,
            aditivos,
            valorProjetoCalculado,
            issContrato: Utils.float2moeda(response.request[0].iss_contrato),
            pisContrato: Utils.float2moeda(response.request[0].pis_contrato),
            cofinsContrato: Utils.float2moeda(
              response.request[0].cofins_contrato
            ),
            csllContrato: Utils.float2moeda(response.request[0].csll_contrato),
            irContrato: Utils.float2moeda(response.request[0].ir_contrato),
          },
          () => {
            if (this.state.linkContrato) {
              this.setState({
                contrato: [{ name: 'Contrato Anexado' }],
              });
            }
            AppState.isLoading = false;
          }
        );
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getProjectTypes = () => {
    projetoAPI
      .getProjectTypes()
      .then((response) => {
        this.setState({
          tiposDeProjeto: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 erro desconhecido');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getClientList = () => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/cliente/GetClientes.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState({
          clienteList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40002') {
            console.log('40001 Dados icorretos/invalidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getResponsaveis = () => {
    projetoAPI
      .getResponsaveis()
      .then((response) => {
        this.setState({
          responsavelList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 erro desconhecido');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getCoordenadores = () => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/usuarios/GetUsers.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          coodernadorList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  closeDialog = () => {
    this.setState(
      {
        edicao: false,
        nome: '',
        nomeErro: false,
        descProjeto: '',
        descProjetoErro: false,
        tipoSelecionado: '',
        tipoSelecionadoErro: false,
        responsavel: '',
        responsavelErro: false,
        cliente: '',
        clienteErro: false,
        date: moment(new Date()).startOf('month').format('DD/MM/YYYY'),
        dateErro: false,
        numeroProcesso: '',
        numeroProcessoErro: false,
        coordenador: [],
        cpfCoordenador: [],
        coordenadorErro: false,
        fiscal: '',
        fiscais: [],
        status: true,
        fiscaisErro: false,
        valorProjeto: '',
        valorProjetoCalculado: 0,
        anexoAditivoLabel: 'Anexar Aditivo',
        valorAditivo: '',
        vencimentoAditivo: '',
        dataInicioVigencia: '',
        anexoAditivo: '',
        anexocontrato: '',
        aditivos: [],
        contrato: [],
        linkContrato: null,
        descricaoCurta: '',
        valorBaseProjeto: '',
        numProjeto: '',
        valorAditivoErro: false,
        dataInicioVigenciaErro: false,
        vencimentoAditivoErro: false,
        issContrato: '',
        pisContrato: '',
        cofinsContrato: '',
        csllContrato: '',
        irContrato: '',
      },
      () => this.props.close()
    );
  };

  coordenadorHandleChange = (event) => {
    if (event.target.value && event.target.value.length > 0) {
      this.setState({
        coordenador: event.target.value,
      });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  fiscaisHandleChange = () => {
    this.setState({
      fiscais: [...this.state.fiscais, { nome_fiscal: this.state.fiscal }],
    });
    this.setState({
      fiscal: '',
    });
  };

  deleteChip = (index) => {
    let arr = this.state.fiscais;

    arr.splice(index, 1);

    this.setState({
      fiscais: arr,
    });
  };

  validaForm = () => {
    let formValido = true;

    if (!this.state.nome || this.state.nome === '') {
      alert('Informe o nome do projeto');
      formValido = false;
      this.setState({
        nomeErro: true,
      });
    }

    if (this.state.nome && this.state.nome.length > 24) {
      alert('O nome do projeto não pode conter mais de 24 caracteres');
      formValido = false;
      this.setState({
        nomeErro: true,
      });
    }

    if (!this.state.descProjeto || this.state.descProjeto === '') {
      alert('Informe a descrição do projeto');
      formValido = false;
      this.setState({
        descProjetoErro: true,
      });
    }

    if (this.state.descProjeto && this.state.descProjeto.length > 128) {
      alert('A descrição do projeto não pode conter mais de 128 caracteres');
      formValido = false;
      this.setState({
        descProjetoErro: true,
      });
    }

    if (!this.state.tipoSelecionado || this.state.tipoSelecionado === '') {
      formValido = false;
      this.setState({
        tipoSelecionadoErro: true,
      });
    }

    if (!this.state.responsavel || this.state.responsavel === '') {
      formValido = false;
      this.setState({
        responsavelErro: true,
      });
    }

    if (!this.state.cliente || this.state.cliente === '') {
      formValido = false;
      this.setState({
        clienteErro: true,
      });
    }

    if (
      !this.state.status &&
      (!this.state.dtEncerramento || this.state.dtEncerramento === '')
    ) {
      formValido = false;
      this.setState({
        dtEncerramentoErro: true,
      });
    }

    if (!this.state.date || this.state.date === '') {
      formValido = false;
      this.setState({
        dateErro: true,
      });
    }

    if (!this.state.issContrato || this.state.issContrato == '') {
      formValido = false;
      this.setState({
        issContratoErro: true,
      });
    }
    if (!this.state.pisContrato || this.state.pisContrato == '') {
      formValido = false;
      this.setState({
        pisContratoErro: true,
      });
    }
    if (!this.state.cofinsContrato || this.state.cofinsContrato == '') {
      formValido = false;
      this.setState({
        cofinsContratoErro: true,
      });
    }
    if (!this.state.csllContrato || this.state.csllContrato == '') {
      formValido = false;
      this.setState({
        csllContratoErro: true,
      });
    }
    if (!this.state.irContrato || this.state.irContrato == '') {
      formValido = false;
      this.setState({
        irContratoErro: true,
      });
    }

    if (!this.isTamanhoNovosAnexosPermitido()) {
      formValido = false;
      alert('A soma do tamanho dos novos anexos não pode exceder os 25mb.');
    }

    if (!this.isQuantidadeAditiviosPermitida()) {
      formValido = false;
      alert('O limite de aditivos a serem anexados é de 4 arquivos');
    }

    if (formValido) {
      this.handleSave();
    }
  };

  handleSave = () => {
    if (this.state.edicao) {
      this.editProject();
    } else if (!this.state.edicao) {
      this.addNewProject();
    }
  };

  addNewProject = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      id_projeto: this.state.numProjeto,
      desc_curta: this.state.nome,
      desc_completa: this.state.descProjeto,
      tipo_projeto: this.state.tipoSelecionado,
      cpf_responsavel: this.state.responsavel,
      numero_processo: this.state.numeroProcesso,
      dt_inicio: Utils.dateToEN(this.state.date),
      dt_encerramento: null,
      cpf_cnpj_cliente: this.state.cliente,
      coordenadores: this.state.coordenador,
      fiscais: this.state.fiscais,
      valor_projeto: Utils.moeda2float(this.state.valorProjeto),
      valor_base_projeto: this.state.valorBaseProjeto,
      aditivos: this.state.aditivos,
      iss_contrato: Utils.percent2float(this.state.issContrato),
      pis_contrato: Utils.percent2float(this.state.pisContrato),
      cofins_contrato: Utils.percent2float(this.state.cofinsContrato),
      csll_contrato: Utils.percent2float(this.state.csllContrato),
      ir_contrato: Utils.percent2float(this.state.irContrato),
    });

    var projetoData = new FormData();
    projetoData.append('json', data);
    projetoData.append('file', this.state.contrato[0]);

    this.state.aditivos.forEach((item, index) => {
      projetoData.append('aditivo' + index, item.anexoAditivo);
    });

    projetoAPI
      .addProject(projetoData)
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        const Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          const ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40004') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40003') {
            console.log('Projeto criado mas falhou ao inserir coordenadores');
            alert(
              'Projeto criado mas falhou ao inserir coordenadores ou fiscais'
            );
          } else if (ret === '40002') {
            console.log(
              '40002 Accountable person is not registered as an user'
            );
            alert('Responsável pelo Projeto não cadastrado como usuário.');
          } else if (ret === '40006') {
            console.log('Este número de projeto já existe.');
            alert('Este número de projeto já existe.');
          } else if (ret === '40007') {
            const primeiroDia = moment(new Date())
              .startOf('month')
              .format('DD/MM/YYYY');
            console.log(
              '4007 data de início do projeto maior que ' + primeiroDia
            );
            alert(
              'A data de início do projeto não pode ser maior que ' +
                primeiroDia
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  isTamanhoNovosAnexosPermitido = () => {
    const tamanhoContratoInKb =
      this.state.contrato[0] && this.state.contrato[0].size
        ? this.state.contrato[0].size / 1000
        : 0;
    const tamanhoAditivosInKb = this.state.aditivos
      .filter((aditivo) => !aditivo.id_aditivo)
      .reduce((acc, { anexoAditivo = 0 }) => {
        return (acc += anexoAditivo.size / 1000);
      }, 0);

    const limitePermitidoInKb = 25000;
    return tamanhoContratoInKb + tamanhoAditivosInKb <= limitePermitidoInKb;
  };

  isQuantidadeAditiviosPermitida = () => {
    return (
      this.state.aditivos.filter((aditivo) => !aditivo.id_aditivo).length <= 4
    );
  };

  editProject = () => {
    AppState.isLoading = true;
    const today = new Date();

    const data = JSON.stringify({
      id_projeto: this.props.idProjeto,
      desc_curta: this.state.nome,
      desc_completa: this.state.descProjeto,
      tipo_projeto: this.state.tipoSelecionado,
      cpf_responsavel: this.state.responsavel,
      numero_processo: this.state.numeroProcesso,
      dt_inicio: Utils.dateToEN(this.state.date),
      dt_encerramento: !this.state.status
        ? this.state.dtEncerramento
          ? Utils.dateToEN(this.state.dtEncerramento)
          : today.toISOString()
        : null,
      cpf_cnpj_cliente: this.state.cliente,
      coordenadores: this.state.coordenador,
      fiscais: this.state.fiscais,
      valor_total_projeto: Utils.moeda2float(this.state.valorProjeto),
      valor_base_projeto: this.state.valorBaseProjeto,
      aditivos: this.state.aditivos,
      link_contrato: this.state.linkContrato,
      iss_contrato: Utils.percent2float(this.state.issContrato),
      pis_contrato: Utils.percent2float(this.state.pisContrato),
      cofins_contrato: Utils.percent2float(this.state.cofinsContrato),
      csll_contrato: Utils.percent2float(this.state.csllContrato),
      ir_contrato: Utils.percent2float(this.state.irContrato),
    });

    var projetoData = new FormData();
    projetoData.append('json', data);
    projetoData.append('file', this.state.contrato[0]);

    this.state.aditivos.forEach((item, index) => {
      projetoData.append('aditivo' + index, item.anexoAditivo);
    });

    projetoAPI
      .editProject(projetoData)
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002' || ret === '40004') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40003') {
            console.log('Projeto criado mas falhou ao inserir coordenadores');
            alert(
              'Projeto criado mas falhou ao inserir coordenadores ou fiscais'
            );
          } else if (ret === '40005') {
            alert(
              'This project cannot be closed because it has revenue on last 3 months.'
            );
          } else if (ret === '40006') {
            alert(
              'Não foi possivel encerrar este projeto pois existem lançamentos posteriores à data de encerramento.'
            );
          } else if (ret === '40007') {
            alert(
              'Não foi possivel encerrar este projeto pois existem títulos em aberto vinculados à este projeto.'
            );
          } else if (ret === '40008') {
            alert(
              'Não foi possivel encerrar este projeto pois existem lançamentos posteriores à data de encerramento.'
            );
          } else if (ret === '40009') {
            alert(
              'Não foi possivel encerrar este projeto pois a data de encerramento é superior ao último dia do mês anterior.'
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  valorProjetoHandleChange = (event) => {
    this.setState({
      valorProjeto: event.target.value,
    });
  };

  valorBaseProjetoHandleChange = (event) => {
    const totalAditivo = this.state.aditivos.reduce(
      (total, { valor }) => (total += valor),
      0
    );
    const valorBaseProjeto = Utils.moeda2float(event.target.value);
    const valorProjetoCalculado = valorBaseProjeto + totalAditivo;
    this.setState({
      valorBaseProjeto,
      valorProjetoCalculado: valorProjetoCalculado,
    });
  };

  statusHandleChange = (event) => {
    if (this.state.edicao) {
      this.setState({
        status: event.target.checked,
        dtEncerramento: moment(new Date())
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      });
    }
  };

  handleAditivoFile = (event) => {
    event.preventDefault();

    if (this.aditivoInput.current.files[0]) {
      this.setState({
        anexoAditivoLabel: this.aditivoInput.current.files[0].name,
        anexoAditivo: this.aditivoInput.current.files[0],
      });
    }

    event.target.value = null;
  };

  deleteAditivoChip = (index) => {
    let aditivos = this.state.aditivos;
    aditivos.splice(index, 1);

    const totalAditivos = aditivos.reduce(
      (total, { valor }) => (total += valor),
      0
    );
    const valorProjetoCalculado = this.state.valorBaseProjeto + totalAditivos;

    this.setState({
      valorProjetoCalculado,
      aditivos,
    });
  };

  handleContratoFile = (event) => {
    event.preventDefault();

    if (this.contratoInput.current.files[0]) {
      this.setState({
        contrato: [this.contratoInput.current.files[0]],
      });
    }

    event.target.value = null;
  };

  deleteContratoChip = (index) => {
    let arr = this.state.contrato;

    arr.splice(index, 1);

    this.setState({
      contrato: arr,
      linkContrato: null,
    });
  };

  validaAditivo = () => {
    let aditivoValido = true;

    if (!this.state.valorAditivo || !this.state.valorAditivo) {
      aditivoValido = false;
      this.setState({
        valorAditivoErro: true,
      });
    }

    if (!this.state.vencimentoAditivo || !this.state.vencimentoAditivo) {
      aditivoValido = false;
      this.setState({
        vencimentoAditivoErro: true,
      });
    } else if (this.state.vencimentoAditivo !== '') {
      if (!moment(this.state.vencimentoAditivo, 'DD/MM/YYYY', true).isValid()) {
        aditivoValido = false;
        this.setState({
          vencimentoAditivoErro: true,
        });
      }
    }

    if (!this.state.dataInicioVigencia || !this.state.dataInicioVigencia) {
      aditivoValido = false;
      this.setState({
        dataInicioVigenciaErro: true,
      });
    } else if (this.state.dataInicioVigencia !== '') {
      if (
        !moment(this.state.dataInicioVigencia, 'DD/MM/YYYY', true).isValid()
      ) {
        aditivoValido = false;
        this.setState({
          dataInicioVigenciaErro: true,
        });
      }
    }

    const novosAditivos = this.state.aditivos.filter(
      (aditivo) => !aditivo.id_aditivo
    );
    if (novosAditivos.length === 4) {
      aditivoValido = false;
      alert('Não é possível anexar mais de 4 novos aditivos por vez');
    }

    if (aditivoValido) {
      this.criaAditivo();
    }
  };

  criaAditivo = () => {
    const aditivos = [
      ...this.state.aditivos,
      {
        id_aditivo: null,
        valor: Utils.moeda2float(this.state.valorAditivo),
        dt_fim_vigencia: Utils.dateToEN(this.state.vencimentoAditivo),
        dt_inicio_vigencia: Utils.dateToEN(this.state.dataInicioVigencia),
        anexoAditivo: this.state.anexoAditivo,
      },
    ];

    const totalAditivo = aditivos.reduce(
      (total, { valor }) => (total += valor),
      0
    );
    const valorProjetoCalculado = this.state.valorBaseProjeto + totalAditivo;

    this.setState({
      valorAditivo: '',
      vencimentoAditivo: '',
      dataInicioVigencia: '',
      anexoAditivo: '',
      anexoAditivoLabel: 'Anexar Aditivo',
      valorProjetoCalculado,
      aditivos: [
        ...this.state.aditivos,
        {
          id_aditivo: null,
          valor: Utils.moeda2float(this.state.valorAditivo),
          dt_fim_vigencia: Utils.dateToEN(this.state.vencimentoAditivo),
          dt_inicio_vigencia: Utils.dateToEN(this.state.dataInicioVigencia),
          anexoAditivo: this.state.anexoAditivo,
        },
      ],
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeDialog}
        onEnter={this.getProjectTypes}
        disableBackdropClick={true}
        fullScreen
      >
        <Grid container style={{ padding: '24px 24px' }}>
          <Grid
            item
            sm={10}
            container
            direction="row"
            justify="space-between"
            style={{
              maxWidth: '100%',
              flexGrow: '1',
              flexBasis: 'auto',
              flexWrap: 'nowrap',
            }}
          >
            <Typography variant="h4">Cadastro de Projeto</Typography>
            <Icon onClick={() => this.closeDialog()}>close</Icon>
          </Grid>

          <Grid container spacing={32} style={{ marginTop: '20px' }}>
            <Grid item sm={6}>
              <Grid container spacing={16}>
                <Grid item style={styles.halfGrid}>
                  <TextField
                    margin="dense"
                    value={this.state.numProjeto}
                    onChange={this.handleChange('numProjeto')}
                    label="Número do Projeto"
                    type="number"
                    fullWidth
                    disabled={this.state.edicao}
                  />
                  <TextField
                    margin="dense"
                    id="nomeProjeto"
                    value={this.state.nome}
                    onChange={this.handleChange('nome')}
                    error={this.state.nomeErro}
                    label="Nome do Projeto"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <TextField
                margin="dense"
                id="descProjeto"
                value={this.state.descProjeto}
                onChange={this.handleChange('descProjeto')}
                error={this.state.descProjetoErro}
                label="Descrição do Projeto"
                type="text"
                fullWidth
              />

              <FormControl fullWidth={true}>
                <InputLabel>Tipo do Projeto</InputLabel>
                <Select
                  value={this.state.tipoSelecionado}
                  error={this.state.tipoSelecionadoErro}
                  onChange={this.handleChange('tipoSelecionado')}
                >
                  {this.state.tiposDeProjeto.map((item, index) => (
                    <MenuItem key={index} value={item.tipo_projeto}>
                      {item.tipo_projeto}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth={true}>
                <InputLabel>Responsável</InputLabel>
                <Select
                  value={this.state.responsavel}
                  error={this.state.responsavelErro}
                  onChange={this.handleChange('responsavel')}
                >
                  {this.state.responsavelList.map((item, index) => (
                    <MenuItem key={index} value={item.cpf_funcionario}>
                      {item.nome_funcionario}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth={true}>
                <InputLabel>Cliente</InputLabel>
                <Select
                  value={this.state.cliente}
                  error={this.state.clienteErro}
                  onChange={this.handleChange('cliente')}
                >
                  {this.state.clienteList.map((item, index) => (
                    <MenuItem key={index} value={item.cpf_cnpj_cliente}>
                      {item.nome_lista}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                margin="dense"
                id="date"
                label="Data de Início"
                type="text"
                error={this.state.dateErro}
                fullWidth
                value={this.state.date}
                onChange={this.handleChange('date')}
                InputProps={{
                  inputComponent: dateMask,
                }}
              />

              <CurrencyInput
                margin="dense"
                value={this.state.valorBaseProjeto}
                onChange={this.valorBaseProjetoHandleChange}
                label="Valor Base do Projeto"
                type="text"
                fullWidth
                style={{ marginTop: '0px' }}
              />

              <TextField
                margin="dense"
                id="numeroProcesso"
                value={this.state.numeroProcesso}
                onChange={this.handleChange('numeroProcesso')}
                error={this.state.numeroProcessoErro}
                label="Número do Processo"
                type="text"
                fullWidth
              />
              <div>
                Valor Total do Projeto: R${' '}
                {Utils.float2moeda(this.state.valorProjetoCalculado)}
              </div>
            </Grid>

            <Grid item sm={6}>
              <div style={{ marginTop: '14px' }}>
                <InputLabel>Status do Projeto: </InputLabel>
                <Switch
                  disabled={this.props.idProjeto === '0'}
                  color={'primary'}
                  checked={this.state.status}
                  onChange={this.statusHandleChange}
                />{' '}
                {this.state.status ? 'Ativo' : 'Encerrado'}
              </div>

              {!this.state.status && (
                <FormControl fullWidth={true}>
                  <TextField
                    label={'Data de Encerramento'}
                    value={Utils.dateToPT(this.state.dtEncerramento)}
                    error={this.state.dtEncerramentoErro}
                    InputProps={{
                      inputComponent: dateMask,
                    }}
                    onChange={this.handleChange('dtEncerramento')}
                  />
                </FormControl>
              )}

              <InputLabel>Percentuais</InputLabel>
              <Grid container spacing={16}>
                <Grid item sm={6} style={styles.halfGrid}>
                  <TextField
                    InputProps={{
                      inputComponent: percentualMask,
                    }}
                    margin="dense"
                    id="iss_contrato"
                    value={this.state.issContrato}
                    onChange={this.handleChange('issContrato')}
                    error={this.state.issContratoErro}
                    label="ISS (%)"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    InputProps={{
                      inputComponent: percentualMask,
                    }}
                    margin="dense"
                    id="pis_contrato"
                    value={this.state.pisContrato}
                    onChange={this.handleChange('pisContrato')}
                    error={this.state.pisContratoErro}
                    label="PIS (%)"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} style={styles.halfGrid}>
                  <TextField
                    InputProps={{
                      inputComponent: percentualMask,
                    }}
                    margin="dense"
                    id="cofins_contrato"
                    value={this.state.cofinsContrato}
                    onChange={this.handleChange('cofinsContrato')}
                    error={this.state.cofinsContratoErro}
                    label="COFINS (%)"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    InputProps={{
                      inputComponent: percentualMask,
                    }}
                    margin="dense"
                    id="csll_contrato"
                    value={this.state.csllContrato}
                    onChange={this.handleChange('csllContrato')}
                    error={this.state.csllContratoErro}
                    label="CSLL (%)"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <FormControl fullWidth>
                <TextField
                  InputProps={{
                    inputComponent: percentualMask,
                  }}
                  margin="dense"
                  id="ir_contrato"
                  value={this.state.irContrato}
                  onChange={this.handleChange('irContrato')}
                  error={this.state.irContratoErro}
                  label="IR (%)"
                  type="text"
                  fullWidth
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Coordenadores</InputLabel>
                <Select
                  multiple
                  error={this.state.coordenadorErro}
                  value={this.state.coordenador}
                  onChange={this.coordenadorHandleChange}
                  input={<Input id="select-multiple-chip" />}
                  style={{ wordWrap: 'break-word' }}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value, index) => (
                        <Chip
                          key={index}
                          label={
                            this.state.coodernadorList.filter(
                              (item) => item.cpf_usuario === value.cpf_usuario
                            )[0].nome_usuario
                          }
                          style={{
                            margin: '3px 3px',
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        ></Chip>
                      ))}
                    </div>
                  )}
                >
                  {this.state.coodernadorList.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.nome_usuario}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Grid container spacing={8}>
                <Grid item sm={10} style={styles.halfGrid}>
                  <TextField
                    margin="dense"
                    id="fiscais"
                    label="Fiscais"
                    type="text"
                    fullWidth
                    error={this.state.fiscaisErro}
                    value={this.state.fiscal}
                    onChange={this.handleChange('fiscal')}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginTop: '20px' }}
                    onClick={this.fiscaisHandleChange}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>

              {this.state.fiscais.map((item, index) => (
                <BigChip
                  key={index}
                  style={{
                    margin: '3px 3px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                  }}
                  label={item.nome_fiscal}
                  onDelete={() => this.deleteChip(index)}
                />
              ))}

              <Grid container spacing={16}>
                <Grid item md={3}>
                  <InputLabel style={{ fontSize: '18px', marginTop: '28px' }}>
                    Contratos
                  </InputLabel>
                </Grid>
                <Grid item md={9}>
                  <label
                    htmlFor="outlined-contrato-file"
                    style={{ marginTop: '21px', width: '100%' }}
                  >
                    <input
                      id="outlined-contrato-file"
                      type="file"
                      style={{ display: 'none' }}
                      ref={this.contratoInput}
                      onChange={this.handleContratoFile}
                      disabled={this.state.contrato.length > 0}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth={true}
                      disabled={this.state.contrato.length > 0}
                    >
                      Anexar Contrato
                    </Button>
                  </label>
                </Grid>
              </Grid>

              {this.state.contrato.map((item, index) => (
                <BigChip
                  key={index}
                  style={{
                    margin: '3px 3px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                  }}
                  label={
                    <a href={this.state.linkContrato} target={'_blank'}>
                      {item.name}
                    </a>
                  }
                  component="a"
                  clickable={this.state.linkContrato !== null}
                  onDelete={() => this.deleteContratoChip(index)}
                />
              ))}

              <InputLabel style={{ fontSize: '18px', marginTop: '40px' }}>
                Aditivos
              </InputLabel>

              <Grid container spacing={16}>
                <Grid item md={4} style={styles.halfGrid}>
                  <FormControl fullWidth={true}>
                    <TextField
                      label={'Início Vigência'}
                      value={this.state.dataInicioVigencia}
                      error={this.state.dataInicioVigenciaErro}
                      InputProps={{
                        inputComponent: dateMask,
                      }}
                      onChange={this.handleChange('dataInicioVigencia')}
                    />
                  </FormControl>
                  <FormControl fullWidth={true}>
                    <TextField
                      label={'Fim Vigência'}
                      value={this.state.vencimentoAditivo}
                      error={this.state.vencimentoAditivoErro}
                      InputProps={{
                        inputComponent: dateMask,
                      }}
                      onChange={this.handleChange('vencimentoAditivo')}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} style={styles.halfGrid}>
                  <FormControl fullWidth={true}>
                    <CurrencyInput
                      label={'Valor do Aditivo'}
                      error={this.state.valorAditivoErro}
                      allowNegative
                      value={this.state.valorAditivo}
                      onChange={this.handleChange('valorAditivo')}
                    />
                  </FormControl>
                  <label
                    htmlFor="outlined-aditivo-file"
                    style={{ marginTop: '16px' }}
                  >
                    <input
                      id="outlined-aditivo-file"
                      type="file"
                      style={{ display: 'none' }}
                      ref={this.aditivoInput}
                      onChange={this.handleAditivoFile}
                    />
                    <Button
                      variant="outlined"
                      fullWidth={true}
                      component="span"
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {this.state.anexoAditivoLabel}
                    </Button>
                  </label>
                </Grid>
              </Grid>

              <Button
                color="primary"
                variant="outlined"
                fullWidth={true}
                style={{ marginTop: '8px', marginBottom: '8px' }}
                onClick={this.validaAditivo}
              >
                Adicionar Aditivo
              </Button>

              {this.state.aditivos.map((item, index) => (
                <BigChip
                  key={index}
                  style={{
                    margin: '3px 3px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                  }}
                  label={
                    item.link_aditivo !== null && item.link_aditivo !== '' ? (
                      <a
                        href={item.link_aditivo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {(item.id_aditivo ? `ID: ${item.id_aditivo} •` : '') +
                          `Início: ${Utils.dateToPT(
                            item.dt_inicio_vigencia
                          )} Vencimento: ${Utils.dateToPT(
                            item.dt_fim_vigencia
                          )} • Valor: R$${Utils.float2moeda(item.valor)}`}
                      </a>
                    ) : (
                      (item.id_aditivo ? `ID: ${item.id_aditivo} •` : '') +
                      `Início: ${Utils.dateToPT(
                        item.dt_inicio_vigencia
                      )} Vencimento: ${Utils.dateToPT(
                        item.dt_fim_vigencia
                      )} • Valor: R$${Utils.float2moeda(item.valor)}`
                    )
                  }
                  clickable={false}
                  onDelete={() => this.deleteAditivoChip(index)}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ margin: '30px 0' }}
        >
          <Button color="primary" variant="contained" onClick={this.validaForm}>
            Salvar
          </Button>
        </Grid>
      </Dialog>
    );
  }
}

export default Form;
