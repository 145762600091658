import React from "react";
import {FormControl, Grid, MenuItem, InputLabel, Select, TextField, Button} from "@material-ui/core";
import ReactTable from "react-table";
import AppState from "../../../state";
import {browserHistory} from "react-router";
import moment from "moment";
import debounce from 'lodash.debounce';
import _ from "lodash";
import ReactExport from "react-data-export";
import { Utils } from "../../../services";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class RelatorioTreinamentos extends React.Component {
    constructor(props) {
        super(props);

        this.reactTable = React.createRef();

        this.state = {
            trainings: [],
            table_data: [],
            period: "EsseMes",
            dtInicio: moment().startOf('month').format('YYYY-MM-DD'),
            dtFim: moment().endOf('month').format('YYYY-MM-DD'),
            temas: [],
            temas_secoes: [],
            tema_selecionado: '',
            id_tema_secao: '',
            nivel_selecionado: 0
        };
        this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
    }

    componentDidMount() {
        this.getTrainings();
        this.getTemasSecoes();
    }

    safe_division = (num, den) => {
        if (num && den && den > 0) {
            return num / den
        } else {
            return 0
        }
    }

    dateFilterHandleChange = name => event => {
        this.setState(
            {
            [name]: event.target.value
            },
            () => {
            if (
                this.state.dtInicio.length === 10 &&
                this.state.dtFim.length === 10
            )
                this._debouncedHandleChange();
            }
        );
    };

    getTemasSecoes = (isLoading = false) => {
        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/questoes/GetTemasSecoes.php`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization':AppState.token,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                }
            })
            .then((response) => {
                this.setState({
                    temas_secoes: response.request,
                    temas: [...new Set(response.request.map((val) => {return val.tema}))]
                }, () => { 
                    if (isLoading == false) {
                        AppState.isLoading = isLoading;
                    };
                    });
                })
            .catch(function(error) {
                if (isLoading == false) {
                    AppState.isLoading = isLoading;
                }
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    if(ret == '40001') {
                        console.log("40001 - Erro desconhecido");
                    }
                    else
                        alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })

    }

    customAccessor = (row) => {
        if (row) {
            return parseInt(100 * this.safe_division(row.respostas_certas, row.respostas))
        }
        return 
    }

    getTrainings = function () {
        AppState.isLoading = true;
        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/relatorios/GetRelatorioTreinamentos.php?json={"dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}"}`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                trainings: response.request,
                table_data: this.reduceTrainingData(response.request)
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    reduceTrainingData = (data) => {
        let users = data.map((val) => {return {'cpf_usuario': val.cpf_usuario, 'nome_usuario': val.nome_usuario}})
        users = this.getUniqueListBy(users, 'cpf_usuario')

        let filtered_data = data.filter((x) => !!x.pergunta)
        if (this.state.nivel_selecionado > 0) {
            filtered_data = filtered_data.filter((x) => x.nivel == this.state.nivel_selecionado)
        }
        if (this.state.tema_selecionado !== '') {
            filtered_data = filtered_data.filter((x) => x.tema == this.state.tema_selecionado)
        }
        if (this.state.id_tema_secao !== '') {
            filtered_data = filtered_data.filter((x) => x.id_tema_secao == this.state.id_tema_secao)
        }

        let response = []
        let obj = []
        for (let i = 0; i < users.length; i++) {
            let user = users[i];
            obj = {
                'cpf_usuario': user.cpf_usuario,
                'nome_usuario': user.nome_usuario,
                'respostas': filtered_data.filter((x) => x.cpf_usuario == user.cpf_usuario).length,
                'respostas_certas': filtered_data.filter((x) => x.cpf_usuario == user.cpf_usuario && x.correto == 't').length
            };
            response.push(obj);
        }
        return response
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            this.setState({
                table_data: this.reduceTrainingData(this.state.trainings)
            })
        });
    };

    periodHandleChange = event => {
        let dtInicio = "";
        let dtFim = "";
        if (event.target.value == "EsseMes") {
            dtInicio = moment().startOf('month').format('YYYY-MM-DD');
            dtFim = moment().endOf('month').format('YYYY-MM-DD');
        } else if (event.target.value == "MesAnterior") {
            dtInicio = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
            dtFim = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
        }
        this.setState(
        {
            dtInicio: dtInicio,
            dtFim: dtFim,
            period: event.target.value
        },
        () => {
            if (this.state.period != "custom") {
                this.getTrainings();
            }
        }
        );
    };

    dateHandleChange = name => event => {
        this.setState(
        {
            [name]: event.target.value
        },
        () => {
            if (this.state.dtInicio.length === 10 && this.state.dtFim.length === 10)
            this._debouncedHandleChange();
        }
        );
    };

    _debouncedHandleChange = () => {
        this.getTrainings();
    }

    render() {
        return (
            <div id={this.constructor.name} style={{ marginTop: "5em" }}>
                <h1>Relatório de Treinamentos</h1>

                <Grid 
                    container
                    justify="center"
                    style={{paddingTop: "4rem"}}
                >
                    <Grid 
                        item
                    >
                        <Grid 
                            container
                            justify="flex-start"
                            alignItems="center"
                            style={{display: "flex", flexDirection: "row", paddingBottom: "1rem"}}
                        >
                            <FormControl
                                component="fieldset"
                                style={{ width: "200px", paddingTop: "13px", marginLeft: "13px" }}
                            >
                                <InputLabel htmlFor="temas">Filtre um Tema</InputLabel>
                                <Select
                                    value={ this.state.tema_selecionado }
                                    inputProps={{
                                        name: 'tema_selecionado',
                                        id: 'tema_selecionado',
                                    }}
                                    // style={{ margin: "0.5em 0px" }}
                                    onChange={(e) => this.handleChange(e)}
                                >
                                    {this.state.temas.map((item, idx)=>(
                                        <MenuItem key={idx} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                component="fieldset"
                                style={{ width: "200px", paddingTop: "13px", marginLeft: "13px" }}
                                disabled={this.state.tema_selecionado == ""}
                            >
                                <InputLabel htmlFor="temas">Filtre uma Seção</InputLabel>
                                <Select
                                    value={ this.state.id_tema_secao }
                                    inputProps={{
                                        name: 'id_tema_secao',
                                        id: 'id_tema_secao',
                                    }}
                                    // style={{ margin: "0.5em 0px" }}
                                    onChange={(e) => this.handleChange(e)}
                                >
                                    {this.state.temas_secoes.filter((x) => x.tema == this.state.tema_selecionado).map((item, idx)=>(
                                        <MenuItem key={idx} value={item.id_tema_secao}>{item.secao}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                component="fieldset"
                                style={{ width: "200px", paddingTop: "13px", marginLeft: "13px" }}
                            >
                                <InputLabel htmlFor={"nivel_selecionadoFilter"}>
                                {" "}
                                Filtro de Nível{" "}
                                </InputLabel>
                                <Select
                                inputProps={{ id: "nivel_selecionado", name: "nivel_selecionado" }}
                                // style={{ margin: "0.5em 0px" }}
                                value={this.state.nivel_selecionado}
                                onChange={this.handleChange}
                                >
                                <MenuItem value={1}>Fácil</MenuItem>
                                <MenuItem value={2}>Médio</MenuItem>
                                <MenuItem value={3}>Difícil</MenuItem>
                                <MenuItem value={0}>Todos</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                            component="fieldset"
                            style={{ width: "200px", paddingTop: "13px", marginLeft: "13px" }}
                            >
                            <InputLabel htmlFor={"period"}>
                                {" "}
                                Período de Resposta{" "}
                            </InputLabel>
                            <Select
                                inputProps={{ id: "period", name: "period" }}
                                // style={{ margin: "0.5em 0px" }}
                                value={this.state.period || "Ontem"}
                                onChange={this.periodHandleChange}
                            >
                                <MenuItem value={"EsseMes"}>Esse Mês</MenuItem>
                                <MenuItem value={"MesAnterior"}>Mês Anterior</MenuItem>
                                <MenuItem value={"custom"}>Customizado</MenuItem>
                            </Select>
                            </FormControl>
                            {this.state.period === "custom" && (
                            <TextField
                                style={{
                                marginLeft: "32px",
                                marginRight: "16px",
                                paddingTop: "13px"
                                }}
                                label="Inicio"
                                type="date"
                                defaultValue={this.state.dtInicio}
                                onChange={this.dateFilterHandleChange("dtInicio")}
                                InputLabelProps={{
                                shrink: true
                                }}
                            />
                            )}

                            {this.state.period === "custom" && (
                            <TextField
                                style={{ paddingTop: "13px", marginRight: "16px" }}
                                label="Fim"
                                type="date"
                                defaultValue={this.state.dtFim}
                                onChange={this.dateFilterHandleChange("dtFim")}
                                InputLabelProps={{
                                shrink: true
                                }}
                            />
                            )}
                            <ExcelFile
                                filename={"RelatorioTreinamentos-" + Utils.getTodayDate()}
                                element={
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.exportarExcel}
                                >
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 192 192"
                                    style={{ fill: "#000000", paddingRight: "6px" }}
                                    >
                                    <g
                                        fill="none"
                                        fillRule="nonzero"
                                        stroke="none"
                                        strokeWidth="1"
                                        strokeLinecap="butt"
                                        strokeLinejoin="miter"
                                        strokeMiterlimit="10"
                                        strokeDasharray=""
                                        strokeDashoffset="0"
                                        fontFamily="none"
                                        fontWeight="none"
                                        fontSize="none"
                                        textAnchor="none"
                                        style={{ mixBlendMode: "normal" }}
                                    >
                                        <path d="M0,192v-192h192v192z" fill="none"></path>
                                        <g fill="#ffffff">
                                        <g id="surface1">
                                            <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                                        </g>
                                        </g>
                                    </g>
                                    </svg>
                                    Exportar
                                </Button>
                                }
                            >
                                <ExcelSheet
                                data={() => this.selectTable.getResolvedState().sortedData}
                                name="Treinamentos"
                                >
                                    <ExcelColumn
                                        label="CPF"
                                        value="cpf_usuario"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    <ExcelColumn
                                        label="Nome"
                                        value="nome_usuario"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    <ExcelColumn
                                        label="Respostas"
                                        value="respostas"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    <ExcelColumn
                                        label="% de acerto"
                                        value="acerto"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    </ExcelSheet>
                                </ExcelFile>
                        </Grid>
                        <ReactTable
                        filterable={true}
                        data={this.state.table_data}
                        pageSize={20}
                        className="-striped -highlight"
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        }
                        ref={r => {
                            this.selectTable = r;
                        }}
                        defaultSorted={[{id: 'acertos', desc: true}]}
                        columns={[
                            {
                                Header: "CPF",
                                accessor: "cpf_usuario",
                                width: 200,
                                PivotValue: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Nome",
                                accessor: "nome_usuario",
                                width: 400,
                                PivotValue: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }
                            , {
                                Header: "Respostas",
                                accessor: 'respostas',
                                width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "% de Acerto",
                                id: 'acerto',
                                accessor: this.customAccessor,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > 
                                    {row.value} % </div> )
                            }
                        ]}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default RelatorioTreinamentos;