import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { Grid, MenuItem, Select, Button } from "@material-ui/core/";
import { browserHistory } from "react-router";
import ReactExport from "react-data-export";
import Utils from "../../../services/utils";

import AppState from "../../../state";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const getTodayDate = () => {
  var today = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  );
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = dd + "/" + mm + "/" + yyyy;
  return today;
};

const FuncionarioProjetoHoras = props => {
  const date = new Date();
  const [data, setData] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(
    `${date.getMonth() + 1}-${date.getFullYear()}`
  );
  useEffect(() => {
    AppState.isLoading = true;

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/relatorios/GetFuncionarioHorasProjetoReport.php?periodo=${currentFilter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: AppState.token
        }
      }
    )
      .then(response => {
        if (response.status === 401) {
          AppState.isLoading = false;
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          return;
        }
        const res = response.json();
        return res;
      })
      .then(response => {
        AppState.isLoading = false;
        setData(response.request);
      })
      .catch(function(error) {
        AppState.isLoading = false;
        console.log("Erro de api!", error);
      });
  }, [currentFilter]);

  const handleChange = event => {
    setCurrentFilter(event.target.value);
  };

  const exportarExcel = () => {};

  const allMonths = {
    1: "Janeiro",
    2: "Fevereiro",
    3: "Março",
    4: "Abril",
    5: "Maio",
    6: "Junho",
    7: "Julho",
    8: "Agosto",
    9: "Setembro",
    10: "Outubro",
    11: "Novembro",
    12: "Dezembro"
  };

  const monthDate = new Date();
  const QTD_ULTIMOS_MESES = 6;
  date.setDate(1);
  let months = [];

  for (let ct = 0; ct < QTD_ULTIMOS_MESES; ct++) {
    months = months.concat(
      <MenuItem
        key={ct}
        value={`${monthDate.getMonth() + 1}-${monthDate.getFullYear()}`}
      >{`${
        allMonths[monthDate.getMonth() + 1]
      }/${monthDate.getFullYear()}`}</MenuItem>
    );
    monthDate.setMonth(monthDate.getMonth() - 1);
  }

  return (
    <div style={{ marginTop: "5em" }}>
      <h1>Apontamento Horas X Funcionários X Projetos</h1>

      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ marginTop: "30px", marginBottom: "10px" }}
      >
        <Grid item xs={6}>
          <Select
            value={currentFilter}
            onChange={handleChange}
            style={{ minWidth: "120px" }}
          >
            {months}
          </Select>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <ExcelFile
            filename={
              "RelatorioApontamentoHorasFuncionarioProjeto-" + getTodayDate()
            }
            element={
              <Button
                variant="contained"
                color="primary"
                onClick={exportarExcel}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 192 192"
                  style={{ fill: "#000000", paddingRight: "6px" }}
                >
                  <g
                    fill="none"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <path d="M0,192v-192h192v192z" fill="none"></path>
                    <g fill="#ffffff">
                      <g id="surface1">
                        <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                      </g>
                    </g>
                  </g>
                </svg>
                Exportar
              </Button>
            }
          >
            <ExcelSheet data={data} name="Apontamentos">
              <ExcelColumn
                label="Nº"
                value="id_projeto"
                style={{ font: { sz: "24", bold: true } }}
              />
              <ExcelColumn
                label="Projeto"
                value="desc_curta"
                style={{ font: { sz: "24", bold: true } }}
              />
              <ExcelColumn
                label="Tipo"
                value="tipo_projeto"
                style={{ font: { sz: "24", bold: true } }}
              />
              <ExcelColumn
                label="CPF Funcionário"
                value="cpf_funcionario"
                style={{ font: { sz: "24", bold: true } }}
              />
              <ExcelColumn
                label="Funcionário"
                value="nome_funcionario"
                style={{ font: { sz: "24", bold: true } }}
              />

              <ExcelColumn
                label="Total de Horas"
                value="total_horas_periodo"
                style={{ font: { sz: "24", bold: true } }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Grid>
        <Grid item xs={12}>
          <ReactTable
            style={{ marginTop: "5px" }}
            filterable
            defaultPageSize={10}
            data={data}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .startsWith(filter.value.toLowerCase())
            }
            columns={[
              {
                Header: "Nº",
                accessor: "id_projeto",
                maxWidth: 50,
                Cell: row => (
                  <div
                    style={{
                      textAlign: "center",
                      position: "relative",
                      top: "30%"
                    }}
                  >
                    {row.value}
                  </div>
                )
              },
              {
                Header: "Projeto",
                accessor: "desc_curta",
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {row.value}
                  </div>
                )
              },
              {
                Header: "Tipo",
                accessor: "tipo_projeto",
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {row.value}
                  </div>
                )
              },
              {
                Header: "CPF Funcionário",
                accessor: "cpf_funcionario",
                maxWidth: 190,
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {row.value}
                  </div>
                )
              },
              {
                Header: "Funcionário",
                accessor: "nome_funcionario",
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {row.value}
                  </div>
                )
              },
              {
                Header: "Total de Horas",
                accessor: "total_horas_periodo",
                maxWidth: 110,
                Cell: row => (
                  <div
                    style={{
                      height: "22px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {Utils.float2moeda(row.value)}
                  </div>
                ),
                Footer: row => {
                  const totalHoras = row.data.reduce(
                    (total, { total_horas_periodo }) =>
                      (total += total_horas_periodo),
                    0
                  );

                  return Utils.float2moeda(totalHoras);
                }
              }
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FuncionarioProjetoHoras;
