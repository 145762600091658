import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";

const RelatorioIndicadores = ({ data, setRef, ...outrasProps }) => (
  <ReactTable
    {...outrasProps}
    data={data}
    ref={setRef}
    columns={[
      {
        Header: "ID",
        accessor: "id_projeto",
        maxWidth: 40
      },
      {
        Header: "Projeto",
        accessor: "desc_curta",
        maxWidth: 160
      },
      {
        Header: "Tipo",
        accessor: "tipo_projeto",
        maxWidth: 140
      },
      {
        Header: "MC/A&C(%)",
        accessor: "mcac",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const mcTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.mc)),
            0
          );
          const acTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.ac)),
            0
          );
          const calc = (mcTotal / acTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "TDD/REC(%)",
        accessor: "tddrec",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const tddTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.tdd)),
            0
          );
          const receitaTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.receita)),
            0
          );
          const calc = (tddTotal / receitaTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "A&C/REC(%)",
        accessor: "acrec",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const acTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.ac)),
            0
          );
          const receitaTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.receita)),
            0
          );
          const calc = (acTotal / receitaTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "I&T/REC(%)",
        accessor: "itrec",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const itTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.it)),
            0
          );
          const receitaTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.receita)),
            0
          );
          const calc = (itTotal / receitaTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "TDI/REC(%)",
        accessor: "tdirec",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const tdiTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.tdi)),
            0
          );
          const receitaTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.receita)),
            0
          );
          const calc = (tdiTotal / receitaTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "RES/REC(%)",
        accessor: "resrec",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const resultadoTotal = row.data.reduce(
            (total, { _original }) =>
              (total += parseFloat(_original.resultado)),
            0
          );
          const receitaTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.receita)),
            0
          );
          const calc = (resultadoTotal / receitaTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "A&C/TDD(%)",
        accessor: "actdd",
        maxWidth: 140,
        filterable: false,

        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const acTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.ac)),
            0
          );
          const tddTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.tdd)),
            0
          );
          const calc = (acTotal / tddTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "I&T/TDD(%)",
        accessor: "ittdd",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const itTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.it)),
            0
          );
          const tddTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.tdd)),
            0
          );
          const calc = (itTotal / tddTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "RES/TDD(%)",
        accessor: "restdd",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const resultadoTotal = row.data.reduce(
            (total, { _original }) =>
              (total += parseFloat(_original.resultado)),
            0
          );
          const tddTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.tdd)),
            0
          );
          const calc = (resultadoTotal / tddTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "BDI(%)",
        accessor: "bdi",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const receitaTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.receita)),
            0
          );
          const tddTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.tdd)),
            0
          );
          const calc = (receitaTotal / tddTotal - 1) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      },
      {
        Header: "BDI E(%)",
        accessor: "bdie",
        maxWidth: 140,
        filterable: false,
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {row.value}
          </div>
        ),
        Footer: row => {
          const tdiTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.tdi)),
            0
          );
          const tddTotal = row.data.reduce(
            (total, { _original }) => (total += parseFloat(_original.tdd)),
            0
          );
          const calc = (tdiTotal / tddTotal) * 100;
          return <span>{Math.round(calc)}%</span>;
        }
      }
    ]}
  />
);

RelatorioIndicadores.propTypes = {
  setRef: PropTypes.func,
  data: PropTypes.array
};

export default RelatorioIndicadores;
