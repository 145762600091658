import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

let fileTypes = {
  'xls': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  'txt': 'text/plain',
  'html': 'text/html',
  'img': 'image/*',
  'csv': '.csv',
  'pdf': '.pdf'
}

const ImportButton = ({
  templateUrl,
  onImport,
  importButtonText = "Importar",
  downloadTemplateText = "Baixar Template",
  fileType = "xls"
}) => (
  <div>
    {templateUrl ?
    (<a href={templateUrl} download>
      {downloadTemplateText}
    </a>)
    : ""
    }
    <div
      style={{
        marginTop: "-14px"
      }}
    >
      <label
        htmlFor="raised-button-file"
        style={{ width: "100%", marginTop: "16px" }}
      >
        <input
          accept={fileTypes[fileType]}
          id="raised-button-file"
          type="file"
          style={{ display: "none" }}
          onChange={onImport}
        />
        <Button
          variant="outlined"
          component="span"
          fullWidth={true}
          color={"primary"}
          style={{ height: "36px" }}
        >
          {importButtonText}
        </Button>
      </label>
    </div>
  </div>
);

ImportButton.propTypes = {
  templateUrl: PropTypes.string,
  onImport: PropTypes.func.isRequired,
  importButtonText: PropTypes.string,
  downloadTemplateText: PropTypes.string,
  fileType: PropTypes.string
};

export default ImportButton;
