import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "@material-ui/core";

const CurrencyInput = ({ allowNegative = false, darkDisabled = false, ...rest}) => {
  return (
    <NumberFormat
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"R$ "}
      inputMode="numeric"
      fixedDecimalScale
      decimalScale={2}
      customInput={TextField}
      allowNegative={allowNegative}
      {...rest}
    />
  );
};

export default CurrencyInput;
