import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField
} from "@material-ui/core";
import ReactTable from "react-table";
import { rateioDespEconomico } from "../../../api/relatorios";
import AppState from "../../../state";
import Utils from "../../../services/utils";
import ReactExport from "react-data-export";
import RelatorioConsolidado from "../gerenciais/RelatorioGerencial";
import {browserHistory} from "react-router";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let preparaDado = dados => {
  if (dados == null || dados.length < 1) return [];
  let ret = dados.slice(0);

  return ret;
};

const RelatorioRateioDespEconomico = () => {
  const [rateios, setRateios] = useState([]);
  let reactTable = useRef();

  const currentDate = new Date();
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const [filterData, setFilterData] = useState({
    dt_inicial: firstDay.toISOString().slice(0,7),
    dt_final: firstDay.toISOString().slice(0,7)
  });

  useEffect(() => {
    const fetchData = async () => {
      AppState.isLoading = true;
      const { dt_inicial, dt_final } = filterData;
      try {
        const {request: data} = await rateioDespEconomico(`${dt_inicial}-01`, `${dt_final}-01`);
        AppState.isLoading = false;
        setRateios(data);
      }catch(error){
          AppState.isLoading = false;
          if (error.toString() == "" || error.toString().split(" ").length < 2) {
            alert("Erro desconhecido, tente novamente");
            return;
          }
          var Err_status = error.toString().split(" ")[1];
          if (Err_status == 400) {
            var ret = error.response["status"].value.split(" ")[0];
            if (ret == "40001") {
              console.log("40001 Invalid JSON value found");
            } else alert("Dados inválidos");
          } else if (Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status == 500) {
            alert("Erro desconhecido, tente novamente");
          }
      }
    };

    fetchData();
  }, [filterData]);

  return (
    <Grid style={{ marginTop: "4em" }}>
      <Grid container alignItems={"center"}>
        <Grid item sm={6}>
          <h1>Rateio Desp. Econômico</h1>
        </Grid>
      </Grid>
      <Grid container justify="space-between">
        <Grid item>
          <FormControl
            component="fieldset"
            style={{ width: "230px", paddingTop: "13px", paddingRight: "10px" }}
          >
            <TextField
              style={{
                margin: "0px 16px 8px 32px",
                paddingTop: "5px"
              }}
              label="Data Inicial"
              type="month"
              defaultValue={filterData.dt_inicial}
              onChange={ev =>
                setFilterData({ ...filterData, dt_inicial: ev.target.value })
              }
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
          <FormControl
            component="fieldset"
            style={{ width: "230px", paddingTop: "13px" }}
          >
            <TextField
              style={{
                margin: "0px 16px 8px 32px",
                paddingTop: "5px"
              }}
              label="Data Final"
              type="month"
              defaultValue={filterData.dt_final}
              onChange={ev =>
                setFilterData({ ...filterData, dt_final: ev.target.value })
              }
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container spacing={32}>
            <Grid item style={{ marginTop: "10px" }}>
              <ExcelFile
                filename={"RelRateioDespEconomica-" + Utils.getTodayDate()}
                element={
                  <Button variant="contained" color="primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="24"
                      height="24"
                      viewBox="0 0 192 192"
                      style={{ fill: "#000000", paddingRight: "6px" }}
                    >
                      <g
                        fill="none"
                        fillRule="nonzero"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="10"
                        strokeDasharray=""
                        strokeDashoffset="0"
                        fontFamily="none"
                        fontWeight="none"
                        fontSize="none"
                        textAnchor="none"
                        style={{ mixBlendMode: "normal" }}
                      >
                        <path d="M0,192v-192h192v192z" fill="none"></path>
                        <g fill="#ffffff">
                          <g id="surface1">
                            <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Exportar
                  </Button>
                }
              >
                <ExcelSheet
                    data={() =>
                        preparaDado(
                            reactTable.getResolvedState().sortedData
                        )
                    }
                    name="Relat. Rateio Desp. Econômica">
                  <ExcelColumn
                    label="Tipo Contraparte"
                    value="tipo_contraparte"
                    style={{ font: { sz: "24", bold: true } }}
                  />
                  <ExcelColumn
                    label="CPF/CNPJ Contraparte"
                    value="cpf_cnpj_contraparte"
                    style={{ font: { sz: "24", bold: true } }}
                  />
                  <ExcelColumn
                    label="Nome Contraparte"
                    value="nome_contraparte"
                    style={{ font: { sz: "24", bold: true } }}
                  />
                  <ExcelColumn
                    label="Código Conta"
                    value="id_conta"
                    style={{ font: { sz: "24", bold: true } }}
                  />
                  <ExcelColumn
                    label="Descrição Conta"
                    value="descricao_conta"
                    style={{ font: { sz: "24", bold: true } }}
                  />
                  <ExcelColumn
                    label="Nº Projeto"
                    value="id_projeto"
                    style={{ font: { sz: "24", bold: true } }}
                  />
                  <ExcelColumn
                    label="Nome Projeto"
                    value="descricao_curta_projeto"
                    style={{ font: { sz: "24", bold: true } }}
                  />
                  <ExcelColumn
                    label="Descrição Pagamento"
                    value="descricao"
                    style={{ font: { sz: "24", bold: true } }}
                  />
                  <ExcelColumn
                    label="Data Pagto/Receb"
                    value={({ dt_pag_recb }) => Utils.dateToPT(dt_pag_recb)}
                    style={{ font: { sz: "24", bold: true } }}
                  />
                  <ExcelColumn
                      label="Valor"
                      value={({ valor }) => `R$ ${Utils.float2moeda(valor)}`}
                      style={{ font: { sz: "24", bold: true } }}
                  />
                </ExcelSheet>
              </ExcelFile>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <ReactTable
          data={rateios}
          defaultPageSize={100}
          defaultFilterMethod={(filter, row) =>
             String(row[filter.id])
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase())
          }
          ref={(r) => (reactTable = r)}
          columns={[
            {
              Header: "Tipo Contraparte",
              filterable: true,
              accessor: "tipo_contraparte"
            },
            {
              Header: "CPF/CNPJ Contraparte",
              filterable: true,
              accessor: "cpf_cnpj_contraparte"
            },
            {
              Header: "Nome Contraparte",
              filterable: true,
              accessor: "nome_contraparte"
            },
            {
              Header: "Código Conta",
              filterable: true,
              accessor: "id_conta"
            },
            {
              Header: "Descrição Conta",
              filterable: true,
              accessor: "descricao_conta"
            },
            {
              Header: "Nº Projeto",
              filterable: true,
              accessor: "id_projeto"
            },
            {
              Header: "Nome Projeto",
              filterable: true,
              accessor: "descricao_curta_projeto"
            },
            {
              Header: "Descrição Pagamento",
              filterable: true,
              accessor: "descricao"
            },
            {
              Header: "Data Pagto/Receb",
              accessor: "dt_pag_recb",
              Cell: ({ value }) => Utils.dateToPT(value)
            },
            {
              Header: "Valor",
              accessor: "valor",
              Cell: ({ value }) => `R$ ${Utils.float2moeda(value)}`,
              Footer: row => (
                <span>
                  {// Get the total of the price
                  "R$ " +
                    Utils.float2moeda(
                      row.data.reduce(
                        (total, { valor }) => (total += parseFloat(valor)),
                        0
                      )
                    )}
                </span>
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default RelatorioRateioDespEconomico;
