import React from "react";
import PropTypes from "prop-types";
import ReactExport from "react-data-export";

import ExportButton from "./ExportButton";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportXLS = ({ columns, data, filename, buttonTitle }) => {
  return (
    <ExcelFile
      filename={filename}
      element={<ExportButton title={buttonTitle} />}
    >
      <ExcelSheet data={data} name="RelatorioDespesasPessoas">
        {columns.map(column => (
          <ExcelColumn style={{ font: { sz: "24", bold: true } }} {...column} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};

ExportXLS.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      string: PropTypes.object
    })
  ),
  data: PropTypes.oneOf([PropTypes.array, PropTypes.func]),
  filename: PropTypes.string,
  buttonTitle: PropTypes.string
};

export default ExportXLS;
