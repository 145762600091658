import AppState from "../state";

const baseUrl = `${process.env.PUBLIC_URL}/${AppState.serverAddr}/funcionarios`;

const addFuncionario = funcionarioData => {
  return fetch(`${baseUrl}/AddFuncionario.php`, {
    method: "POST",
    headers: {
      Authorization: AppState.token
    },
    body: funcionarioData
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }

    const res = response.json();
    return res;
  });
};

const editFuncionario = funcionarioData => {
  return fetch(`${baseUrl}/EditFuncionario.php`, {
    method: "POST",
    headers: {
      Authorization: AppState.token
    },
    body: funcionarioData
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }

    const res = response.json();
    return res;
  });
};

const addDespesaPessoalFuncionario = funcionarios => {
  return fetch(`${baseUrl}/AddDespesasPessoaisFuncionarios.php`, {
    method: "POST",
    headers: {
      Authorization: AppState.token
    },
    body: JSON.stringify(funcionarios)
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        const error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }

    const res = response.json();
    return res;
  });
};

const getDespesasPessoaisFuncionarios = data => {
  return fetch(
    `${baseUrl}/GetDespesasPessoaisFuncionarios.php?filtro_data=${data}`,
    {
      method: "GET",
      headers: {
        Authorization: AppState.token
      }
    }
  )
    .then(response => {
      if (!response.ok) {
        return response.json().then(json => {
          const error = new Error(response.status);
          error.response = json;
          throw error;
        });
      }

      const res = response.json();
      return res;
    })
    .then(response => {
      return response.request;
    });
};

export {
  addFuncionario,
  editFuncionario,
  addDespesaPessoalFuncionario,
  getDespesasPessoaisFuncionarios
};
