import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Checkbox,
} from "@material-ui/core";
import { Utils } from "../../../services";

const ConfirmDeleteForm = ({ lancamento, onConfirm }) => {
  const { valor, id_projeto, dt_pag_recb, id_lancamento } = lancamento;
  const [isSelected, setIsSelected] = useState(false);
  const toogle = () => {
    setIsSelected(!isSelected);
  };
  return (
    <Grid
      container
      spacing={32}
    >
      <Grid item md={10}>
        Tem certeza que deseja apagar o pagamento de R${" "}
        {Utils.float2moeda(valor)} realizado na data{" "}
        {Utils.dateToPT(dt_pag_recb)} para o projeto {id_projeto}?
      </Grid>
      <Grid item md={10}>
        <Checkbox
          checked={isSelected}
          onChange={toogle}
          inputProps={{
            "aria-label": "primary checkbox"
          }}
        />{" "}
        Deletar o título e o pgto/Recb
      </Grid>
      <Grid container item md={10} direction="row" justify="center" alignItems="center">
        <Button onClick={() => onConfirm(id_lancamento, isSelected)} color="primary" variant="contained">
          Deletar
        </Button>
      </Grid>
    </Grid>
  );
};

ConfirmDeleteForm.propTypes = {
  lancamento: PropTypes.shape({
    valor: PropTypes.string,
    id_projeto: PropTypes.string,
    id_lancamento: PropTypes.string,
    dt_pag_recb: PropTypes.string,
  }).isRequired,
  onConfirm: PropTypes.func
};

export default ConfirmDeleteForm;
