import React, { useState } from 'react';
import './style.css';

import Menu from './Menu';
import { Navbar, Nav, NavItem } from 'react-bootstrap/lib';
import Menu_structure from './Menu_structure';
import useMediaQuery from '../../hooks/useMediaQuery';

const maxMenu = 5;

const Topbar = (props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [treinamentos, setTreinamentos] = useState(
    window.location.href.includes('treinamento') ? true : false
  );

  const data = JSON.parse(localStorage.getItem('Menu')) || {};
  const userActs = data.views.map((f) => f.id_view);
  const topbarMenuPermission = userActs;

  const hasAccess = (topMenu) => {
    if (!topMenu) return false;

    if (topMenu.view) {
      return topbarMenuPermission.includes(topMenu.view);
    }

    if (topMenu.items && topMenu.items != []) {
      let ret = false;
      topMenu.items.forEach(function (item, index) {
        ret = ret || topbarMenuPermission.includes(item.view);
      });
      return ret;
    }
    return false;
  };

  let menuItems = Menu_structure.menus.items.filter((e) => hasAccess(e));
  const menuKeys = menuItems.length;

  const shouldShowTreinamentos = menuKeys > maxMenu;

  return (
    <Navbar
      fixedTop={true}
      style={{ backgroundColor: '#528e70', color: 'white' }}
      collapseOnSelect
    >
      {isMobile ? (
        <>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Menu key={treinamentos} treinamentos={treinamentos} />
            <MenuRightButtons
              shouldShowTreinamentos={shouldShowTreinamentos}
              setTreinamentos={setTreinamentos}
            />
          </Navbar.Collapse>
        </>
      ) : (
        <>
          <Menu key={treinamentos} treinamentos={treinamentos} />
          <MenuRightButtons
            shouldShowTreinamentos={shouldShowTreinamentos}
            setTreinamentos={setTreinamentos}
          />
        </>
      )}
    </Navbar>
  );
};

const MenuRightButtons = ({ shouldShowTreinamentos, setTreinamentos }) => {
  const userName = JSON.parse(localStorage.getItem('userInfo')).nome_usuario;

  return (
    <>
      <Nav pullRight={true} style={{ marginRight: '0.3em' }}>
        <NavItem className="menuNavItem" href={'/perfil'}>
          <span className="profileLinkText">{userName}</span>
        </NavItem>
      </Nav>
      {shouldShowTreinamentos && (
        <Nav pullRight={true} style={{ marginRight: '0.3em' }}>
          <NavItem
            className="menuNavItem"
            onClick={() => {
              setTreinamentos((prevState) => !prevState);
            }}
          >
            Treinamento / Avaliação
          </NavItem>
        </Nav>
      )}
    </>
  );
};

export default Topbar;
