import React from 'react'
import ReactTable from "react-table";

import {
    MenuItem,
    Select,
    TextField,
    Paper
} from '@material-ui/core/';
import AppState from "../../../state";
import {browserHistory} from "react-router";
import {Utils} from "../../../services";


class UsuarioXprojeto extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: []
        }
        this.getReport()
    }

    getReport = () => {
        AppState.isLoading = true;


        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/relatorios/GetUsuarioProjetoReport.php?`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': AppState.token
                }
            })
            .then((response) => {
                if (response.status === 401) {
                    AppState.isLoading = false;
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                    return;
                }
                const res = response.json();
                return res;
            })
            .then((response) => {
                AppState.isLoading = false;
                this.setState({
                    data: response.request
                })
            })
            .catch(function (error) {
                AppState.isLoading = false;
                console.log("Erro de api!", error);
            });
    }


    render() {

        return (
            <div id={this.constructor.name} style={{marginTop: "5em"}}>
                <h1>Usuários X Projetos</h1>

                <ReactTable
                    style={{marginTop: '88px'}}
                    filterable
                    defaultPageSize={100}
                    data={this.state.data}
                    defaultFilterMethod={(filter, row) =>
                        (
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        )
                    }
                    columns={
                        [
                            {
                                "Header": "CPF Usuário",
                                "accessor": "cpf_usuario",
                                "maxWidth": 190,
                                Cell: row => (
                                    <div
                                        style={{
                                            height: '22px',
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                            position: 'relative',
                                        }}
                                    >{row.value}
                                    </div>
                                )
                            },
                            {
                                "Header": "Usuário",
                                "accessor": "nome_usuario",
                                Cell: row => (
                                    <div
                                        style={{
                                            height: '22px',
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                            position: 'relative',
                                        }}
                                    >{row.value}
                                    </div>
                                )
                            },
                            {
                                "Header": "Projeto",
                                "accessor": "projeto_display",
                                Cell: row => (
                                    <div
                                        style={{
                                            height: '22px',
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                            position: 'relative',
                                        }}
                                    >{row.value}
                                    </div>
                                )
                            },
                            {
                                "Header": "Coordenador",
                                "accessor": "is_coordenador",
                                "maxWidth": 110,
                                Cell: row => (
                                    <div
                                        style={{
                                            height: '22px',
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                            position: 'relative',
                                        }}
                                    >{row.value}
                                    </div>
                                )
                            }
                        ]
                    }
                />

            </div>
        )
    }

}

export default UsuarioXprojeto;