import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Utils } from "../../services";

const styles = {
  card: {
    minWidth: 250,
    minHeight: 120
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
};

function SimpleCard(props) {
  const { classes, title, value, format = 'money', secondValue = null, subtitle = null } = props;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="h2">
          {subtitle && "Total: "}
          {
          format === 'money' ? 'R$ ' + `${Utils.float2moeda(value).slice(0, -3)}` : 
          format === 'percentage' ? `${Math.round(value)}%` :
          value
          }
        </Typography>
        <Typography variant="body" component="h4">
          {subtitle}
          {subtitle && ": "}
          { secondValue && (
          format === 'money' ? 'R$ ' + `${Utils.float2moeda(secondValue).slice(0, -3)}` : 
          format === 'percentage' ? `${Math.round(secondValue)}%` :
          secondValue )
          }
        </Typography>
      </CardContent>
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleCard);