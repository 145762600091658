import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";

import Utils from "../../../services/utils";

const RelatorioConsolidado = ({ data, setRef, ...outrasProps }) => (
  <ReactTable
    {...outrasProps}
    data={data}
    ref={setRef}
    columns={[
      {
        Header: "ID",
        accessor: "p_id_projeto",
        maxWidth: 60,
        Footer: () => <span style={{ textAlign: "center" }}>Total</span>
      },
      {
        Header: "Projeto",
        accessor: "desc_curta",
        maxWidth: 160
      },
      {
        Header: "Tipo",
        accessor: "tipo_projeto",
        maxWidth: 140
      },
      {
        Header: "Receita",
        accessor: "receita",
        maxWidth: 140,
        filterable: false,
        Footer: row => (
          <span>
            {// Get the total of the price
            "R$ " +
              Utils.float2moeda(
                row.data.reduce(
                  (total, { receita }) => (total += parseFloat(receita)),
                  0
                )
              )}
          </span>
        ),
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {"R$ " + Utils.float2moeda(row.value)}
          </div>
        )
      },
      {
        Header: "TDD",
        accessor: "tdd",
        maxWidth: 140,
        filterable: false,
        Footer: row => (
          <span>
            {// Get the total of the price
            "R$ " +
              Utils.float2moeda(
                row.data.reduce(
                  (total, { tdd }) => (total += parseFloat(tdd)),
                  0
                )
              )}
          </span>
        ),
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {"R$ " + Utils.float2moeda(row.value)}
          </div>
        )
      },
      {
        Header: "TDI",
        accessor: "tdi",
        maxWidth: 140,
        filterable: false,
        Footer: row => (
          <span>
            {// Get the total of the price
            "R$ " +
              Utils.float2moeda(
                row.data.reduce(
                  (total, { tdi }) => (total += parseFloat(tdi)),
                  0
                )
              )}
          </span>
        ),
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {"R$ " + Utils.float2moeda(row.value)}
          </div>
        )
      },
      {
        Header: "Resultado",
        accessor: "resultado",
        maxWidth: 140,
        filterable: false,
        Footer: row => (
          <span>
            {// Get the total of the price
            "R$ " +
              Utils.float2moeda(
                row.data.reduce(
                  (total, { resultado }) => (total += parseFloat(resultado)),
                  0
                )
              )}
          </span>
        ),
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {"R$ " + Utils.float2moeda(row.value)}
          </div>
        )
      },
      {
        Header: "Saldo a Receber",
        accessor: "p_saldo_periodo",
        maxWidth: 140,
        filterable: false,
        Footer: row => (
          <span>
            {// Get the total of the price
            "R$ " +
              Utils.float2moeda(
                row.data.reduce(
                  (total, { p_saldo_periodo = 0 }) =>
                    (total += parseFloat(p_saldo_periodo)),
                  0
                )
              )}
          </span>
        ),
        Cell: row => (
          <div
            style={{
              height: "22px",
              verticalAlign: "middle",
              position: "relative",
              top: "15%"
            }}
          >
            {"R$ " + Utils.float2moeda(row.value)}
          </div>
        )
      }
    ]}
    className="-striped -highlight"
    defaultFilterMethod={(filter, row) =>
      String(row[filter.id])
        .toLowerCase()
        .startsWith(filter.value.toLowerCase())
    }
  />
);

RelatorioConsolidado.propTypes = {
  setRef: PropTypes.func,
  data: PropTypes.array
};

export default RelatorioConsolidado;
