import React from 'react';
// import {Link, browserHistory} from 'react-router';
import {observer} from 'mobx-react'
import {CircularProgress} from '@material-ui/core/';
// import MenuIcon from '@material-ui/icons/Menu';
// import AccountIcon from '@material-ui/icons/AccountBox';
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import Grid from '@material-ui/core/Grid';
import AppState from '../../state';

import {

  Topbar
}                             from '../../components';

import './style.css';



const styles = {
  small: {
    width: 72,
    height: 72,
    padding: 16
  },
  smallIcon: {
    width: 36,
    height: 36
  }
}

const App = (props) => {

  return (<div id="App">
    <div>
 <Topbar props />
      <div className="main-container">
        <div className="content">
          {props.children}
        </div>
      </div>
      {
        AppState.isLoading && (<div className="loading-overlay">
          <div className="loading-container">
            <CircularProgress size={60} thickness={4}/>
          </div>
        </div>)
      }
    </div>
  </div>);
}

export default observer(App);
