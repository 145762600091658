import React from 'react';
import { Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ReactTable from 'react-table';
import Form from './Form';
import AppState from '../../../state';
import { browserHistory } from 'react-router';
import { Utils } from '../../../services';

import * as contratoAPI from '../../../api/contratos';

class ContratoFornecedor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edicao: false,
      dialog: false,
      contratos: [],
    };
  }

  componentDidMount() {
    this.getTableData();
  }

  openEdicaoDialog = (contrato) => {
    this.setState({
      edicao: true,
      dialog: true,
      contrato: contrato,
    });
  };

  getTableData = () => {
    AppState.isLoading = true;
    contratoAPI
      .getContratos()
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          contratos: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  closeDialog = () => {
    this.setState({
      dialog: false,
      edicao: false,
    });
  };

  handleChangeActive = (cpf, ativo) => {
    if (ativo === 'INATIVO') {
      alert('INATIVANDO');
    } else if (ativo === 'ATIVO') {
      alert('ATIVANDO');
    }
  };

  canEditContrato = () => {
    var menu = JSON.parse(localStorage.getItem('Menu'));
    if (!menu || !menu.views) return false;
    var view = menu.views.find(
      (elem) => elem.id_view === 'fornecedores_contrato'
    );
    if (!view) return false;
    var func = view.funcionalidades;
    if (!func) return false;
    if (func.find((elem) => elem.id_funcionalidade === 'add_contrato'))
      return true;
    else return false;
  };

  render() {
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <h1>Cadastro de Contratos</h1>
        {this.canEditContrato() && (
          <Grid
            item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingBottom: '16px',
              paddingTop: '16px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ contrato: null, dialog: true })}
            >
              <AddIcon />
              Novo Contrato
            </Button>
          </Grid>
        )}
        <ReactTable
          filterable
          data={this.state.contratos}
          defaultPageSize={20}
          className="-striped -highlight"
          defaultFilterMethod={(filter, row) =>
            filter.id === 'fim_contrato'
              ? Utils.dateToPT(String(row[filter.id])).startsWith(
                  filter.value.toLowerCase()
                )
              : String(row[filter.id])
                  .toLowerCase()
                  .startsWith(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: 'Contratos de fornecedores',
              columns: [
                {
                  Header: 'ID',
                  accessor: 'id_contrato',
                  width: 50,
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  width: 70,
                  id: 'Status',
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{
                        paddingLeft: '0px',
                        textAlign: 'center',
                        color:
                          row.original['fim_vigencia'] === null
                            ? 'green'
                            : 'red',
                      }}
                    >
                      {row.original['fim_vigencia'] === null
                        ? 'Vigênte'
                        : 'Vencido'}
                    </div>
                  ),
                },
                {
                  Header: 'CNPJ / CPF',
                  accessor: 'cpf_cnpj_fornecedor',
                  width: 140,
                },
                {
                  Header: 'Razão Social / Nome',
                  accessor: 'razao_social',
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{ textAlign: 'center' }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'descricao_curta',
                },
                {
                  Header: 'Tipo de Contrato',
                  accessor: 'tipo_contrato',
                  width: 200,
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{ paddingLeft: '0px', textAlign: 'center' }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Nome PJ',
                  accessor: 'nome_pj',
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{ textAlign: 'center' }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  id: 'fim_contrato',
                  Header: 'Fim do Contrato',
                  accessor: (row) =>
                    row['fim_vigencia'] === null
                      ? row.dt_encerramento
                      : row.fim_vigencia,
                  width: 120,
                  show: true,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                      }}
                    >
                      {row.original['fim_vigencia'] === null
                        ? Utils.dateToPT(row.original['dt_encerramento'])
                        : Utils.dateToPT(row.original['fim_vigencia'])}
                    </div>
                  ),
                  sortMethod: (a, b) => {
                    a = new Date(a);
                    b = new Date(b);
                    return a - b;
                  },
                },
                {
                  Header: 'Valor Total',
                  accessor: 'valor_total_contrato',
                  width: 120,
                  Footer: (row) => (
                    <span>
                      {
                        // Get the total of the price
                        'R$ ' +
                          Utils.float2moeda(
                            row.data.reduce(
                              (total, { valor }) =>
                                (total += parseFloat(valor)),
                              0
                            )
                          )
                      }
                    </span>
                  ),
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      {'R$ ' + Utils.float2moeda(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Inicio da Vigência',
                  accessor: 'inicio_vigencia',
                  width: 120,
                  show: false,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Fim da Vigência',
                  accessor: 'fim_vigencia',
                  id: 'fim_vigencia',
                  width: 120,
                  show: false,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Ação',
                  filterable: false,
                  accessor: 'action',
                  show: () => this.canEditContrato(),
                  width: 50,
                  Cell: (row) => (
                    <div className="action-cell" style={{ padding: '0px' }}>
                      {
                        <EditIcon
                          onClick={() =>
                            this.openEdicaoDialog(row.original['id_contrato'])
                          }
                          style={{
                            cursor: 'pointer',
                            width: '22px',
                            height: '22px',
                            minWidth: '22px',
                            minHeight: '22px',
                            marginLeft: '5px',
                          }}
                        />
                      }
                    </div>
                  ),
                },
              ],
            },
          ]}
        />

        {this.state.dialog && (
          <Form
            open={this.state.dialog}
            close={this.closeDialog}
            contrato={this.state.contrato}
            edicao={this.state.edicao}
            carregaTabela={this.getTableData}
          />
        )}
      </div>
    );
  }
}

export default ContratoFornecedor;
