import React from 'react';
import ReactTable from 'react-table';
import AppState from '../../../state';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Form from './Form.js';
import './style.scss';
import { browserHistory } from 'react-router';
import EditIcon from '@material-ui/icons/Edit';
import { Grid } from '@material-ui/core';

class Clientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      clientes: [],
    };
    this.getTableData();
  }

  getTableData = () => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/cliente/GetClientes.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          clientes: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  closeDialog = () => {
    this.setState({
      dialog: false,
      edicao: false,
    });
  };

  openEdicaoDialog = (cliente) => {
    this.setState({
      edicao: true,
      dialog: true,
      cliente: cliente,
    });
  };

  render() {
    return (
      <div
        id={this.constructor.name}
        style={{
          marginTop: '5em',
        }}
      >
        <h1>Cadastro de Clientes</h1>
        <Grid
          item
          style={{
            display: 'flex',
            gap: '24px',
            justifyContent: 'flex-end',
            padding: '16px 0',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ dialog: true })}
          >
            <AddIcon />
            Novo Cliente
          </Button>
        </Grid>
        <ReactTable
          filterable={true}
          data={this.state.clientes}
          defaultPageSize={20}
          className="-striped -highlight"
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .startsWith(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: 'Cliente',
              columns: [
                {
                  Header: 'Status',
                  accessor: 'ativo',
                  width: 80,
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{
                        paddingLeft: '0px',
                        textAlign: 'center',
                        color: row.value == 't' ? 'green' : 'red',
                      }}
                    >
                      {row.value == 't' ? 'Ativo' : 'Inativo'}
                    </div>
                  ),
                },
                {
                  Header: 'CPF / CNPJ',
                  accessor: 'cpf_cnpj_cliente',
                  maxWidth: 140,
                },
                {
                  Header: 'Nome / Razão Social',
                  accessor: 'razao_social',
                },
                {
                  Header: 'Nome Fantasia',
                  accessor: 'nome_fantasia',
                },
                {
                  Header: 'Tipo',
                  accessor: 'tipo_cliente',
                  maxWidth: 125,
                },
                {
                  Header: 'Telefone',
                  accessor: 'tel_comercial',
                  maxWidth: 110,
                },
                {
                  Header: 'E-mail',
                  accessor: 'email_comercial',
                  maxWidth: 200,
                },
                {
                  Header: 'CEP',
                  accessor: 'cep_comercial',
                  width: 80,
                },
                {
                  Header: 'Ação',
                  accessor: 'status',
                  width: 55,
                  filterable: false,
                  Cell: (row) => (
                    <div className="action-cell" style={{ paddingLeft: '0px' }}>
                      {
                        <EditIcon
                          onClick={() =>
                            this.openEdicaoDialog(
                              row.original['cpf_cnpj_cliente']
                            )
                          }
                          style={{
                            cursor: 'pointer',
                            width: '22px',
                            height: '22px',
                            minWidth: '22px',
                            minHeight: '22px',
                            marginLeft: '10px',
                          }}
                        />
                      }
                    </div>
                  ),
                },
              ],
            },
          ]}
        />

        <Form
          open={this.state.dialog}
          close={this.closeDialog}
          cliente={this.state.cliente}
          edicao={this.state.edicao}
          carregaTabela={this.getTableData}
        />
      </div>
    );
  }
}

export default observer(Clientes);
