import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Button,
  Typography,
  DialogContent,
  Icon,
  Dialog,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import AppState from '../../../state';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Utils } from '../../../services';
import AutoSelect from '../../../components/inputs/AutoSelect';
import CurrencyInput from '../../../components/lib/CurrencyInput';

const styles = {
  halfGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%',
    maxWidth: '100%',
    flexBasis: 'auto',
  },
};

const DateMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

DateMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CPFMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};

CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CurrencyMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  const numberMask = createNumberMask({
    prefix: 'R$ ',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    allowNegative: true,
  });

  return (
    <MaskedInput
      {...other}
      /*ref={inputRef}*/ mask={numberMask}
      guide={false}
    />
  );
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: '',
      accountList: [],
      account_group: '',
      title_issuer: '',
      accountGroupList: [],
      check_number: '',
      contract: '',
      contractList: [],
      cpf_or_cnpj: '',
      cliente: '',
      clienteList: [],
      current_or_not_current: '',
      description: '',
      paymentDate: Utils.dateToPT(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      ),
      emmit_at_title: '',
      employee: '',
      employeeList: [],
      name_or_company_name: '',
      number_of_document: '',
      payment_Open: 'true',
      payment_mode: '',
      paymentModeList: [],
      project: '',
      projectList: [],
      provider: '',
      providerList: [],
      pj: '',
      pjList: [],
      responsible: '',
      title_sel_mode: '',
      type_of_title: 'despesa',
      title: '',
      titleList: [],
      titleValue: '',
      bankList: [],
      pgtoRecebBank: '',
      titleErro: false,
      // type_of_titleErro: false,
      title_issuerErro: false,
      employeeErro: false,
      pjErro: false,
      providerErro: false,
      contractErro: false,
      clienteErro: false,
      name_or_company_nameErro: false,
      cpf_or_cnpjErro: false,
      paymentDateErro: false,
      projectErro: false,
      account_groupErro: false,
      accountErro: false,
      titleValueErro: false,
      descriptionErro: false,
      payment_modeErro: false,
      pgtoRecebBankErro: false,
      payment_status: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  validaForm = (pagar = false) => {
    let formValido = true;
    let chainedEvent = false;

    if (
      this.state.payment_mode === 'Transferência - DOC' ||
      this.state.payment_mode === 'Transferência - TED'
    ) {
      if (this.state.pgtoRecebBank === '') {
        formValido = false;
        alert('Por favor preencha o Banco do Pgto./Receb.');
        this.setState({
          pgtoRecebBankErro: true,
        });
      }
    }

    if (!this.state.type_of_title || this.state.type_of_title === '') {
      formValido = false;
      alert('Há um erro na seleção do Tipo de Titulo');
      // this.setState({
      //   type_of_titleErro: true
      // });
    }

    if (this.state.payment_Open === 'true') {
      if (this.state.title === '') {
        formValido = false;
        alert('Por favor selecione um titulo a ser pago');
        this.setState({
          titleErro: true,
        });
      }
    }

    if (this.state.title_issuer === '' || this.state.title_issuer === 'null') {
      formValido = false;
      this.setState({
        title_issuerErro: true,
      });
    }

    if (this.state.title_issuer === 'funcionario') {
      if (this.state.employee === '' || this.state.employee === 'null') {
        formValido = false;
        this.setState({
          employeeErro: true,
        });
      }
    }

    if (this.state.title_issuer === 'pj') {
      if (this.state.pj === '' || this.state.title_issuer === 'null') {
        formValido = false;
        this.setState({
          pjErro: true,
        });
      }
    }

    if (this.state.title_issuer === 'fornecedor') {
      if (this.state.provider === '' || this.state.provider === 'null') {
        formValido = false;
        this.setState({
          providerErro: true,
        });
      }

      if (this.state.contract === '' || this.state.contract === 'null') {
        formValido = false;
        this.setState({
          contractErro: true,
        });
      }
    }

    if (this.state.title_issuer === 'cliente') {
      if (this.state.cliente === '' || this.state.cliente === 'null') {
        formValido = false;
        this.setState({
          clienteErro: true,
        });
      }
    }

    if (this.state.title_issuer === 'outros') {
      if (
        this.state.name_or_company_name === '' ||
        this.state.name_or_company_name === 'null'
      ) {
        formValido = false;
        this.setState({
          name_or_company_nameErro: true,
        });
      }

      if (this.state.cpf_or_cnpj === '' || this.state.cpf_or_cnpj === 'null') {
        formValido = false;
        this.setState({
          cpf_or_cnpjErro: true,
        });
      }
    }

    if (this.state.project === '' || this.state.project === 'null') {
      formValido = false;
      this.setState({
        projectErro: true,
      });
    }

    if (
      this.state.account_group === '' ||
      this.state.account_group === 'null'
    ) {
      formValido = false;
      this.setState({
        account_groupErro: true,
      });
    }

    if (this.state.account === '' || this.state.account === 'null') {
      formValido = false;
      this.setState({
        accountErro: true,
      });
    }

    if (this.state.titleValue === '' || this.state.titleValue === 'null') {
      formValido = false;
      this.setState({
        titleValueErro: true,
      });
    }

    if (
      this.state.description === '' ||
      this.state.description.length > 256 ||
      this.state.description === 'null'
    ) {
      formValido = false;
      this.setState({
        descriptionErro: true,
      });
    }

    if (
      this.state.payment_mode === '' ||
      this.state.payment_mode === 'null' ||
      !this.state.payment_mode
    ) {
      formValido = false;
      this.setState({
        payment_modeErro: true,
      });
    }

    if (
      this.state.current_or_not_current !== 'true' &&
      this.state.current_or_not_current !== 'false'
    ) {
      formValido = false;
      alert('Por favor selecione o Tipo de Conta');
      // this.setState({
      //   current_or_not_currentErro: true
      // });
    }

    //Valida data de lançamento
    if (this.state.paymentDate === '') {
      formValido = false;
      this.setState({
        paymentDateErro: true,
      });
    } else {
      const endDate = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      );
      const dataPagamento = Utils.dateToEN(this.state.paymentDate);
      const pagamento = new Date(dataPagamento);

      if (pagamento.getTime() > endDate.getTime()) {
        formValido = false;
        alert('Não é possível lançar um titulo em datas futuras');
        this.setState({
          paymentDateErro: true,
        });
      }
      if (this.state.emmit_at_title !== '') {
        let dataTitulo = Utils.dateToEN(this.state.emmit_at_title);
        const titulo = new Date(dataTitulo);
        if (pagamento.getTime() < titulo.getTime()) {
          var r = window.confirm(
            `Este pagamento está associado a um titulo lançado depois de ${this.state.paymentDate}. Para prosseguir é necessário atualizar a data de lançamento do titulo de ${this.state.emmit_at_title} para ${this.state.paymentDate}. Confirmar?`
          );
          if (r == true) {
            chainedEvent = true;
            this.setState(
              {
                emmit_at_title: this.state.paymentDate,
              },
              () => {
                if (formValido) {
                  if (pagar == true) {
                    this.editPagReceb(true);
                  } else {
                    if (this.state.edicao === true) {
                      this.editPagReceb();
                    } else {
                      this.addPagReceb();
                    }
                  }
                }
              }
            );
          } else {
            formValido = false;
            this.setState({
              paymentDateErro: true,
            });
          }
        }
      }
    }

    if (!chainedEvent) {
      if (formValido) {
        if (pagar == true) {
          this.editPagReceb(true);
        } else {
          if (this.state.edicao === true) {
            this.editPagReceb();
          } else {
            this.addPagReceb();
          }
        }
      }
    }
  };

  componentDidMount() {
    this.getPaymentModes(this.props.isLoading);
    this.getTitulosAbertos(this.props.isLoading);
    this.getBankList(this.props.isLoading);
    this.getProjects(this.props.isLoading);
    this.getFuncionarios(this.props.isLoading);
    this.getFornecedores(this.props.isLoading);
    this.getClientes(this.props.isLoading);
    this.getPJ(this.props.isLoading);
    this.getContas(this.props.isLoading);
    this.getGrupoContas(this.props.isLoading);
    this.getContratos(this.props.isLoading);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.edicao != this.props.edicao) {
      this.getTitulosAbertos(true);
      this.setState(
        {
          edicao: this.props.edicao,
        },
        () => {
          if (this.props.edicao) {
            this.getPagamentoReceb(this.props.pagamento);
          }
        }
      );
    }
  }

  closeDialog = () => {
    this.clearFields();
    this.props.close();
  };

  getPagamentoReceb = (pagamento) => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetPgtoReceb.php?json={"id_pgtoReceb":"${pagamento}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            type_of_title: response.request[0].natureza,
            account_group: response.request[0].grupo,
            provider:
              response.request[0].tipo_contraparte === 'fornecedor'
                ? response.request[0].cpf_cnpj_contraparte
                : '',
          },
          () => {
            // Promise para atualizar lista de emissores dado o titulo recebido
            this.updateEmissorList(
              response.request[0].tipo_contraparte,
              true
            ).then(() => {
              // Atualiza lista de grupos de contas dada a natureza
              this.getGrupoContas(true).then(() => {
                // Atualiza contas dada a lista de contas
                this.getContas(true).then(() => {
                  // Atualiza os contratos dado o cpf
                  this.getContratos(true).then(() => {
                    // Resolvendo estado com todos as listas pre carregadas
                    AppState.isLoading = false;
                    this.setState({
                      id_lancamento: response.request[0].id_lancamento,
                      project: response.request[0].id_projeto,
                      account: response.request[0].id_conta,
                      titleValue: parseFloat(response.request[0].valor),
                      paymentDate: Utils.dateToPT(
                        response.request[0].dt_pag_recb
                      ),
                      emmit_at_title: Utils.dateToPT(
                        response.request[0].dt_lancamento
                      ),
                      description: response.request[0].descricao,
                      payment_mode: response.request[0].forma_pagamento,
                      check_number: response.request[0].n_cheque,
                      current_or_not_current:
                        response.request[0].eh_corrente == 't'
                          ? 'true'
                          : 'false',
                      number_of_document: response.request[0].num_documento,
                      title: response.request[0].id_titulo,
                      pgtoRecebBank: response.request[0].banco_pgto_receb,
                      title_issuer: response.request[0].tipo_contraparte,
                      employee:
                        response.request[0].tipo_contraparte === 'funcionario'
                          ? response.request[0].cpf_cnpj_contraparte
                          : '',
                      contract: response.request[0].id_contrato,
                      cpf_or_cnpj:
                        response.request[0].tipo_contraparte === 'outros'
                          ? response.request[0].cpf_cnpj_contraparte
                          : '',
                      name_or_company_name:
                        response.request[0].nome_contraparte_outro,
                      cliente:
                        response.request[0].tipo_contraparte === 'cliente'
                          ? response.request[0].cpf_cnpj_contraparte
                          : '',
                      pj:
                        response.request[0].tipo_contraparte === 'pj'
                          ? response.request[0].cpf_cnpj_contraparte
                          : '',
                      cpf_usuario: response.request[0].criado_por,
                      payment_status: response.request[0].status,
                    });
                  });
                });
              });
            });
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        } else {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  tipoHandleChange = (event) => {
    this.state.title && this.clearFields();
    this.setState(
      {
        title: '',
        type_of_title: event.target.value,
        paymentDate: Utils.dateToPT(
          new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 10)
        ),
      },
      () => {
        AppState.isLoading = true;
        this.getTitulosAbertos();
        this.getGrupoContas();
      }
    );

    if (event.target.value === 'receita') {
      this.setState({
        current_or_not_current: 'true',
      });
    } else {
      this.setState({
        current_or_not_current: '',
      });
    }
  };

  accountGroupHandleChange = (event) => {
    this.setState(
      {
        account_group: event.target.value,
      },
      () => {
        AppState.isLoading = true;
        this.getContas(false);
      }
    );
  };

  modoPagamentoHandleChange = (event) => {
    if (this.state.payment_mode === 'Cheque') {
      this.setState({
        check_number: null,
      });
    }
    if (event.target.value === 'Dinheiro') {
      this.setState({
        payment_mode: event.target.value,
        pgtoRecebBank: null,
      });
    } else {
      this.setState({
        payment_mode: event.target.value,
      });
    }
  };

  BancoDoPagamentoHandleChange = (value) => {
    if (value === null) {
      this.setState({
        pgtoRecebBank: '',
      });
    } else {
      this.setState({
        pgtoRecebBank: value.value,
      });
    }
  };

  updateEmissorList = async (value, isLoading = false) =>
    new Promise((resolve, reject) => {
      AppState.isLoading = true;
      switch (value) {
        case 'funcionario':
          this.getFuncionarios(isLoading)
            .then(() => {
              resolve(null);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        case 'pj':
          this.getPJ(isLoading)
            .then(() => {
              resolve(null);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        case 'fornecedor':
          this.getFornecedores(isLoading)
            .then(() => {
              resolve(null);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        case 'cliente':
          this.getClientes(isLoading)
            .then(() => {
              resolve(null);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        default:
          resolve(null);
          break;
      }
    });

  emissorHandleChange = (event) => {
    this.setState({
      pj: '',
      employee: '',
      project: '',
      account_group: '',
      provider: '',
      account: '',
      contract: '',
      cliente: '',
      name_or_company_name: '',
      cpf_or_cnpj: '',
      title_issuer: event.target.value,
    });
    this.updateEmissorList(event.target.value);
    this.getProjects();
  };

  funcionarioHandleChange = (value) => {
    if (value === null) {
      this.setState({
        employee: '',
      });
    } else {
      this.setState(
        {
          employee: value.value,
        },
        () => {
          this.getProjectByUser(value.value);
        }
      );
    }
  };

  fornecedorHandleChange = (value) => {
    if (value === null) {
      this.setState({
        provider: '',
      });
    } else {
      this.setState(
        {
          provider: value.value,
        },
        () => {
          AppState.isLoading = true;
          this.getContratos();
        }
      );
    }
  };

  pjHandleChange = (value) => {
    if (value === null) {
      this.setState({
        pj: '',
      });
    } else {
      this.setState(
        {
          pj: value.value,
        },
        () => this.getProjectByUser(value.value)
      );
    }
  };

  clienteHandleChange = (value) => {
    if (value === null) {
      this.setState({
        cliente: '',
      });
    } else {
      this.setState(
        {
          cliente: value.value,
        },
        () => this.getProjectByCliente(value.value)
      );
    }
  };

  getProjectByCliente = (cliente) => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/projetos/GetProjectsByClient.php?json={"cnpj":"${cliente}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          projectList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - invalid/incomplete client CNPJ or CPF');
          }
          if (ret == '40002') {
            console.log('40002 - Dados icorretos/invalidos');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  openPaymentHandleChange = (event) => {
    !this.state.edicao && this.clearFields();
    this.setState({
      payment_Open: event.target.value,
      title: '',
    });
  };

  TituloHandleChange = (value) => {
    this.clearFields();
    if (value === null) {
      this.setState({
        title: '',
      });
    } else {
      this.setState(
        {
          title: value.value,
        },
        () => {
          this.getTituloData();
        }
      );
    }
  };

  getProjects = (isLoading = false) => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/projetos/GetProjects.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            projectList: response.request,
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getProjectByUser = (user) => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/projetos/GetUserProjects.php?json={"user":"${user}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          projectList: response.request.filter(function (item) {
            return item.dt_encerramento === null;
          }),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - invalid/incomplete client CNPJ or CPF');
          }
          if (ret === '40002') {
            console.log('40002 - Dados icorretos/invalidos');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getPaymentModes = (isLoading = false) => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetFormaPagamentoList.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            paymentModeList: response.request,
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getBankList = (isLoading = false) => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetBancos.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        const banks = response.request;

        this.setState(
          {
            bankList: banks.map((suggestion) => ({
              value: suggestion.banco,
              label: suggestion.banco,
            })),
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Erro Desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getPJ = (isLoading = false) => {
    return fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/usuarios/GetPJList.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        const pj = response.request;

        this.setState(
          {
            pjList: pj.map((suggestion) => ({
              value: suggestion.cpf_pj,
              label: suggestion.nome_pj,
            })),
          },
          () => {
            if (isLoading == false) {
              console.log('pq nao desligou merda');
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getFuncionarios = (isLoading = false) => {
    return fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/funcionarios/GetFuncionarios.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        const employee = response.request;

        this.setState(
          {
            employeeList: employee.map((suggestion) => ({
              value: suggestion.cpf_funcionario,
              label: suggestion.nome_funcionario,
            })),
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getFornecedores = (isLoading = false) => {
    return fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/fornecedores/GetFornecedores.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        const provider = response.request;

        this.setState(
          {
            providerList: provider.map((suggestion) => ({
              value: suggestion.cpf_cnpj_fornecedor,
              label:
                suggestion.nome_fantasia == '' || !suggestion.nome_fantasia
                  ? suggestion.razao_social
                  : suggestion.nome_fantasia,
            })),
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getClientes = (isLoading = false) => {
    return fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/cliente/GetClientes.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        const cliente = response.request;

        this.setState(
          {
            clienteList: cliente.map((suggestion) => ({
              value: suggestion.cpf_cnpj_cliente,
              label:
                suggestion.nome_fantasia == '' || !suggestion.nome_fantasia
                  ? suggestion.razao_social
                  : suggestion.nome_fantasia,
              is_active: suggestion.cliente_ativo,
            })),
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001' || ret == '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getContratos = (isLoading = false) =>
    new Promise((resolve, reject) => {
      return fetch(
        `${process.env.PUBLIC_URL}/${AppState.serverAddr}/fornecedores/GetContratosLista.php?json={"cnpj_fornecedor":"${this.state.provider}"}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: AppState.token,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => {
              let error = new Error(response.status);
              error.response = json;
              throw error;
            });
          } else {
            const res = response.json();
            return res;
          }
        })
        .then((response) => {
          this.setState(
            {
              contractList: response.request,
            },
            () => {
              if (isLoading == false) {
                AppState.isLoading = isLoading;
              }
              resolve();
            }
          );
        })
        .catch(function (error) {
          if (isLoading == false) {
            AppState.isLoading = isLoading;
          }
          if (
            error.toString() == '' ||
            error.toString().split(' ').length < 2
          ) {
            alert('Erro desconhecido, tente novamente');
            reject(error);
          }
          var Err_status = error.toString().split(' ')[1];
          if (Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if (ret == '40001' || ret == '40002') {
              console.log('40001 Erro desconhecido');
            } else alert('Dados inválidos');
          } else if (Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status == 500) {
            alert('Erro desconhecido, tente novamente');
          }
          reject(error);
        });
    });

  getContas = (isLoading = false) =>
    new Promise((resolve, reject) => {
      return fetch(
        `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetContasByGroup.php?json={"grupo_conta":"${this.state.account_group}"}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: AppState.token,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => {
              let error = new Error(response.status);
              error.response = json;
              throw error;
            });
          } else {
            const res = response.json();
            return res;
          }
        })
        .then((response) => {
          this.setState(
            {
              accountList: response.request,
            },
            () => {
              if (isLoading == false) {
                AppState.isLoading = isLoading;
              }
              resolve();
            }
          );
        })
        .catch(function (error) {
          if (isLoading == false) {
            AppState.isLoading = isLoading;
          }
          if (
            error.toString() == '' ||
            error.toString().split(' ').length < 2
          ) {
            alert('Erro desconhecido, tente novamente');
            reject(error);
          }
          var Err_status = error.toString().split(' ')[1];
          if (Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if (ret == '40001') {
              console.log('40001 Erro desconhecido');
            } else alert('Dados inválidos');
          } else if (Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status == 500) {
            alert('Erro desconhecido, tente novamente');
          }
          reject(error);
        });
    });

  getGrupoContas = (isLoading = false) =>
    new Promise((resolve, reject) => {
      const json = JSON.stringify({
        tipo: this.state.type_of_title,
      });

      fetch(
        `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetGrupoContas.php?json=${json}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: AppState.token,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => {
              let error = new Error(response.status);
              error.response = json;
              throw error;
            });
          } else {
            const res = response.json();
            return res;
          }
        })
        .then((response) => {
          this.setState(
            {
              accountGroupList: response.request,
              accountList: [],
            },
            () => {
              if (isLoading == false) {
                AppState.isLoading = isLoading;
              }
              resolve();
            }
          );
        })
        .catch(function (error) {
          if (isLoading == false) {
            AppState.isLoading = isLoading;
          }
          if (
            error.toString() == '' ||
            error.toString().split(' ').length < 2
          ) {
            alert('Erro desconhecido, tente novamente');
            reject(error);
          }
          var Err_status = error.toString().split(' ')[1];
          if (Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if (ret == '40001') {
              console.log('40001 erro desconhecido');
            } else alert('Dados inválidos');
          } else if (Err_status == 401) {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status == 500) {
            alert('Erro desconhecido, tente novamente');
          }
          reject(error);
        });
    });

  getTitulosAbertos = (isLoading = false) => {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetTitulosEmAberto.php?json={"natureza":"${this.state.type_of_title}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        const title = response.request;

        this.setState(
          {
            titleList: title.map((suggestion) => ({
              value: suggestion.id_titulo,
              label: suggestion.display,
            })),
          },
          () => {
            if (isLoading == false) {
              AppState.isLoading = isLoading;
            }
          }
        );
      })
      .catch(function (error) {
        if (isLoading == false) {
          AppState.isLoading = isLoading;
        }
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 natureza invalido/não informada');
          } else if (ret === '40002') {
            console.log('40002 json invalido/não informado');
          } else if (ret === '40003') {
            console.log('40003 erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getTituloData = () => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetTitulo.php?json={"id_titulo":"${this.state.title}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.updateEmissorList(response.request[0].tipo_contraparte).then(
          () => {
            this.setState(
              {
                emmit_at_title: Utils.dateToPT(
                  response.request[0].dt_lancamento
                ),
                provider:
                  response.request[0].tipo_contraparte === 'fornecedor'
                    ? response.request[0].cpf_cnpj_contraparte
                    : '',
                employee:
                  response.request[0].tipo_contraparte === 'funcionario'
                    ? response.request[0].cpf_cnpj_contraparte
                    : '',
                cpf_or_cnpj:
                  response.request[0].tipo_contraparte === 'outros'
                    ? response.request[0].cpf_cnpj_contraparte
                    : '',
                cliente:
                  response.request[0].tipo_contraparte === 'cliente'
                    ? response.request[0].cpf_cnpj_contraparte
                    : '',
                pj:
                  response.request[0].tipo_contraparte === 'pj'
                    ? response.request[0].cpf_cnpj_contraparte
                    : '',
                project: response.request[0].id_projeto
                  ? response.request[0].id_projeto
                  : '',
                title_issuer: response.request[0].tipo_contraparte
                  ? response.request[0].tipo_contraparte
                  : '',
                accountList: [
                  {
                    nome_display: response.request[0].display_conta,
                    id_conta: response.request[0].id_conta,
                  },
                ],
                account: response.request[0].id_conta
                  ? response.request[0].id_conta
                  : '',
                description: response.request[0].descricao
                  ? response.request[0].descricao
                  : '',
                titleValue: Utils.float2moeda(response.request[0].valor),
                payment_mode: response.request[0].forma_pagamento
                  ? response.request[0].forma_pagamento
                  : '',
                check_number: response.request[0].n_cheque
                  ? response.request[0].n_cheque
                  : '',
                number_of_document: response.request[0].num_documento
                  ? response.request[0].num_documento
                  : '',
                current_or_not_current:
                  response.request[0].eh_corrente === 't' ? 'true' : 'false',
                name_or_company_name: response.request[0].nome_contraparte_outro
                  ? response.request[0].nome_contraparte_outro
                  : '',
                contract: response.request[0].id_contrato
                  ? response.request[0].id_contrato
                  : '',
                account_group: response.request[0].grupo
                  ? response.request[0].grupo
                  : '',
              },
              () => {
                AppState.isLoading = false;
              }
            );
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 natureza invalido/não informada');
          } else if (ret === '40002') {
            console.log('40002 json invalido/não informado');
          } else if (ret === '40003') {
            console.log('40003 erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  moeda2float = (moeda) => {
    if (moeda === '') {
      return '';
    } else {
      if (typeof moeda === 'string') {
        moeda = moeda.replace('R$ ', '');
        moeda = moeda.replace(/\./g, '');
        moeda = moeda.replace(/\,/g, '.');
        moeda = moeda.replace(/\ /g, '');
      }
      return parseFloat(moeda);
    }
  };

  clearFields = () => {
    this.setState({
      account: '',
      account_group: '',
      title_issuer: '',
      check_number: '',
      contract: '',
      cpf_or_cnpj: '',
      cliente: '',
      current_or_not_current: '',
      description: '',
      paymentDate: Utils.dateToPT(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      ),
      emmit_at_title: '',
      name_or_company_name: '',
      number_of_document: '',
      payment_Open: 'true',
      payment_mode: '',
      project: '',
      provider: '',
      pj: '',
      responsible: '',
      title_sel_mode: '',
      title: '',
      titleValue: '',
      pgtoRecebBank: '',
      titleErro: false,
      // type_of_titleErro: false,
      title_issuerErro: false,
      employeeErro: false,
      pjErro: false,
      providerErro: false,
      contractErro: false,
      clienteErro: false,
      name_or_company_nameErro: false,
      cpf_or_cnpjErro: false,
      paymentDateErro: false,
      projectErro: false,
      account_groupErro: false,
      accountErro: false,
      titleValueErro: false,
      descriptionErro: false,
      payment_modeErro: false,
      pgtoRecebBankErro: false,
      payment_status: '',
    });
  };

  editPagReceb = (pagar = false) => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      id_lancamento: this.state.id_lancamento,
      natureza: this.state.type_of_title,
      projeto: this.state.project,
      conta: this.state.account,
      valor: this.moeda2float(this.state.titleValue),
      dt_pgtoRecb: Utils.dateToEN(this.state.paymentDate),
      dt_lancamento_titulo: Utils.dateToEN(this.state.emmit_at_title),
      descricao: this.state.description,
      forma_pgto: this.state.payment_mode,
      numero_cheque: this.state.check_number,
      eh_corrente: this.state.current_or_not_current === 'true' ? true : false,
      titulo_vinculado: this.state.title,
      numero_doc: this.state.number_of_document,
      banco_pgto: this.state.pgtoRecebBank,
      n_cheque: this.state.check_number,
      tipo_contraparte: this.state.title_issuer,
      cpf_funcionario: this.state.employee,
      cnpj_fornecedor: this.state.provider,
      cpf_pj: this.state.pj,
      cnpj_cliente: this.state.cliente,
      n_contrato: this.state.contract,
      cpf_cnpj_outro: this.state.cpf_or_cnpj,
      nome_outro: this.state.name_or_company_name,
      criado_por: AppState.userInfo.cpf_usuario,
      pagar: pagar,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/EditPgtoReceb.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
        AppState.isLoading = false;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (
            ret == '40001' ||
            ret == '40002' ||
            ret == '40003' ||
            ret == '40008'
          ) {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40004') {
            console.log('Não é possivel fazer lançamento futuro');
            alert('Não é possivel fazer lançamento futuro');
          } else if (ret == '40005') {
            console.log(
              'Não é Possível lançar ou pagar um titulo fora do período de exercício do Projeto'
            );
            alert(
              'Não é Possível lançar ou pagar um titulo fora do período de exercício do Projeto'
            );
          } else if (ret == '40006') {
            console.log(
              'Não é possível alterar a data de titulos no projeto 0'
            );
            alert('Não é possível alterar a data de titulos no projeto 0');
          } else if (ret == '40007') {
            console.log(
              'Não é possível lançar um pagamento anterior ao lançamento do titulo'
            );
            alert(
              'Não é possível lançar um pagamento anterior ao lançamento do titulo'
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  addPagReceb = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      natureza: this.state.type_of_title,
      projeto: this.state.project,
      conta: this.state.account,
      valor: this.moeda2float(this.state.titleValue),
      dt_pgtoRecb: Utils.dateToEN(this.state.paymentDate),
      dt_lancamento_titulo: Utils.dateToEN(this.state.emmit_at_title),
      descricao: this.state.description,
      forma_pgto: this.state.payment_mode,
      numero_cheque: this.state.check_number,
      eh_corrente: this.state.current_or_not_current === 'true' ? true : false,
      titulo_vinculado: this.state.title,
      numero_doc: this.state.number_of_document,
      banco_pgto: this.state.pgtoRecebBank,
      n_cheque: this.state.check_number,
      tipo_contraparte: this.state.title_issuer,
      cpf_funcionario: this.state.employee,
      cnpj_fornecedor: this.state.provider,
      cpf_pj: this.state.pj,
      cnpj_cliente: this.state.cliente,
      n_contrato: this.state.contract,
      cpf_cnpj_outro: this.state.cpf_or_cnpj,
      nome_outro: this.state.name_or_company_name,
      criado_por: AppState.userInfo.cpf_usuario,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/AddPgtoRecb.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.closeDialog();
        this.props.reload('5dias');
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002' || ret === '40003') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40004') {
            console.log('Não é possivel fazer lançamento em data futura');
            alert('Não é possivel fazer lançamento em data futura');
          } else if (ret === '40005') {
            console.log('Não é possível fazer um lançamento retrotativo');
            alert('Não é possível fazer um lançamento retrotativo');
          } else if (ret === '40006') {
            console.log(
              'Não é Possível lançar ou pagar um titulo fora do período de exercício do Projeto'
            );
            alert(
              'Não é Possível lançar ou pagar um titulo fora do período de exercício do Projeto'
            );
          } else if (ret === '40007') {
            console.log(
              'Não é possível pagar um titulo antes da data de lançamento do titulo'
            );
            alert(
              'Não é possível pagar um titulo antes da data de lançamento do titulo'
            );
          } else if (ret == '40008') {
            console.log('Este titulo já foi pago');
            alert('Este titulo já foi pago');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  hasPaymentPermission = () => {
    const permissions = JSON.parse(localStorage.getItem('Menu'));
    const aprovacaoView = permissions.views
      .filter((view) => view.id_view === 'lancamento_pgto_receb')
      .pop();

    const hasPaymentPermission = aprovacaoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === 'pagar_lancamentos';
      },
      false
    );

    return hasPaymentPermission;
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.close}
        disableBackdropClick={true}
        fullScreen
      >
        <DialogContent>
          <Icon
            style={{ float: 'right' }}
            title={'fechar'}
            onClick={this.closeDialog}
          >
            close
          </Icon>
          <div id={this.constructor.name} /*fullWidth={true}*/>
            <Typography variant="h4" style={{ marginBottom: '40px' }}>
              Lançamento de Pagto./Receb.
            </Typography>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              spacing={40}
            >
              <Grid item xs>
                <Grid container spacing={8} fullWidth>
                  <Grid item xs>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel>
                        {this.state.type_of_title === 'receita'
                          ? 'Receb.de Título em Aberto?'
                          : 'Pagto. de Título em Aberto?'}
                      </FormLabel>
                      <RadioGroup
                        aria-label="pagamentos em aberto"
                        name="payment_Open"
                        row={true}
                        value={this.state.payment_Open}
                        onChange={this.openPaymentHandleChange}
                        fullWidth
                      >
                        <FormControlLabel
                          disabled={!(this.state.edicao && !this.state.title)}
                          value={'true'}
                          control={<Radio color="primary" />}
                          label="Sim"
                        />
                        <FormControlLabel
                          disabled={!(this.state.edicao && !this.state.title)}
                          value={'false'}
                          control={<Radio color="primary" />}
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" fullWidth>
                      <InputLabel>Tipo do Título</InputLabel>
                      <Select
                        name="type_of_title"
                        // row={true}
                        value={this.state.type_of_title}
                        onChange={this.tipoHandleChange}
                        // error={this.state.type_of_titleErro}
                        disabled={this.state.title !== ''}
                      >
                        <MenuItem value={'despesa'}>à Pagar</MenuItem>
                        <MenuItem value={'receita'}>à Receber</MenuItem>
                        <MenuItem value={'controle'}>Controle</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {this.state.edicao && (
                  <FormControl component="fieldset" fullWidth>
                    <TextField
                      label={'Status do Pagto.'}
                      name="payment_status"
                      value={this.state.payment_status}
                      disabled={true}
                    ></TextField>
                  </FormControl>
                )}

                <FormControl
                  fullWidth={true}
                  style={{
                    display:
                      this.state.payment_Open === 'false' ? 'none' : 'flex',
                  }}
                >
                  {this.state.edicao && this.state.title ? (
                    <TextField
                      label={'Titulo'}
                      inputProps={{
                        readOnly: true,
                      }}
                      value={this.state.title + ' - ' + this.state.description}
                    />
                  ) : (
                    <AutoSelect
                      value={this.state.title}
                      suggestions={this.state.titleList}
                      handleChange2={this.TituloHandleChange}
                      placeholder="Selecione o Título"
                      error={this.state.titleErro}
                    />
                  )}
                </FormControl>

                <FormControl
                  fullWidth={true}
                  style={{
                    display:
                      this.state.payment_Open !== 'false' ? 'none' : 'flex',
                  }}
                >
                  <span style={{ color: 'red' }}>
                    {
                      '* Atenção: Pagamentos sem título vinculado não serão registrados no modelo "econômico".'
                    }
                  </span>
                </FormControl>

                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="title_sel_mode">
                    Pagamento/Recebimento de{' '}
                  </InputLabel>
                  <Select
                    disabled={
                      (this.state.title &&
                        this.state.title_issuer &&
                        !this.state.edicao) ||
                        this.state.payment_status == 'Pago'
                        ? true
                        : false
                    }
                    value={this.state.title_issuer}
                    inputProps={{
                      name: 'title_sel_mode',
                      id: 'title_sel_mode',
                    }}
                    onOpen={this.selectOnOpen}
                    onChange={this.emissorHandleChange}
                    error={this.state.title_issuerErro}
                  >
                    <MenuItem value={'funcionario'}>Funcionário</MenuItem>
                    <MenuItem value={'pj'}>Pessoa Jurídica (PJ)</MenuItem>
                    <MenuItem value={'fornecedor'}>Fornecedor</MenuItem>
                    <MenuItem value={'cliente'}>Cliente</MenuItem>
                    <MenuItem value={'outros'}>Outros</MenuItem>
                  </Select>
                </FormControl>
                {this.state.title_issuer === 'funcionario' && (
                  <FormControl fullWidth={true}>
                    {/*<InputLabel htmlFor="employee">*/}
                    {/*  Selecione o Funcionário*/}
                    {/*</InputLabel>*/}
                    {/*<Select*/}
                    {/*    readOnly={*/}
                    {/*      this.state.title !== "" && this.state.employee !== ""*/}
                    {/*    }*/}
                    {/*    value={this.state.employee}*/}
                    {/*    inputProps={{*/}
                    {/*      name: "employee",*/}
                    {/*      id: "employee"*/}
                    {/*    }}*/}
                    {/*    error={this.state.employeeErro}*/}
                    {/*    onChange={this.funcionarioHandleChange}*/}
                    {/*>*/}
                    {/*  {this.state.employeeList.map(item => (*/}
                    {/*      <MenuItem*/}
                    {/*          key={item.cpf_funcionario}*/}
                    {/*          value={item.cpf_funcionario}*/}
                    {/*      >*/}
                    {/*        {item.nome_funcionario}*/}
                    {/*      </MenuItem>*/}
                    {/*  ))}*/}
                    {/*</Select>*/}
                    <AutoSelect
                      value={this.state.employee}
                      disabled={
                        (this.state.title &&
                          this.state.employee &&
                          !this.state.edicao) ||
                          this.state.payment_status == 'Pago'
                          ? true
                          : false
                      }
                      initialValue={this.state.employee}
                      suggestions={this.state.employeeList}
                      handleChange2={this.funcionarioHandleChange}
                      placeholder="Selecione o Funcionário"
                      error={this.state.employeeErro}
                    />
                  </FormControl>
                )}

                {this.state.title_issuer === 'pj' && (
                  <FormControl fullWidth={true}>
                    {/*<InputLabel htmlFor="employee">Selecione o PJ</InputLabel>*/}
                    {/*<Select*/}
                    {/*    readOnly={this.state.title !== "" && this.state.pj !== ""}*/}
                    {/*    value={this.state.pj}*/}
                    {/*    onChange={this.pjHandleChange}*/}
                    {/*    error={this.state.pjErro}*/}
                    {/*>*/}
                    {/*  {this.state.pjList.map(item => (*/}
                    {/*      <MenuItem key={item.cpf_pj} value={item.cpf_pj}>*/}
                    {/*        {item.nome_pj}*/}
                    {/*      </MenuItem>*/}
                    {/*  ))}*/}
                    {/*</Select>*/}
                    <AutoSelect
                      value={this.state.pj}
                      initialValue={this.state.pj}
                      disabled={
                        (this.state.title &&
                          this.state.pj &&
                          !this.state.edicao) ||
                          this.state.payment_status == 'Pago'
                          ? true
                          : false
                      }
                      suggestions={this.state.pjList}
                      handleChange2={this.pjHandleChange}
                      placeholder="Selecione o PJ"
                      error={this.state.pjErro}
                    />
                  </FormControl>
                )}

                {this.state.title_issuer === 'fornecedor' && (
                  <FormControl fullWidth={true}>
                    {/*<InputLabel htmlFor="provider">*/}
                    {/*  Selecione o Fornecedor*/}
                    {/*</InputLabel>*/}
                    {/*<Select*/}
                    {/*    readOnly={*/}
                    {/*      this.state.title !== "" && this.state.provider !== ""*/}
                    {/*    }*/}
                    {/*    value={this.state.provider}*/}
                    {/*    inputProps={{*/}
                    {/*      name: "provider",*/}
                    {/*      id: "provider"*/}
                    {/*    }}*/}
                    {/*    onChange={this.fornecedorHandleChange}*/}
                    {/*    error={this.state.providerErro}*/}
                    {/*>*/}
                    {/*  {this.state.providerList.map(item => (*/}
                    {/*      <MenuItem*/}
                    {/*          key={item.cpf_cnpj_fornecedor}*/}
                    {/*          value={item.cpf_cnpj_fornecedor}*/}
                    {/*      >*/}
                    {/*        {item.nome_fantasia*/}
                    {/*            ? item.nome_fantasia*/}
                    {/*            : item.razao_social}*/}
                    {/*      </MenuItem>*/}
                    {/*  ))}*/}
                    {/*</Select>*/}
                    <AutoSelect
                      value={this.state.provider}
                      initialValue={this.state.provider}
                      disabled={
                        (this.state.title &&
                          this.state.provider &&
                          !this.state.edicao) ||
                          this.state.payment_status == 'Pago'
                          ? true
                          : false
                      }
                      suggestions={this.state.providerList}
                      handleChange2={this.fornecedorHandleChange}
                      placeholder="Selecione o Fornecedor"
                      error={this.state.providerErro}
                    />
                  </FormControl>
                )}

                {this.state.title_issuer === 'cliente' && (
                  <FormControl fullWidth={true}>
                    {/*<InputLabel htmlFor="provider">Selecione o Cliente</InputLabel>*/}
                    {/*<Select*/}
                    {/*    readOnly={*/}
                    {/*      this.state.title !== "" && this.state.cliente !== ""*/}
                    {/*    }*/}
                    {/*    value={this.state.cliente}*/}
                    {/*    inputProps={{*/}
                    {/*      name: "provider",*/}
                    {/*      id: "provider"*/}
                    {/*    }}*/}
                    {/*    onChange={this.clienteHandleChange}*/}
                    {/*    error={this.state.clienteErro}*/}
                    {/*>*/}
                    {/*  {this.state.clienteList.map(item => (*/}
                    {/*      <MenuItem*/}
                    {/*          key={item.cpf_cnpj_cliente}*/}
                    {/*          value={item.cpf_cnpj_cliente}*/}
                    {/*      >*/}
                    {/*        {item.nome_fantasia*/}
                    {/*            ? item.nome_fantasia*/}
                    {/*            : item.razao_social}*/}
                    {/*      </MenuItem>*/}
                    {/*  ))}*/}
                    {/*</Select>*/}
                    <AutoSelect
                      value={this.state.cliente}
                      initialValue={this.state.cliente}
                      disabled={
                        (this.state.title &&
                          this.state.cliente &&
                          !this.state.edicao) ||
                          this.state.payment_status == 'Pago'
                          ? true
                          : false
                      }
                      suggestions={this.state.clienteList.filter((cliente) => {
                        return (
                          cliente.is_active == 't' ||
                          cliente.value == this.state.cliente
                        );
                      })}
                      handleChange2={this.clienteHandleChange}
                      placeholder="Selecione o Cliente"
                      error={this.state.clienteErro}
                    />
                  </FormControl>
                )}

                {this.state.title_issuer === 'fornecedor' && (
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="contract">
                      Selecione o Contrato
                    </InputLabel>
                    <Select
                      disabled={
                        (this.state.title &&
                          this.state.contract &&
                          !this.state.edicao) ||
                          this.state.payment_status == 'Pago'
                          ? true
                          : false
                      }
                      value={this.state.contract}
                      inputProps={{
                        name: 'contract',
                        id: 'contract',
                      }}
                      onChange={this.handleChange}
                      error={this.state.contractErro}
                    >
                      {this.state.contractList.map((item) => (
                        <MenuItem
                          key={item.id_contrato}
                          value={item.id_contrato}
                        >
                          {item.display_nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {this.state.title_issuer === 'outros' && (
                  <TextField
                    inputProps={{
                      readOnly:
                        (this.state.title &&
                          this.state.name_or_company_name &&
                          !this.state.edicao) ||
                        this.state.payment_status == 'Pago',
                    }}
                    value={this.state.name_or_company_name}
                    label={'Nome'}
                    name={'name_or_company_name'}
                    fullWidth
                    onChange={this.handleChange}
                    error={this.state.name_or_company_nameErro}
                  />
                )}

                {this.state.title_issuer === 'outros' && (
                  <TextField
                    inputProps={{
                      readOnly:
                        (this.state.title &&
                          this.state.cpf_or_cnpj &&
                          !this.state.edicao) ||
                        this.state.payment_status == 'Pago',
                    }}
                    value={this.state.cpf_or_cnpj}
                    label={'CPF'}
                    name={'cpf_or_cnpj'}
                    fullWidth
                    onChange={this.handleChange}
                    InputProps={{
                      inputComponent: CPFMaskCustom,
                    }}
                    error={this.state.cpf_or_cnpjErro}
                  />
                )}

                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="project">Selecione o Projeto</InputLabel>
                  <Select
                    disabled={
                      (this.state.title &&
                        this.state.project &&
                        !this.state.edicao) ||
                        this.state.payment_status == 'Pago'
                        ? true
                        : false
                    }
                    value={this.state.project}
                    inputProps={{
                      name: 'project',
                      id: 'project',
                    }}
                    onOpen={this.selectOnOpen}
                    onChange={this.handleChange}
                    error={this.state.projectErro}
                  >
                    {this.state.projectList.map((item) => (
                      <MenuItem key={item.id_projeto} value={item.id_projeto}>
                        {item.id_projeto} - {item.desc_curta}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="account_group">
                    Grupo de Contas
                  </InputLabel>
                  <Select
                    disabled={
                      (this.state.title &&
                        this.state.account_group &&
                        !this.state.edicao) ||
                        this.state.payment_status == 'Pago'
                        ? true
                        : false
                    }
                    value={this.state.account_group}
                    inputProps={{
                      name: 'account_group',
                      id: 'account_group',
                    }}
                    onChange={this.accountGroupHandleChange}
                    error={this.state.account_groupErro}
                  >
                    {this.state.accountGroupList.map((item) => (
                      <MenuItem key={item.id_conta} value={item.id_conta}>
                        {item.nome_display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="account">Conta</InputLabel>
                  <Select
                    disabled={
                      (this.state.title &&
                        this.state.account &&
                        !this.state.edicao) ||
                        this.state.payment_status == 'Pago'
                        ? true
                        : false
                    }
                    value={this.state.account}
                    inputProps={{
                      name: 'account',
                      id: 'account',
                    }}
                    onChange={this.handleChange}
                    error={this.state.accountErro}
                  >
                    {this.state.accountList.map((item) => (
                      <MenuItem key={item.id_conta} value={item.id_conta}>
                        {item.nome_display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={true}>
                <FormControl fullWidth>
                  <CurrencyInput
                    allowNegative={true}
                    name={'titleValue'}
                    label={'Valor'}
                    inputProps={{
                      readOnly:
                        (this.state.title &&
                          this.state.titleValue &&
                          !this.state.edicao) ||
                        this.state.payment_status == 'Pago' ||
                        this.state.account.substring(0, 3) == '2.3',
                    }}
                    value={this.state.titleValue}
                    onChange={this.handleChange}
                    error={this.state.titleValueErro}
                  />
                </FormControl>

                <FormControl fullWidth={true}>
                  <TextField
                    inputProps={{
                      readOnly:
                        this.state.title &&
                        this.state.description &&
                        !this.state.edicao,
                    }}
                    label={'Descrição'}
                    name={'description'}
                    onChange={this.handleChange}
                    value={this.state.description}
                    error={this.state.descriptionErro}
                  />
                </FormControl>

                <FormControl fullWidth={true}>
                  <TextField
                    label={'Data de pgto./receb.'}
                    disabled={
                      this.state.edicao &&
                      this.state.project === '0' &&
                      this.state.type_of_title !== 'controle'
                    }
                    value={this.state.paymentDate}
                    type={'text'}
                    name={'paymentDate'}
                    onChange={this.handleChange}
                    InputProps={{
                      inputComponent: DateMaskCustom,
                    }}
                    error={this.state.paymentDateErro}
                  />
                </FormControl>

                <FormControl fullWidth={true}></FormControl>

                <FormControl fullWidth>
                  <InputLabel htmlFor="payment_mode">
                    Forma de pagamento
                  </InputLabel>
                  <Select
                    fullWidth
                    style={{ minWidth: '200px' }}
                    value={this.state.payment_mode}
                    inputProps={{
                      name: 'payment_mode',
                      id: 'payment_mode',
                    }}
                    onChange={this.modoPagamentoHandleChange}
                    error={this.state.payment_modeErro}
                  >
                    {this.state.paymentModeList.map((item, index) => (
                      <MenuItem value={item.forma_pagamento} key={index}>
                        {item.forma_pagamento}
                      </MenuItem>
                    ))}
                  </Select>
                  {this.state.payment_mode === 'Cheque' && (
                    <TextField
                      inputProps={{
                        readOnly:
                          this.state.title &&
                          this.state.check_number &&
                          !this.state.edicao,
                        maxLength: '20',
                      }}
                      fullWidth
                      label={'Nº do cheque'}
                      value={this.state.check_number}
                      name={'check_number'}
                      onChange={this.handleChange}
                      error={this.state.check_numberErro}
                    />
                  )}
                </FormControl>
                {this.state.payment_mode &&
                  this.state.payment_mode !== 'Dinheiro' && (
                    <FormControl fullWidth={true}>
                      <AutoSelect
                        value={this.state.pgtoRecebBank}
                        initialValue={this.state.pgtoRecebBank}
                        suggestions={this.state.bankList}
                        handleChange2={this.BancoDoPagamentoHandleChange}
                        placeholder="Banco do Pgto/Receb."
                        error={this.state.pgtoRecebBankErro}
                      />
                    </FormControl>
                  )}

                <FormControl fullWidth={true}>
                  <TextField
                    value={this.state.number_of_document}
                    label={'Nº do documento'}
                    name={'number_of_document'}
                    onChange={this.handleChange}
                  />
                </FormControl>

                <FormControl component="fieldset">
                  <FormLabel style={{ marginTop: '10px' }}>
                    Tipo de Conta
                  </FormLabel>
                  <RadioGroup
                    aria-label="corrent/não corrente"
                    name="current_or_not_current"
                    row={true}
                    value={this.state.current_or_not_current || 'not_current'}
                    onChange={(e, value) =>
                      this.setState({ current_or_not_current: value })
                    }
                  // error={this.state.current_or_not_currentErro}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label="Corrente"
                      disabled={
                        (this.state.title &&
                          this.state.current_or_not_current &&
                          !this.state.edicao) ||
                        this.state.type_of_title == 'receita'
                      }
                    />
                    {localStorage.getItem('noInvoiceCurrent') === 'false' && (<FormControlLabel
                      value="false"
                      control={<Radio color="primary" />}
                      label="Não Corrente"
                      disabled={
                        (this.state.title &&
                          this.state.current_or_not_current &&
                          !this.state.edicao) ||
                        this.state.type_of_title == 'receita'
                      }
                    />)}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              style={{ marginTop: '2em' }}
              container={true}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                color="primary"
                variant="contained"
                onClick={this.validaForm}
              >
                Salvar
              </Button>
              {this.hasPaymentPermission() &&
                this.state.payment_status == 'Aprovado' && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.validaForm(true)}
                    style={{ marginLeft: 30 }}
                  >
                    Pagar
                  </Button>
                )}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Form;
