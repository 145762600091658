var moment = require('moment');

export default class conciliacaoUtils {
    static printFile (text) {
        let lines = text.split('\n');
        for(let line = 0; line < lines.length; line++){
            let data = lines[line].split('\t');
            for(let tab = 0; tab < data.length; tab++){
                let cell = data[tab];
                if (cell.length <= 1) continue;
                console.log(line, tab, data[tab]);
            }
        }
    }

    static moeda2float = moeda => {
        if (moeda === "") {
            return "";
        } else {
            if ((typeof moeda) === "string") {
            moeda = moeda.replace('R$ ', '');
            moeda = moeda.replace(/\./g, '');
            moeda = moeda.replace(/\,/g, '.');
            moeda = moeda.replace(/\ /g, '');
            }
            return parseFloat(moeda);
        }
    };

    static itauPreProcess = (text, bank) => {
        let processedData = [];
        let date = "";
        let value = "";
        let description = "";
        let valid = true;

        const ignoreConstants = ['SALDO', 'REND PAGO', 'SDO CTA/APL', 'APLIC AUT', 'APLICACAO'];

        let lines = text.split('\n');
        for(let line = 0; line < lines.length; line++){
            let data = lines[line].split(';');
            for(let tab = 0; tab < data.length; tab++){
                let cell = data[tab];
                if (cell.length <= 1) continue;

                if (tab == 0) {
                    let timestamp = moment(cell, "DD/MM/YYYY");
                    if (isNaN(timestamp) == false) {
                        date = new Date(timestamp).toISOString().slice(0, 10);
                        description = data[tab+1];
                        value = data[tab+2];
                        for (let i = 0; i < ignoreConstants.length; i++) {
                            if (description.includes(ignoreConstants[i])) valid = false;
                        }
                        if (valid) {
                            processedData.push({
                                'data': date,
                                'descricao': description,
                                'numero': null,
                                'valor': this.moeda2float(value),
                                'banco': bank
                            });
                        }
                        valid = true;
                    }
                } else continue;
            }
        }
        return processedData;
    }

    static santanderPreProcess = (text, bank) => {
        let processedData = [];
        let date = "";
        let value = "";
        let number = "";
        let description = "";
        let valid = true;

        const ignoreConstants = ['RESGATE', 'APLICACAO', 'SALDO ANTERIOR', 'APLICACAO EM FUNDO'];

        let lines = text.split('\n');
        for(let line = 0; line < lines.length; line++){
            let data = lines[line].split('\t');
            for(let tab = 0; tab < data.length; tab++){
                let cell = data[tab];
                if (cell.length <= 1) continue;

                if (tab == 0) {
                    let timestamp = moment(cell, "DD/MM/YYYY");
                    if (isNaN(timestamp) == false) {
                        date = new Date(timestamp).toISOString().slice(0, 10);
                        description = data[tab+2].replace(/  +/g, ' ');
                        number = data[tab+3];
                        value = data[tab+4];
                        for (let i = 0; i < ignoreConstants.length; i++) {
                            if (description.includes(ignoreConstants[i])) valid = false;
                        }
                        if (valid) {
                            processedData.push({
                                'data': date,
                                'descricao': description,
                                'numero': number,
                                'valor': this.moeda2float(value),
                                'banco': bank
                            });
                        }
                        valid = true;
                    }
                } else continue;
            }
        }
        return processedData;
    }

    static bradescoPreProcess = (text, bank, eh_corrente, cpf_diretor) => {
        let processedData = [];
        let date = "";
        let value = "";
        let number = "";
        let description = "";
        let valid = true;

        // Conteúdos de linhas que precisam ser ignoradas na importação
        const ignoreConstants = ['INVEST', 'Invest', 'Resgate', 'Doc/tedinternet', 'Rendimentos', 'SALDO', 'Total', 'Data', 'APLICACAO', 'CARTAO CREDITO', 'Extrato'];
        const breakConstants = ['Últimos Lançamentos', 'Saldos Invest Fácil / Plus', 'Os dados acima têm como base'];

        let lines = text.split('\n');
        for(let line = 0; line < lines.length; line++){
            let data = lines[line]
            if (data.length <= 1) continue;

            // Linhas padrão começam com as datas de lançamento
            let date_text = data.substring(0,10).trim();
            if (moment(date_text, "DD/MM/YYYY", true).isValid() ||
                moment(date_text, "DD/MM/YY", true).isValid()) {
                let timestamp = moment(date_text, "DD/MM/YYYY")
                date = new Date(timestamp).toISOString().slice(0, 10);

                // Extraída a data, sobram a descrição e os valores do lançamento
                let str = data.substring(11, data.length);
                str = str.replace(/ +/gm, ' ');
                let nummericValues = str.match(/[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]+)?(?:\.[0-9]+)?(?:\,[0-9]+)?/gm)
                if (nummericValues.length < 2) {
                    continue
                }

                number = nummericValues[0];
                value = nummericValues[1];

                // Removendo os valores numericos, sobra apenas a descrição
                description = str;
                for (let i = 0; i < nummericValues.length; i++) {
                    description = description.replace(nummericValues[i], '').trim()
                }
                
                for (let i = 0; i < ignoreConstants.length; i++) {
                    if (description.includes(ignoreConstants[i])) valid = false;
                }
                if (valid) {
                    processedData.push({
                        'data': date,
                        'descricao': description,
                        'numero': number,
                        'valor': this.moeda2float(value),
                        'banco': bank,
                        'eh_corrente': eh_corrente === false ? "false" : "true",
                        'cpf_socio': cpf_diretor
                    });
                }
                valid = true;
            } else {
                let str = data.replace(/ +/gm, ' ');
                // Caso a linha - que não é um lançamento normal - tenha algum padrão indesejado, a removemos.
                for (let i = 0; i < ignoreConstants.length; i++) {
                    if (str.includes(ignoreConstants[i])) valid = false;
                }
                for (let i = 0; i < breakConstants.length; i++) {
                    if (str.includes(breakConstants[i])) return processedData;
                }
                if (valid && processedData.length > 0) {
                    // Passando pelo filtro, o que sobra é quando há a continuação da descrição do lançamento anterior
                    processedData[processedData.length - 1].descricao = processedData[processedData.length - 1].descricao + ' ' + str.trim();
                }
                valid = true;
            }
        }
        return processedData;
    }
}