import React from "react";
import { Nav, NavDropdown, NavItem, MenuItem } from "react-bootstrap/lib";
import { observer } from "mobx-react";

import $ from "jquery";
import { Link } from "react-router";
import Menu_structure from "./Menu_structure";

const maxMenu = 5;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.treinamentos = props.treinamentos;
    this.structure = Menu_structure;
    this.topbarMenuPermission = [];
    this.configureMenuAcess = this.configureMenuAccess.bind(this);
    this.hasAccess = this.hasAccess.bind(this);
  }

  getTitle(id_view) {
    const data = JSON.parse(localStorage.getItem("Menu")) || {};
    return data.views.map(e => {
      if (id_view === e.id_view) return e.desc_view;
    });
  }

  configureMenuAccess() {
    const data = JSON.parse(localStorage.getItem("Menu")) || {};
    const userActs = data.views.map(f => f.id_view);
    this.topbarMenuPermission = userActs;
  }

  hasAccess(topMenu) {
    if (!topMenu) return false;

    if (topMenu.view) {
      return this.topbarMenuPermission.includes(topMenu.view);
    }

    if (topMenu.items && topMenu.items != []) {
      let me = this;
      let ret = false;
      topMenu.items.forEach(function(item, index) {
        ret = ret || me.topbarMenuPermission.includes(item.view);
      });
      return ret;
    }
    return false;
  }

  hasAccess2(key) {
    return key && this.topbarMenuPermission.includes(key);
  }
  componentWillMount() {
    $(document).ready(function() {
      $(function() {
        $(".dropdown").hover(
          function() {
            $(this).addClass("open");
          },
          function() {
            $(this).removeClass("open");
          }
        );
      });
    });

    this.configureMenuAccess();
  }

  generateNavDropdown = (name, items, index) => {
    if (items === undefined || items === null) {
      return "";
    }
    if (items.length === 0) {
      const item = this.structure.menus.items.find(e => e.title === name);


      if (item) {
        return (
          <NavItem 
            key={index} 
            href={item.action}
            style={{
              textDecoration: "none",
              color: "white"
            }}>
              {name}
          </NavItem>
        );
      } else {
        return name;
      }
    }
    var array = [];
    for (let index = 0; index < items.length; index++) {
      let e = items[index];
      if (this.hasAccess2(e.view)) {
        if (array.length > 0) {
          array.push(<MenuItem key={100 + index} divider />);
          array.push(
            <MenuItem key={index}
                href={e.action}
                style={{
                  textDecoration: "none",
                  color: "white",
                  width: "99%",
                  height: "99%"
                }}
              >
                {this.getTitle(e.view)}
            </MenuItem>
          );
        } else {
          array.push(
            <MenuItem key={index}
                href={e.action}
                style={{
                  textDecoration: "none",
                  color: "white",
                  width: "99%",
                  height: "99%"
                }}
              >
                {this.getTitle(e.view)}
            </MenuItem>
          );
        }
      }
    }

    return (
      <NavDropdown
        title={name}
        key={index}
        id="basic-nav-dropdown"
        style={{ color: "white" }}
      >
        {array}
      </NavDropdown>
    );
  };

  generateMenuItem = items => {
    if (items === undefined) return "";

    let menuItems = items
    .filter(e => this.hasAccess(e))
    .map((e, index) => {
      return (
          this.generateNavDropdown(e.title, e.items, index)
      );
    });


    if (menuItems.length > maxMenu) {
      return items
        .filter(e => e.treinamentos == this.treinamentos)
        .map((e, index) => {
          return (
              this.generateNavDropdown(e.title, e.items, index)
          );
      });
    };
    return menuItems
  }
    
  render() {
    return <Nav>{this.generateMenuItem(this.structure.menus.items)}</Nav>;
  }
}

export default observer(Menu);
