import AppState from "../state";

const baseUrl = `${process.env.PUBLIC_URL}/${AppState.serverAddr}/contratos`;

const getContratos = () => {
  return fetch(`${baseUrl}/GetContratos.php`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: AppState.token
    }
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        let error = new Error(response.status);
        error.response = json;
        throw error;
      });
    } else {
      const res = response.json();
      return res;
    }
  });
};

const getContratoById = contratoId => {
  return fetch(
    `${baseUrl}/GetContrato.php?json={"id_contrato":"${contratoId}"}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: AppState.token
      }
    }
  ).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        let error = new Error(response.status);
        error.response = json;
        throw error;
      });
    } else {
      const res = response.json();
      return res;
    }
  });
};

export { getContratos, getContratoById };
