import AppState from "../state";

const baseUrl = `${process.env.PUBLIC_URL}/${AppState.serverAddr}/fornecedores`;

const editFornecedor = fornecedorData =>
  fetch(`${baseUrl}/EditFornecedor.php`, {
    method: "POST",
    headers: {
      Authorization: AppState.token
    },
    body: fornecedorData
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }
    return response.json();
  });

const addFornecedor = fornecedorData => {
  return fetch(`${baseUrl}/AddFornecedor.php`, {
    method: "POST",
    headers: {
      Authorization: AppState.token
    },
    body: fornecedorData
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }

    const res = response.json();
    return res;
  });
};

const getFornecedor = cnpjFornecedor => {
  return fetch(
    `${baseUrl}/GetFornecedor.php?json={"cnpj_fornecedor":"${cnpjFornecedor}"}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: AppState.token
      }
    }
  ).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        let error = new Error(response.status);
        error.response = json;
        throw error;
      });
    } else {
      const res = response.json();
      return res;
    }
  });
};

const getEspecialidades = () => {
  return fetch(`${baseUrl}/GetEspecialidades.php`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: AppState.token
    }
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        let error = new Error(response.status);
        error.response = json;
        throw error;
      });
    } else {
      const res = response.json();
      return res;
    }
  });
};

const getCriterioSelecao = () => {
  return fetch(`${baseUrl}/GetCriterioSelecao.php?`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: AppState.token
    }
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        let error = new Error(response.status);
        error.response = json;
        throw error;
      });
    } else {
      const res = response.json();
      return res;
    }
  });
};

const editEspecialidade = especialidadeData =>
  fetch(`${baseUrl}/EditEspecialidade.php`, {
    method: "POST",
    headers: {
      Authorization: AppState.token
    },
    body: especialidadeData
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }
    return response.json();
  });

const addEspecialidade = especialidadeData =>
  fetch(`${baseUrl}/AddEspecialidade.php`, {
    method: "POST",
    headers: {
      Authorization: AppState.token
    },
    body: especialidadeData
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }
    return response.json();
  });

export {
  editFornecedor,
  addFornecedor,
  getFornecedor,
  getEspecialidades,
  getCriterioSelecao,
  editEspecialidade,
  addEspecialidade
};
