import React from 'react';
import Form from './Form';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ReactTable from 'react-table';
import AppState from '../../state';
import EditIcon from '@material-ui/icons/Edit';
import { browserHistory } from 'react-router';
import { Utils } from '../../services';
import { Grid } from '@material-ui/core';
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Funcionarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      funcionarios: [],
      funcionario: '',
      edicao: false,
    };
    this.getTableData();
  }

  getTableData = () => {
    AppState.isLoading = true;
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/funcionarios/GetFuncionarios.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          AppState.isLoading = false;
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          funcionarios: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  closeDialog = () => {
    this.setState({
      dialog: false,
      edicao: false,
    });
  };

  openDialog = () => {
    this.setState({
      edicao: false,
      dialog: true,
    });
  };

  openEdicaoDialog = (funcionario) => {
    this.setState({
      edicao: true,
      dialog: true,
      funcionario: funcionario,
    });
  };

  closeDialog = () => {
    this.setState({
      dialog: false,
      edicao: false,
    });
  };

  preparaDado = (dados) => {
    if (dados == null || dados.length < 1) return [];
    let ret = dados.slice(0);
    for (let i = 0; i < dados.length; i++) {
      ret[i].dt_nascimento = Utils.dateToPT(dados[i].dt_nascimento);
      ret[i].dt_admissao = Utils.dateToPT(dados[i].dt_admissao);
      ret[i].dt_demissao = Utils.dateToPT(dados[i].dt_demissao);
      ret[i].rg_dt_emissao = Utils.dateToPT(dados[i].rg_dt_emissao);
      ret[i].ctps_data = Utils.dateToPT(dados[i].ctps_data);
      ret[i].cnh_dt_emissao = Utils.dateToPT(dados[i].cnh_dt_emissao);
      ret[i].timestamp_criacao = Utils.dateToPT(dados[i].timestamp_criacao);
      ret[i].ultima_atualizacao = Utils.dateToPT(dados[i].ultima_atualizacao);
      ret[i].status = dados[i].status == 'ativo' ? 'Ativo' : 'Desligado';
      ret[i].sexo = dados[i].sexo == 'M' ? 'Masculino' : 'Feminino';
      ret[i].custo_total_mensal =
        'R$ ' + Utils.float2moeda(dados[i].custo_total_mensal);
    }
    return ret;
  };

  render() {
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <h1>Cadastro de Funcionário</h1>

        <Grid
          style={{
            marginTop: '48px',
            marginBottom: '16px',
          }}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={40}
        >
          <Grid
            item
            style={{
              padding: '0 20px',
              display: 'flex',
              gap: '24px',
              maxWidth: '400px',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={this.openDialog}
            >
              <AddIcon />
              Novo funcionário
            </Button>

            <ExcelFile
              filename={'RelatorioFuncionarios-' + Utils.getTodayDate()}
              element={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.exportarExcel}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 192 192"
                    style={{ fill: '#000000', paddingRight: '6px' }}
                  >
                    <g
                      fill="none"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                      style={{ mixBlendMode: 'normal' }}
                    >
                      <path d="M0,192v-192h192v192z" fill="none"></path>
                      <g fill="#ffffff">
                        <g id="surface1">
                          <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  Exportar
                </Button>
              }
            >
              <ExcelSheet
                data={() => this.preparaDado(this.state.funcionarios)}
                name="Funcionarios"
              >
                <ExcelColumn
                  label="CPF Funcionário"
                  value="cpf_funcionario"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Matricula"
                  value="matricula_funcionario"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Nome"
                  value="nome_funcionario"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Sexo"
                  value="sexo"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Nacionalidade"
                  value="nacionalidade"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Data de Nascimento"
                  value="dt_nascimento"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Cidade Nascimento"
                  value="cidade_nascimento"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Nome do pai"
                  value="nome_pai"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Nome da Mãe"
                  value="nome_mae"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Estado Civil"
                  value="estado_civil"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Celular"
                  value="celular"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Tel. Residência"
                  value="tel_residencia"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="E-mail Pessoal"
                  value="email_pessoal"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="E-mail Profissional"
                  value="email_profissional"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Data de Admissão"
                  value="dt_admissao"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Data de Demissão"
                  value="dt_demissao"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="RG"
                  value="rg_numero"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="RG Dt. Emissão"
                  value="rg_dt_emissao"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="RG Emissor"
                  value="rg_emissor"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="RG UF"
                  value="rg_uf"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CTPS Número"
                  value="ctps_numero"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CTPS Série"
                  value="ctps_serie"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CTPS Data"
                  value="ctps_data"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CTPS UF"
                  value="ctps_uf"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Certif. Alistamento Militar"
                  value="cert_alistamento_militar"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="PIS Número"
                  value="pis_numero"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CNH Número"
                  value="cnh_numero"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CNH Categoria"
                  value="cnh_categoria"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CNH Dt. Emissão"
                  value="cnh_dt_emissao"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CNH Dt. Vencimento"
                  value="cnh_dt_vencimento"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Título Eleitor"
                  value="titulo_eleitor"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Título Zona"
                  value="titulo_zona"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Título Sessão"
                  value="titulo_sessao"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="CEP Residência"
                  value="cep_residencia"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Endereço Residência"
                  value="end_residencia"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Número Residência"
                  value="numero_residencia"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Complemento Residência"
                  value="complemento_residencia"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Cidade Residência"
                  value="cidade_residencia"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="UF Residência"
                  value="uf_residencia"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Tipo de Vínculo"
                  value="tipo_vinculo"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Criado Em"
                  value="timestamp_criacao"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Jornada Diária"
                  value="jornada_diaria_trabalho"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="ID Projeto Vinculado"
                  value="id_projeto_vinculado"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Custo Mensal"
                  value="custo_total_mensal"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Bairro Residência"
                  value="bairro_residencia"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Cargo"
                  value="cargo"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Link Documentação"
                  value="link_documentacao"
                  style={{ font: { sz: '24', bold: true } }}
                />
                <ExcelColumn
                  label="Status"
                  value="status"
                  style={{ font: { sz: '24', bold: true } }}
                />
              </ExcelSheet>
            </ExcelFile>
          </Grid>
        </Grid>

        <ReactTable
          filterable={true}
          data={this.state.funcionarios}
          defaultPageSize={20}
          className="-striped -highlight"
          defaultFilterMethod={(filter, row) =>
            filter.id === 'dt_admissao'
              ? Utils.dateToPT(String(row[filter.id])).startsWith(
                  filter.value.toLowerCase()
                )
              : String(row[filter.id])
                  .toLowerCase()
                  .startsWith(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: 'Funcionários',
              columns: [
                {
                  Header: 'Status',
                  accessor: 'status',
                  width: 80,
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{
                        paddingLeft: '0px',
                        textAlign: 'center',
                        color: row.value == 'ativo' ? 'green' : 'red',
                      }}
                    >
                      {row.value == 'ativo' ? 'Ativo' : 'Desligado'}
                    </div>
                  ),
                },
                {
                  Header: 'Matrícula',
                  accessor: 'matricula_funcionario',
                  width: 110,
                },
                {
                  Header: 'CPF',
                  accessor: 'cpf_funcionario',
                  width: 180,
                },
                {
                  Header: 'Nome',
                  accessor: 'nome_funcionario',
                },
                {
                  Header: 'Celular',
                  accessor: 'celular',
                  width: 130,
                },
                {
                  Header: 'E-mail',
                  accessor: 'email_profissional',
                  width: 250,
                },
                {
                  Header: 'Tipo de Vinculo',
                  accessor: 'tipo_vinculo',
                  width: 110,
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{ textAlign: 'center' }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Dt. Admissão',
                  accessor: 'dt_admissao',
                  width: 100,
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{ textAlign: 'center' }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Ação',
                  filterable: false,
                  accessor: 'action',
                  width: 50,
                  Cell: (row) => (
                    <div className="action-cell" style={{ paddingLeft: '0px' }}>
                      {
                        <EditIcon
                          onClick={() =>
                            this.openEdicaoDialog(
                              row.original['cpf_funcionario']
                            )
                          }
                          style={{
                            cursor: 'pointer',
                            width: '22px',
                            height: '22px',
                            minWidth: '22px',
                            minHeight: '22px',
                            marginLeft: '5px',
                          }}
                        />
                      }
                    </div>
                  ),
                },
              ],
            },
          ]}
        />

        <Form
          open={this.state.dialog}
          close={this.closeDialog}
          edicao={this.state.edicao}
          carregaTabela={this.getTableData}
          funcionario={this.state.funcionario}
        />
      </div>
    );
  }
}

export default Funcionarios;
