import React, { useState } from 'react';
import AppState from '../../state';

const CheckBoxNoInvoiceCurrent = () => {
    const [isChecked, setIsChecked] = useState(() => {
        const saved = localStorage.getItem('noInvoiceCurrent');
        return saved === 'true';
    });

    const changeIsInvoiceCurrent = async () => {
        try {
            await fetch(
                `${AppState.serverAddr}/config/CheckChangeInvoiceCurrent.php?isInvoiceCurrent=${!isChecked}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                }
            );

        } catch (error) {
            console.log("Erro no changeIsInvoiceCurrent:", error);
        }
    };

    const handleCheck = () => {
        const newValue = !isChecked;
        setIsChecked(newValue);
        localStorage.setItem('noInvoiceCurrent', newValue);
        changeIsInvoiceCurrent();
    };

    return (
        <div
            style={styles.checkboxContainer}
            onClick={handleCheck}
        >
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheck}
                style={styles.checkboxInput}
            />
            <span
                style={{
                    ...styles.checkboxCustom,
                    backgroundColor: isChecked ? '#007bff' : 'transparent',
                }}
            >
                <span
                    style={{
                        ...styles.checkboxCheckmark,
                        opacity: isChecked ? 1 : 0,
                    }}
                />
            </span>
            {/*  <span style={styles.checkboxLabel}>
                {isChecked ? 'Corrente' : 'Não Corrente'}
            </span> */}
        </div>
    );
};

const styles = {
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        fontFamily: 'Arial, sans-serif',
    },
    checkboxInput: {
        display: 'none',
    },
    checkboxCustom: {
        width: '20px',
        height: '20px',
        border: '2px solid #007bff',
        borderRadius: '4px',
        display: 'inline-block',
/*         position: 'relative',
 */        transition: 'background-color 0.3s ease',
    },
    checkboxCheckmark: {
        content: '""',
        position: 'absolute',
        top: '60%',
        left: '60%',
        width: '10px',
        height: '10px',
        backgroundColor: 'white',
        transform: 'translate(-50%, -50%)',
        opacity: 0,
        transition: 'opacity 0.3s ease',
    },
    checkboxLabel: {
        marginLeft: '10px',
        color: '#333',
        fontSize: '16px',
    },
};

export default CheckBoxNoInvoiceCurrent;
