import React from "react";
import AppState from "../../../state";
import { browserHistory } from "react-router";
import ReactExport from "react-data-export";
import { Utils } from "../../../services";

import RelatorioConsolidado from "./RelatorioConsolidado";

import {
  MenuItem,
  Select,
  TextField,
  Paper,
  FormControl,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  Grid,
  InputLabel,
  MenuList,
  Checkbox,
  Tooltip
} from "@material-ui/core/";
import Settings from "@material-ui/icons/Settings";
import ImporteIndices from "./ImporteIndices";
import RelatorioDetalhado from "./RelatorioDetalhado";
import RelatorioIndicadores from "./RelatorioIndicadores";

import debounce from 'lodash.debounce';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class RelatorioGerencial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projetos: [],
      relatorio: [],
      responsaveis: [],
      dt_inicio: this.getThisYear(),
      dt_fim: this.getFinishDay(),
      responsavel: "todos",
      statusProjeto: "andamento",
      tipoRelatorio: "consolidado",
      totalDespesasBanco: 0,
      showConfMenu: false,
      dialog: false,
      impostos: 0,
      calculadf: true,
      modeloRelatorio: "financeiro"
    };

    this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
  }

  componentDidMount() {
    this.carrega_relatorio();
    this.getResponsaveis();
  }

  getResponsaveis = () => {
    AppState.isLoading = true;

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/projetos/GetSocios.php?`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: AppState.token
        }
      }
    )
      .then(response => {
        if (response.status === "401") {
          AppState.isLoading = false;
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          return;
        }
        const res = response.json();
        return res;
      })
      .then(response => {
        AppState.isLoading = false;
        response.request.unshift({
          cpf_funcionario: "todos",
          nome_funcionario: "Todos"
        });
        this.setState({
          responsaveis: response.request
        });
      })
      .catch(function(error) {
        AppState.isLoading = false;
        console.log("Erro de api!", error);
      });
  };

  carrega_relatorio = () => {
    AppState.isLoading = true;
    const json = JSON.stringify({
      dt_inicio: this.state.dt_inicio,
      dt_fim: this.state.dt_fim,
      tipo: this.state.tipoRelatorio,
      calculadf: this.state.calculadf
    });

    const url =
      this.state.modeloRelatorio === "financeiro"
        ? "/relatorios/RelatorioGerencial.php"
        : "/relatorios/RelatorioGerencialEco.php";

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}${url}?json=${json}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: AppState.token
        }
      }
    )
      .then(response => {
        if (response.status === "401") {
          AppState.isLoading = false;
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          return;
        }
        const res = response.json();
        return res;
      })
      .then(response => {
        this.setState(
          {
            projetos: response.request,
            relatorio: response.request
          },
          () => {
            this.filtroRelatorio();
            AppState.isLoading = false;
          }
        );
      })
      .catch(function(error) {
        AppState.isLoading = false;
        console.log("Erro de api!", error);
      });
  };

  getDespesas = () => {
    AppState.isLoading = true;
    const json = JSON.stringify({
      dt_inicio: this.state.dt_inicio,
      dt_fim: this.state.dt_fim
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/relatorios/GetDespesas.php?json=${json}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: AppState.token
        }
      }
    )
      .then(response => {
        if (response.status === "401") {
          AppState.isLoading = false;
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          return;
        }
        const res = response.json();
        return res;
      })
      .then(response => {
        AppState.isLoading = false;
        this.setState({
          totalDespesasBanco: response.request[0].valor
        });
      })
      .catch(function(error) {
        AppState.isLoading = false;
        console.log("Erro de api!", error);
      });
  };

  getImpostos = () => {
    AppState.isLoading = true;
    const json = JSON.stringify({
      dt_inicio: this.state.dt_inicio,
      dt_fim: this.state.dt_fim
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/relatorios/GetImpostos.php?json=${json}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: AppState.token
        }
      }
    )
      .then(response => {
        if (response.status === "401") {
          AppState.isLoading = false;
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          return;
        }
        const res = response.json();
        return res;
      })
      .then(response => {
        AppState.isLoading = false;
        this.setState({
          impostos: response.request[0].valor
        });
      })
      .catch(function(error) {
        AppState.isLoading = false;
        console.log("Erro de api!", error);
      });
  };

  clearTimeout = () => {
    this.setState({
      timeout: null
    })
  }

  _handleChange = name => event => {
    var value =
      event.target && event.target.value
        ? event.target.value
        : typeof event === "string"
        ? event
        : "";

    this.setState({ [name]: value }, () => {
      this._debouncedHandleChange();
    });
  };

  _debouncedHandleChange = () => {
    this.carrega_relatorio();
    this.getDespesas();
    this.getImpostos();
  }

  _handleCheck = name => event => {
    var value =
      event.target && event.target.checked
        ? event.target.checked
        : typeof event === "string"
        ? event
        : "";

    this.setState({ [name]: value }, () => {
      this.carrega_relatorio();
      this.getDespesas();
      this.getImpostos();
    });
  };

  filterHandleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        this.filtroRelatorio();
      }
    );
  };

  filtroRelatorio = () => {
    let state = this.state;

    function filtraRelatorio(projeto) {
      let status = state.statusProjeto;
      let responsavel = state.responsavel;

      //Atribui Valore a status
      if (state.statusProjeto === "andamento") {
        status = "Ativo";
      } else if (state.statusProjeto === "encerrado") {
        status = "Encerrado";
      }

      //Realiza o filtro de acordo com as condições
      if (status !== "todos" && responsavel !== "todos") {
        return (
          projeto.status === status && projeto.cpf_responsavel === responsavel
        );
      } else if (status !== "todos" && responsavel === "todos") {
        return projeto.status === status;
      } else if (status === "todos" && responsavel !== "todos") {
        return projeto.cpf_responsavel === responsavel;
      } else if (status === "todos" && responsavel === "todos") {
        return projeto;
      }
    }

    if (this.state.projetos) {
      this.setState({
        relatorio: this.state.projetos.filter(filtraRelatorio)
      });
    }
  };

  getFinishDay() {
    var date = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    );
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    var dd = String(lastDay.getDate()).padStart(2, "0");
    var mm = String(lastDay.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = lastDay.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }

  getThisYear() {
    var today = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    );
    var yyyy = today.getFullYear();

    if (today.getMonth() === 0) {
      return `${yyyy - 1}-01-01`;
    }

    return yyyy + "-01-01";
  }

  toggleConfMenu = () => {
    this.setState(state => ({
      showConfMenu: !state.showConfMenu
    }));
  };

  closeConfMenu = () => {
    this.setState(state => ({
      showConfMenu: false
    }));
  };

  openDialog = () => {
    this.setState({
      dialog: true
    });
  };

  closeDialog = () => {
    this.setState({
      dialog: false
    });
  };

  getDadosExportacao = tipoRelatorio => {
    const data = this.selectTable.getResolvedState().sortedData;

    const indicadores = data.reduce(
      (total, { _original }) => {
        total.tdi += parseFloat(_original.tdi);
        total.mc += parseFloat(_original.mc);
        total.ac += parseFloat(_original.ac);
        total.tdd += parseFloat(_original.tdd);
        total.receita += parseFloat(_original.receita);
        total.it += parseFloat(_original.it);
        total.saldo_periodo += parseFloat(_original.saldo_periodo);
        total.resultado += parseFloat(_original.resultado);
        total.pessoal += parseFloat(_original.pessoal);
        total.outros += parseFloat(_original.outros);

        return total;
      },
      {
        tdi: 0,
        mc: 0,
        ac: 0,
        tdd: 0,
        receita: 0,
        it: 0,
        resultado: 0,
        saldo_periodo: 0,
        pessoal: 0,
        outros: 0
      }
    );

    const mcAc = (indicadores.mc / indicadores.ac) * 100;
    const tddReceita = (indicadores.tdd / indicadores.receita) * 100;
    const acReceita = (indicadores.ac / indicadores.receita) * 100;
    const itReceita = (indicadores.it / indicadores.receita) * 100;
    const tdiReceita = (indicadores.tdi / indicadores.receita) * 100;
    const resReceita = (indicadores.resultado / indicadores.receita) * 100;
    const acTdd = (indicadores.ac / indicadores.tdd) * 100;
    const itTdd = (indicadores.it / indicadores.tdd) * 100;
    const resTdd = (indicadores.resultado / indicadores.tdd) * 100;
    const receitaTdd = (indicadores.receita / indicadores.tdd - 1) * 100;
    const tdiTdd = (indicadores.tdi / indicadores.tdd) * 100;

    const mcac = `${Math.round(mcAc)}%`;
    const tddrec = `${Math.round(tddReceita)}%`;
    const acrec = `${Math.round(acReceita)}%`;
    const itrec = `${Math.round(itReceita)}%`;
    const tdirec = `${Math.round(tdiReceita)}%`;
    const resrec = `${Math.round(resReceita)}%`;
    const actdd = `${Math.round(acTdd)}%`;
    const ittdd = `${Math.round(itTdd)}%`;
    const restdd = `${Math.round(resTdd)}%`;
    const bdi = `${Math.round(receitaTdd)}%`;
    const bdie = `${Math.round(tdiTdd)}%`;

    let totalizadores;

    if (tipoRelatorio === "indicadores") {
      totalizadores = {
        id_projeto: "",
        desc_curta: "",
        tipo_projeto: "",
        mcac,
        tddrec,
        acrec,
        itrec,
        tdirec,
        resrec,
        actdd,
        ittdd,
        restdd,
        bdi,
        bdie
      };
    } else if (tipoRelatorio === "consolidado") {
      totalizadores = {
        id_projeto: "",
        desc_curta: "",
        tipo_projeto: "",
        receita: ` R$ ${Utils.float2moeda(indicadores.receita)}`,
        tdd: ` R$ ${Utils.float2moeda(indicadores.tdd)}`,
        tdi: ` R$ ${Utils.float2moeda(indicadores.tdi)}`,
        resultado: ` R$ ${Utils.float2moeda(indicadores.resultado)}`,
        saldo_periodo: ` R$ ${Utils.float2moeda(indicadores.saldo_periodo)}`
      };
    } else {
      totalizadores = {
        id_projeto: "",
        desc_curta: "",
        tipo_projeto: "",
        receita: `R$ ${Utils.float2moeda(indicadores.receita)}`,
        tdd: `R$ ${Utils.float2moeda(indicadores.tdd)}`,
        pessoal: `R$ ${Utils.float2moeda(indicadores.pessoal)}`,
        outros: `R$ ${Utils.float2moeda(indicadores.outros)}`,
        tdi: `R$ ${Utils.float2moeda(indicadores.tdi)}`,
        it: `R$ ${Utils.float2moeda(indicadores.it)}`,
        ac: `R$ ${Utils.float2moeda(indicadores.ac)}`,
        df: `R$ ${Utils.float2moeda(indicadores.df)}`,
        mc: `R$ ${Utils.float2moeda(indicadores.mc)}`,
        resultado: `R$ ${Utils.float2moeda(indicadores.resultado)}`,
        saldo_periodo: `R$ ${Utils.float2moeda(indicadores.saldo_periodo)}`
      };
    }

    const periodo = `Período: De ${Utils.dateToPT(
      this.state.dt_inicio
    )} à ${Utils.dateToPT(this.state.dt_fim)}`;

    const row1 = {
      id_projeto: "",
      desc_curta: periodo,
      tipo_projeto: "",
      receita: "",
      tdd: "",
      pessoal: "",
      outros: "",
      tdi: "",
      it: "",
      ac: "",
      df: "",
      mc: "",
      resultado: "",
      saldo_periodo: ""
    };

    return [...data, totalizadores, row1];
  };

  render() {
    return (
      <Grid id={this.constructor.name} style={{ marginTop: "4em" }}>
        <Grid container alignItems={"center"}>
          <Grid item sm={6}>
            <h1>Relatório Gerencial</h1>
          </Grid>
          <Grid item sm={6} style={{ zIndex: "2" }}>
            <div>
              <Button
                style={{ float: "right" }}
                buttonRef={node => {
                  this.anchorEl = node;
                }}
                variant="outlined"
                aria-owns={
                  this.state.showConfMenu ? "menu-list-zoom" : undefined
                }
                aria-haspopup="true"
                onClick={this.toggleConfMenu}
              >
                <Settings />
              </Button>
              <Popper
                open={this.state.showConfMenu}
                anchorEl={this.anchorEl}
                transition
                disablePortal
                placement="left-start"
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{ transformOrigin: "top right" }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={this.closeConfMenu}>
                        <MenuList>
                          <MenuItem onClick={this.openDialog}>
                            Gerenciamento de Índices
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={32}
          justify="space-between"
          style={{ marginTop: "30px" }}
        >
          <Grid item>
            <Grid container spacing={32}>
              <Grid item>
                <TextField
                  style={{ marginRight: "20px" }}
                  id="date"
                  label="Inicio"
                  type="date"
                  defaultValue={this.state.dt_inicio}
                  onChange={this._handleChange("dt_inicio")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  id="date"
                  label="Fim"
                  type="date"
                  defaultValue={this.state.dt_fim}
                  onChange={this._handleChange("dt_fim")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={32}>
              <Grid item>
                <FormControl>
                  <InputLabel>Status do Projeto</InputLabel>
                  <Select
                    style={{ marginBottom: "14px", minWidth: "156px" }}
                    id={"status_projeto"}
                    value={this.state.statusProjeto}
                    onChange={this.filterHandleChange("statusProjeto")}
                  >
                    <MenuItem key={1} value="andamento">
                      Em Andamento
                    </MenuItem>
                    <MenuItem key={2} value="encerrado">
                      Encerrados
                    </MenuItem>
                    <MenuItem key={0} value="todos">
                      Todos
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl>
                  <InputLabel>Responsável</InputLabel>
                  <Select
                    style={{ marginBottom: "14px", minWidth: "156px" }}
                    id={"tipo_relatorio"}
                    value={this.state.responsavel}
                    onChange={this.filterHandleChange("responsavel")}
                  >
                    {this.state.responsaveis.map(item => (
                      <MenuItem
                        key={item.cpf_funcionario}
                        value={item.cpf_funcionario}
                      >
                        {item.nome_funcionario}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container>
              <Grid item>
                <FormControl>
                  <InputLabel>Relatório</InputLabel>
                  <Select
                    style={{ marginBottom: "14px", minWidth: "156px" }}
                    id={"tipo_relatorio"}
                    value={this.state.tipoRelatorio}
                    onChange={this._handleChange("tipoRelatorio")}
                  >
                    <MenuItem key={0} value="consolidado">
                      Consolidado
                    </MenuItem>
                    <MenuItem key={1} value="detalhado">
                      Detalhado
                    </MenuItem>
                    <MenuItem key={2} value="indicadores">
                      Indicadores
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Tooltip title="Econômico vigente desde Jan/2020">
                  <FormControl>
                    <InputLabel>Modelo*</InputLabel>
                    <Select
                      style={{ marginBottom: "14px", minWidth: "156px" }}
                      id={"modelo_relatorio"}
                      value={this.state.modeloRelatorio}
                      onChange={this._handleChange("modeloRelatorio")}
                    >
                      <MenuItem key={0} value="financeiro">
                        Financeiro
                      </MenuItem>
                      <MenuItem key={1} value="economico">
                        Econômico
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={32}>
              <Grid item style={{ marginTop: "10px" }}>
                <ExcelFile
                  filename={`RelatorioGerencial-${
                    this.state.modeloRelatorio
                  }-${Utils.getTodayDate()}`}
                  element={
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.exportarExcel}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="24"
                        height="24"
                        viewBox="0 0 192 192"
                        style={{ fill: "#000000", paddingRight: "6px" }}
                      >
                        <g
                          fill="none"
                          fillRule="nonzero"
                          stroke="none"
                          strokeWidth="1"
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          strokeMiterlimit="10"
                          strokeDasharray=""
                          strokeDashoffset="0"
                          fontFamily="none"
                          fontWeight="none"
                          fontSize="none"
                          textAnchor="none"
                          style={{ mixBlendMode: "normal" }}
                        >
                          <path d="M0,192v-192h192v192z" fill="none"></path>
                          <g fill="#ffffff">
                            <g id="surface1">
                              <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                      Exportar
                    </Button>
                  }
                >
                  {this.state.tipoRelatorio === "consolidado" ? (
                    <ExcelSheet
                      data={() =>
                        this.getDadosExportacao(this.state.tipoRelatorio)
                      }
                      name="Relat. Gerencial"
                    >
                      <ExcelColumn
                        label="ID"
                        value="p_id_projeto"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Projeto"
                        value="desc_curta"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Tipo"
                        value="tipo_projeto"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Receita"
                        value="receita"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="TDD"
                        value="tdd"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="TDI"
                        value="tdi"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Resultado"
                        value="resultado"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Saldo a Receber"
                        value="p_saldo_periodo"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                    </ExcelSheet>
                  ) : this.state.tipoRelatorio === "detalhado" ? (
                    <ExcelSheet
                      data={() =>
                        this.getDadosExportacao(this.state.tipoRelatorio)
                      }
                      name="Relat. Gerencial"
                    >
                      <ExcelColumn
                        label="ID"
                        value="p_id_projeto"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Projeto"
                        value="desc_curta"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Tipo"
                        value="tipo_projeto"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Receita"
                        value="receita"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="TDD"
                        value="tdd"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Pessoal"
                        value="pessoal"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Outros"
                        value="outros"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="TDI"
                        value="tdi"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="IT"
                        value="it"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="AC"
                        value="ac"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="MC"
                        value="mc"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Resultado"
                        value="resultado"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Saldo a Receber"
                        value="p_saldo_periodo"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      {this.state.modeloRelatorio === "financeiro" &&
                      this.state.calculadf ? (
                        <ExcelColumn
                          label="DF"
                          value="df"
                          style={{ font: { sz: "24", bold: true } }}
                        />
                      ) : (
                        <ExcelColumn label="" value="" />
                      )}
                    </ExcelSheet>
                  ) : (
                    <ExcelSheet
                      data={() =>
                        this.getDadosExportacao(this.state.tipoRelatorio)
                      }
                      name="Relat. Gerencial"
                    >
                      <ExcelColumn
                        label="ID"
                        value="id_projeto"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Projeto"
                        value="desc_curta"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="Tipo"
                        value="tipo_projeto"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="MC/A&C(%)"
                        value="mcac"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="TDD/REC(%)"
                        value="tddrec"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="A&C/REC (%)"
                        value="acrec"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="I&T/REC (%)"
                        value="itrec"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="TDI/REC(%)"
                        value="tdirec"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="RES/REC(%)"
                        value="resrec"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="A&C/TDD(%)"
                        value="actdd"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="I&T/TDD(%)"
                        value="ittdd"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="RES/TDD(%)"
                        value="restdd"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="BDI(%)"
                        value="bdi"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                      <ExcelColumn
                        label="BDI E(%)"
                        value="bdie"
                        style={{ font: { sz: "24", bold: true } }}
                      />
                    </ExcelSheet>
                  )}
                </ExcelFile>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.state.modeloRelatorio === "financeiro" && (
          <Grid container>
            <Grid item>
              <Checkbox
                checked={this.state.calculadf}
                onChange={this._handleCheck("calculadf")}
              />
            </Grid>
            <Grid item style={{ paddingTop: "14px" }}>
              Considerar a DF?
            </Grid>
          </Grid>
        )}

        {this.state.tipoRelatorio === "consolidado" ? (
          <RelatorioConsolidado
            filterable
            setRef={ref => (this.selectTable = ref)}
            data={this.state.relatorio}
            defaultPageSize={20}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .startsWith(filter.value.toLowerCase())
            }
          />
        ) : this.state.tipoRelatorio === "detalhado" ? (
          <RelatorioDetalhado
            filterable
            data={this.state.relatorio}
            setRef={r => {
              this.selectTable = r;
            }}
            displayDF={
              this.state.modeloRelatorio === "financeiro" &&
              this.state.calculadf === true
            }
            defaultPageSize={20}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .startsWith(filter.value.toLowerCase())
            }
          />
        ) : (
          <RelatorioIndicadores
            filterable
            data={this.state.relatorio}
            setRef={r => {
              this.selectTable = r;
            }}
            defaultPageSize={20}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .startsWith(filter.value.toLowerCase())
            }
          />
        )}

        {this.state.tipoRelatorio === "detalhado" &&
          this.state.modeloRelatorio === "financeiro" && (
            <div style={{ marginTop: "16px" }}>
              <Paper elevation={1} style={{ padding: "16px", width: "22%" }}>
                <p style={{ margin: "0" }}>
                  <strong>Receita Fin. Banco:</strong>{" "}
                  <span style={{ float: "right" }}>{`R$ ${Utils.float2moeda(
                    this.state.totalDespesasBanco
                  )}`}</span>
                </p>
                <p style={{ margin: "0" }}>
                  <strong>Imposto Banco:</strong>{" "}
                  <span style={{ float: "right" }}>{`R$ ${Utils.float2moeda(
                    this.state.impostos
                  )}`}</span>
                </p>
              </Paper>
            </div>
          )}

        {this.state.dialog && (
          <ImporteIndices open={this.state.dialog} close={this.closeDialog} />
        )}
      </Grid>
    );
  }
}

export default RelatorioGerencial;
