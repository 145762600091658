import AppState from "../state";

const baseUrl = `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos`;

const deleteLancamento = (id_lancamento, deletar_titulo_pgto_receb) =>{
  const body = JSON.stringify({ id_lancamento, deletar_titulo_pgto_receb });
  return fetch(`${baseUrl}/DeletePgtoReceb.php`, {
    method: "DELETE",
    headers: {
      Authorization: AppState.token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }
    return response.json();
  });
}

export {
  deleteLancamento
};
