import React from "react";
import PropTypes from 'prop-types';

export const Tooltip = (props) => {
  const { datum, x, y } = props;
  let y_data = datum.yLabel ? datum.yLabel : datum.y;
  return (
    <g style={{ pointerEvents: "none" }}>
      <foreignObject
        x={x - 40}
        y={y - 50}
        width="45"
        height="45"
        style={{ overflow: "visible" }}
      >
        <div className="graph-tooltip">
          <div>
            <span className="yValue">{y_data}</span>
            <span className="yUnits">{datum.yUnits}</span>
          </div>
          <div className="xValue">{datum.subtitle}</div>
        </div>
      </foreignObject>
    </g>
  );
};

Tooltip.propTypes = {
  active: PropTypes.bool,
  datum: PropTypes.any,
  x: PropTypes.number,
  y: PropTypes.number,
  yLabel: PropTypes.number,
  center: PropTypes.any,
  subtitle: PropTypes.string
}

export default Tooltip;
