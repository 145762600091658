import React from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogContent,
    Icon
} from "@material-ui/core"
import AppState from "../../../state";
import {browserHistory} from "react-router";
import DualListBox from 'react-dual-listbox';

class Form extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            nome_treinamento: '',
            id_treinamento: '',
            temas_secoes: [],
            temas: [],
            tema_selecionado: '',
            temas_secoes_treinamento: [],
            dificeis: 0,
            medias: 0,
            faceis: 0,
            dificeis_disponiveis: 0,
            medias_disponiveis: 0,
            faceis_disponiveis: 0,
            status: false
        }
    }

    componentDidMount() {
        AppState.isLoading = this.props.isLoading;
        this.getTemasSecoes(this.props.isLoading)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.edicao != this.props.edicao) {
            this.setState({
                edicao: this.props.edicao
            },()=>{
                if (this.props.edicao) {
                    this.setState({
                        temas_secoes_treinamento: this.props.treinamento.temas.map((val) => val.id_tema_secao),
                        dificeis: this.props.treinamento.dificeis,
                        medias: this.props.treinamento.medias,
                        faceis: this.props.treinamento.faceis,
                        nome_treinamento: this.props.treinamento.nome,
                        id_treinamento: this.props.treinamento.id_treinamento,
                        status: this.props.treinamento.status == 't' ? true : false
                    }, () => {
                        this.getQuestoesDisponiveis()
                    });
                }
            })
        }
    }

    closeDialog = () =>{
        this.setState({
            nome_treinamento: '',
            tema_selecionado: '',
            temas_secoes_treinamento: [],
            dificeis: 0,
            medias: 0,
            faceis: 0,
            dificeis_disponiveis: 0,
            medias_disponiveis: 0,
            faceis_disponiveis: 0,
            status: false
        })
        this.props.close()
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    selectedHandleChange(selected) {
        this.setState({
            temas_secoes_treinamento: selected
        }, () => {
            this.getQuestoesDisponiveis();
        })
    }

    getTemasSecoes = (isLoading = false) => {
        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/questoes/GetTemasSecoes.php`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization':AppState.token,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                }
            })
            .then((response) => {
                this.setState({
                    temas_secoes: response.request,
                    temas: [...new Set(response.request.map((val) => {return val.tema}))]
                }, () => { 
                    if (isLoading == false) {
                        AppState.isLoading = isLoading;
                    };
                    });
                })
            .catch(function(error) {
                if (isLoading == false) {
                    AppState.isLoading = isLoading;
                }
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    if(ret == '40001') {
                        console.log("40001 - Erro desconhecido");
                    }
                    else
                        alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })

    }

    getQuestoesDisponiveis = () => {
        AppState.isLoading = true
        let temas_secoes = this.state.temas_secoes_treinamento.join();
        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/questoes/GetTemasQuestoesDisponiveis.php?json=["${temas_secoes}"]`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization':AppState.token,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                    AppState.isLoading = false
                }
            })
            .then((response) => {
                AppState.isLoading = false;
                let faceis = response.request[0].faceis
                let medias = response.request[0].medias
                let dificeis = response.request[0].dificeis
                this.setState({
                    faceis_disponiveis: faceis ? faceis : 0,
                    medias_disponiveis: medias ? medias : 0,
                    dificeis_disponiveis: dificeis ? dificeis : 0
                })
            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    addTreinamento = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            nome: this.state.nome_treinamento,
            faceis: this.state.faceis,
            medias: this.state.medias,
            dificeis: this.state.dificeis,
            temas: this.state.temas_secoes_treinamento
        })

        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/treinamentos/AddTreinamento.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })

            .then((response) => {
                if(!response.ok) {
                    return response.json().then((json) => {
                        var error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                }

                const res = response.json()
                return res;
                AppState.isLoading = false
            })
            .then((response) => {
                AppState.isLoading = false;
                this.closeDialog()

            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    editTreinamento = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            id_treinamento: this.state.id_treinamento,
            nome: this.state.nome_treinamento,
            faceis: this.state.faceis,
            medias: this.state.medias,
            dificeis: this.state.dificeis,
            temas: this.state.temas_secoes_treinamento,
            status: this.state.status
        })

        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/treinamentos/UpdateTreinamento.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })
        .then((response) => {
            if(!response.ok) {
                return response.json().then((json) => {
                    var error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            }

            const res = response.json()
            return res;
            AppState.isLoading = false
        })
        .then((response) => {
            AppState.isLoading = false;
            this.closeDialog()
        })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                console.log(ret)
                alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    render() {
        return (
            <Dialog
                style={{ height: "110vh" }}
                maxWidth={"md"}
                fullWidth={true}
                open={this.props.open}
                onClose={this.props.close}
                disableBackdropClick={true}
            >
                <DialogContent>
                    <Icon
                        style={{ float: "right" }}
                        title={"fechar"}
                        onClick={this.closeDialog}
                    >
                        close
                    </Icon>
                <Typography variant="h4" style={{marginBottom:'40px'}}>Treinamento</Typography>

                <Grid
                    container
                    spacing={32}
                >
                    <Grid item sm={6}>
                        <TextField
                            value={this.state.nome_treinamento}
                            label={"Nome do Treinamento"}
                            name={"nome_treinamento"}
                            fullWidth
                            onChange={(e) => this.handleChange(e)}
                        />

                        <div style={{paddingTop: "1rem"}}>
                            <FormControl fullWidth={true}>
                                <InputLabel htmlFor="temas">Selecione um Tema</InputLabel>
                                <Select
                                    value={ this.state.tema_selecionado }
                                    inputProps={{
                                        name: 'tema_selecionado',
                                        id: 'tema_selecionado',
                                    }}
                                    onChange={(e) => this.handleChange(e)}
                                >
                                    {this.state.temas.map((item, idx)=>(
                                        <MenuItem key={idx} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>

                    <Grid 
                        item 
                        sm={6}
                        style={{display: "flex", flexDirection: "row"}}
                    >

                        <FormControl style={{width: "25%"}} >
                            <TextField label={"Dificeis - Máx " + this.state.dificeis_disponiveis}
                                value={this.state.dificeis}
                                type={"number"} name={"dificeis"}
                                onChange={(e) => this.handleChange(e)}
                                disabled={this.state.dificeis_disponiveis == 0}
                                InputProps={{inputProps: { max: this.state.dificeis_disponiveis, min: 0 }}}
                            />
                        </FormControl>

                        <FormControl 
                        style={{paddingLeft: "2rem", paddingRight: "2rem", width: "33%"}} 
                        >
                            <TextField label={"Medias - Máx " + this.state.medias_disponiveis}
                                value={this.state.medias}
                                type={"number"} name={"medias"}
                                onChange={(e) => this.handleChange(e)}
                                disabled={this.state.medias_disponiveis == 0}
                                InputProps={{inputProps: { max: this.state.medias_disponiveis, min: 0 }}}
                            />
                        </FormControl>

                        <FormControl style={{width: "25%"}} >
                            <TextField label={"Faceis - Máx " + this.state.faceis_disponiveis}
                                value={this.state.faceis}
                                type={"number"} name={"faceis"}
                                onChange={(e) => this.handleChange(e)}
                                disabled={this.state.faceis_disponiveis == 0}
                                InputProps={{inputProps: { max: this.state.faceis_disponiveis, min: 0 }}}
                            />
                        </FormControl>

                    </Grid>

                </Grid>
                <Grid
                    container
                    spacing={32}
                    style={{display: "flex", flexDirection: "row"}}
                >
                    <div style={{width: "100%", paddingTop: "3rem", paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "3rem"}}>
                        <DualListBox
                            options={this.state.temas_secoes.map((val) => {
                                return {
                                    'value': val.id_tema_secao,
                                    'label': val.tema + ' / ' + val.secao
                                }
                            })}
                            selected={this.state.temas_secoes_treinamento}
                            available={this.state.temas_secoes.filter((val) => val.tema == this.state.tema_selecionado).map((val) => {
                                return val.id_tema_secao
                            })}
                            onChange={(e) => this.selectedHandleChange(e)}
                            showHeaderLabels={true}
                            showNoOptionsText={true}
                            lang={{
                                availableHeader: 'Seções do Tema',
                                moveAllLeft: 'Remover todos',
                                moveAllRight: 'Adicionar todos',
                                moveLeft: 'Remover',
                                moveRight: 'Adicionar',
                                noAvailableOptions: this.state.tema_selecionado == '' ? "Selecione um tema":  'Não há seções disponíveis',
                                noSelectedOptions: 'Nenhuma seção selecionada',
                                selectedHeader: 'Meus temas / seções',
                            }}
                        />
                    </div>
                </Grid>

                <Grid style={{marginTop: "2em"}} container={true} direction="row" justify="center" alignItems="center">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.state.edicao ? this.editTreinamento : this.addTreinamento}
                    >
                        Salvar
                    </Button>
                </Grid>

                </DialogContent>
            </Dialog>)
    }   

}

export default Form;