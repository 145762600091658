import React, { Component } from 'react';
import { TextField, Button, Grid } from '@material-ui/core/';
import { observer } from 'mobx-react';
import { Utils } from '../../services';
import AppState from '../../state';
import './style.css';
import { browserHistory } from 'react-router';
import MaskedInput from 'react-text-mask';

const TextMaskCPF = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};

const TextMaskCEL = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};

const TextMaskData = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      placeholder="dd/mm/aaaa"
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

const emptyState = {
  nome: '',
  cpf: '',
  email: '',
  celular: '',
  senhaAtual: '',
  novaSenha: '',
  repetirSenha: '',
  empresa: '',
  perfil: '',
};

const emptyErrors = {
  nomeError: '',
  cpfError: '',
  emailError: '',
  celError: '',
  senhaAtualError: '',
  novaSenhaError: '',
  repetirSenhaError: '',
  empresaError: '',
  perfilError: '',
};

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class Perfil extends Component {
  state = {
    ...emptyState,
    ...emptyErrors,
  };

  componentWillMount = () => {};

  componentDidMount = () => {
    this._getUserInfo();
    // window.events.on('LoggedIn', () => {
    //   this._populateUserInfo();
    // });
  };

  _populateUserInfo = () => {
    const userInfo = AppState.userInfo;
    if (userInfo) {
      const data = {
        nome: userInfo.nome_usuario,
        cpf: userInfo.cpf_usuario,
        email: userInfo.email_usuario,
        celular: userInfo.celular,
        empresa: userInfo.empresa,
        perfil: userInfo.id_perfil,
      };

      this.setState({ ...data });
    }
  };

  // ambiental-server/server/perfil/GetProfile.php
  //${AppState.serverAddr}/ProfileGet.php
  _getUserInfo = () => {
    AppState.isLoading = true;

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/perfil/GetProfile.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          return;
        }
        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        AppState.userInfo = response.request[0];
        //localStorage.setItem('userInfo', JSON.stringify(response.request[0]) );
        this._populateUserInfo();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        console.log('Erro de api user info: ', error);
      });
  };

  _handleChange = (name) => (event) => {
    const value =
      event.target && event.target.value
        ? event.target.value
        : typeof event === 'string'
        ? event
        : '';
    this.setState({ [name]: value });
  };

  _handlesKeyUp = (event) => {
    if (event.keyCode === 13) {
      this._submitChangePassword();
    }
  };

  _clearErrors = () => {
    this.setState({ ...emptyErrors });
  };

  _validaFormChangeProfile = () => {
    const { nome, cpf, email, celular } = this.state;
    this._clearErrors();
    var formValido = true;
    if (!nome) {
      this._handleChange('nomeError')('Nome inválido');
      formValido = false;
    }
    if (!cpf || !Utils.cpfValido(cpf)) {
      this._handleChange('cpfError')('CPF inválido');
      formValido = false;
    }
    if (!email || !Utils.emailValido(email)) {
      this._handleChange('emailError')('Email inválido');
      formValido = false;
    }
    if (!celular) {
      this._handleChange('celularError')('Celular inválido');
      formValido = false;
    }
    return formValido;
  };

  _validaFormChangePassword = () => {
    const { senhaAtual, novaSenha, repetirSenha } = this.state;
    this._clearErrors();

    if (!senhaAtual) {
      this._handleChange('senhaAtualError')('Senha atual inválida');
      return false;
    }
    if (senhaAtual.length < 6) {
      this._handleChange('senhaAtualError')(
        'Sua senha deve conter no mínimo 6 caracteres'
      );
      return false;
    }
    if (!novaSenha) {
      this._handleChange('novaSenhaError')('Nova senha inválida');
      return false;
    }
    if (novaSenha.length < 6) {
      this._handleChange('senhaAtualError')(
        'Sua senha deve conter no mínimo 6 caracteres'
      );
      return false;
    }
    return true;
  };

  _submitChangeProfile = () => {
    // Aqui vai a chamada AJAX
    const { nome, cpf, email, celular } = this.state;

    const json = JSON.stringify({
      nome: nome,
      email: email, // cuidado, o servidor envia email para esse email.
      cel: celular,
    });
    if (this._validaFormChangeProfile()) {
      AppState.userInfo.nome_usuario = nome;
      AppState.isLoading = true;
      fetch(
        `${process.env.PUBLIC_URL}/${AppState.serverAddr}/perfil/EditProfile.php`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: AppState.token,
          },
          body: json,
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => {
              var error = new Error(response.status);
              error.response = json;
              throw error;
            });
          }

          const res = response.json();
          return res;
        })
        .then((response) => {
          AppState.isLoading = false;
          alert('Perfil editado com sucesso');
          window.location.reload();
        })
        .catch(function (error) {
          AppState.isLoading = false;
          if (
            error.toString() == '' ||
            error.toString().split(' ').length < 2
          ) {
            alert('Erro desconhecido, tente novamente');
            return;
          }
          var Err_status = error.toString().split(' ')[1];
          if (Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if (ret == '40008') alert('Dados incompletos ou incorretos');
            else if (ret == '40007') alert('Email ou nome inválido');
            else if (ret == '40009') alert('Data de nascimento inválida');
            else alert('Erro de conexão');
          } else if (Err_status === '401') {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status == 500) {
            alert('Erro desconhecido, tente novamente');
          }
          //window.location.reload();
        });
    }
  };

  _submitChangePassword = () => {
    const { senhaAtual, novaSenha, repetirSenha } = this.state;
    if (this._validaFormChangePassword()) {
      AppState.isLoading = true;
      const requestBody = JSON.stringify({
        oldpwd: senhaAtual,
        newpwd: novaSenha,
      });
      fetch(
        `${process.env.PUBLIC_URL}/${AppState.serverAddr}/comum/ChangePwd.php`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: AppState.token,
          },
          body: requestBody,
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => {
              var error = new Error(response.status);
              error.response = json;
              throw error;
            });
          }

          const res = response.json();
          return res;
        })
        .then((response) => {
          AppState.isLoading = false;

          this.setState({ senhaAtual: '', novaSenha: '' });
          alert('Senha alterada com sucesso');
        })
        .catch(function (error) {
          AppState.isLoading = false;
          if (
            error.toString() == '' ||
            error.toString().split(' ').length < 2
          ) {
            alert('Erro desconhecido, tente novamente');
            return;
          }
          var Err_status = error.toString().split(' ')[1];
          if (Err_status == 400) {
            var ret = error.response['status'].value.split(' ')[0];
            if (ret == '40102')
              alert('Senha fora do padrão. Min. 5 caracteres.');
            else if (ret == '40008') alert('Dados incompletos ou incorretos');
            else if (ret == '40007') alert('Senha atual incorreta');
            else alert('Erro de conexão');
          } else if (Err_status === '401') {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status == 500) {
            alert('Erro desconhecido, tente novamente');
          }
        });
    }
  };

  render() {
    return (
      <div id="Perfil">
        <Grid
          container
          direction="row"
          justify="center"
          spacing={40}
          xs md={8}
        >
          <Grid item md>
            <h3>Alterar perfil</h3>
            <TextField
              id="empresa"
              value={this.state.empresa}
              label="Empresa"
              placeholder="Nome da Empresa"
              disabled={true}
              fullWidth
            />
            <TextField
              id="nome"
              value={this.state.nome}
              label="Nome"
              placeholder="Seu Nome"
              onChange={this._handleChange('nome')}
              fullWidth
            />
            <TextField
              id="perfil"
              value={this.state.perfil}
              label="Perfil de Acesso"
              disabled={true}
              fullWidth
            />
            <TextField
              id="cpf"
              label="CPF"
              disabled={true}
              InputProps={{
                inputComponent: TextMaskCPF,
                value: this.state.cpf,
                onChange: this._handleChange('cpf'),
              }}
              fullWidth
            />
            <TextField
              id="email"
              type="email"
              value={this.state.email}
              label="Email"
              onChange={this._handleChange('email')}
              fullWidth
            />
            <TextField
              id="celular"
              label="Celular"
              placeholder="(dd) xxxxx-xxxx"
              InputProps={{
                inputComponent: TextMaskCEL,
                value: this.state.celular,
                onChange: this._handleChange('celular'),
              }}
              fullWidth
            />
            <div className="button-container">
              <Button
                color="primary"
                variant="contained"
                onClick={this._submitChangeProfile}
              >
                Alterar Perfil
              </Button>
            </div>
          </Grid>
          <Grid item xs>
            <h3>Alterar senha</h3>
            <TextField
              id="senhaAtual"
              inputProps={{
                maxLength: 12,
              }}
              type="password"
              value={this.state.senhaAtual}
              placeholder="Senha Atual"
              label="Senha Atual"
              onChange={this._handleChange('senhaAtual')}
              onKeyUp={this._handlesKeyUp}
              fullWidth
            />
            <TextField
              id="novaSenha"
              inputProps={{
                maxLength: 12,
              }}
              type="password"
              value={this.state.novaSenha}
              placeholder="Nova Senha"
              label="Nova Senha"
              onChange={this._handleChange('novaSenha')}
              onKeyUp={this._handlesKeyUp}
              fullWidth
            />
            <div className="button-container">
              <Button
                color="primary"
                variant="contained"
                onClick={this._submitChangePassword}
              >
                Alterar Senha
              </Button>
            </div>

            <Button
              variant="outlined"
              href="/logout"
              style={{ marginTop: '40px' }}
            >
              Sair do sistema
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default observer(Perfil);
