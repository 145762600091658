import React from "react";
import AppState from "../../../state";
import { Utils } from "../../../services";
import moment from "moment";

import { get_dashboard_data } from "../../../api/dashboard";

import IndicatorCard from "../../../components/lib/IndicatorCard";
import Tooltip from "../../../components/lib/Tooltip";

import {
  Grid
} from "@material-ui/core/";

import { VictoryStack,
        VictoryTooltip,
        VictoryLine, 
        VictoryChart,
        VictoryAxis,
        VictoryLegend,
        VictoryArea,
        VictoryScatter
} from "victory";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
		  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
		  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
		  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
		  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
		  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
		  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
		  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
		  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
      '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

const monthNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
  "Jul", "Ago", "Set", "Out", "Nov", "Dez"
];

class DashboardGerencial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evolucao_anual: {},
      evolucao_mensal: {'financeiro': [], 'economico': []},
      evolucao_mensal_ativos: {'financeiro': [], 'economico': []},
      faturas_em_aberto: 0,
      faturas_em_aberto90d: 0,
      relatorio_mtd: [],
      relatorio_ytd: [],
      roi_12m: 0,
      prazos_pagamento: [],
      animation: 500,
      stackedChartColorMap: ["#528e70", "gold", "#3E3A2F", "#6666D0", "red"],
      projetosCores: {},
      projetoSelecionado: ""
    };

  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    AppState.isLoading = true;
    try {
      console.time('Extração de Dados')
      let res = await get_dashboard_data()
      console.timeEnd('Extração de Dados')
      console.log(res);
      this.setState({
        evolucao_anual: res.evolucao_anual,
        evolucao_mensal: res.evolucao_mensal,
        evolucao_mensal_ativos: res.evolucao_mensal_ativos,
        faturas_em_aberto: res.faturas_em_aberto,
        faturas_em_aberto90d: res.faturas_em_aberto90d,
        relatorio_mtd: res.relatorio_mtd,
        relatorio_ytd: res.relatorio_ytd,
        roi_12m: res.roi_12m,
        prazos_pagamento: res.prazos_pagamento,
        projetosCores: this.mapColors(res)
      }, () => {
        AppState.isLoading = false;
      })
    } catch (err){
      alert('Erro na atualização dos dados');
      console.log(err);
      AppState.isLoading = false;
    }
  }

  mapColors = (data) => {
    let projetos = data.prazos_pagamento.map(obj => obj.id_projeto).filter(onlyUnique)
    let mapping = Object.assign({}, ...projetos.map((projeto, index) => ({[projeto]:  colorArray[index]})))
    return mapping
  }

  render() {
    return (
      <Grid id={this.constructor.name} style={{ marginTop: "4em" }}>
        <Grid container alignItems={"center"}>
          <Grid item sm={5}>
            <h1>Painel Gerencial</h1>
          </Grid>
          <Grid
            container
            justify="space-between"
            style={{ marginTop: "16px", marginBottom: "8px" }}
          >
            <Grid container justify="space-around" spacing={8}>
              <Grid item sm={2} style={{textAlign:"center"}}>
                <IndicatorCard
                  title={'Resultado ' + new Date().getFullYear()}
                  value={this.state.relatorio_ytd.resultado}
                  format={'money'}
                />
              </Grid>
              <Grid item sm={2} style={{textAlign:"center"}}>
                <IndicatorCard
                  title={'Resultado ' + monthNames[parseInt(new Date().getMonth())] + "/" + new Date().getFullYear()}
                  value={this.state.relatorio_mtd.resultado}
                  format={'money'}
                />
              </Grid>
              <Grid item sm={2} style={{textAlign:"center"}}>
                <IndicatorCard
                  title={'ROI - Últimos 12 meses'}
                  value={this.state.roi_12m}
                  format={'percentage'}
                />
              </Grid>
              <Grid item sm={2} style={{textAlign:"center"}}>
                <IndicatorCard
                  title={'Faturas em Aberto'}
                  subtitle={'90 dias'}
                  value={this.state.faturas_em_aberto}
                  format={'money'}
                  secondValue={this.state.faturas_em_aberto90d}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justify="space-between"
            style={{ marginTop: "16px", marginBottom: "8px" }}
          >
            <Grid container justify="space-around" spacing={8}>
              <Grid item sm={5} style={{textAlign:"center"}}>
                <h3>Despesa</h3>
                <VictoryChart
                  animate={{duration: this.state.animation}}
                  domainPadding={{x: [20, 20]}}
                  padding={{left: 50, right: 50, top: 80, bottom: 50}}
                >
                  <VictoryLine
                    data={this.state.evolucao_mensal.financeiro.map( (obj) => {
                      return {
                        'y': obj.despesa,
                        'x': moment(obj.dt_inicio).format('MM-YYYY')
                      }
                    })}
                    labels={({ datum }) => Math.round(datum.y / 10000) / 100}
                    style={{data: {stroke: "#528e70"}}}
                  />
                  <VictoryLine
                    data={this.state.evolucao_mensal.economico.map( (obj) => {
                      return {
                        'y': obj.despesa,
                        'x': moment(obj.dt_inicio).format('MM-YYYY')
                      }
                    })}
                    labels={({ datum }) => Math.round(datum.y / 10000) / 100}
                    style={{data: {stroke: "gold"}}}
                  />
                  <VictoryLegend 
                    orientation="vertical"
                    gutter={20}
                    data={[
                      { name: "Financeiro", symbol: { fill: "#528e70" }},
                      { name: "Economico", symbol: { fill: "gold" }},
                    ]}
                  />
                  <VictoryAxis dependentAxis tickFormat={(tick) => `${Math.round(tick/100000) / 10}MM`}/>
                  <VictoryAxis />
                </VictoryChart>
              </Grid>
              <Grid item sm={5} style={{textAlign:"center"}}>
                <h3>Saldo Contratual - Evolução</h3>
                <VictoryChart
                  animate={{duration: this.state.animation}}
                  domainPadding={{x: [20, 20]}}
                  padding={{left: 50, right: 50, top: 80, bottom: 50}}
                >
                  <VictoryArea
                    data={this.state.evolucao_mensal_ativos.financeiro.map( (obj) => {
                      return {
                        'y': obj.saldo_periodo,
                        'x': moment(obj.dt_inicio).format('MM-YYYY')
                      }
                    })}
                    labels={({ datum }) => Math.round(datum.y / 100000) / 10}
                    style={{data: {fill: "#528e70"}}}
                  />
                  <VictoryAxis dependentAxis tickFormat={(tick) => `${Math.round(tick/1000000)}MM`}/>
                  <VictoryAxis />
                </VictoryChart>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid
            container
            justify="space-between"
            style={{ marginTop: "80px", marginBottom: "8px" }}
          >
            <Grid container justify="space-around" spacing={8}>
              <Grid item sm={5} style={{textAlign:"center"}}>
                <h3>Prazo Médio de recebimento</h3>
                <VictoryChart
                  animate={{duration: this.state.animation}}
                  domainPadding={{x: [20, 20]}}
                  padding={{left: 50, right: 120, top: 50, bottom: 50}}
                  colorScale={"qualitative"}
                >
                  <VictoryScatter
                    labels={() => " "}
                    labelComponent={<VictoryTooltip flyoutComponent={<Tooltip/>} active={(props) => props.datum.id_projeto == this.state.projetoSelecionado ? true : false}/>}
                    data={this.state.prazos_pagamento.map((obj) => {  
                      return {
                        'x': obj.ano,
                        'y': Math.round(parseFloat(obj.mediana_dias)),
                        'yUnits': 'Dias',
                        'id_projeto': obj.id_projeto,
                        'fill': `${this.state.projetosCores[obj.id_projeto]}`,
                        'subtitle': `Projeto: ${obj.id_projeto}`,
                        'size': `${this.state.projetoSelecionado === obj.id_projeto ? 10 : 5}`
                      }
                    })}
                    style={{
                      data: {
                        fill: ({datum}) => datum.fill,
                        size: ({datum}) => datum.size
                      },
                    }}
                  />
                <VictoryLegend 
                  x={350}
                  y={0}
                  orientation="vertical"
                  style={{labels: {fontSize: 8}}}
                  itemsPerRow={17}
                  data=
                    {Object.entries(this.state.projetosCores).map(
                      ([key, val]) => {
                        return { name: key, symbol: { fill: val }}
                      }
                    )}
                    events={[{
                      target: "data",
                      eventHandlers: {
                        onMouseOver: (undefined, props) => {
                          this.setState({
                            projetoSelecionado: props.datum.name
                          })
                      }
                    }},
                    {
                      target: "data",
                      eventHandlers: {
                        onMouseOut: () => {
                          this.setState({
                            projetoSelecionado: ""
                          })
                      }
                    }}]}
                  />
                </VictoryChart>
              </Grid>
              <Grid item sm={5} style={{textAlign:"center"}}>
                <h3>Receita x Tipo de contrato</h3>
                <VictoryChart
                  animate={{duration: this.state.animation}}
                  domainPadding={{x: [20, 20]}}
                  padding={{left: 50, right: 50, top: 50, bottom: 50}}
                >
                  <VictoryStack colorScale={this.state.stackedChartColorMap}>
                    {Object.entries(this.state.evolucao_anual).map(
                      ([key, arr], index) => {
                        return (
                        <VictoryArea key={index} data={arr.map((obj) => {
                          return {
                            x: obj.ano,
                            y: parseFloat(obj.receita)
                          }   
                        })}/>
                        )
                      }
                    )}
                  </VictoryStack>
                  <VictoryLegend 
                    orientation="horizontal"
                    gutter={20}
                    data=
                      {Object.entries(this.state.evolucao_anual).map(
                        ([key, arr], index) => {
                          return { name: key, symbol: { fill: this.state.stackedChartColorMap[index] }}
                        }
                      )}
                  />
                  <VictoryAxis dependentAxis tickFormat={(tick) => `${Math.round(tick/1000000)}MM`}/>
                  <VictoryAxis />
                </VictoryChart>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default DashboardGerencial;
