import React from "react";
import {Grid, 
        Paper,
        Button,
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import Cancel from "@material-ui/icons/Cancel";
import ReactTable from "react-table";
import AppState from "../../../../state";
import {browserHistory} from "react-router";
import Form from "./Form";
import AutoSelect from '../../../../components/inputs/AutoSelect'
import { Utils } from "../../../../services";

const CustomNoDataComponent = () => {
    return <div className="rt-noData" style={{zIndex: 0}}>Selecione uma Avaliação</div>
}

class Avaliacoes extends React.Component {
    constructor(props) {
        super(props);

        this.reactTable = React.createRef();

        this.state = {
            trials: [],
            selectedTrialId: '',
            selectedTrialData: [],
            selected: '',
            dialog: false
        };
    }

    componentDidMount() {
        this.getTrials();
    }

    closeDialog = () => {
        this.setState({ dialog: false,  edicao: false, trials: [], selectedTrialData: [], selectedTrialId: '', selected: '' }, () => this.getTrials());
    };

    openDialog = (edicao = false) => {
        this.setState({
            edicao: edicao,
            dialog: true
        });
    };

    getTrials = function () {
        AppState.isLoading = true;
        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/avaliacoes/GetAvaliacoes.php`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                trials: response.request
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    getTrialData = function () {
        AppState.isLoading = true;
        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/avaliacoes/GetAvaliacao.php?json={"id_avaliacao":${this.state.selectedTrialId}}`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                selectedTrialData: response.request
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    
    trialHandleChange = event => {
        this.setState({
            selectedTrialId: event ? event.value : '',
        }, () => {
            if (event) {
                return this.getTrialData();
            } else {
                this.setState ({
                    selectedTrialData: []
                })
            }
        });
    };

    render() {
        return (
            <div id={this.constructor.name} style={{ marginTop: "5em" }}>
                <h1>Admin Avaliação</h1>

                <Grid 
                    container
                    justify="center"
                    style={{paddingTop: "4rem"}}
                >
                    <Grid xs={8}
                        item
                    >
                        <Grid 
                            container
                            justify="space-between"
                            style={{display: "flex", flexDirection: "row", paddingBottom: "1rem"}}
                        >
                            <Grid>
                                <h4>Selecionar Avaliação</h4>
                            </Grid>
                            <Grid style={{minWidth: "25rem"}}>
                                {this.state.trials.length > 0 && 
                                <AutoSelect
                                    suggestions={this.state.trials.map((val) => {return {'label': val.nome, 'value': val.id_avaliacao}})}
                                    placeholder='Avaliações'
                                    multi={false}
                                    value={this.state.selectedTrialId}
                                    handleChange2={this.trialHandleChange}
                                />}
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.openDialog()}
                                >
                                    <AddIcon />
                                    Avaliação
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.openDialog(true)}
                                    disabled={this.state.selectedTrialId == ''}
                                    style={{marginLeft: 15}}
                                >
                                    Editar
                                </Button>
                            </Grid>
                        </Grid>
                        <ReactTable
                        filterable={true}
                        data={this.state.selectedTrialData.questoes}
                        pageSize={10}
                        className="-striped -highlight"
                        NoDataComponent={CustomNoDataComponent}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        }
                        columns={[
                            {
                                Header: 'Tema',
                                accessor: "tema",
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: 'Seção',
                                accessor: "secao",
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: 'Nível',
                                accessor: "nivel",
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value == '1' ? 'Fácil' : row.value == '2' ? 'Média' : 'Difícil'} </div> )
                            }, {
                                Header: 'Pergunta',
                                accessor: "pergunta",
                                width: 600,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }
                        ]}
                        />
                    </Grid>
                    <Grid xs={4}
                        item
                        // justify="center"
                        style={{paddingLeft: "10rem"}}
                    >
                        <Grid 
                            container
                            justify="flex-start" 
                        >
                            <h4>Detalhe da Avaliação</h4>
                        </Grid>
                        <Paper style={{width: "100%", height: "480px", overflow: 'auto'}}>
                            {Object.keys(this.state.selectedTrialData).length > 0 && (
                                <Grid 
                                    container
                                    justify="center"
                                >
                                    <Grid 
                                        container 
                                        justify="space-between"
                                        style={{display: "flex", flexDirection: "row", paddingBottom: "2rem", paddingTop: "2rem", paddingLeft: "3rem", paddingRight: "3rem"}}
                                    >
                                        <Grid>
                                            <h4>{this.state.selectedTrialData.nome_avaliacao}</h4>
                                        </Grid>
                                        <Grid>
                                            {this.state.selectedTrialData.status == 't' ? (
                                                <div>
                                                    <Check/>Ativo
                                                </div>
                                            ) : (
                                                <div>
                                                    <Cancel/>Inativo
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid 
                                        container 
                                        fullWidth={true}
                                        style={{paddingLeft: "3rem", paddingRight: "3rem"}}
                                    >
                                        <Grid>
                                            <div style={{paddingBottom: "1rem"}}>
                                                <h5 style={{fontWeight: "bold"}}>Mix de Perguntas</h5>
                                            </div>
                                            <div style={{paddingBottom: "0.5rem"}}>
                                                Difíceis: {this.state.selectedTrialData.questoes.filter((x) => x.nivel == 3).length} / {this.state.selectedTrialData.dificeis_disponiveis}
                                            </div>
                                            <div style={{paddingBottom: "0.5rem"}}>
                                                Médias: {this.state.selectedTrialData.questoes.filter((x) => x.nivel == 2).length} / {this.state.selectedTrialData.medias_disponiveis}
                                            </div>
                                            <div style={{paddingBottom: "0.5rem"}}>
                                                Fáceis: {this.state.selectedTrialData.questoes.filter((x) => x.nivel == 1).length} / {this.state.selectedTrialData.faceis_disponiveis}
                                            </div>
                                            <div style={{paddingBottom: "0.5rem", fontWeight: "bold"}}>
                                                Total: {this.state.selectedTrialData.questoes.length} / {Number(this.state.selectedTrialData.dificeis_disponiveis) + Number(this.state.selectedTrialData.medias_disponiveis) + Number(this.state.selectedTrialData.faceis_disponiveis)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid 
                                        container
                                        fullWidth={true}
                                        style={{paddingLeft: "3rem", paddingRight: "3rem", paddingTop:"0.5rem", paddingBottom: "0.5rem"}}
                                    >
                                        <Grid>
                                            <div style={{paddingBottom: "1rem"}}>
                                                <h5 style={{fontWeight: "bold"}}>Grupos</h5>
                                            </div>
                                            {this.state.selectedTrialData.grupos.map((val, idx) => {
                                                return (
                                                <div key={idx} style={{paddingBottom: "0.5rem"}}>
                                                    {val.nome_grupo}
                                                </div>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid 
                                        container 
                                        fullWidth={true}
                                        style={{paddingLeft: "3rem", paddingRight: "3rem"}}
                                    >
                                        <Grid>
                                            <div style={{paddingBottom: "1rem"}}>
                                                <h5 style={{fontWeight: "bold"}}>Período</h5>
                                            </div>
                                            <div style={{paddingBottom: "0.5rem"}}>
                                                Inicio: {Utils.dateToPT(this.state.selectedTrialData.dt_inicio)}
                                            </div>
                                            <div style={{paddingBottom: "0.5rem"}}>
                                                Fim: {Utils.dateToPT(this.state.selectedTrialData.dt_fim)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </Grid>
                    
                </Grid>
            <Form
                open={this.state.dialog}
                close={this.closeDialog}
                avaliacao={this.state.selectedTrialData}
                edicao={this.state.edicao}
                isLoading={true}
            />
            </div>
        )
    }
}

export default Avaliacoes;