export default class Utils {
  static emailValido(email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  static cpfValido(c) {
    if ((c = c.replace(/[^\d]/g, "")).length !== 11) {
      return false;
    }

    if (c === "00000000000") {
      return false;
    }

    let r;
    let s = 0;
    let i = null;

    for (i = 1; i <= 9; i++) {
      s = s + parseInt(c[i - 1]) * (11 - i);
    }

    r = (s * 10) % 11;

    if (r === 10 || r === 11) {
      r = 0;
    }

    if (r !== parseInt(c[9])) {
      return false;
    }

    s = 0;

    for (i = 1; i <= 10; i++) {
      s = s + parseInt(c[i - 1]) * (12 - i);
    }

    r = (s * 10) % 11;

    if (r === 10 || r === 11) {
      r = 0;
    }

    if (r !== parseInt(c[10])) {
      return false;
    }

    return true;
  }

  static sqlToJsDate(sqlDate) {
    if (!sqlDate) return sqlDate;
    //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
    var sqlDateArr1 = sqlDate.split("-");

    //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']

    var sYear = sqlDateArr1[0];

    var sMonth = (Number(sqlDateArr1[1]) - 1).toString();

    var sqlDateArr2 = sqlDateArr1[2].split(" ");

    //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']

    var sDay = sqlDateArr2[0];

    var sqlDateArr3 = sqlDateArr2[1].split(":");

    //format of sqlDateArr3[] = ['hh','mm','ss']

    var sHour = sqlDateArr3[0];

    var sMinute = sqlDateArr3[1];

    var sSecond = sqlDateArr3[2];

    return new Date(
      sYear,
      sMonth,
      sDay,
      sHour,
      sMinute,
      sSecond
    ).toLocaleString("pt-BR");
  }

  static cnpjValido(cnpj) {
    let i = null;
    let n = null;

    const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    cnpj = cnpj.replace(/[^\d]/g, "");

    const r = /^(0{14}|1{14}|2{14}|3{14}|4{14}|5{14}|6{14}|7{14}|8{14}|9{14})$/;
    if (!cnpj || cnpj.length !== 14 || r.test(cnpj)) {
      return false;
    }
    cnpj = cnpj.split("");

    for (i = 0, n = 0; i < 12; i++) {
      n = n + cnpj[i] * b[i + 1];
    }
    n = 11 - (n % 11);
    n = n >= 10 ? 0 : n;
    if (parseInt(cnpj[12]) !== n) {
      return false;
    }

    for (i = 0, n = 0; i <= 12; i++) {
      n = n + cnpj[i] * b[i];
    }
    n = 11 - (n % 11);
    n = n >= 10 ? 0 : n;
    if (parseInt(cnpj[13]) !== n) {
      return false;
    }
    return true;
  }

  static dateToEN(date) {
    if (date === null || date === "") return null;
    if (date.indexOf("/") > 0) {
      return date
        .split("/")
        .reverse()
        .join("-");
    } else return date;
  }

  static ptToSqlDate(date) {
    if (date === null || date === "") return null;
    if (date.indexOf("/") > 0) {
      return date
        .split("/")
        .reverse()
        .join("-");
    } else return date;
  }

  static dateToPT(date) {
    if (date === null || date === "") return "";
    if (date.indexOf("-") > 0) {
      return date
        .split("-")
        .reverse()
        .join("/");
    } else return date;
  }

  static getTodayDate = () => {
    var today = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    );
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = dd + "/" + mm + "/" + yyyy;
    return today;
  };

  static float2moeda = num => {
    let x = 0;

    if (num < 0) {
      num = Math.abs(num);
      x = 1;
    }
    if (isNaN(num)) num = "0";
    let cents = Math.floor((num * 100 + 0.5) % 100);

    num = Math.floor((num * 100 + 0.5) / 100).toString();

    if (cents < 10) cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
      num =
        num.substring(0, num.length - (4 * i + 3)) +
        "." +
        num.substring(num.length - (4 * i + 3));
    let ret = num + "," + cents;
    if (x === 1) ret = "-" + ret;

    return ret;
  };

  static moeda2float = moeda => {
    if(moeda === ''){
        return ''
    }else{
        if ((typeof moeda) === "string") {
            moeda = moeda.replace('R$ ', '');
            moeda = moeda.replace(/\./g, '');
            moeda = moeda.replace(/\,/g, '.');
            moeda = moeda.replace(/\ /g, '');
        }
        return parseFloat(moeda);
    }
}
  
  static percent2float = moeda => {
    if (moeda === "") {
      return "";
    } else {
      moeda = moeda.replace("% ", "");
      moeda = moeda.replace(/\./g, "");
      moeda = moeda.replace(/,/g, ".");
      return parseFloat(moeda);
    }
  };
}
