import React from "react";
import {Grid} from '@material-ui/core';
import ReactTable from "react-table";
import { Utils } from "../../../services";
import AppState from "../../../state";
import {browserHistory} from "react-router";
import Quiz from "../quiz/Quiz";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

class Avaliacao extends React.Component {
    constructor(props) {
        super(props);

        this.reactTable = React.createRef();

        this.state = {
            table_data: [],
            quizDialog: false,
            selectedName: '',
            selectedId: ''
        };
    }

    componentDidMount() {
        this.getTableData();
    }

    closeQuizDialog = () => {
        this.setState({ quizDialog: false }, () => this.getTableData());
    };

    openQuizDialog = () => {
        this.setState({
            quizDialog: true
        });
    };

    getTableData = function () {
        AppState.isLoading = true;
        fetch(`${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/avaliacoes/GetAvaliacaoesAtivas.php`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                table_data: response.request
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }      

    render() {
        return (
            <div id={this.constructor.name} style={{ marginTop: "5em" }}>
                <h1>Minhas Avaliações</h1>

                <Grid 
                    container
                    justify="center"
                    style={{"paddingTop": "4rem"}}
                >
                    <Grid item>
                        <ReactTable
                        filterable={true}
                        data={this.state.table_data}
                        pageSize={10}
                        className="-striped -highlight"
                        defaultFilterMethod={(filter, row) =>
                            filter.id === "dt_fim" || filter.id === "dt_inicio"
                                ? Utils.dateToPT(String(row[filter.id])).startsWith(filter.value.toLowerCase())
                                : String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                            }
                        columns={[
                            {
                                Header: 'Avaliacao',
                                accessor: "nome",
                                width: 400,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            },
                            {
                                Header: 'Inicio',
                                accessor: "dt_inicio",
                                width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {Utils.dateToPT(row.value)} </div> )
                            },
                            {
                                Header: 'Vencimento',
                                accessor: "dt_fim",
                                width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {Utils.dateToPT(row.value)} </div> )
                            },
                            {
                                Header: 'Status',
                                accessor: "status",
                                width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            },
                            {
                                Header: 'Ação',
                                accessor: "acao",
                                filterable: false,
                                width: 100,
                                Cell: row =>
                                <div
                                    style={{
                                    height: "22px",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    position: "relative"
                                    }}
                                >
                                    <Grid
                                    container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="baseline"
                                    >
                                    {row.original.status == 'Disponível' ? (
                                    <PlayCircleOutlineIcon
                                        onClick={() =>
                                            this.setState({
                                                selectedName: row.original.nome,
                                                selectedId: row.original.id_avaliacao
                                            }, () => {
                                                this.openQuizDialog();
                                            })
                                        }
                                        style={{
                                            cursor: "pointer",
                                            width: "22px",
                                            height: "22px",
                                            minWidth: "22px",
                                            minHeight: "22px",
                                            marginLeft: "5px"
                                        }}
                                    />
                                    ) : (
                                        ""
                                    )}
                                    </Grid>
                                </div>
                                }
                        ]}
                        />
                    </Grid>
                </Grid>
                <Quiz
                    open={this.state.quizDialog}
                    close={this.closeQuizDialog}
                    titulo={this.state.selectedName}
                    quizType='avaliacao'
                    id_avaliacao={this.state.selectedId}
                    isLoading={true}
                />
            </div>
        )
    }
}

export default Avaliacao;