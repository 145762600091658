import React from 'react';
import { Grid, TextField, FormControl, Button, Paper } from '@material-ui/core';
import ReactTable from 'react-table';
import { Utils } from '../../../services';
import AppState from '../../../state';
import { browserHistory } from 'react-router';
import Carousel from 'react-material-ui-carousel';
import CurrencyInput from '../../../components/lib/CurrencyInput';
import MaskedInput from 'react-text-mask';
import AutoSelect from '../../../components/inputs/AutoSelect';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const DateMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

class Aprovacao extends React.Component {
  constructor(props) {
    super(props);

    this.reactTable = React.createRef();

    this.state = {
      titulosList: [],
      pagamentosList: [],
      todosList: [],
      filteredTodosList: [],
      allTitulosList: [],
      aprovadoresList: [],
      aprovadorFilter: JSON.parse(localStorage.getItem('userInfo')).cpf_usuario,
      reloadPagamentos: true,
      reloadTitulos: true,
      cpf_usuario: JSON.parse(localStorage.getItem('userInfo')).cpf_usuario,
      nome_usuario: JSON.parse(localStorage.getItem('userInfo')).nome_usuario,
      checked: [],
      selectAll: false,
      confirmation: false,
      confirmationMessage: '',
    };
  }

  componentDidMount() {
    AppState.isLoading = true;
    this.getData();
  }

  getData = () => {
    this.getTitulos();
    this.getPagamentos();
    this.getAprovadores();
  };

  getAprovadores = function () {
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetAprovadores.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            aprovadoresList: response.request.map((aprovador) => ({
              label: aprovador.nome_usuario,
              value: aprovador.cpf_usuario,
            })),
          },
          () => {
            AppState.isLoading = false;
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getTitulos = function () {
    this.setState({
      reloadTitulos: true,
    });
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetTitulosAprovacao.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            todosList: this.state.pagamentosList.concat(response.request),
            filteredTodosList: this.state.pagamentosList
              .concat(response.request)
              .filter((lancamento) => this.filterApproverData(lancamento)),
            allTitulosList: response.request,
            titulosList: response.request.filter((lancamento) =>
              this.filterApproverData(lancamento)
            ),
            reloadTitulos: false,
          },
          () => {
            AppState.isLoading = false;
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  finishTitulo = function (id_titulo, approve) {
    AppState.isLoading = true;
    let status = approve == true ? 'Aprovado' : 'Reprovado';

    const data = JSON.stringify({
      id_titulos: id_titulo,
      status: status,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/FinalizaTitulo.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          this.setState({
            confirmation: true,
            confirmationMessage:
              this.state.confirmationMessage +
              `\n` +
              (id_titulo.length > 1
                ? 'Foram ' +
                  status +
                  's os Titulos número: ' +
                  id_titulo.map((obj) => obj) +
                  ' com sucesso.'
                : 'O Titulo número ' +
                  id_titulo +
                  ' foi ' +
                  status +
                  ' com sucesso.'),
          });
          return res;
        }
      })
      .then((response) => {
        this.getData();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40002') {
            console.log('40002 - Erro na atualização dos Titulos');
            alert('Erro na atualização dos Titulos');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getPagamentos = function () {
    this.setState({
      reloadPagamentos: true,
    });
    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/GetPgtoRecebsAprovacao.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState(
          {
            pagamentosList: response.request,
            todosList: this.state.allTitulosList.concat(response.request),
            filteredTodosList: this.state.allTitulosList
              .concat(response.request)
              .filter((lancamento) => this.filterApproverData(lancamento)),
            reloadPagamentos: false,
          },
          () => {
            AppState.isLoading = false;
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  finishPagamento = function (id_lancamento, approve) {
    AppState.isLoading = true;

    let status = approve == true ? 'Aprovado' : 'Reprovado';
    const data = JSON.stringify({
      id_lancamentos: id_lancamento,
      status: status,
    });

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/lancamentos/FinalizaPgtoReceb.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          this.setState({
            confirmation: true,
            confirmationMessage:
              this.state.confirmationMessage +
              `\n` +
              (id_lancamento.length > 1
                ? 'Foram ' +
                  status +
                  's os Pagamentos / Recebimentos número: ' +
                  id_lancamento.map((obj) => obj) +
                  ' com sucesso.'
                : 'O Pagamento / Recebimento número ' +
                  id_lancamento +
                  ' foi ' +
                  status +
                  ' com sucesso.'),
          });
          return res;
        }
      })
      .then((response) => {
        this.getData();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40002') {
            console.log('40002 - Erro no Rateio dos impostos');
            alert(
              'Erro no rateio dos impostos. Por favor verifique titulos rateados.'
            );
          } else if (ret == '40003') {
            console.log('40003 - Erro na atualização dos lançamentos');
            alert('Erro na atualização dos lançamentos');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  switchContraparte = function (tipo_contraparte) {
    switch (tipo_contraparte) {
      case 'funcionario':
        return 'Funcionário';
      case 'pj':
        return 'Pessoa Jurídica (PJ)';
      case 'fornecedor':
        return 'Fornecedor';
      case 'cliente':
        return 'Cliente';
      case 'outros':
        return 'Outros';
    }
  };

  switchNatureza = function (natureza) {
    switch (natureza) {
      case 'despesa':
        return 'à Pagar';
      case 'receita':
        return 'à Receber';
      case 'controle':
        return 'Controle';
    }
  };

  getTituloForm = function (titulo) {
    return (
      <Paper className="approvePaper" key={titulo.id_titulo}>
        <h3>
          {titulo.id_titulo} - {titulo.descricao}
        </h3>
        <Grid container spacing={32}>
          <Grid item sm={6}>
            <FormControl component="fieldset" fullWidth={true}>
              <TextField
                label={'Tipo do Titulo'}
                name="title_type"
                value={this.switchNatureza(titulo.natureza)}
                editable={false}
              ></TextField>
              <TextField
                label={'Emissor do Titulo'}
                name="title_emitter"
                value={this.switchContraparte(titulo.tipo_contraparte)}
                editable={false}
              ></TextField>
              <TextField
                label={'Nome Contraparte'}
                name="name"
                value={titulo.nome_contraparte}
                editable={false}
              ></TextField>
              <TextField
                label={'Projeto'}
                name="project"
                value={titulo.nome_display}
                editable={false}
              ></TextField>
              <TextField
                label={'Conta'}
                name="account"
                value={titulo.id_conta}
                editable={false}
              ></TextField>
              <CurrencyInput
                allowNegative={true}
                label={'Valor'}
                name={'titleValue'}
                value={parseFloat(titulo.valor)}
                editable={false}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl component="fieldset" fullWidth={true}>
              <TextField
                label={'Data de Lançamento'}
                name="titleDate"
                value={Utils.dateToPT(titulo.dt_lancamento)}
                editable={false}
                InputProps={{
                  inputComponent: DateMaskCustom,
                }}
              ></TextField>
              <TextField
                label={'Data de Vencimento'}
                name="expirationDate"
                value={Utils.dateToPT(titulo.dt_vencimento)}
                editable={false}
                InputProps={{
                  inputComponent: DateMaskCustom,
                }}
              ></TextField>
              <TextField
                label={'Descrição'}
                name="description"
                value={titulo.descricao}
                editable={false}
              ></TextField>
              <TextField
                label={'Nº do Documento'}
                name="document_number"
                value={titulo.num_documento}
                editable={false}
              ></TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={32} className="buttonsGrid">
          <Grid item sm={6} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.finishTitulo([titulo.id_titulo], false)}
            >
              Reprovar
            </Button>
          </Grid>
          <Grid item sm={6} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.finishTitulo([titulo.id_titulo], true)}
            >
              Aprovar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  getPgtoForm = function (pagamento) {
    return (
      <Paper className="approvePaper" key={pagamento.id_lancamento}>
        <h3>
          {pagamento.id_lancamento} - {pagamento.descricao}
        </h3>
        <Grid container spacing={32}>
          <Grid item sm={6}>
            <FormControl component="fieldset" fullWidth={true}>
              <TextField
                label={'Tipo do Titulo'}
                name="title_type"
                value={this.switchNatureza(pagamento.natureza)}
                editable={false}
              ></TextField>
              <TextField
                label={'Titulo'}
                name="title"
                value={pagamento.id_titulo + ' - ' + pagamento.descricao}
                editable={false}
              ></TextField>
              <TextField
                label={'Emissor do Titulo'}
                name="title_emitter"
                value={this.switchContraparte(pagamento.tipo_contraparte)}
                editable={false}
              ></TextField>
              <TextField
                label={'Nome Contraparte'}
                name="name"
                value={pagamento.nome_contraparte}
                editable={false}
              ></TextField>
              <TextField
                label={'Projeto'}
                name="project"
                value={pagamento.nome_display}
                editable={false}
              ></TextField>
              <TextField
                label={'Conta'}
                name="account"
                value={pagamento.id_conta}
                editable={false}
              ></TextField>
              <CurrencyInput
                allowNegative={true}
                label={'Valor'}
                name={'titleValue'}
                value={parseFloat(pagamento.valor)}
                editable={false}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl component="fieldset" fullWidth={true}>
              <TextField
                label={'Data de de Pgto/Receb'}
                name="titleDate"
                value={Utils.dateToPT(pagamento.dt_pag_recb)}
                editable={false}
                InputProps={{
                  inputComponent: DateMaskCustom,
                }}
              ></TextField>
              <TextField
                label={'Data de Vencimento'}
                name="expirationDate"
                value={Utils.dateToPT(pagamento.dt_vencimento)}
                editable={false}
                InputProps={{
                  inputComponent: DateMaskCustom,
                }}
              ></TextField>
              <TextField
                label={'Descrição'}
                name="description"
                value={pagamento.descricao}
                editable={false}
              ></TextField>
              <TextField
                label={'Forma de Pagamento'}
                name="payment_method"
                value={
                  pagamento.forma_pagamento ? pagamento.forma_pagamento : ''
                }
                editable={false}
              ></TextField>
              <TextField
                label={'Banco'}
                name="bank"
                value={
                  pagamento.banco_pgto_receb ? pagamento.banco_pgto_receb : ''
                }
                editable={false}
              ></TextField>
              <TextField
                label={'Nº do Documento'}
                name="document_number"
                value={pagamento.num_documento ? pagamento.num_documento : ''}
                editable={false}
              ></TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={32} className="buttonsGrid">
          <Grid item sm={6} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                this.finishPagamento([pagamento.id_lancamento], false)
              }
            >
              Reprovar
            </Button>
          </Grid>
          <Grid item sm={6} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.finishPagamento([pagamento.id_lancamento], true)
              }
            >
              Aprovar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  renderCaroussel = function (param) {
    if (param == 'titulos') {
      if (this.state.titulosList.length > 0 && !this.state.reloadTitulos) {
        return (
          <Carousel
            strictIndexing={true}
            autoPlay={false}
            timeout={200}
            className="caroussel-aprovacao"
          >
            {this.state.titulosList.map((titulo) => this.getTituloForm(titulo))}
          </Carousel>
        );
      } else {
        return <Paper className="approvePaper">Não há Titulos Pendentes</Paper>;
      }
    }
    if (param == 'pagamentos') {
      if (
        this.state.pagamentosList.length > 0 &&
        !this.state.reloadPagamentos
      ) {
        return (
          <Carousel
            strictIndexing={true}
            autoPlay={false}
            timeout={200}
            className="caroussel-aprovacao"
          >
            {this.state.pagamentosList.map((titulo) =>
              this.getPgtoForm(titulo)
            )}
          </Carousel>
        );
      } else {
        return (
          <Paper className="approvePaper">Não há Lançamentos Pendentes</Paper>
        );
      }
    }
  };

  hasApprovePermission = () => {
    const permissions = JSON.parse(localStorage.getItem('Menu'));
    const aprovacaoView = permissions.views
      .filter((view) => view.id_view === 'aprovacoes')
      .pop();

    const hasApprovePermission = aprovacaoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === 'aprovar_lancamentos';
      },
      false
    );

    return hasApprovePermission;
  };

  filterApproverData = (lancamento, aprovador = null) => {
    if (lancamento.tipo == 'Pgto/Recb') {
      return true;
    }
    if (aprovador == null) {
      if (
        lancamento.aprovador == this.state.aprovadorFilter ||
        lancamento.id_projeto == '0' ||
        this.hasApprovePermission()
      ) {
        return true;
      }
    } else {
      if (lancamento.aprovador == aprovador) {
        return true;
      }
    }
    return false;
  };

  aprovadorFilterHandleChange = (aprovador) => {
    if (!aprovador || aprovador == '') {
      this.setState({
        filteredTodosList: this.state.todosList,
        aprovadorFilter: '',
      });
    } else {
      this.setState({
        filteredTodosList: this.state.todosList.filter((lancamento) =>
          this.filterApproverData(lancamento, aprovador.value)
        ),
        aprovadorFilter: aprovador.value,
      });
    }
  };

  singleCheckboxHandleChange = (index) => {
    let checkedCopy = this.state.checked;
    checkedCopy[index] = !this.state.checked[index];
    if (checkedCopy[index] === false) {
      this.setState({ selectAll: false });
    }

    this.setState({
      checked: checkedCopy,
    });
  };

  selectAllHandleChange = () => {
    let selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });

    let checkedCopy = [];
    this.state.filteredTodosList.forEach((e, index) => {
      checkedCopy.push(false);
    });

    let table = this.reactTable.current.getResolvedState().sortedData;
    table.forEach((e, index) => {
      e.aprovador = e._original.aprovador;
      if (this.filterApproverData(e)) {
        checkedCopy[e._index] = selectAll;
      }
    });

    this.setState({
      checked: checkedCopy,
    });
  };

  finishMultiple = () => {
    let table = this.reactTable.current.getResolvedState().sortedData;
    let selected = table.filter((el, index) => {
      if (this.state.checked[el._index] == true) {
        return true;
      }
      return false;
    });
    let titulos = selected
      .filter((el) => {
        return el.tipo == 'Titulo';
      })
      .map((el) => {
        return el.id_lancamento;
      });
    let pagamentos = selected
      .filter((el) => {
        return el.tipo == 'Pgto/Recb';
      })
      .map((el) => {
        return el.id_lancamento;
      });
    if (pagamentos.length > 0) {
      this.finishPagamento(pagamentos, true);
    }

    if (titulos.length > 0) {
      this.finishTitulo(titulos, true);
    }
    this.setState({
      checked: [],
      selectAll: false,
    });
  };

  render() {
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <div>
          <Grid
            container
            direction="row"
            justify="space-around"
            style={{ marginTop: '1em', margin: 'auto' }}
          >
            <Grid style={{ maxWidth: 500 }}>
              <h4>
                Titulos - {this.state.titulosList.length}{' '}
                {this.state.titulosList.length > 1
                  ? 'Reconhecimentos de Receita e Despesa pendentes'
                  : 'Reconhecimento de Receita e Despesa pendente'}
              </h4>
              {this.renderCaroussel('titulos')}
            </Grid>
            <Grid>
              <h4>
                Pagamentos - {this.state.pagamentosList.length}{' '}
                {this.state.pagamentosList.length > 1
                  ? 'Autorizações de Pagamento pendentes'
                  : 'Autorização de Pagamento pendente'}
              </h4>
              {this.renderCaroussel('pagamentos')}
            </Grid>
          </Grid>
        </div>
        <div>
          <br />
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-end"
            style={{ margin: 'auto' }}
          >
            <div
              style={{
                display: 'inline-block',
                width: 300,
              }}
            >
              {this.state.aprovadoresList.length > 0 && (
                <AutoSelect
                  suggestions={this.state.aprovadoresList}
                  placeholder="Aprovadores"
                  handleChange2={this.aprovadorFilterHandleChange}
                  value={this.state.aprovadorFilter}
                  initialValue={this.state.aprovadorFilter}
                />
              )}
            </div>
            <h2>Autorizações Pendentes</h2>
            <div
              style={{
                display: 'inline-block',
                width: 300,
                textAlign: 'end',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={this.finishMultiple}
                style={{
                  display:
                    this.state.checked.filter((el) => {
                      return el;
                    }).length > 0
                      ? 'Block'
                      : 'None',
                }}
              >
                Aprovar Selecionados
              </Button>
            </div>
          </Grid>

          <br />
          <div style={{ width: 1200, margin: 'auto' }}>
            <ReactTable
              filterable
              ref={this.reactTable}
              data={this.state.filteredTodosList.sort((a, b) => {
                var a1 = new Date(a.data).getTime();
                var b1 = new Date(b.data).getTime();
                if (a1 < b1) {
                  return -1;
                } else if (a1 > b1) {
                  return 1;
                } else {
                  return 0;
                }
              })}
              defaultPageSize={20}
              className="-striped -highlight"
              defaultFilterMethod={(filter, row) =>
                filter.id === 'data' || filter.id === 'dt_vencimento'
                  ? Utils.dateToPT(String(row[filter.id])).startsWith(
                      filter.value.toLowerCase()
                    )
                  : String(row[filter.id])
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase())
              }
              columns={[
                {
                  Header: (
                    <input
                      type="checkbox"
                      onChange={this.selectAllHandleChange}
                      checked={this.state.selectAll}
                    />
                  ),
                  Cell: (row) => (
                    <div style={{ textAlign: 'center' }}>
                      <input
                        type="checkbox"
                        checked={this.state.checked[row.index]}
                        onChange={() =>
                          this.singleCheckboxHandleChange(row.index)
                        }
                        disabled={!this.filterApproverData(row.original)}
                      />
                    </div>
                  ),
                  sortable: false,
                  filterable: false,
                  width: 60,
                },
                {
                  Header: 'Tipo',
                  accessor: 'tipo',
                  width: 80,
                },
                {
                  Header: 'Nº',
                  accessor: 'id_lancamento',
                  width: 60,
                },
                {
                  Header: 'Natureza',
                  accessor: 'natureza',
                  width: 75,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                        textTransform: 'capitalize',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Conta',
                  accessor: 'id_conta',
                  width: 60,
                },
                {
                  Header: 'Desc. Conta',
                  accessor: 'desc_conta',
                },
                {
                  Header: 'Aprovador',
                  accessor: 'nome_aprovador',
                  Cell: (row) => (
                    <div>{row.value ? row.value : 'Diretores'}</div>
                  ),
                },
                {
                  Header: 'Projeto',
                  accessor: 'nome_display',
                  width: 144,
                  Cell: (row) => <div>{row.value}</div>,
                },
                {
                  Header: 'Descrição',
                  accessor: 'descricao',
                },
                {
                  Header: 'Valor',
                  accessor: 'valor',
                  width: 110,
                  sortMethod: (a, b) => {
                    a = parseFloat(a);
                    b = parseFloat(b);
                    if (a < b) {
                      return -1;
                    } else if (a > b) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                  Footer: (row) => (
                    <span>
                      {
                        // Get the total of the price
                        'R$ ' +
                          Utils.float2moeda(
                            row.data.reduce(
                              (total, { valor }) =>
                                (total += parseFloat(valor)),
                              0
                            )
                          )
                      }
                    </span>
                  ),
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      {'R$ ' + Utils.float2moeda(row.value)}
                    </div>
                  ),
                },
                {
                  Header: 'Lançamento',
                  id: 'data',
                  accessor: 'data',
                  width: 90,
                  sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                    if (a1 < b1) {
                      return 1;
                    } else if (a1 > b1) {
                      return -1;
                    } else {
                      return 0;
                    }
                  },
                  Cell: (row) => Utils.dateToPT(row.value),
                },
                {
                  Header: 'Vencimento',
                  accessor: 'dt_vencimento',
                  id: 'dt_vencimento',
                  width: 90,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      {Utils.dateToPT(row.value)}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
        <Dialog
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={this.state.confirmation}
        >
          <DialogTitle id="confirmation-dialog-title">Confirmação</DialogTitle>
          <DialogContent dividers>
            {this.state.confirmationMessage}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ confirmation: false, confirmationMessage: '' })
              }
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Aprovacao;
