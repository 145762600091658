import React, { Component } from "react";
import { browserHistory, IndexRoute, Route, Router } from "react-router";
import { observer } from "mobx-react";
import AppState from "./state";
import { App } from "./components";
import {
  Funcionarios,
  Login,
  Perfil,
  Projetos,
  Timesheets,
  Usuarios,
  Titulos,
  Clientes,
  Pagamentos,
  CadastroFornecedor,
  ContratoFornecedor,
  RelatorioGerencial,
  RelatorioTimesheet,
  UsuarioXprojeto,
  FuncionarioProjetoHoras,
  FuncionariosDespesaPessoalEconomica,
  Aprovacao,
  Conciliacao,
  RelatorioConciliacao,
  DashboardGerencial,
  PainelIndicadores,
  RelatorioAvaliacao,
  CapitalGiro,
  RelatorioCapitalGiro,
  PlanoDeContas,
  GraficoProjetos,
  Avaliacao,
  Treinamento,
  Avaliacoes,
  Questoes,
  GruposUsuarios,
  RelatorioDespesasDiretas,
  RelatorioAvaliacoes,
  RelatorioTreinamentos,
  RelatorioQuestoes,
  ConsolidadoDespesasPessoalFinanceiro
} from "./views";

import "react-table/react-table.css";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'font-awesome/css/font-awesome.min.css';
import RelatorioRateioDespEconomico from "./views/relatorios/rateio/RelatorioRateioDespEconomico";

class Main extends Component {

  componentWillMount() {
    this._startPollingForCheckboxChange();
    this._checkPreviouslyLoggedIn();
  }

  _startPollingForCheckboxChange = () => {
    this.pollingInterval = setInterval(async () => {
      try {
        const response = await fetch(
          `${AppState.serverAddr}/config/GetValueInvoiceCurrent.php`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Authorization: AppState.token,
            },
          }
        );
        const result = await response.json();
        const { data } = result

        if (data !== localStorage.getItem('noInvoiceCurrent')) {
          this._userLogout();
          clearInterval(this.pollingInterval);
        }
      } catch (error) {
        console.log("Erro no polling:", error);
      }
    }, 5000);
  };

  _checkPreviouslyLoggedIn = () => {
    const token = localStorage.getItem("token");
    let userInfo = {};
    try {
      userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } catch (e) { }

    if (token && userInfo) {
      AppState.isLoggedIn = true;
      AppState.token = token;
      AppState.empresa = userInfo.empresa;
      AppState.idPerfil = userInfo.idPerfil;
      AppState.userInfo = userInfo;
    } else {
      this._userLogout();
    }
  };

  _getUserInfo = async () => {
    AppState.isLoading = true;
    await fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/perfil/GetProfile.php`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: AppState.token
        }
      }
    )
      .then(async response => {
        console.log("(inspect) response", response);
        if (response.status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          return;
        }

        return await response.json();
      })
      .then(response => {
        AppState.isLoading = false;
        AppState.userInfo = response.request[0];
        localStorage.setItem(
          "userInfo",
          JSON.stringify(response.request[0]),
          () => {
            window.events.emit("LoggedIn");
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        console.log(error);
      });
  };

  _userLogout = () => {
    AppState.isLoggedIn = false;
    localStorage.clear();
    browserHistory.push(`${process.env.PUBLIC_URL}/login`);
  };

  _requireLoggedIn = () => {
    if (!AppState.isLoggedIn) {
      browserHistory.push(`${process.env.PUBLIC_URL}/login`);
    }
  };

  _requireNotLoggedIn = () => {
    if (AppState.isLoggedIn) {
      browserHistory.push(`${process.env.PUBLIC_URL}/timesheets`);
    }
  };



  render() {
    return (
      <Router history={browserHistory} state={AppState}>
        <Route
          path={`${process.env.PUBLIC_URL}/`}
          component={App}
          onEnter={this._requireLoggedIn}
        >
          <IndexRoute component={Timesheets} onEnter={this._requireLoggedIn} />
          <Route
            path={`${process.env.PUBLIC_URL}/projetos`}
            component={Projetos}
            onEnter={this._requireLoggedIn}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/relatorio/despesas-pessoal-financeiro`}
            component={ConsolidadoDespesasPessoalFinanceiro}
            onEnter={this._requireLoggedIn}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/clientes`}
            component={Clientes}
            onEnter={this._requireLoggedIn}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/timesheets`}
            component={Timesheets}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/timesheets`}
            component={Timesheets}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/fornecedores`}
            component={CadastroFornecedor}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/fornecedores`}
            component={CadastroFornecedor}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio_fornecedores`}
            component={RelatorioAvaliacao}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/funcionarios`}
            component={Funcionarios}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/despesa-pessoal-economica`}
            component={FuncionariosDespesaPessoalEconomica}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/perfil`}
            component={Perfil}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/logout`}
            onEnter={this._userLogout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/timesheets`}
            component={Timesheets}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/titulos`}
            component={Titulos}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pagamentos`}
            component={Pagamentos}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/aprovacoes`}
            component={Aprovacao}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/conciliacao`}
            component={Conciliacao}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/capital_giro`}
            component={CapitalGiro}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/despesas_diretas`}
            component={RelatorioDespesasDiretas}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/usuarios`}
            component={Usuarios}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/plano_de_contas`}
            component={PlanoDeContas}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contratos`}
            component={ContratoFornecedor}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/timesheets`}
            component={RelatorioTimesheet}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/gerenciais`}
            component={RelatorioGerencial}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/administracao`}
            component={UsuarioXprojeto}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/rateio-despesa-economico`}
            component={RelatorioRateioDespEconomico}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/funcionario-projeto-horas`}
            component={FuncionarioProjetoHoras}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/relatorio-conciliacao`}
            component={RelatorioConciliacao}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/dashboard-gerencial`}
            component={DashboardGerencial}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/painel-indicadores`}
            component={PainelIndicadores}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/graficos-projetos`}
            component={GraficoProjetos}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/relatorio/relatorio-capital`}
            component={RelatorioCapitalGiro}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/treinamentos/avaliacao`}
            component={Avaliacao}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/treinamentos/treinamento`}
            component={Treinamento}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/treinamentos/admin/avaliacoes`}
            component={Avaliacoes}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/treinamentos/admin/questoes`}
            component={Questoes}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/treinamentos/admin/usuarios`}
            component={GruposUsuarios}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/treinamentos/relatorios/avaliacoes`}
            component={RelatorioAvaliacoes}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/treinamentos/relatorios/treinamentos`}
            component={RelatorioTreinamentos}
            onEnter={this._requireLoggedIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/treinamentos/relatorios/questoes`}
            component={RelatorioQuestoes}
            onEnter={this._requireLoggedIn}
          />
        </Route>
        <Route
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
          onEnter={this._requireNotLoggedIn}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/*`}
          component={Login}
          onEnter={this._requireNotLoggedIn}
        />
      </Router>
    );
  }
}

export default observer(Main);
