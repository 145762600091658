const Menu_structure = {
  menus: {
    base: "nav.navbar:eq(0)",
    items: [
      {
        title: "Timesheet",
        view: "timesheet",
        action: "/timesheets",
        act: "show:timesheet",
        items: [],
        treinamentos: false
      },
      {
        title: "Funcionário",
        action: null,
        treinamentos: false,
        items: [
          {
            view: "funcionarios_cadastro",
            title: "cadastro",
            action: "/funcionarios"
          }
        ]
      },
      {
        title: "Fornecedor",
        action: null,
        treinamentos: false,
        act: "generic",
        items: [
          {
            view: "fornecedores_cadastro",
            title: "cadastro",
            action: "/fornecedores"
          },
          {
            view: "fornecedores_contrato",
            title: "contrato",
            action: "/contratos"
          },
          {
            view: "fornecedores_buscar",
            title: "buscar",
            action: "/create/contract/provider"
          },
          {
            view: "relatorio_avaliacoes",
            title: "relatorio",
            action: "/relatorio_fornecedores"
          }
        ]
      },
      {
        title: "Sistema",
        action: null,
        treinamentos: false,
        act: "generic",
        items: [
          {
            view: "usuario",
            title: "usuário",
            action: "/usuarios"
          },
          {
            view: "plano_de_contas",
            title: "Plano de Contas",
            action: "/plano_de_contas"
          }
        ]
      },
      {
        title: "Projeto",
        action: null,
        treinamentos: false,
        act: "generic",
        items: [
          {
            view: "cadastrar_projeto",
            title: "cadastrar projeto",
            action: "/projetos"
          },
          {
            view: "cadastrar_cliente",
            title: "cadastrar cliente",
            action: "/clientes"
          }
        ]
      },
      {
        title: "Financeiro",
        action: null,
        treinamentos: false,
        act: "generic",
        items: [
          {
            view: "lancamento_titulo",
            title: "título",
            action: "/titulos"
          },
          {
            view: "lancamento_pgto_receb",
            title: "pagto/recebimento",
            action: "/pagamentos"
          },
          {
            view: "funcionarios_despesa_pessoal_economico",
            title: "Despesa Pessoal Econômica",
            action: "/despesa-pessoal-economica"
          },
          {
            view: "aprovacoes",
            title: "aprovações",
            action: "/aprovacoes"
          },
          {
            view: "conciliacao",
            title: "conciliação",
            action: "/conciliacao"
          },
          {
            view: "capital_giro",
            title: "capital de giro",
            action: "/capital_giro"
          },
          {
            view: "despesas_diretas",
            title: "Despesas Diretas",
            action: "/despesas_diretas"
          }
        ]
      },
      {
        title: "Relatório",
        action: null,
        treinamentos: false,
        items: [
          {
            view: "relatorio_timesheet",
            title: "timesheet",
            action: "/relatorio/timesheets"
          },
          {
            view: "relatorio_gerencial",
            title: "gerencial",
            action: "/relatorio/gerenciais"
          },
          {
            view: "usuarioxprojeto",
            title: "usuario x projeto",
            action: "/relatorio/administracao"
          },
          {
            view: "relatorio_funcionario_projeto_horas",
            title: "Apontamento de Horas de funcionário x projeto",
            action: "/relatorio/funcionario-projeto-horas"
          },
          {
            view: "relatorio_rateio_desp_economico",
            title: "Rateio Desp. Econômico",
            action: "/relatorio/rateio-despesa-economico"
          },
          {
            view: "relatorio_conciliacao",
            title: "Relatório de Conciliação",
            action: "/relatorio/relatorio-conciliacao"
          },
          {
            view: "dashboard",
            title: "Painel Gerencial",
            action: "/relatorio/dashboard-gerencial"
          },
          {
            view: "indicadores",
            title: "Painel Indicadores",
            action: "/relatorio/painel-indicadores"
          },
          {
            view: "relatorio_capital",
            title: "Relatorio Capital de Giro",
            action: "/relatorio/relatorio-capital"
          },{
            view: "graficos_projetos",
            title: "Gráficos por Projeto",
            action: "/relatorio/graficos-projetos"
          },{
            view: "relatorio_financeiro_pessoal",
            title: "Relatório financeiro de encargos e benefícios",
            action: "/relatorio/despesas-pessoal-financeiro"
          }
          ,{
            view: "consolidado_pessoal_financeiro",
            title: "Consolidado financeiro de encargos e benefícios",
            action: "relatorio/despesas-pessoal-financeiro"
          }
        ]
      },
      {
        title: "Treinamento",
        view: "treinamento",
        act: "show:treinamento",
        action: "/treinamentos/treinamento",
        treinamentos: true,
        items: [],
      },
      {
        title: "Avaliação",
        view: "avaliacao",
        act: "show:avaliacao",
        action: "/treinamentos/avaliacao",
        treinamentos: true,
        items: [],
      },
      {
        title: "Admin",
        action: null,
        treinamentos: true,
        items: [
          {
            view: "admin_grupos",
            title: "grupos_usuarios",
            action: "/treinamentos/admin/usuarios"
          },
          {
            view: "admin_questoes",
            title: "questoes",
            action: "/treinamentos/admin/questoes"
          },
          {
            view: "admin_avaliacao",
            title: "avaliacao",
            action: "/treinamentos/admin/avaliacoes"
          }
        ]
      },
      {
        title: "Relatórios",
        action: null,
        treinamentos: true,
        items: [
          {
            view: "relatorio_questoes",
            title: "relatorio_questoes",
            action: "/treinamentos/relatorios/questoes"
          },
          {
            view: "relatorio_treinamentos",
            title: "relatorio_treinamentos",
            action: "/treinamentos/relatorios/treinamentos"
          },
          {
            view: "relatorio_avaliacoes_treinamentos",
            title: "relatorio_avaliacoes",
            action: "/treinamentos/relatorios/avaliacoes"
          }
        ]
      }
    ]
  }
};

export default Menu_structure;
