import React from 'react';
import AppState from '../../../state';
import moment from 'moment';
import { Utils } from '../../../services';

import { get_indicators_data } from '../../../api/dashboard';
import Tooltip from '../../../components/lib/Tooltip';

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core/';

import {
  VictoryTooltip,
  VictoryLine,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLegend,
  VictoryGroup,
  VictoryTheme,
  VictoryVoronoiContainer,
} from 'victory';

const monthNames = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

const indicatorNames = {
  mcAc: 'MC/A&C(%)',
  tddReceita: 'TDD/REC(%)',
  acReceita: 'A&C/REC(%)',
  itReceita: 'I&T/REC(%)',
  tdiReceita: 'TDI/REC(%)',
  resReceita: 'RES/REC(%)',
  acTdd: 'A&C/TDD(%)',
  itTdd: 'I&T/TDD(%)',
  resTdd: 'RES/TDD(%)',
  receitaTdd: 'BDI(%)',
  tdiTdd: 'BDI E(%)',
};

class PainelIndicadores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analysis: 'E&F',
      animation: 500,
      ef_financeiro: [],
      ef_economico: [],
      ef_financeiro_acum: [],
      ef_economico_acum: [],
      main_color: '#528e70',
      secondary_color: 'gold',
      dt_inicial: moment().subtract(12, 'months').format('YYYY-MM'),
      dt_final: moment().subtract(1, 'months').format('YYYY-MM'),
      chart_filter: ['Financeiro', 'Economico'],
    };
  }

  componentDidMount() {
    this.getData();
  }

  calculateBarOffset = () => {
    // Testando vi que para 12 meses o offset de 5 funciona bem e para 5 meses, 15 funciona bem
    // Tracei uma reta entre esses pontos e abaixo retorno o resultado dessa equação
    if (this.state.ef_financeiro.length == 0) {
      return 5;
    } else {
      return (-10 * this.state.ef_financeiro.length + 155) / 7;
    }
  };

  getData = async () => {
    AppState.isLoading = true;
    try {
      console.time('Extração de Dados');
      let res = await get_indicators_data(
        this.state.dt_inicial,
        this.state.dt_final,
        this.state.analysis == 'indicadores_acum' ? true : false,
        this.state.analysis.includes('indicadores')
          ? 'detalhado'
          : 'consolidado'
      );
      console.timeEnd('Extração de Dados');
      console.log(res);
      this.setState(
        {
          ef_financeiro: res.financeiro,
          ef_financeiro_acum:
            this.state.analysis == 'indicadores_acum'
              ? []
              : this.acumulate(res.financeiro),
          ef_economico: res.economico,
          ef_economico_acum:
            this.state.analysis == 'indicadores_acum'
              ? []
              : this.acumulate(res.economico),
        },
        () => {
          AppState.isLoading = false;
        }
      );
    } catch (err) {
      alert('Erro na atualização dos dados');
      console.log(err);
      AppState.isLoading = false;
    }
  };

  acumulate = (data) => {
    let accum_data = [];
    data.reduce((acum, val) => {
      accum_data.push({
        tdi: (acum.tdi || 0) + val.tdi,
        tdd: (acum.tdd || 0) + val.tdd,
        despesa: (acum.despesa || 0) + val.despesa,
        receita: (acum.receita || 0) + val.receita,
        resultado: (acum.resultado || 0) + val.resultado,
        saldo_periodo: (acum.saldo_periodo || 0) + val.saldo_periodo,
        dt_inicio: val.dt_inicio,
        dt_fim: val.dt_fim,
      });
      return {
        tdi: (acum.tdi || 0) + val.tdi,
        tdd: (acum.tdd || 0) + val.tdd,
        despesa: (acum.despesa || 0) + val.despesa,
        receita: (acum.receita || 0) + val.receita,
        resultado: (acum.resultado || 0) + val.resultado,
        saldo_periodo: (acum.saldo_periodo || 0) + val.saldo_periodo,
        dt_inicio: val.dt_inicio,
        dt_fim: val.dt_fim,
      };
    }, {});
    return accum_data;
  };

  typeHandleChange = (event) => {
    this.setState(
      {
        analysis: event.target.value,
        ef_financeiro: [],
        ef_economico: [],
        ef_financeiro_acum: [],
        ef_economico_acum: [],
      },
      () => {
        this.getData();
      }
    );
  };

  dateHandleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        ef_financeiro: [],
        ef_economico: [],
        ef_financeiro_acum: [],
        ef_economico_acum: [],
      },
      () => {
        this.getData();
      }
    );
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  renderBarChart = (target, type = 'fin') => {
    return (
      <Grid item sm={3} style={{ textAlign: 'center' }}>
        <h3>
          {indicatorNames[target]
            ? indicatorNames[target]
            : this.capitalize(target)}{' '}
          no mês
        </h3>
        <VictoryChart
          animate={{ duration: this.state.animation }}
          domainPadding={{ x: [20, 20] }}
          padding={{ left: 50, right: 20, top: 30, bottom: 30 }}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryVoronoiContainer
              labelComponent={<VictoryTooltip flyoutComponent={<Tooltip />} />}
              labels={() => ' '}
            />
          }
        >
          <VictoryGroup offset={this.calculateBarOffset()}>
            {this.state.chart_filter.includes('Financeiro') && (
              <VictoryBar
                data={this.state.ef_financeiro.map((val) => {
                  return {
                    x: moment(val.dt_fim).format('MM-YYYY'),
                    y: val[target],
                    yLabel:
                      type == 'percent'
                        ? `${Math.round(val[target])}%`
                        : `R$${Utils.float2moeda(val[target]).slice(0, -3)}`,
                    subtitle:
                      monthNames[
                        parseInt(moment(val.dt_fim).format('MM')) - 1
                      ] +
                      moment(val.dt_fim).format('/YYYY') +
                      '\n Financeiro',
                  };
                })}
                style={{ data: { fill: this.state.main_color } }}
                labelComponent={
                  <VictoryTooltip flyoutComponent={<Tooltip />} />
                }
                labels={() => ' '}
              />
            )}
            {this.state.chart_filter.includes('Financeiro') && (
              <VictoryLine
                data={this.state.ef_financeiro.map((val) => {
                  let avg =
                    this.state.ef_financeiro.reduce(
                      (acum, value) => acum + value[target],
                      0
                    ) / this.state.ef_financeiro.length;
                  return {
                    x: moment(val.dt_fim).format('MM-YYYY'),
                    y: avg,
                    yLabel:
                      type == 'percent'
                        ? `${Math.round(avg)}%`
                        : `R$${Utils.float2moeda(avg).slice(0, -3)}`,
                    subtitle: 'Média Financeiro',
                  };
                })}
                style={{
                  data: {
                    stroke: this.state.main_color,
                    strokeDasharray: '4, 8',
                  },
                }}
                labelComponent={
                  <VictoryTooltip flyoutComponent={<Tooltip />} />
                }
                labels={() => ' '}
              />
            )}
            {this.state.chart_filter.includes('Economico') && (
              <VictoryBar
                data={this.state.ef_economico.map((val) => {
                  return {
                    x: moment(val.dt_fim).format('MM-YYYY'),
                    y: val[target],
                    yLabel:
                      type == 'percent'
                        ? `${Math.round(val[target])}%`
                        : `R$${Utils.float2moeda(val[target]).slice(0, -3)}`,
                    subtitle:
                      monthNames[
                        parseInt(moment(val.dt_fim).format('MM')) - 1
                      ] +
                      moment(val.dt_fim).format('/YYYY') +
                      '\n Economico',
                  };
                })}
                style={{ data: { fill: this.state.secondary_color } }}
                labelComponent={
                  <VictoryTooltip flyoutComponent={<Tooltip />} />
                }
                labels={() => ' '}
              />
            )}
            {this.state.chart_filter.includes('Economico') && (
              <VictoryLine
                data={this.state.ef_economico.map((val) => {
                  let avg =
                    this.state.ef_economico.reduce(
                      (acum, value) => acum + value[target],
                      0
                    ) / this.state.ef_economico.length;
                  return {
                    x: moment(val.dt_fim).format('MM-YYYY'),
                    y: avg,
                    yLabel:
                      type == 'percent'
                        ? `${Math.round(avg)}%`
                        : `R$${Utils.float2moeda(avg).slice(0, -3)}`,
                    subtitle: 'Média Economico',
                  };
                })}
                style={{
                  data: {
                    stroke: this.state.secondary_color,
                    strokeDasharray: '4, 8',
                  },
                }}
                labelComponent={
                  <VictoryTooltip flyoutComponent={<Tooltip />} />
                }
                labels={() => ' '}
              />
            )}
          </VictoryGroup>
          {this.renderLegend()}
          <VictoryAxis
            dependentAxis
            tickFormat={(tick) =>
              type == 'percent'
                ? `${tick}%`
                : `${Math.round(tick / 100000) / 10}MM`
            }
          />
          <VictoryAxis
            style={{
              tickLabels: { padding: 30, angle: 90 },
            }}
          />
        </VictoryChart>
      </Grid>
    );
  };

  renderLineChart = (target, data_fin, data_eco, type = 'fin') => {
    return (
      <Grid item sm={3} style={{ textAlign: 'center' }}>
        <h3>
          {indicatorNames[target]
            ? indicatorNames[target]
            : this.capitalize(target)}{' '}
          até o mês
        </h3>
        <VictoryChart
          animate={{ duration: this.state.animation }}
          domainPadding={{ x: [20, 20] }}
          padding={{ left: 50, right: 20, top: 30, bottom: 30 }}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryVoronoiContainer
              labelComponent={<VictoryTooltip flyoutComponent={<Tooltip />} />}
              labels={() => ' '}
            />
          }
        >
          {this.state.chart_filter.includes('Financeiro') && (
            <VictoryLine
              data={data_fin.map((val) => {
                return {
                  x: moment(val.dt_fim).format('MM-YYYY'),
                  y: val[target],
                  yLabel:
                    type == 'percent'
                      ? `${Math.round(val[target])}%`
                      : `R$${Utils.float2moeda(val[target]).slice(0, -3)}`,
                  subtitle:
                    monthNames[parseInt(moment(val.dt_fim).format('MM')) - 1] +
                    moment(val.dt_fim).format('/YYYY') +
                    '\n Financeiro Acumulado',
                };
              })}
              style={{ data: { stroke: this.state.main_color } }}
            />
          )}
          {this.state.chart_filter.includes('Economico') && (
            <VictoryLine
              data={data_eco.map((val) => {
                return {
                  x: moment(val.dt_fim).format('MM-YYYY'),
                  y: val[target],
                  yLabel:
                    type == 'percent'
                      ? `${Math.round(val[target])}%`
                      : `R$${Utils.float2moeda(val[target]).slice(0, -3)}`,
                  subtitle:
                    monthNames[parseInt(moment(val.dt_fim).format('MM')) - 1] +
                    moment(val.dt_fim).format('/YYYY') +
                    '\n Economico Acumulado',
                };
              })}
              style={{ data: { stroke: this.state.secondary_color } }}
            />
          )}
          {this.renderLegend()}
          <VictoryAxis
            dependentAxis
            tickFormat={(tick) =>
              type == 'percent'
                ? `${tick}%`
                : `${Math.round(tick / 100000) / 10}MM`
            }
          />
          <VictoryAxis
            style={{
              tickLabels: { padding: 30, angle: 90 },
            }}
          />
        </VictoryChart>
      </Grid>
    );
  };

  renderBothCharts = (
    target,
    data_fin,
    data_eco,
    data_fin_acum,
    data_eco_acum,
    type = 'fin'
  ) => {
    return (
      <Grid item sm={3} style={{ textAlign: 'center', marginBottom: '80px' }}>
        <h3>
          {indicatorNames[target]
            ? indicatorNames[target]
            : this.capitalize(target)}
        </h3>
        <VictoryChart
          animate={{ duration: this.state.animation }}
          domainPadding={{ x: [20, 20] }}
          padding={{ left: 50, right: 20, top: 30, bottom: 30 }}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryVoronoiContainer
              labelComponent={<VictoryTooltip flyoutComponent={<Tooltip />} />}
              labels={() => ' '}
            />
          }
        >
          {this.state.chart_filter.includes('Financeiro') && (
            <VictoryLine
              data={data_fin_acum.map((val) => {
                return {
                  x: moment(val.dt_fim).format('MM-YYYY'),
                  y: val[target],
                  yLabel:
                    type == 'percent'
                      ? `${Math.round(val[target])}%`
                      : `R$${Utils.float2moeda(val[target]).slice(0, -3)}`,
                  subtitle:
                    monthNames[parseInt(moment(val.dt_fim).format('MM')) - 1] +
                    moment(val.dt_fim).format('/YYYY') +
                    '\n Financeiro Acumulado',
                };
              })}
              style={{ data: { stroke: this.state.main_color } }}
            />
          )}
          {this.state.chart_filter.includes('Financeiro') && (
            <VictoryLine
              data={data_fin.map((val) => {
                let avg =
                  data_fin.reduce((acum, value) => acum + value[target], 0) /
                  data_fin.length;
                return {
                  x: moment(val.dt_fim).format('MM-YYYY'),
                  y: avg,
                  yLabel:
                    type == 'percent'
                      ? `${Math.round(avg)}%`
                      : `R$${Utils.float2moeda(avg).slice(0, -3)}`,
                  subtitle: 'Média Financeiro',
                };
              })}
              style={{
                data: {
                  stroke: this.state.main_color,
                  strokeDasharray: '4, 8',
                },
              }}
            />
          )}
          {this.state.chart_filter.includes('Economico') && (
            <VictoryLine
              data={data_eco_acum.map((val) => {
                return {
                  x: moment(val.dt_fim).format('MM-YYYY'),
                  y: val[target],
                  yLabel:
                    type == 'percent'
                      ? `${Math.round(val[target])}%`
                      : `R$${Utils.float2moeda(val[target]).slice(0, -3)}`,
                  subtitle:
                    monthNames[parseInt(moment(val.dt_fim).format('MM')) - 1] +
                    moment(val.dt_fim).format('/YYYY') +
                    '\n Economico Acumulado',
                };
              })}
              style={{ data: { stroke: this.state.secondary_color } }}
            />
          )}
          {this.state.chart_filter.includes('Economico') && (
            <VictoryLine
              data={data_eco_acum.map((val) => {
                let avg =
                  data_eco.reduce((acum, value) => acum + value[target], 0) /
                  data_eco.length;
                return {
                  x: moment(val.dt_fim).format('MM-YYYY'),
                  y: avg,
                  yLabel:
                    type == 'percent'
                      ? `${Math.round(avg)}%`
                      : `R$${Utils.float2moeda(avg).slice(0, -3)}`,
                  subtitle: 'Média Economico',
                };
              })}
              style={{
                data: {
                  stroke: this.state.secondary_color,
                  strokeDasharray: '4, 8',
                },
              }}
            />
          )}
          <VictoryGroup offset={this.calculateBarOffset()}>
            {this.state.chart_filter.includes('Financeiro') && (
              <VictoryBar
                data={data_fin.map((val) => {
                  return {
                    x: moment(val.dt_fim).format('MM-YYYY'),
                    y: val[target],
                    yLabel: `R$${Utils.float2moeda(val[target]).slice(0, -3)}`,
                    subtitle:
                      monthNames[
                        parseInt(moment(val.dt_fim).format('MM')) - 1
                      ] +
                      moment(val.dt_fim).format('/YYYY') +
                      '\n Financeiro',
                  };
                })}
                style={{ data: { fill: this.state.main_color } }}
              />
            )}
            {this.state.chart_filter.includes('Economico') && (
              <VictoryBar
                data={data_eco.map((val) => {
                  return {
                    x: moment(val.dt_fim).format('MM-YYYY'),
                    y: val[target],
                    yLabel: `R$${Utils.float2moeda(val[target]).slice(0, -3)}`,
                    subtitle:
                      monthNames[
                        parseInt(moment(val.dt_fim).format('MM')) - 1
                      ] +
                      moment(val.dt_fim).format('/YYYY') +
                      '\n Economico',
                  };
                })}
                style={{ data: { fill: this.state.secondary_color } }}
              />
            )}
          </VictoryGroup>
          {this.renderLegend()}
          <VictoryAxis
            dependentAxis
            tickFormat={(tick) =>
              type == 'percent'
                ? `${tick}%`
                : `${Math.round(tick / 100000) / 10}MM`
            }
          />
          <VictoryAxis
            style={{
              tickLabels: { padding: 30, angle: 90 },
            }}
          />
        </VictoryChart>
      </Grid>
    );
  };

  renderLegend = () => {
    return (
      <VictoryLegend
        orientation="horizontal"
        gutter={20}
        data={[{ name: 'Financeiro' }, { name: 'Economico' }]}
        colorScale={[this.state.main_color, this.state.secondary_color]}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onClick: () => {
                return [
                  {
                    target: 'data',
                    mutation: (props) => {
                      let option, new_filter;
                      option = props.datum.name;
                      if (this.state.chart_filter.includes(option)) {
                        new_filter = this.state.chart_filter.filter(
                          (item) => item !== option
                        );
                      } else {
                        new_filter = [...this.state.chart_filter, option];
                      }
                      this.setState({
                        chart_filter: new_filter,
                      });
                    },
                  },
                ];
              },
            },
          },
        ]}
      />
    );
  };

  render() {
    return (
      <Grid id={this.constructor.name} style={{ marginTop: '4em' }}>
        <Grid container alignItems={'center'}>
          <Grid item sm={5}>
            <h1>Painel Indicadores</h1>
          </Grid>
          <Grid container spacing={32} style={{ marginTop: '15px' }}>
            <Grid item>
              <FormControl>
                <InputLabel>Análise</InputLabel>
                <Select
                  style={{ marginBottom: '14px', minWidth: '156px' }}
                  name={'analysis'}
                  value={this.state.analysis}
                  onChange={this.typeHandleChange}
                >
                  <MenuItem key={0} value="E&F">
                    E&F Geral
                  </MenuItem>
                  <MenuItem key={1} value="indicadores_acum">
                    Indicadores até o mês
                  </MenuItem>
                  <MenuItem key={2} value="indicadores">
                    Indicadores no mês
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                component="fieldset"
                style={{ marginBottom: '14px', minWidth: '156px' }}
              >
                <TextField
                  style={{
                    margin: '0px 16px 8px 32px',
                  }}
                  label="Data Inicial"
                  type="month"
                  name="dt_inicial"
                  defaultValue={this.state.dt_inicial}
                  onChange={this.dateHandleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl
                component="fieldset"
                style={{ marginBottom: '14px', minWidth: '156px' }}
              >
                <TextField
                  style={{
                    margin: '0px 16px 8px 32px',
                  }}
                  label="Data Final"
                  type="month"
                  name="dt_final"
                  defaultValue={this.state.dt_final}
                  onChange={this.dateHandleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          {this.state.analysis == 'E&F' && (
            <Grid container>
              <Grid
                container
                justify="space-between"
                style={{ marginTop: '16px', marginBottom: '8px' }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderBothCharts(
                    'receita',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    this.state.ef_financeiro_acum,
                    this.state.ef_economico_acum
                  )}
                  {this.renderBothCharts(
                    'tdd',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    this.state.ef_financeiro_acum,
                    this.state.ef_economico_acum
                  )}
                  {this.renderBothCharts(
                    'tdi',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    this.state.ef_financeiro_acum,
                    this.state.ef_economico_acum
                  )}
                  {this.renderBothCharts(
                    'resultado',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    this.state.ef_financeiro_acum,
                    this.state.ef_economico_acum
                  )}
                </Grid>
              </Grid>

              {/* <Grid
                container
                justify="space-between"
                style={{ marginTop: "80px", marginBottom: "8px" }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderLineChart('receita', this.state.ef_financeiro_acum, this.state.ef_economico_acum)}
                  {this.renderLineChart('tdd', this.state.ef_financeiro_acum, this.state.ef_economico_acum)}
                  {this.renderLineChart('tdi', this.state.ef_financeiro_acum, this.state.ef_economico_acum)}
                  {this.renderLineChart('resultado', this.state.ef_financeiro_acum, this.state.ef_economico_acum)}
                </Grid>
              </Grid> */}
            </Grid>
          )}
          {this.state.analysis == 'indicadores' && (
            <Grid container>
              <Grid
                container
                justify="space-between"
                style={{ marginTop: '16px', marginBottom: '8px' }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderBarChart('mcAc', 'percent')}
                  {this.renderBarChart('tddReceita', 'percent')}
                  {this.renderBarChart('acReceita', 'percent')}
                </Grid>
              </Grid>

              <Grid
                container
                justify="space-between"
                style={{ marginTop: '80px', marginBottom: '8px' }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderBarChart('itReceita', 'percent')}
                  {this.renderBarChart('tdiReceita', 'percent')}
                  {this.renderBarChart('resReceita', 'percent')}
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-between"
                style={{ marginTop: '80px', marginBottom: '8px' }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderBarChart('acTdd', 'percent')}
                  {this.renderBarChart('itTdd', 'percent')}
                  {this.renderBarChart('resTdd', 'percent')}
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-between"
                style={{ marginTop: '80px', marginBottom: '8px' }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderBarChart('receitaTdd', 'percent')}
                  {this.renderBarChart('tdiTdd', 'percent')}
                </Grid>
              </Grid>
            </Grid>
          )}
          {this.state.analysis == 'indicadores_acum' && (
            <Grid container>
              <Grid
                container
                justify="space-between"
                style={{ marginTop: '16px', marginBottom: '8px' }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderLineChart(
                    'mcAc',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                  {this.renderLineChart(
                    'tddReceita',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                  {this.renderLineChart(
                    'acReceita',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                justify="space-between"
                style={{ marginTop: '80px', marginBottom: '8px' }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderLineChart(
                    'itReceita',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                  {this.renderLineChart(
                    'tdiReceita',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                  {this.renderLineChart(
                    'resReceita',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-between"
                style={{ marginTop: '80px', marginBottom: '8px' }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderLineChart(
                    'acTdd',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                  {this.renderLineChart(
                    'itTdd',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                  {this.renderLineChart(
                    'resTdd',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-between"
                style={{ marginTop: '80px', marginBottom: '8px' }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderLineChart(
                    'receitaTdd',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                  {this.renderLineChart(
                    'tdiTdd',
                    this.state.ef_financeiro,
                    this.state.ef_economico,
                    'percent'
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default PainelIndicadores;
