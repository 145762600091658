import React from 'react';
import ReactTable from 'react-table';
import AddIcon from '@material-ui/icons/Add';
import Settings from '@material-ui/icons/Settings';
import { Button, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import * as fornecedorAPI from '../../../api/fornecedores';
import AutoSelect from '../../../components/inputs/AutoSelect';
import StarRating from 'react-svg-star-rating';

import Form from './Form';
import Especializacoes from './Especializacao';
import AppState from '../../../state';
import { browserHistory } from 'react-router';

class CadastroFornecedor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      dialog_espec: false,
      fornecedorList: [],
      especialidadesList: [],
      filteredFornecedorList: [],
      especializacoesFilter: [],
    };
  }

  componentDidMount() {
    this.getTableData();
    this.getEspecialidades();
  }

  getEspecialidades = () => {
    AppState.isLoading = true;
    fornecedorAPI
      .getEspecialidades()
      .then((response) => {
        AppState.isLoading = false;

        this.setState({
          especialidadesList: response.request.map((item) => ({
            value: item.especialidade,
            label: item.especialidade,
          })),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getTableData = () => {
    AppState.isLoading = true;

    const status = {
      t: 'Ativo',
      f: 'Inativo',
    };

    const aprovacaoWorkflows = {
      aprovado: 'Aprovado',
      em_aprovacao: 'Em Aprovação',
      reprovado: 'Reprovado',
    };

    fetch(
      `${process.env.PUBLIC_URL}/${AppState.serverAddr}/fornecedores/GetFornecedores.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          fornecedorList: response.request.map((fornecedor) => ({
            ...fornecedor,
            dsc_status: status[fornecedor.status],
            dsc_aprovacao_workflow:
              aprovacaoWorkflows[fornecedor.aprovacao_workflow],
          })),
          filteredFornecedorList: response.request.map((fornecedor) => ({
            ...fornecedor,
            dsc_status: status[fornecedor.status],
            dsc_aprovacao_workflow:
              aprovacaoWorkflows[fornecedor.aprovacao_workflow],
          })),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  closeDialog = () => {
    this.setState({
      edicao: false,
      dialog: false,
      dialog_espec: false,
      fornecedor: '',
    });
  };

  openEdicaoDialog = (fornecedor) => {
    this.setState({
      edicao: true,
      dialog: true,
      fornecedor: fornecedor,
    });
  };

  canEditFornecedor = () => {
    var menu = JSON.parse(localStorage.getItem('Menu'));
    //    console.log(localStorage.getItem('Menu'));
    if (!menu || !menu.views) return false;
    var view = menu.views.find(
      (elem) => elem.id_view === 'fornecedores_cadastro'
    );
    if (!view) return false;
    var func = view.funcionalidades;
    if (!func) return false;
    if (func.find((elem) => elem.id_funcionalidade === 'add_fornecedor'))
      return true;
    else return false;
  };

  especializacaoFilterHandleChange = (especialidade) => {
    if (especialidade.length == 0) {
      this.setState({
        filteredFornecedorList: this.state.fornecedorList,
        especializacoesFilter: [],
      });
    } else {
      this.setState({
        filteredFornecedorList: this.state.fornecedorList.filter(
          (fornecedor) => {
            let result = false;
            especialidade.forEach((filterValue) => {
              if (fornecedor.especialidades.includes(filterValue.value)) {
                result = true;
              }
            });
            return result;
          }
        ),
        especializacoesFilter: especialidade,
      });
    }
  };

  render() {
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <h1>Cadastro de Fornecedor</h1>
        <Grid
          item
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginBottom: '16px',
            gap: '16px'
          }}
        >
          <div
            style={{
              display: 'inline-block',
              width: 600,
            }}
          >
            <AutoSelect
              suggestions={this.state.especialidadesList}
              placeholder="Especialidades"
              handleChange2={this.especializacaoFilterHandleChange}
              value={this.state.especializacoesFilter}
              multi={true}
            />
          </div>
          {this.canEditFornecedor() && (
            <Grid
              item
              style={{
                display: 'flex',
                gap: '24px',
                maxWidth: '400px',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  float: 'right',
                  marginLeft: '20px',
                }}
                onClick={() => this.setState({ dialog: true })}
              >
                <AddIcon />
                Novo Fornecedor
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  float: 'right',
                }}
                onClick={() => this.setState({ dialog_espec: true })}
              >
                <Settings />
                Especializações
              </Button>
            </Grid>
          )}
        </Grid>
        <ReactTable
          filterable={true}
          data={this.state.filteredFornecedorList}
          defaultPageSize={20}
          className="-striped -highlight"
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .startsWith(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: 'Fornecedores',
              columns: [
                {
                  Header: 'status',
                  accessor: 'dsc_status',
                  width: 100,
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{
                        paddingLeft: '0px',
                        textAlign: 'center',
                        color: row.original.status === 't' ? 'green' : 'red',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },

                {
                  Header: 'Workflow',
                  accessor: 'dsc_aprovacao_workflow',
                  width: 100,
                  Cell: (row) => (
                    <div
                      className="action-cell"
                      style={{
                        paddingLeft: '0px',
                        textAlign: 'center',
                        color:
                          row.original.aprovacao_workflow === 'aprovado'
                            ? 'green'
                            : row.original.aprovacao_workflow === 'em_aprovacao'
                            ? 'black'
                            : 'red',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },

                {
                  Header: 'CPF / CNPJ',
                  accessor: 'cpf_cnpj_fornecedor',
                  width: 140,
                },
                {
                  Header: 'Razão Social / Nome',
                  accessor: 'razao_social',
                  filterMethod: (filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                },
                {
                  Header: 'Tel. Comercial',
                  accessor: 'telefone_comercial',
                  width: 130,
                },
                {
                  Header: 'Email Comercial',
                  accessor: 'email',
                  width: 200,
                },
                {
                  Header: 'Avaliação',
                  accessor: 'avaliacao',
                  width: 130,
                  Cell: (row) => (
                    <StarRating
                      initialRating={row.original['avaliacao']}
                      isReadOnly
                      isHalfRating={true}
                      key={row.original['avaliacao']}
                      emptyColor={'#9ea6d8'}
                      size={'20px'}
                    />
                  ),
                },
                {
                  Header: 'ação',
                  accessor: 'action',

                  width: 50,
                  filterable: false,
                  show: () => this.canEditFornecedor(),
                  Cell: (row) => (
                    <div className="action-cell" style={{ paddingLeft: '0px' }}>
                      {row.original['em_aprovacao'] !== 't' && (
                        <EditIcon
                          onClick={() =>
                            this.openEdicaoDialog(
                              row.original['cpf_cnpj_fornecedor']
                            )
                          }
                          style={{
                            cursor: 'pointer',
                            width: '22px',
                            height: '22px',
                            minWidth: '22px',
                            minHeight: '22px',
                            marginLeft: '5px',
                          }}
                        />
                      )}
                    </div>
                  ),
                },
              ],
            },
          ]}
        />

        {this.state.dialog && (
          <Form
            open={this.state.dialog}
            close={this.closeDialog}
            fornecedor={this.state.fornecedor}
            edicao={this.state.edicao}
            carregaTabela={this.getTableData}
          />
        )}
        {this.state.dialog_espec && (
          <Especializacoes
            open={this.state.dialog_espec}
            close={this.closeDialog}
          />
        )}
      </div>
    );
  }
}

export default CadastroFornecedor;
