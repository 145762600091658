import React from 'react'
import {
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Icon,
    Paper
} from "@material-ui/core"
import AppState from "../../../state";
import {browserHistory} from "react-router";

class Quiz extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            titulo: '',
            id_treinamento: '',
            id_avaliacao: '',
            questoes: [],
            quizType: '',
            selectedAnswer: '',
            questionNumber: 0,
            longAnswer: '',
            correctAnswer: '',
            correctCounter: 0,
            finished: false
        }
    }

    componentDidMount() {
        AppState.isLoading = true;
        this.getQuestoes()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open != this.props.open && this.props.open) {
            this.setState({
                titulo: this.props.titulo,
                id_treinamento: this.props.id_treinamento,
                id_avaliacao: this.props.id_avaliacao,
                quizType: this.props.quizType
            },()=>{
                this.getQuestoes()
            })
        }
    }

    closeDialog = () =>{
        this.setState({
            titulo: '',
            id_treinamento: '',
            id_avaliacao: '',
            questoes: [],
            quizType: '',
            selectedAnswer: '',
            questionNumber: 0,
            longAnswer: '',
            correctAnswer: '',
            correctCounter: 0,
            finished: false
        })
        this.props.close()
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    getQuestoes = () => {
        AppState.isLoading = true;
        let url;
        if (this.state.quizType == 'treinamento') {
            url = `${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/treinamentos/GetTreinamentoQuestoes.php?json={"id_treinamento":${this.state.id_treinamento}}`;
        } else if (this.state.quizType == 'avaliacao') {
            url = `${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/avaliacoes/GetAvaliacaoQuestoes.php?json={"id_avaliacao":${this.state.id_avaliacao}}`;
        } else {
            return
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization':AppState.token,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                }
            })
            .then((response) => {
                this.setState({
                    titulo: response.request.nome,
                    questoes: response.request.questoes,
                    questionNumber: response.request.questoes.filter((val) => val.a_responder == 'f').length,
                    correctCounter: response.request.questoes.filter((val) => val.correta == 't').length
                }, () => { 
                    AppState.isLoading = false;
                });
                })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    if(ret == '40001') {
                        console.log("40001 - Erro desconhecido");
                    }
                    else
                        alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })

    }
    
    postRespostaQuestao = () => {
        AppState.isLoading = true
        let url;
        let data;
        if (this.state.quizType == 'treinamento') {
            url = `${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/treinamentos/AddRespostaTreinamentoOpcao.php`;
            data = {
                "id_treinamento":this.state.id_treinamento,
                "id_questao_opcao":this.state.selectedAnswer
            }
        } else if (this.state.quizType == 'avaliacao') {
            url = `${process.env.PUBLIC_URL}/${AppState.serverAddr}/modulo-treinamento/avaliacoes/AddRespostaAvaliacaoOpcao.php`;
            data = {
                "id_avaliacao_questao": this.state.questoes[this.state.questionNumber].id_avaliacao_questao,
                "id_questao_opcao": this.state.selectedAnswer
            }
        } else {
            return
        }
        console.log(url);
        fetch(url,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                    AppState.isLoading = false
                }
            })
            .then((response) => {
                AppState.isLoading = false;
                this.setState({
                    longAnswer: response.request[0].resposta,
                    correctAnswer: response.request[0].id_questao_opcao
                })
            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    render() {
        return (
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={this.props.open}
                onClose={this.props.close}
                disableBackdropClick={true}
            >
                <DialogContent style={{ height: "70rem" }}>
                    <Grid container alignItems='flex-start' style={{height:"100%"}}>
                        <Grid container >
                            <Grid container direction="row" justify="space-between" alignItems="flex-start">
                                <Typography variant="h4" style={{marginBottom:'10px', textTransform: "capitalize",}}>{this.state.quizType == 'avaliacao' ? 'Avaliação' : 'Treinamento'}: {this.state.titulo} ({this.state.questionNumber + 1}/{this.state.questoes.length})</Typography>
                                <Icon
                                    title={"fechar"}
                                    onClick={this.closeDialog}
                                >
                                    close
                                </Icon>
                            </Grid>

                            {this.state.questoes.length > 0 && (
                                <Grid
                                    container
                                    spacing={32}
                                >
                                    {!this.state.finished ? (
                                        <Grid 
                                            item 
                                        >
                                            <Grid item style={{paddingBottom: '2rem'}}>
                                                {this.state.correctAnswer &&
                                                <h3 style={{color: this.state.correctAnswer == this.state.selectedAnswer ? 'rgb(82, 142, 112)' : 'red'}}>
                                                    {this.state.selectedAnswer == this.state.correctAnswer ? "Resposta certa!" : "Resposta Errada.."}
                                                </h3>
                                                }
                                                <h3 style={{textAlign: 'center'}}>{this.state.questoes[this.state.questionNumber].id_questao} - {this.state.questoes[this.state.questionNumber].pergunta}</h3>
                                            </Grid>

                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    aria-label="questoes"
                                                    name="selectedAnswer"
                                                    row={true}
                                                    onChange={ (e) => this.handleChange(e)}
                                                >
                                                    {this.state.questoes[this.state.questionNumber].options.map((val, idx) => {
                                                        return (
                                                            <FormControlLabel 
                                                                value={val.id_questao_opcao}
                                                                key={idx}
                                                                control={<Radio color='primary'/>}
                                                                label={<Typography style={{color: this.state.correctAnswer == val.id_questao_opcao ? 'rgb(82, 142, 112)' : 'black', fontSize: 18}} >{val.opcao}</Typography>}
                                                                style={{width: "100%"}}
                                                                disabled={this.state.correctAnswer != ''}
                                                            />
                                                        )
                                                    })}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <Grid 
                                            container
                                            justify="center" 
                                            alignItems="center"
                                            direction="column"
                                            style={{marginTop: "5rem"}}
                                        >
                                            <Grid item>
                                                <h3>{(this.state.correctCounter / this.state.questoes.length) >= 0.5 ? "Parabéns, você finalizou" : "Você finalizou"}  {this.state.quizType == 'avaliacao' ? 'sua avaliação' : 'seu treinamento'}!</h3>
                                            </Grid>
                                            <Grid item style={{marginTop: "2rem", textAlign: "center"}}>
                                                <h4>Você acertou {this.state.correctCounter} de {this.state.questoes.length} questões!</h4>
                                                <h4>Acerto de {parseInt((this.state.correctCounter / this.state.questoes.length) * 100)}%</h4>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                            {this.state.longAnswer.length > 0 && (
                                <Paper>
                                    <div style={{padding: "2rem", marginTop: "2rem"}}>
                                        <h4>Resposta:</h4>
                                        {this.state.longAnswer}
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Grid container justify='center' style={{paddingBottom: "4rem"}}>
                        {this.state.finished ?
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.closeDialog}
                        >
                            Fechar
                        </Button>
                        :
                        this.state.correctAnswer == '' ?
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={ () => this.postRespostaQuestao()}
                        >
                            Responder
                        </Button>
                        :
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={ () => this.setState((prevState) => ({
                                correctAnswer: '',
                                selectedAnswer: '',
                                longAnswer: '',
                                questionNumber: prevState.questionNumber < prevState.questoes.length - 1 ? prevState.questionNumber + 1 : prevState.questionNumber,
                                correctCounter: prevState.correctCounter + (prevState.correctAnswer == prevState.selectedAnswer ? 1 : 0),
                                finished: prevState.questionNumber == prevState.questoes.length - 1 ? true : false
                            }), () => {
                                console.log(this.state.finished)
                            })}
                        >
                            Avançar
                        </Button>
                        }
                    </Grid>
                </DialogActions>
            </Dialog>)
    }   

}

export default Quiz;