import React from 'react';
import {
  FormControl,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Icon,
} from '@material-ui/core';
import Switch from 'react-switch';
import AppState from '../../../state';
import { browserHistory } from 'react-router';
import EditIcon from '@material-ui/icons/Edit';
import ReactTable from 'react-table';
import AddIcon from '@material-ui/icons/Add';

import * as fornecedorAPI from '../../../api/fornecedores';

class EspecializacaoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: true,
      edicao: false,
      especialidadeEditada: '',
      novaEspecializacao: '',
      especializacaoEditada: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.renderEditable = this.renderEditable.bind(this);
  }

  componentDidMount() {
    this.getEspecialidades();
  }

  hasAddPermission = () => {
    const permissions = JSON.parse(localStorage.getItem('Menu'));
    const lancamentoView = permissions.views
      .filter((view) => view.id_view === 'fornecedores_cadastro')
      .pop();

    const hasAddPermission = lancamentoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === 'add_especialidade';
      },
      false
    );

    return hasAddPermission;
  };

  hasEditPermission = () => {
    const permissions = JSON.parse(localStorage.getItem('Menu'));
    const lancamentoView = permissions.views
      .filter((view) => view.id_view === 'fornecedores_cadastro')
      .pop();

    const hasEditPermission = lancamentoView.funcionalidades.reduce(
      (acc, value) => {
        if (acc) return acc;
        return value.id_funcionalidade === 'edit_especialidade';
      },
      false
    );

    return hasEditPermission;
  };

  addEspecialidade = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      especialidade: this.state.novaEspecializacao,
    });

    let especialidadeData = new FormData();
    especialidadeData.append('json', data);

    fornecedorAPI
      .addEspecialidade(especialidadeData)
      .then(() => {
        this.setState(
          {
            novaEspecializacao: '',
          },
          () => {
            this.getEspecialidades();
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40003') {
            console.log('40003 - Especialidade já existente');
            alert('Já existe um especialidade cadastrado com esta descrição.');
          } else if (ret === '40004') {
            console.log(
              '40004 - Ocorreu um erro ao cadastrar esta especialidade'
            );
            alert('Ocorreu um erro ao cadastrar esta especialidade.');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  editEspecialidade = (especialidadeOriginal) => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      especialidade: especialidadeOriginal,
      nova_especialidade: this.state.especializacaoEditada,
    });

    if (
      especialidadeOriginal !== this.state.especializacaoEditada &&
      this.state.especializacaoEditada !== ''
    ) {
      let especialidadeData = new FormData();
      especialidadeData.append('json', data);

      fornecedorAPI
        .editEspecialidade(especialidadeData)
        .then(() => {
          this.setState(
            {
              especialidadeEditada: '',
            },
            () => {
              this.getEspecialidades();
            }
          );
        })
        .catch(function (error) {
          AppState.isLoading = false;
          if (
            error.toString() === '' ||
            error.toString().split(' ').length < 2
          ) {
            alert('Erro desconhecido, tente novamente');
            return;
          }
          var Err_status = error.toString().split(' ')[1];
          if (Err_status === '400') {
            var ret = error.response['status'].value.split(' ')[0];
            if (
              ret === '40001' ||
              ret === '40002' ||
              ret === '40003' ||
              ret === '40004' ||
              ret === '40005'
            ) {
              console.log('ERRO dados inválidos');
              alert('Dados inválidos');
            } else if (ret === '40006') {
              console.log('40006 - Erro ao atualizar especialidade');
              alert('Erro ao atualizar especialidade');
            } else {
              alert('Dados inválidos');
            }
          } else if (Err_status === '401') {
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          } else if (Err_status === '500') {
            alert('Erro desconhecido, tente novamente');
          }
        });
    } else {
      AppState.isLoading = false;
      this.setState({
        especialidadeEditada: '',
      });
    }
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  statusHandleChange(especialidade, value) {
    AppState.isLoading = true;

    const data = JSON.stringify({
      especialidade: especialidade,
      ativo: value === true ? 'true' : 'false',
    });

    let especialidadeData = new FormData();
    especialidadeData.append('json', data);

    fornecedorAPI
      .editEspecialidade(especialidadeData)
      .then(() => {
        this.setState(
          {
            especialidadeEditada: '',
          },
          () => {
            this.getEspecialidades();
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (
            ret === '40001' ||
            ret === '40002' ||
            ret === '40003' ||
            ret === '40004' ||
            ret === '40005'
          ) {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40006') {
            console.log('40006 - Erro ao atualizar especialidade');
            alert('Erro ao atualizar especialidade');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  }

  closeDialog = () => {
    this.props.close();
  };

  getEspecialidades = () => {
    AppState.isLoading = true;
    fornecedorAPI
      .getEspecialidades()
      .then((response) => {
        AppState.isLoading = false;

        this.setState({
          especialidadesList: response.request.map((item) => ({
            especialidade: item.especialidade,
            ativo: item.ativo === 't' ? true : false,
          })),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  renderEditable(cellInfo) {
    return (
      <div
        ref={(input) => input && input.focus()}
        contentEditable={
          this.state.especialidadeEditada ==
          this.state.especialidadesList[cellInfo.index][cellInfo.column.id]
            ? true
            : false
        }
        suppressContentEditableWarning
        style={{ height: 35 }}
        onInput={(e) =>
          this.setState({
            especializacaoEditada: e.currentTarget.textContent,
          })
        }
        dangerouslySetInnerHTML={{
          __html:
            this.state.especialidadesList[cellInfo.index][cellInfo.column.id],
        }}
      />
    );
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeDialog}
        disableBackdropClick={true}
        fullScreen
      >
        <DialogContent style={{ paddingTop: '16px' }}>
          <Grid
            container={true}
            alignItems={'flex-start'}
            justify={'space-around'}
            direction={'row'}
            spacing={24}
          >
            <Grid
              item
              sm={10}
              container
              direction="row"
              justify="space-between"
              style={{
                maxWidth: '100%',
                flexGrow: '1',
                flexBasis: 'auto',
                flexWrap: 'nowrap',
              }}
            >
              <Typography variant="h4">Gestão de Especializações</Typography>
              <Icon onClick={this.closeDialog}>close</Icon>
            </Grid>
            {this.hasAddPermission() && (
              <>
                <Grid
                  item
                  sm={10}
                  container
                  direction="row"
                  justify="space-between"
                  style={{
                    maxWidth: '100%',
                    flexGrow: '1',
                    flexBasis: 'auto',
                    flexWrap: 'wrap',
                    gap: '16px',
                  }}
                >
                  <FormControl style={{ width: '60%' }}>
                    <TextField
                      label={'Especialização'}
                      name={'novaEspecializacao'}
                      value={this.state.novaEspecializacao}
                      onChange={this.handleChange}
                      error={this.state.novaEspecializacaoErro}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      float: 'right',
                      marginLeft: '20px',
                    }}
                    onClick={() => this.addEspecialidade()}
                  >
                    <AddIcon />
                    Nova Especialização
                  </Button>
                </Grid>
              </>
            )}
            <ReactTable
              filterable={true}
              data={this.state.especialidadesList}
              defaultPageSize={10}
              className="-striped -highlight"
            //   style={{ height: '200px !important' }}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .startsWith(filter.value.toLowerCase())
              }
              columns={[
                {
                  columns: [
                    {
                      Header: 'Especialização',
                      accessor: 'especialidade',
                      Cell: this.renderEditable,
                    },
                    {
                      Header: 'ação',
                      accessor: 'action',
                      width: 100,
                      filterable: false,
                      Cell: (row) => (
                        <div
                          className="action-cell"
                          style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            transform: [{ scaleX: 0.1 }, { scaleY: 0.1 }],
                          }}
                        >
                          {this.state.especialidadeEditada == '' && (
                            <>
                              {this.hasEditPermission() && (
                                <>
                                  <EditIcon
                                    onClick={() =>
                                      this.setState({
                                        especialidadeEditada:
                                          row.original['especialidade'],
                                      })
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      width: '22px',
                                      height: '22px',
                                      minWidth: '22px',
                                      minHeight: '22px',
                                      verticalAlign: 'middle',
                                      // marginLeft: "5px"
                                    }}
                                  />
                                </>
                              )}
                              <Switch
                                className={'react-switch'}
                                height={15}
                                width={35}
                                uncheckedIcon={false}
                                disabled={!this.hasEditPermission()}
                                checkedIcon={false}
                                handleDiameter={20}
                                onHandleColor={'#3f51b5'}
                                offHandleColor={'#fafafa'}
                                offColor={'#9f9f9f'}
                                onColor={'#9ea6d8'}
                                value={
                                  this.state.especialidadesList[row.index][
                                    'ativo'
                                  ]
                                }
                                checked={
                                  this.state.especialidadesList[row.index][
                                    'ativo'
                                  ] === true
                                }
                                onChange={(e) =>
                                  this.statusHandleChange(
                                    row.original['especialidade'],
                                    e
                                  )
                                }
                              />
                            </>
                          )}
                          {this.state.especialidadeEditada !== '' &&
                            this.state.especialidadeEditada ===
                              this.state.especialidadesList[row.index][
                                'especialidade'
                              ] && (
                              <>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    this.editEspecialidade(
                                      row.original['especialidade']
                                    )
                                  }
                                >
                                  Salvar
                                </Button>
                              </>
                            )}
                        </div>
                      ),
                    },
                  ],
                },
              ]}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default EspecializacaoForm;
